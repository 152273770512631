import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { openNotification } from '../../../../services/firebase/notifications/notifications'
import { buttonTypes } from '../../../../utils/constants/constants'
import { useNavigate } from 'react-router'
import Avatar from '../../../core/Shared/Avatar'
import FollowButton from '../../../generic/Buttons/FollowButton/FollowButton'
import './SingleNotification.scss'
import { awardNotificationIcon, chatNotificationIcon, followingNotificationIcon, groupNotificationIcon, ideaNotificaionIcon, reactionNotificationIcon } from '../../../../assets/images/svgIcons/svgIcons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectSingleNotification } from '../../../../services/redux/reducers/notifications/notifications'
const SingleNotification = ({notificationId,setNewNotification=false}:any) => {

    const notification = useSelector((state:any)=>selectSingleNotification(state,notificationId)) 
    useEffect(() => {
      if(setNewNotification&&!notification.Read){
        setNewNotification(true);
      }
    }, [notification,setNewNotification])
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {Data} = notification
    const getNotificationElements = useCallback(() => {
          let El :{
            textEl:any,
            buttonEl:string|false,
            icon:any
          } = {
                textEl:<span className='notification-text'></span>,
                buttonEl:false,
                icon:false
            }
          switch(notification.Type){
            case "PostMention":
              El.icon = ideaNotificaionIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostMention')} <span className='notification-comment'>{Data?.Object?.Text}</span> <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostReaction":
              El.icon = reactionNotificationIcon   
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostReaction')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostComment":
              El.icon = ideaNotificaionIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostComment')} <span className='notification-comment'>{Data?.Object?.Text}</span> <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostCommentMention":
              El.icon = ideaNotificaionIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b>  {t('notifications.PostCommentMention')} <span className='notification-comment'>{Data?.Object?.Text}</span> <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span> </span>
              break;
            case "PostCommentReaction":
              El.icon = reactionNotificationIcon   
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostCommentReaction')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostCommentReply":
              El.icon = ideaNotificaionIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostCommentReply')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostCommentReplyMention":
              El.icon = ideaNotificaionIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostCommentReplyMention',{name:Data?.Target?.Author?.Name})} <span className='notification-comment'>{Data?.Object?.Text}</span> <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostCommentReplyReaction":
              El.icon = reactionNotificationIcon   
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostCommentReplyReaction',{name:Data?.Target?.Author?.Name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostShare":
              El.icon = ideaNotificaionIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostShare')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "PostAward":
              El.icon = awardNotificationIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.PostAward')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "NewFollower":
              El.icon = followingNotificationIcon   
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.NewFollower')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              El.buttonEl = Data?.Author?.Id
              break;
            case "Achievement":
              El.icon = awardNotificationIcon
              El.textEl = <span className='notification-text'> {t('notifications.Achievement')}<span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "DiscussionInvitation":
              let discussionWording
              switch (Data?.Object?.Type){
                case 'Group':
                  if(notification?.ObjectIds?.length>1){
                    discussionWording = t('notifications.DiscussionInvitation.multipleDiscussions',{length:notification?.ObjectIds?.length})
                  }
                  else discussionWording = t('notifications.DiscussionInvitation.singleGroup')
                  break;
                case 'Direct':
                  discussionWording = t('notifications.DiscussionInvitation.directMessage')
                  break;
              }
              El.icon = chatNotificationIcon   
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {discussionWording} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserGroupInvitation":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.UserGroupInvitation',{name:Data?.group_name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserGroupJoinRequest":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name??Data?.Author?.DisplayName}</b> {t('notifications.UserGroupJoinRequest',{name:Data?.group_name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserGroupRequestAccepted":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> <b>{Data?.group_name}</b> {t('notifications.UserGroupRequestAccepted')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserJoinsGroup":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name}</b> {t('notifications.UserJoinsGroup',{name:Data?.group_name})}<span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserGroupSubscriptionConfirmed":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> <b>{Data?.Author?.Name}</b> {t('notifications.UserGroupSubscriptionConfirmed',{name:Data?.group_name})}<span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UpcomingUserGroupSubscriptionFailed":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> {t('notifications.UpcomingUserGroupSubscriptionFailed',{name:Data?.group_name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UpcomingUserGroupSubscription":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> {t('notifications.UpcomingUserGroupSubscription',{name:Data?.group_name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserGroupNotEnoughBalance":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> {t('notifications.UserGroupNotEnoughBalance',{name:Data?.group_name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            case "UserGroupSubscriptionRenewed":
              El.icon = groupNotificationIcon
              El.textEl = <span className='notification-text'> {t('notifications.UserGroupSubscriptionRenewed',{name:Data?.group_name})} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
            default:
              El.textEl = <span className='notification-text nothandled'> {t('notifications.notHandledNotification')} <span className='notification-timestamp'>{moment.unix(notification.Date.seconds).fromNow(true)}</span></span>
              break;
          }
          return El
        }
        ,
        [Data?.Author?.Id,Data?.Author?.DisplayName,t, Data?.Author?.Name, Data?.Object?.Text, Data?.Object?.Type, Data?.Target?.Author?.Name, Data?.group_name, notification.Date.seconds, notification?.ObjectIds?.length,notification.Type],
    )
    const notificationElements = getNotificationElements()

    return (
        <span onClick={()=>openNotification(notification,navigate)} className='single-notification'>
            <span className='notification-left'>
              <span className='notification-picture-container'>
                  <Avatar width={"42px"} height={"42px"} id={Data?.Author?.Id}/>
                  {notificationElements.icon}
              </span>
              {notificationElements.textEl}
            </span>
            <span className='notification-right'>
              {notificationElements.buttonEl&&<FollowButton buttonVersion={buttonTypes.notification} targetUserId={notificationElements?.buttonEl}/>}
              {!notification?.Read&&<span className='new-post-indicator'></span>}
            </span>
        </span>
    )
}

export default SingleNotification