import StatusAsync from "../../../../utils/StatusAsync";
import {createSelector} from "reselect";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    status: StatusAsync.IDLE,
    data: {showCoinsPopup:false },
    error: null,
};

export const selectDataGeneral = (state: any) => state.general.data;
export const selectShowCoinsPopup = createSelector([selectDataGeneral], (data) => (data.showCoinsPopup));

export const generalReducer = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setShowCoinsPopup: (state, action) => {
            state.data.showCoinsPopup = action.payload;
        }
    }
});


export const { setShowCoinsPopup } = generalReducer.actions;
export default generalReducer.reducer;