import {arrayUnion, doc} from "firebase/firestore";
import {db} from "../auth/auth";
import {collections} from "../../../utils/constants/firebase";
import {arrayRemove, updateDoc} from "@firebase/firestore";

export const savePost = async (userId: string, postId: string) => {
    const userDocRef = doc(db, collections.userProtected, userId);
    return updateDoc(userDocRef, {
        'FavoriteIdeas': arrayUnion(postId),
    });
}

export const unSavePost = async (userId: string, postId: string) => {
    const userDocRef = doc(db, collections.userProtected, userId);
    return updateDoc(userDocRef, {
        'FavoriteIdeas': arrayRemove(postId),
    });
}

