import React, {FC, useMemo} from 'react'
import {Col, Row} from '../../../../core/Container'
import {dotIcon} from '../../../../../assets/images/svgIcons/svgIcons'
import {IAuthorProps} from '../../../../../types/user.types'
import {Link} from "react-router-dom";
import Avatar from '../../../../core/Shared/Avatar'
import './Author.scss'
import moment from 'moment'
import {groupPagePath, postPagePath, profilePagePath} from "../../../../../routes/Routes";
import {images} from "../../../../../assets/images/images";


const AuthorIdea: FC<IAuthorProps> = ({authorData, dateIdea, isMessage, groupId, groupData, fullPostId}) => {


    const postLink = useMemo(() => fullPostId ? `/${postPagePath}/${fullPostId ?? ""}` : '', [fullPostId]);

    const groupPost = useMemo(() => {
        return (
            <Row className='author jcfs'>
                <Avatar groupId={groupId} url={groupData?.Image ?? images.defaultGroupImage}/>
                <Col className='a-infos aifs'>
                    <Link className='text-btn' to={`/${groupPagePath}/${groupId}`}>
                        <h4>{groupData?.Title}</h4>
                    </Link>
                    <Row className='a-details'>
                        {authorData?.Name &&
                            <>
                                <Link className='text-btn' to={`/${profilePagePath}/${authorData?.Id}`}>
                                    <h5>{authorData?.Name} | {authorData?.Job}</h5>
                                </Link>
                                {dotIcon}
                            </>}
                        <Link to={postLink}>
                            <h5>{dateIdea && moment(new Date(dateIdea)).fromNow()}</h5>
                        </Link>
                    </Row>
                </Col>
            </Row>
        )
    }, [authorData?.Id, authorData?.Job, authorData?.Name, dateIdea, groupData?.Image, groupData?.Title, groupId, postLink]);

    const userPost = useMemo(() => {

        return (
            <Row className='author jcfs'>
                <Link className='text-btn' to={`/${profilePagePath}/${authorData?.Id}`}>
                    {<Avatar id={authorData?.Id}/>}
                </Link>
                <Col className='a-infos aifs'>
                    <Link className='text-btn' to={`/${profilePagePath}/${authorData?.Id}`}>
                        {authorData?.Name && <h4 className={isMessage ? 'preview-name' : ''}>{authorData?.Name}</h4>}
                    </Link>
                    <Row className='a-details'>
                        {authorData?.Job && <> <h5>{authorData?.Job} </h5>{dotIcon} </>}
                        <Link to={postLink}>
                            <h5>{dateIdea && moment(new Date(dateIdea)).fromNow()}</h5>
                        </Link>
                    </Row>
                </Col>
            </Row>
        )
    }, [authorData?.Id, authorData?.Job, authorData?.Name, dateIdea, isMessage, postLink]);


    return useMemo(() => {
        if (groupId) {
            return groupPost;
        } else {
            return userPost;
        }
    }, [groupId, groupPost, userPost]);
}

export default AuthorIdea