import { createSlice, createSelector } from "@reduxjs/toolkit";
import { IMessage } from "../../../../types/discussion.types";
import { chatType, listMode } from "../../../../utils/constants/discussions";
const initialState:any = {
    data: { 
        discussions:{}, 
        messages:{}, 
        chatView:false, 
        openDiscussion:false, 
        currentDiscussion:false
    },
    setting:{
        selected:false,
        discussion:false,
        view:false,
        showConfirmation:false,
        confirmationData:false,
        notificationActivated:null
    },
    error: null
}

export const selectDataDiscussion = (state: any) => state.discussion?.data;
export const selectDataId = (state:any, id: string) => id;
export const selectDataChatSetting = (state:any) => state.discussion?.setting;

export const selectDiscussions = createSelector([selectDataDiscussion], (data) => (data?.discussions));
export const selectMessages = createSelector([selectDataDiscussion,selectDataId],(data,id)=>(data?.messages[id]));
export const selectCurrentDiscussion = createSelector([selectDataDiscussion],(data)=>(data?.currentDiscussion));
export const selectSingleDiscussion = createSelector([selectDataDiscussion,selectDataId],(data,id)=>(data?.discussions[id]));
export const selectChatView = createSelector([selectDataDiscussion],(data)=>(data?.chatView));
export const selectOpenDiscussion = createSelector([selectDataDiscussion],(data)=>(data?.openDiscussion));
export const selectSettingName = createSelector([selectDataChatSetting],(data)=>(data?.selected));
export const selectSettingDiscussion = createSelector([selectDataChatSetting],(data)=>(data?.discussion));
export const selectSettingView = createSelector([selectDataChatSetting],(data)=>(data?.view));
export const selectSettingConfirmation = createSelector([selectDataChatSetting],(data)=>(data?.showConfirmation))
export const selectSettingConfirmData = createSelector([selectDataChatSetting],(data)=>(data?.confirmationData))
export const selectNotificationActivated = createSelector([selectDataChatSetting],(data)=>(data?.notificationActivated))
export const selectNewMessagesIndicator = createSelector([selectDataDiscussion],(data)=>(data?.newMessages))

export const discussionSlice = createSlice({
    name:"discussions",
    initialState,
    reducers:{
        setDiscussionData: (state,action) =>{
            let discState = state.data.discussions
            let payload = action?.payload
            state.data.discussions = {
                ...discState,
                [payload.discussionId]:{
                    Id: payload.discussionId,
                    ...payload.discussionData,
                },
            };
        },
        partiallyUpdateDiscussion:(state,action)=>{
            let discState = state.data.discussions
            const {discussionId, update} = action.payload
            state.data.discussions ={
                ...discState,
                [discussionId]:{
                    ...discState[discussionId],
                    Data:{
                        ...discState[discussionId].Data,
                        ...update
                    }
                }
            }
        },
        setInvitesData: (state,action) =>{
            state.data.invites = action?.payload.invites;
        },
        removeDiscussion: (state,action)=>{
            delete state.data.discussions[action.payload.discussionId]
        },
        setMessageData:(state,action)=>{
            const {updatedMessages} = action.payload
            if (!updatedMessages?.length) {
                return state;
            }
            updatedMessages.forEach((message:any) => {
                state.data.messages = {
                  ...state.data.messages,
                  [action.payload.discussionId]: {
                    ...state.data.messages[action.payload.discussionId],
                    [message.Id]: message,
                  },
                };
              });
        },
        setCurrentDiscussion:(state,action)=>{
            const {discussionId} = action.payload
            state.data.currentDiscussion = discussionId;
        }
        ,
        prependMessage:(state,action) =>{
            const {discussionId,messageId,messageData} = action.payload
            state.data.messages = {
                ...state.data.messages,
                [messageId]:{
                    Id: messageId,
                    ...messageData,
                    IsSending: true,
                }
            }
            const LastMessage = {
                Id: messageId,
                Date:messageData.Date,
                ...messageData.Data,
                IsSending: true,
            }
            state.data.discussions[discussionId].Data.LastMessageData = LastMessage
        },
        addMessages:(state,action)=>{
            const {discussionId, messages: addedMessages} = action.payload;

            if (!addedMessages?.length) {
                return state;
            }

            addedMessages.forEach((message:any) => {
                if (message.Id) {
                    state.data.messages[discussionId] = {
                    ...state.data.messages[discussionId],
                    [message.Id]: {
                    ...message,
                    },
                };
                }
            });
        },
        deleteMessage:(state,action) =>{
            const {messagesIds,discussionId} = action.payload
            if (!messagesIds.length) {
                return state;
              }
        
              let newState = {
                ...state.data.messages,
                [discussionId]: {
                  ...state.data.messages?.[discussionId],
                },
              };
        
              messagesIds.forEach((messageId:IMessage["Id"]) => {
                delete newState[discussionId][messageId];
              });

              state.data.messages = {
                ...newState,
                [discussionId]:{
                    ...newState[discussionId],
                }
              }
        },
        setChatView:(state)=>{
            state.data.chatView = !state.data.chatView
        },
        setOpenDiscussion:(state,action) =>{
            const {userId = false,type,discussionId = false,listMode = false}:{userId:string[]|false,type:chatType,discussionId:string|false,listMode:listMode|false} = action.payload
            if(!userId&&!discussionId&&!listMode){
                state.data.openDiscussion =  false
            }
            else{
                state.data.chatView = true;
                state.data.openDiscussion = {
                    userId:userId,
                    type:type,
                    discussionId:discussionId,
                    listMode:listMode
                };
            }
        },
        setSelectedChatSetting:(state,action) =>{
            const {name} = action.payload
            state.setting = {...state.setting,selected:name}
        },
        setDiscussionSetting:(state,action)=>{
            const {discussion} = action.payload
            state.setting = {...state.setting,discussion:discussion}
        },
        setSettingView:(state,action)=>{
            const {view} = action.payload
            state.setting = {...state.setting,view:view}
        },
        setConfirmationScreen:(state,action)=>{
            const {view} = action.payload
            state.setting = {...state.setting,showConfirmation:view}
        },
        setConfirmationData:(state,action)=>{
            const {data} = action.payload
            state.setting = {...state.setting,confirmationData:data}
        },
        settingReset:(state)=>{
            state.setting = initialState.setting
        },
        setNotificationActivated:(state,action)=>{
            const {muted} = action.payload
            
            state.setting.notificationActivated = muted;
        },
        setNewMessageIndicator:(state,action) =>{
            const {count} = action.payload
            state.data.newMessages = count
        }
    }
})


export const { 
                setDiscussionData, 
                setInvitesData, 
                removeDiscussion ,
                setMessageData, 
                prependMessage, 
                addMessages, 
                deleteMessage, 
                setChatView, 
                setOpenDiscussion, 
                setSelectedChatSetting, 
                setDiscussionSetting, 
                setSettingView, 
                partiallyUpdateDiscussion,
                setConfirmationData,
                setConfirmationScreen,
                settingReset,
                setCurrentDiscussion,
                setNotificationActivated,
                setNewMessageIndicator
            } = discussionSlice.actions;
export default discussionSlice.reducer
