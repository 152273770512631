import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container,Col } from '../../core/Container'
import {useAuthState} from "react-firebase-hooks/auth";
import { auth } from '../../../services/firebase/auth/auth'
import './SingleContact.scss'
import Avatar from '../../core/Shared/Avatar';
import { Button } from '../../core/Button';
import { useTranslation } from 'react-i18next';
import { MODE_DEBUG } from '../../../utils/constants/config';
import { IAuthor } from '../../../types/user.types';
import {ensureUser} from '../../../services/firebase/user/user';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers } from '../../../services/redux/reducers/users/users';
import { selectOpenDiscussion, setOpenDiscussion, settingReset } from '../../../services/redux/reducers/discussion/discussion';
import { getDiscussionName, shouldShowBadge } from '../../../services/firebase/discussions/discussions';


const SingleContact = ({discussion, select, selected, contact}:any) => {
    
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const users = useSelector(selectUsers);
    const openDiscussion = useSelector(selectOpenDiscussion)
    const [user] = useAuthState(auth);

    const [details, setdetails] = useState<IAuthor|null>();
    const [lastMessage, setLastMessage] = useState<any>()
    const [contactId, setContactId] = useState<IAuthor["Id"]>()
    const [data,setData] = useState<any>(discussion.Data)
    const [newMessage, setNewMessage] = useState<boolean>(false)


    useEffect(() => {
      if(selected && !openDiscussion.userId){
        contact(details)
      }
    }, [contact,openDiscussion,selected,details])

    useEffect(()=>{
      if(contactId && discussion.Type ==='Direct' && !details){
        setdetails({...users[contactId],Id:contactId})
      }
    },[users,details,discussion.Type,contactId])

    useEffect(()=>{
      setData(discussion.Data)
    },[discussion])

    useEffect(()=>{
      setNewMessage(shouldShowBadge(discussion,user?.uid));
    },[user?.uid, discussion])
    
    useEffect(() => {
      const { MembersIds, MembersData, InvitedMembersIds, IgnoringMembersIds, IgnoringInvitedMembersIds } = data
      
      switch (discussion.Type) {
        case "Direct":
          if(MembersIds.length === 2){
              setContactId(MembersIds.find((member:any)=>member !== user?.uid))
            if(!details){
                if (MembersData && contactId) {
                    setdetails({...MembersData[contactId],Id:contactId})
                    return
                }
                if (!MembersData && MODE_DEBUG) {
                  console.log("members data is empty");
              }
            }
          }
          if(MembersIds?.length === 1 && !contactId){
            if(MembersIds?.includes(user?.uid) ){
              if(InvitedMembersIds?.length>0){
                  setContactId(InvitedMembersIds[0])
              }
              else if(IgnoringMembersIds?.length>0){
                  setContactId(IgnoringMembersIds[0])
              }
              else if (IgnoringInvitedMembersIds?.length>0){
                  setContactId(IgnoringInvitedMembersIds[0])
              }
            }
            else{
                setContactId(MembersIds[0])
                contactId && setdetails({...MembersData[contactId],Id:contactId})
            }
            ensureUser(contactId)
          }
          break;
        case "Group":
            if(!data?.Name){
              const discussionName = getDiscussionName(discussion);
              const newData = {...data, Name:discussionName}
              setData(newData)
            }
            setdetails(data)
          break;
        default:
          break;
        }
    }, [contactId, data, details, discussion, user?.uid])
    
    useEffect(() => {
      const {LastMessageData} = data;
      if(LastMessageData?.Text){
        setLastMessage(LastMessageData?.Text);
      }
      else if(LastMessageData?.SharedPostId){
        setLastMessage(t<any>('chat.postShare'))
      }
      else if(LastMessageData?.Audio){
        setLastMessage(t<any>('chat.audioShare'))
      }
      else if(LastMessageData?.Images){
        setLastMessage(t<any>('chat.imageShare'))
      }
    }, [t,data])
    

    const handleSelectChat = useCallback(
      (e:React.MouseEvent<Element>,discussionId:string) => {
        e.preventDefault()
        dispatch(settingReset())
        dispatch(setOpenDiscussion({}));
        select(discussionId)
      }
    , [dispatch, select])
    

    return useMemo(() => <Button onClick={(e)=>handleSelectChat(e,discussion.Id)} className={'contactlist jcc wb-100 aifs '+(selected?'selected':'')}>
    {
      details&&
      <Container className='jcfs'>
        <Avatar url={data.Avatar} id={details.Id}/>
          <Col className='contact-details aifs'>
            <span className='contact-name'>{details.Name}</span>
            {lastMessage&&<span className='contact-designation'>{lastMessage}</span>}
          </Col>
          {
            newMessage&&<span className='new-message-indicator'></span>
          }
      </Container>
    }
  </Button>, [data.Avatar,details,discussion.Id,handleSelectChat,lastMessage,newMessage,selected])
}

export default SingleContact