import React, { useEffect, useRef, useState } from 'react'
import './AudioVisualiser.scss'

const AudioVisualiser = ({audioData}:any) => {
    const canvasRef = useRef<HTMLCanvasElement|null>(null)
    const parentDiv = useRef<HTMLDivElement|null>(null)
    const [width, setWidth] = useState<number>(0)

    const draw = () =>{
        if(canvasRef.current){
            const canvas:HTMLCanvasElement = canvasRef.current;
            const width = canvas.width;
            const height = canvas.height;
            const context = canvas.getContext("2d");
            let x = 0;
            const sliceWidth = (width * 1.0) / audioData.length;
            if(context){
                context.lineWidth = 2;
                context.strokeStyle = "#01A385";
                context.clearRect(0, 0, width, height);
                context.beginPath();
                context.moveTo(0, height / 2);
                for (const item of audioData) {
                    const y = (item / 255.0) * height;
                    context.lineTo(x, y);
                    x += sliceWidth;
                }
                context.lineTo(x, height / 2);
                context.stroke();
            }
        }
    }
    useEffect (()=>{
        draw()
    },[draw])
    useEffect(() => {
        if(parentDiv.current){
            const {clientWidth} = parentDiv.current
            setWidth(clientWidth)
        }
    }, [])
  return (
    <div ref={parentDiv} className="visualizer-wrapper wb-100">
        <canvas className="audioShow" width={width} height={10} ref={canvasRef} />
    </div>
  )
}

export default AudioVisualiser