import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';

const initialState = {
    status: StatusAsync.IDLE,
    data: {marketPrices: {}, marketPairs: {}},
    error: null,
};

const selectMarketPricesData = (state: any) => state?.marketPrices?.data;
export const selectMarketPrices = createSelector([selectMarketPricesData], (data) => (data?.marketPrices));
export const selectMarketPairs = createSelector([selectMarketPricesData], (data) => (data?.marketPairs));

export const marketPricesReducer = createSlice({
    name: 'marketPrices',
    initialState,
    reducers: {
        setMarketPricesData: (state, action) => {
            const oldMarketPrices: any = state?.data?.marketPrices;
            let newMarketPrices: any = {
                ...oldMarketPrices,
            };
            if (action?.payload?.key) {
                newMarketPrices[action?.payload?.key] = action.payload;
            }
            state.data.marketPrices = newMarketPrices;
        },
        updateMarketPairs: (state, action) => {
            const updatedMarketPairs = action.payload;

            if (Array.isArray(updatedMarketPairs) && updatedMarketPairs?.length) {
                const newState: { [k: string]: any } = {...state?.data?.marketPairs};
                let didUpdateAnAsset = false;
                updatedMarketPairs.forEach(assetPair => {
                    const assetPairKey =
                        assetPair.key ?? assetPair.Key;
                    if (assetPairKey) {
                        didUpdateAnAsset = true;
                        newState[assetPairKey] = {
                            ...(newState[assetPairKey] ?? {}),
                            ...assetPair,
                        };
                    }
                });
                if (didUpdateAnAsset) {
                    state.data.marketPairs = {...newState};
                }
            }
        },
    }
});

export const marketPricesActions = marketPricesReducer.actions;

export default marketPricesReducer.reducer;
