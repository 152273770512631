import {FC, useEffect, useMemo, useState} from "react";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import "./BuyARYACoinsForm.scss";
import {PaymentIntent} from "@stripe/stripe-js";
import {Button} from "../../../core/Button";
import {Col, Row} from "../../../core/Container";
import {aryaCoin} from "../../../../assets/images/svgIcons/svgIcons";
import {priceFormat} from "../../../../utils/helpers";
import {Picture} from "../../../core/Picture";
import {images} from "../../../../assets/images/images";
import {ARYACoinToUSDRation, FIRESTORE_COINS_MULTIPLIER} from "../../../../utils/constants/prices";
import {CoinProductType} from "../../../../types/wallet.types";
import {useSelector} from "react-redux";
import {selectUserWallet} from "../../../../services/redux/reducers/userAuth/user";
import {Link} from "react-router-dom";
import {Spinner} from "../../../core/Loading";
import {useTranslation} from "react-i18next";

const BuyARYACoinsForm: FC<{
    onSubmit: (paymentIntent: PaymentIntent | undefined) => void,
    coinPack: CoinProductType,
    paymentSucceeded: boolean,
}> = ({onSubmit, coinPack, paymentSucceeded}) => {

    const stripe = useStripe();
    const elements = useElements();

    const walletData = useSelector(selectUserWallet)

    const {t} = useTranslation();

    const [message, setMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [balanceAfterPurchase, setBalanceAfterPurchase] = useState<number>(0);

    useEffect(() => {
        if (walletData) {
            const currentBalance = (walletData?.Balance ?? 0) / FIRESTORE_COINS_MULTIPLIER;
            const coinProductAmount = (coinPack?.amount ?? 0) / FIRESTORE_COINS_MULTIPLIER;

            setBalanceAfterPurchase(currentBalance + coinProductAmount)
        }
    }, [coinPack?.amount, walletData])

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    setMessage(t<any>("checkout.paymentSucceeded!"));
                    break;
                case "processing":
                    setMessage(t<any>("checkout.yourPaymentIsProcessing"));
                    break;
                case "requires_payment_method":
                    setMessage(t<any>("checkout.yourPaymentWasNotSuccessful"));
                    break;
                default:
                    setMessage(t<any>("errors.somethingWentWrong"));
                    break;
            }
        });
    }, [stripe, t]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const origin = window.location.origin;

        const {error, paymentIntent} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: origin + "/payment-confirmation"
            },
            redirect: 'if_required'
        }).finally(() => {
            setIsLoading(false);
        });

        onSubmit(paymentIntent)

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error) {
            if (error?.type === "card_error" || error?.type === "validation_error") {
                setMessage(error?.message ?? "");
            } else {
                setMessage(t<any>("errors.somethingWentWrong"));
            }
        }

        setIsLoading(false);
    };

    const footer = useMemo(() => {
        const coinProductAmount = coinPack.amount / FIRESTORE_COINS_MULTIPLIER;
        let USDAmount = coinProductAmount * ARYACoinToUSDRation;
        if (coinPack.discount) {
            USDAmount -= USDAmount * coinPack.discount / 100;
        }
        if (paymentSucceeded) {
            return (
                <Col className="bacf-footer">
                    <Col className="bacf-success-message">
                        <Picture src={images.paymentSucceeded} alt=""/>
                        <h4>{t<any>("checkout.ARYACoinsPurchaseSuccess")}</h4>
                    </Col>
                    <Col className="wb-100 aifs bacf-order-summary">
                        <h5>{t<any>("checkout.orderSummary")}</h5>
                        <Row className="wb-100 jcsb bacf-order-summary-value">
                            <h5 className="bacf-coins-after">{t<any>("checkout.ARYACoinsAmount", {formattedCoinsCount: priceFormat(coinProductAmount)})}</h5>
                            <h5>{priceFormat(USDAmount)} USD</h5>
                        </Row>
                    </Col>
                    <Row className="wb-100 jcsb bacf-available-coins-container">
                        <h4 className="bacf-coins-after">{t<any>("checkout.newCoinBalance")}</h4>
                        <h4 className="">{t<any>("checkout.coins", {formattedCoinsCount: priceFormat(balanceAfterPurchase)})}</h4>
                    </Row>
                </Col>
            )
        } else {
            return (
                <Col className="bacf-footer">
                    <Row className='wb-100 jcfe'>
                        <h3 className="wb-100 bacf-total">{t<any>("checkout.total")} :${priceFormat(USDAmount)}</h3>
                        <Picture src={images.poweredByStripe} alt="" className="bacf-powered-stripe-img"/>
                    </Row>
                    <p className="description-text bacf-agree-to-agreement"> {t<any>("checkout.byPurchasingYouAgreeTo")}
                        <Link to="" className="bacf-user-agreement">{t<any>("checkout.userAgreement")}</Link>.
                    </p>
                    <Row className="wb-100 jcsb bacf-available-coins-container">
                        <Row className="bacf-coins-after">
                            {aryaCoin}
                            <h4>{t<any>("checkout.availableCoinsAfterPurchase")}</h4>
                        </Row>
                        <h3 className="bacf-arya-coins">{priceFormat(balanceAfterPurchase)}</h3>
                    </Row>
                </Col>
            )
        }
    }, [balanceAfterPurchase, coinPack.amount, coinPack.discount, paymentSucceeded, t]);


    const paymentElement = useMemo(() => {
        return (
            <PaymentElement
                id="payment-element"
                className="payment-element"
                options={{
                    layout: "tabs"
                }}
            />
        )
    }, []);

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="buy-arya-coins-form">
            {!(paymentSucceeded) &&
                <>
                    {paymentElement}
                    {message && <p id="payment-message" className="input-error">{message}</p>}
                </>
            }
            {footer}
            {!(paymentSucceeded) && <Button className="gw-btn bacf-purchase-btn" type="submit" disabled={isLoading}>
                {isLoading ? <Spinner/> : "Complete purchase"}
            </Button>}
        </form>
    )
}

export default BuyARYACoinsForm;