import { useCallback, useEffect, useState } from 'react'
import './ChatInput.scss'
import { attachIcon, cameraIcon, messageSend, micIcon } from '../../../assets/images/svgIcons/svgIcons'
import { Row } from '../../core/Container'
import { emojiIcon, closeIcon } from '../../../assets/images/svgIcons/svgIcons'
import OutsideAlerter from '../../core/OutsideAlerter'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import { useSelector } from 'react-redux'
import { selectUserMetadata } from '../../../services/redux/reducers/userAuth/user'
import ReplyBox from '../ReplyBox/ReplyBox'
import { IMessageData } from '../../../types/discussion.types'
import { formatPlainTextWithMentions, isValidUrl } from '../../../utils/helpers'
import AudioRecorder from '../../generic/Popups/PostIdea/AudioRecorder/AudioRecorder'
import useImageUploader from '../../../common/hooks/useImageUploader'
import { uploadAudioAttachement } from '../../../services/firebase/assets'
import { Button } from '../../core/Button'
import FormTextHandler from '../../core/FormTextHandler/FormTextHandler'
import {separateMentions} from "../../../utils/helpers/posts";
import {OnChangeHandlerFunc} from "react-mentions";

const ChatInput = ({reply,clearQuote, editedData, clearEdit,onSubmit}:any) => {
  // Message related states
  const userMetadata = useSelector(selectUserMetadata);
  const [inputValue, setInputValue] = useState("")
  const [formattedText, setFormattedText] = useState("")
  const [userMentions, setUserMentions] = useState<IMessageData["UserMentions"]|[]>([]);
  const [indexMentions, setIndexMentions] = useState<IMessageData["IndexMentions"]|[]>([]);
  const [quotedMessage, setQuotedMessage] = useState<IMessageData["QuotedMessage"]>();
  const [audioMessage, setAudioMessage] = useState<IMessageData["Audio"]|null>(null);
  const [attachmentView, setAttachmentView] = useState<boolean>(false);
  const [emojiView, setEmojiView ] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [recorderView, setRecorderView] = useState<boolean>(false);
  // TODO:Images interface
  
  const {files,setFiles,onChangeFile,removeAttachment} = useImageUploader()
  const imageInput = 'imageInput'

  let style = {
    input: {
      overflowY: 'auto',
      maxHeight: '100px',
      minHeight: '30px',
      padding:'5px 10px',
    },
    highlighter: {
      boxSizing: 'border-box',
      maxHeight: '100px',
      minHeight: '30px',
      padding:'5px 10px',
      border:'0px',
      width:'100%',
      height:'100%'
    },
  }

  const getBackAudioFile = useCallback((audio: any) => {
    setAudioMessage(audio)
  }, [])

  const clearState = useCallback(
    () => {
      setInputValue('');
      setUserMentions([]);
      setIndexMentions([]);
      setFormattedText('');
      setAudioMessage(null);
      setFiles([]);
      clearQuote();
      hideAttachment();
      setRecorderView(false);
    }
    ,
    [clearQuote,setFiles],
  )

  const hideAttachment = () =>{
    setAttachmentView(false);
    setEmojiView(false);
  }

  const displayRecorder = ()=>{
    setRecorderView(true);
    setAttachmentView(false);
    setEmojiView(false);
  }

  const handleInputChange: OnChangeHandlerFunc = (event, newValue, newPlainTextValue, mentions) =>{
    setFormattedText(newValue);
    setInputValue(newPlainTextValue);
    const {userMentions, assetMention} = separateMentions(mentions);
    setIndexMentions(assetMention ?? []);
    setUserMentions(userMentions);
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
  };

  const AddEmoji = ( emojiData: EmojiClickData) =>{
    setInputValue(prevInput=> prevInput+emojiData.emoji)
    setFormattedText(formattedText+emojiData.emoji)
  }
  const removeAudio = () =>{
    setAudioMessage(null)
    setRecorderView(false)
  }
  const handleSubmit = useCallback(
    async (e:any) => {
      e.preventDefault()
      if (sending) return
      setSending(true);

      const setAudio = async () =>{
        if(audioMessage){
          if(isValidUrl(audioMessage)){
            return audioMessage
          }
          else{
            return await uploadAudioAttachement(audioMessage)
          }
        }

        return undefined
      }

      const message = {
        Body: inputValue,
        UserMentions: userMentions,
        IndexMentions: indexMentions,
        Attachments: files,
        Audio: await setAudio(),
        userData:userMetadata,
        QuotedMessage: quotedMessage
      }
      await onSubmit?.(message).then(
        (success:any)=>{
         if(success){
           hideAttachment()
           clearState();
           if(editedData){
            clearEdit()
           }
         }
        }
      ).finally(()=>setSending(false))
    },
    [
      sending,
      inputValue,
      audioMessage,
      onSubmit,
      files,
      userMentions,
      indexMentions,
      quotedMessage,
      clearState,
      userMetadata,
      clearEdit,
      editedData
    ],
  )

  useEffect(() => {
    setQuotedMessage(reply)
  }, [reply])


  useEffect(()=>{
    if (editedData) {
      
      clearState()
      setFormattedText(formatPlainTextWithMentions(editedData?.text,editedData?.userMentions,editedData?.indexMentions))
      setInputValue(editedData?.text);
      setUserMentions(editedData?.userMentions);
      setIndexMentions(editedData?.indexMentions);
      setFiles(
        editedData?.attachments.length>0
          ? editedData.attachments.map((url:string)=>{
              return {url}
          })
          : null,
      );
      if(editedData?.audio.length>0){
        setAudioMessage(editedData?.audio);
      }
  }},[editedData,clearState,setFiles])

  return (
    <div className='chat-input-wrapper'>
      <OutsideAlerter fn={hideAttachment}>
        {attachmentView&&
            <Row className='attach-buttons'>
              <button onClick={() => { document.getElementById(imageInput)?.click()}}>{cameraIcon}</button>
              <button onClick={()=>displayRecorder()}>{micIcon}</button>
            </Row>
        }
        <form onSubmit={handleSubmit} className='input-buttons'>
          {
            <>
              <button type='button' className='show-attach' onClick={()=>{setAttachmentView(!attachmentView)}}>{attachIcon}</button>
              <span className='element-wrapper'>
              {
                quotedMessage?.Id &&
                <ReplyBox clearQuote={clearQuote} QuotedMessage={quotedMessage}/>
              }
              {recorderView&&
                <Row className='audio-attachment wb-100 jcfs'>
                  <AudioRecorder audioView={setRecorderView} audioRecorded={getBackAudioFile} />
                  <Button onClick={()=>removeAudio()} className='remove-file'>{closeIcon}</Button>
                </Row>
              }
              {files&&files.length>0 &&
                <Row className='images-display jcfs'>
                    {files.map(((image:any, i: number) => {
                        return (
                            <span key={i} className='image-card'>
                                <img className='pic-preview' src={image?.url} alt="" />
                                <button type='button' onClick={()=>removeAttachment(i)} className='remove-image'>{closeIcon}</button>
                            </span>
                        )
                    }))}
                </Row>}
                {emojiView&&
                  <div className='emoji-picker'>
                    <EmojiPicker width={300} height={350} previewConfig={{showPreview:false}} onEmojiClick={AddEmoji}/>
                  </div>
                }
                <Row className='input-wrapper wb-100 aife'>
                  <FormTextHandler
                      onClick={hideAttachment}
                      value={formattedText}
                      onChangeHandler={handleInputChange}
                      placeholder={'Start typing'}
                      style={style}
                      onKeyDownHandler={onEnterPress}
                  />
                  <input
                      accept="image/png, image/jpeg, image/jpg"
                      type='file'
                      id={imageInput}
                      onChange={onChangeFile}
                      style={{ display: "none" }}
                      multiple
                  />
                  <button type='button' onClick={()=>setEmojiView(!emojiView)} className='show-emoji'>{emojiIcon}</button>
                </Row>
              </span>
            </>
          }
          {
            editedData?
              <span className='edit-actions'>
                <button type='button' className='close-edit' onClick={()=>{clearState();clearEdit()}}>{closeIcon}</button>
                <button type='submit'>{messageSend}</button>
              </span>
            :<button type='submit' className='send-message'>{messageSend}</button>
          }
        </form>
      </OutsideAlerter>
    </div>
  )
}
export default ChatInput
