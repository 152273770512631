import {FC, RefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {awardsImages} from '../../../../assets/images/awards';
import {
    commentIcon,
    cupIcon,
    heartIcon,
    moreOptionsIcon,
    sendIcon
} from '../../../../assets/images/svgIcons/svgIcons'
import {firstCap, shortNumberFormat} from '../../../../utils/helpers'
import {Button} from '../../../core/Button'
import {Col, Container, ContainerWithRef, Row} from '../../../core/Container'
import {Picture} from '../../../core/Picture'
import ImageFullScreen from '../../../generic/Popups/AssetDisplay/ImageFullScreen'
import AudioDisplay from '../../../generic/Popups/PostIdea/audio-miscs/AudioDisplay'
import AuthorIdea from './Idea/Author'
import PostFinancialData from '../../Post/Post/PostFinancialData/PostFinancialData'
import {LinkPreview} from '@dhaiwat10/react-link-preview';
import {useDispatch, useSelector} from 'react-redux'
import {selectUser, selectUserMetadata, selectUserProtected, selectUserRole} from '../../../../services/redux/reducers/userAuth/user'
import {MODE_DEBUG} from '../../../../utils/constants/config'
import {useTranslation} from 'react-i18next'
import "./Idea.scss";
import {getPostReactions, setPostReaction} from '../../../../services/firebase/posts/reactions'
import {decrementLikes, incrementLikes} from '../../../../services/redux/reducers/home/home'
import {auth} from '../../../../services/firebase/auth/auth'
import {addListenerToPostEvents, removeListenerToPostEvents} from '../../../../services/realtime/realtimeChannel'
import {images} from "../../../../assets/images/images";
import GroupSubscriptionButton from "../../Groups/GroupSubscription/GroupSubscriptionButton/GroupSubsrictionButton";
import GiveAwardPopup from "../GiveAwardPopup/GiveAwardPopup";
import {awardsData} from "../../../../utils/constants/wallet";
import AwardsListPopup from "../AwardsListPopup/AwardsListPopup";
import ConfirmationPopup from '../../../core/ConfirmationPopup/ConfirmationPopup';
import { createSupportTicket, createSupportTicketConstants } from '../../../../services/firebase/support';
import { toast } from 'react-toastify';
import { deletePost } from '../../../../services/firebase/posts/posts';
import OptionsMenu, {Option} from '../../../core/OptionsMenu/OptionsMenu';
import { othersPostOptions, postOptions, ownPostOptions, postOptionConstants } from '../../../../utils/constants/idea';
import { superAdmin } from '../../../../utils/constants/constants';
import useFollowUnfollow from '../../../../common/hooks/useFollowUnfollow';
import { reactionsLimit } from '../../../../types/idea.types';
import PostLastUpdate from "./Idea/PostLastUpdate/PostLastUpdate";
import UpdatePostPopup from "../../../generic/Popups/PostIdea/UpdatePost/UpdatePostPopup";

interface IPropsIdea {
    idea: any,
    index: number,
    isMessage?: boolean
}

const Idea: FC<IPropsIdea> = ({idea, index, isMessage = false}) => {
    const optionRef = useRef() as RefObject<HTMLDivElement>;

    const {t} = useTranslation();
    const {onClickUnfollow,onClickFollow} = useFollowUnfollow();
    const dispatch = useDispatch();
    const userData = useSelector(selectUser);

    const  userRole = useSelector(selectUserRole)
    const userProtected = useSelector(selectUserProtected);
    const userMetadata = useSelector(selectUserMetadata);
    const currentUserMetadata = useSelector(selectUserMetadata);

    const [imageDisplay, setImageDisplay] = useState<boolean>(false)
    const [moreOptions, setMoreOptions] = useState<boolean>(false)
    const [deletePostPopup, setDeletePostPopup] = useState<boolean>(false)
    const [postLiked, setPostLiked] = useState<boolean>(false)
    const [showGiveAwardsPopup, setShowGiveAwardsPopup] = useState<boolean>(false);
    const [showAwardPopup, setShowAwardPopup] = useState<string>("");
    const [notificationsDisabled,setNotificationsDisabled] = useState<boolean>(false);
    const [followingUser, setFollowingUser] = useState(currentUserMetadata?.FollowingUsers?.includes(idea?.Author.Id))
    const [showReport,setShowReport] = useState<boolean>(false);
    const [showUpdatePostPopup, setShowUpdatePostPopup] = useState<boolean>(false)


    const handleOptionClick = (option:string) =>{
        switch (option){
            case postOptionConstants.deletePost:
                setDeletePostPopup(true);
                break;
            case postOptionConstants.followAuthor:
                onClickFollow(idea?.Author.Id);
                break;
            case postOptionConstants.unfollowAuthor:
                onClickUnfollow(idea?.Author.Id);
                break
        }
    }

    const closePopup = () => {
        setImageDisplay(!imageDisplay)
    }

    const getAwardSum = useCallback(
        (awards: object) => {
            let sum = 0
            Object.values(awards).map((value) => sum = sum + value)
            return sum
        },
        [],
    )

    const onGetReactions = useCallback(
        async () => {
            const fullPostId = idea?._id
            const [postAuthorId, postId]: Array<string> = fullPostId?.split('/') ?? []
            try {
                const reactions = await getPostReactions({
                    postAuthorId, postId, limit: reactionsLimit,
                    before: new Date(),
                })
                const userAuth: any = auth.currentUser?.uid
                if (reactions?.find(likes => likes.UserId === userAuth)) {
                    setPostLiked(true)
                } else {
                    setPostLiked(false)
                }
            } catch (error) {
                if(MODE_DEBUG){
                    console.log("onGetReactions error", error)
                }
            }
        },
        [],
    )

    const onDeletePost = async () => {
        const postDate = idea?.Date
        const fullPostId = idea?._id
        if (fullPostId) {
            try {
                await deletePost(fullPostId, postDate)
            } catch (error) {
                if (MODE_DEBUG) {
                    console.log('post deleted')
                }
            } finally {
                setDeletePostPopup(false)
            }
        }
    }
    const handleClickOutside = (event: any) => {
        if (optionRef.current && !optionRef.current.contains(event.target)) {
            setMoreOptions(false)
        }
    }
    const onLikePost = () => {
        const fullPostId = idea?._id
        const [authorId, postId]: Array<string> = fullPostId?.split('/') ?? []

        if (!postLiked) {
            dispatch(incrementLikes({_id: idea?._id}))
            setPostReaction({authorId, postId, type: true, user: userMetadata}).then(() => {
                setPostLiked(true)
            })
        } else {
            dispatch(decrementLikes({_id: idea?._id}))
            setPostReaction({authorId, postId, type: false, user: userMetadata}).then(() => {
                setPostLiked(false)
            })
        }
    }
    const onReport = async () =>{

        if(!userMetadata?.Id||!idea?._id){
            if(MODE_DEBUG){
                console.log(`User ID ${userMetadata?.Id} or Post Id ${idea?._id} is falsy`)
            }
            toast.error(t<any>("groups.about.error.somethingWentWrong"))
            return
        }

        const message:string = `Post ID ${idea?._id} is reported by user ID ${userMetadata?.Id}`

        createSupportTicket({subject:'Post reported by user',message}).then((response)=>{
            if (response === createSupportTicketConstants.requestDelayInfo){
                toast.warning(t<any>(response))
            }
            else{
                toast.success(t<any>(response))
            }
            setShowReport(false)
        })
        .catch((err)=>{
            toast.error(err)
        })

    }
    useEffect(() => {
        !isMessage && onGetReactions()
    }, [onGetReactions]);

    useEffect(() => {
        if (!isMessage || !idea?._id) {
            return;
        }
        addListenerToPostEvents(idea?._id);
        return () => {
            if (idea?._id) {
                removeListenerToPostEvents(idea?._id);
            }
        };
    }, [idea?._id, isMessage]);

    useEffect(() => {
        if (userProtected && idea?.Author.Id) {
            setNotificationsDisabled(userProtected?.NotificationOptions?.disabledAuthors?.includes(idea?.Author.Id) ?? false);
        }
    }, [userProtected, idea?.Author.Id]);

    useEffect(()=>{
        if(currentUserMetadata?.FollowingUsers && idea?.Author.Id){
            setFollowingUser(currentUserMetadata?.FollowingUsers?.includes(idea?.Author.Id)??false)
        }
    },[currentUserMetadata?.FollowingUsers,idea?.Author.Id])

    const postOption = useMemo(()=>{
        return(
            <ContainerWithRef reference={optionRef}>
                <Button onClick={() => {
                    setMoreOptions(!moreOptions);
                }}>{moreOptionsIcon}</Button>
                <OptionsMenu parentRef={optionRef} showMenu={moreOptions} onClose={() => {
                    setMoreOptions(false);
                }}>
                    {
                        userData.uid !== idea?.Author.Id?
                        othersPostOptions.map((option)=>{
                            const renderNotifications = !(option===postOptionConstants.turnOffNotification&&notificationsDisabled)&&!(option===postOptionConstants.turnOnNotification&&!notificationsDisabled)
                            const renderFollowing = !(option === postOptionConstants.followAuthor&&followingUser)&& !(option === postOptionConstants.unfollowAuthor&&!followingUser)
                            if (renderNotifications&&renderFollowing){
                                return <Option icon={postOptions[option].icon} onClick={()=>handleOptionClick(option)}>
                                    {
                                        <Col className='op-text aifs'>
                                            <h4> {t<any>(postOptions[option].title)}</h4>
                                            <p>{t<any>(postOptions[option].subTitle)}</p>
                                        </Col>
                                    }
                                </Option>
                            }
                        })
                        :
                        (userRole===superAdmin?
                            Object.keys(postOptions).map((option)=>{
                                return <Option icon={postOptions[option].icon} onClick={()=>handleOptionClick(option)}>
                                {
                                    <Col className='op-text aifs'>
                                        <h4> {t<any>(postOptions[option].title)}</h4>
                                        <p>{t<any>(postOptions[option].subTitle)}</p>
                                    </Col>
                                }
                                </Option>
                            })
                        :
                        ownPostOptions.map((option)=>{

                            return <Option icon={postOptions[option].icon} onClick={()=>handleOptionClick(option)}>
                                {
                                    <Col className='op-text aifs'>
                                        <h4> {t<any>(postOptions[option].title)}</h4>
                                        <p>{t<any>(postOptions[option].subTitle)}</p>
                                    </Col>
                                }
                            </Option>
                        }))
                    }
                </OptionsMenu>
            </ContainerWithRef>
        )
    },[idea?.Author.Id, moreOptions, t,userData.uid,followingUser,handleOptionClick,notificationsDisabled, userRole])

    const awards = useMemo(() => {
        if (!isMessage && idea?.AwardsCount) {
            const awardsKeys = Object.keys(idea?.AwardsCount);

            // sort rewards in descending order
            awardsKeys.sort((a: string, b: string) => {
                const aAward = awardsData[a];
                const bAward = awardsData[b];
                return bAward.order - aAward.order;
            });

            return (
                <Row className='awards jcfs'>
                    <Row className='aw-pics jcfs'>
                        {awardsKeys.map((key, i: number) => {
                            const awardCount = idea?.AwardsCount ? idea?.AwardsCount[key] : 0;
                            return (
                                <Button className="flex text-btn aw-pic" onClick={() => {
                                    setShowAwardPopup(key)
                                }}>
                                    <p>{shortNumberFormat(awardCount)}</p>
                                    <Picture key={i} alt='award' src={awardsImages[key]}/>
                                </Button>
                            );
                        })}
                    </Row>
                    <Col className='aw-count aife'>
                        {shortNumberFormat(getAwardSum(idea?.AwardsCount))} {t<any>('idea.awards')}
                    </Col>
                    {showAwardPopup &&
                        <AwardsListPopup
                            show={(!!showAwardPopup)}
                            awardType={showAwardPopup}
                            onClose={() => {
                                setShowAwardPopup("")
                            }}
                            postId={idea._id}
                        />}
                </Row>
            );
        }
    }, [showAwardPopup, getAwardSum, idea, isMessage, t]);


    return (
        <Container className={'fdc jcsb aifs ' + (isMessage ? "idea-preview" : "idea")}>
            <ConfirmationPopup title={t<any>('idea.confirmReport.title')} message={t<any>('idea.confirmReport.message')} confirmButtonText={t<any>('feed.ideaUtils.report')} cancelButtonText={t<any>('cancel')} show={showReport} onConfirmButtonClick={onReport} onCancelButtonClick={()=>setShowReport(false)} onClose={()=>setShowReport(false)}/>
            {!isMessage && deletePostPopup && <Col className='popup-container'>
                <Col className='delete-post-popup'>
                    <h4>
                        {t<any>('idea.deleteIdea')}
                    </h4>
                    <Row className='btns'>
                        <Button className='arya-btn confirm' onClick={onDeletePost}>Confirm</Button>
                        <Button className='arya-btn' onClick={() => setDeletePostPopup(false)}>Cancel</Button>
                    </Row>
                </Col>
            </Col>}
            {!isMessage && showUpdatePostPopup && <UpdatePostPopup show={showUpdatePostPopup} fullPostId={idea?._id}
                                                                   onClose={() => setShowUpdatePostPopup(false)}/>}
            {!isMessage &&
                <Row className='wb-100 jcsb'>
                    {!isMessage && imageDisplay &&
                        <ImageFullScreen closePopup={closePopup}
                                         images={typeof idea?.Images !== "boolean" ? (idea?.Images ?? []) : []}/>}
                    <AuthorIdea isMessage={isMessage} authorData={idea?.Author}
                                dateIdea={Date.parse(idea?.Date ?? "")}/>
                    <Container className='more-options'>
                        {postOption}
                    </Container>
                </Row>}
            {idea?.FinancialData && <Row className='tags jcfs'>
                {idea?.FinancialData?.Sell ? <Button className='tag sell'>
                        {t<any>('idea.sell')}
                    </Button> :
                    <Button className='tag buy'>
                        {t<any>('idea.buy')}
                    </Button>}
                {idea?.FinancialData?.Instrument && <Button className='tag index'>
                    {idea?.FinancialData?.Instrument.Name}
                </Button>}
                {idea?.FinancialData.Timeframe && <Button className='tag'>
                    {firstCap(idea?.FinancialData.Timeframe) + ' Term'}
                </Button>}
                {idea?.FinancialData.AssetType && <Button className='tag'>
                    {firstCap(idea?.FinancialData.AssetType)}
                </Button>}
            </Row>}
            {idea?._locked &&
                <Row className='aic jcsb idea-locked'>
                    <Row className='idea-locked-label'>
                        <Picture src={images.lockGreyOutlined} alt=''></Picture>
                        <p className='description-text'>{t<any>('feed.postIdea.subscribeToUnlockThisPost')}</p>
                    </Row>
                    <GroupSubscriptionButton/>
                </Row>
            }
            {idea?.Text && <Col className='idea-text jcfs aifs'>
                {idea?.FinancialData?.Title && <h3>{idea?.FinancialData?.Title}</h3>}
                <h5>{idea?.Text}</h5>
            </Col>}

            {idea?.LastUpdate && <PostLastUpdate lastUpdate={idea?.LastUpdate} postId={idea._id}/>}

            {(idea?.Audio || idea?.Images) && <Row className='assets-r jcfs'>
                {idea?.Audio && <Row className='audio-display'>
                    <AudioDisplay id={`${index}`} audioData={idea?.Audio}/>
                </Row>}
                {idea?.Images && typeof idea?.Images !== 'boolean' && <Row className='images-display jcfs'>
                    {idea?.Images?.slice(0, 3).map(((image: string, i: number) => {
                        if (i === 2) {
                            const remainingImagesCount = typeof idea?.Images !== "boolean" ? ((idea?.Images?.length ?? 0) - 2) : 0;
                            return (
                                <Col className='img-c' key={i} onClick={closePopup}>
                                    <Col className='img-overlay'>
                                        <h5>+{remainingImagesCount}</h5>
                                    </Col>
                                    <Picture alt='img' src={image}/>
                                </Col>
                            )
                        } else {
                            return (
                                <Col className='img-c' key={i} onClick={closePopup}>
                                    <Picture alt='img' src={image}/>
                                </Col>
                            )
                        }
                    }))}
                </Row>}
            </Row>}
            {
                !isMessage && (idea?.Link || idea?.YoutubeId) &&
                <Col className='link-prv aifs jcfs'>
                    {idea?.Link &&
                        <LinkPreview url='https://arya.com/' imageHeight={'100%'} width='50%' height='110px'/>}
                    {idea?.YoutubeId && <iframe
                        src={`https://www.youtube.com/embed/${idea.YoutubeId}`}
                        title={t<any>('idea.youtubeTitle')}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>}
                </Col>
            }

            {
                idea?.FinancialData?.EntryValue &&
                idea?.FinancialData?.BreakEven &&
                idea?.FinancialData?.StopLoss &&
                idea?.FinancialData?.TakeProfits &&
                idea?.FinancialData?.Instrument &&
                <PostFinancialData financialData={idea?.FinancialData}
                                   ideaCreationTime={new Date(Date.parse(idea?.Date ?? ""))}
                                   postData={idea}
                />
            }
            {awards}
            {!isMessage && ((idea?.ReactionsCount || idea?.SharesCount || idea?.CommentsCount) ?
                <Row className='social-count jcfs'>
                    {idea?.ReactionsCount && <span onClick={() => onLikePost()}
                                                   className={`${postLiked && 'liked'}`}>{heartIcon} {idea?.ReactionsCount.Likes}</span>}
                    {idea?.CommentsCount && <span>{commentIcon} {idea?.CommentsCount}</span>}
                    {idea?.SharesCount && <span>{sendIcon} {idea?.SharesCount}</span>}
                    <span>{cupIcon}</span>
                </Row> : <Row className='social-count jcfs'>
                    <span onClick={() => onLikePost()} className={`${postLiked && 'liked'}`}>{heartIcon}</span>
                    <span>{commentIcon} </span>
                    <span>{sendIcon}</span>
                    <span onClick={() => {
                        setShowGiveAwardsPopup(true)
                    }}>{cupIcon}</span>
                    {showGiveAwardsPopup &&
                        <GiveAwardPopup
                            show={showGiveAwardsPopup}
                            onClose={() => {
                                setShowGiveAwardsPopup(false)
                            }}
                            post={idea}
                        />}
                </Row>)
            }
        </Container>
    )
}

export default Idea