import {useMemo} from 'react'
import {IndexMention, UserMention} from '../../../types/common.types';
import {Link} from 'react-router-dom';
import {profilePagePath} from '../../../routes/Routes';
import {Anchorme} from 'react-anchorme'
import './FormattedText.scss'
import {getAssetDetailsLink} from '../../../services/firebase/market/market';
import {MarketItemType} from "../../../types/post.types";


export type PostTextAreaValueType = { Text?: string, UserMentions?: UserMention[], IndexMentions?: IndexMention[] };

interface IFormattedText {
    value?: PostTextAreaValueType,
    clickable?: boolean,
    tempMentionStart?: number,
    tempMentionLength?: number
}


const FormattedText = ({
                           value,
                           clickable = true,
                           tempMentionStart,
                           tempMentionLength
                       }: IFormattedText) => {

    const formattedTextComponents = useMemo(
        () => {
            const mentions: {
                Name: string,
                Start?: number,
                Length?: number,
                Id?: string,
                MarketItem?: MarketItemType
            }[] = [...(value?.UserMentions ?? []), ...(value?.IndexMentions ?? [])];
            let offset = 0;
            let formattedText = []
            if (value?.Text) {
                if ((tempMentionStart || tempMentionStart === 0) && tempMentionLength) {
                    mentions?.push({
                        Name: "",
                        Start: tempMentionStart,
                        Length: tempMentionLength,
                        Id: "",
                    });
                }
                mentions.sort((a, b) => (a?.Start ?? 0) - (b?.Start ?? 0));

                if (mentions?.length) {
                    mentions.forEach((mention, index) => {
                        const start = (mention?.Start ?? offset);
                        const end = start + (mention?.Length ?? 0);
                        const beforeMention = value?.Text?.substring(offset, start);
                        const mentionText = value?.Text?.substring(start, end);
                        const mentionComponent = <span key={(mentionText ?? "mention") + index}
                                                       className="mention-text">{mentionText}</span>;

                        if (beforeMention) {
                            formattedText.push(
                                <Anchorme className='formatted-link' key={beforeMention + index}>{beforeMention}</Anchorme>,
                            );
                        }
                        if (mentionText) {
                            if (mention?.Start === tempMentionStart && mention?.Length === tempMentionLength) {
                                const tempMentionComponent = (
                                    <span key={mentionText + index}
                                          className="mention-text current-span">{mentionText}</span>
                                );
                                formattedText.push(tempMentionComponent);
                            } else {
                                if (clickable) {
                                    if (mention?.MarketItem) {
                                        const marketItem = mention?.MarketItem;
                                        formattedText.push(
                                            <Link
                                                key={mentionText + index}
                                                to={getAssetDetailsLink(marketItem?.Symbol ?? "", marketItem.Market ?? "", marketItem.To ?? "", marketItem.Name ?? "")}
                                            >
                                                {mentionComponent}
                                            </Link>
                                        );
                                    } else {
                                        formattedText.push(
                                            <Link key={mentionText + index}
                                                  to={`/${profilePagePath}/${mention.Id}`}>{mentionComponent}</Link>
                                        )
                                    }
                                } else {
                                    formattedText.push(mentionComponent);
                                }
                            }
                        }
                        offset = end;
                    });
                    const remainingText = value?.Text.substring(offset);
                    if (remainingText) {
                        formattedText.push(<Anchorme className='formatted-link'
                                                     key={remainingText}>{remainingText}</Anchorme>);
                    }
                } else {
                    if (value?.Text) {
                        formattedText.push(<Anchorme className='formatted-link'
                                                     key={value?.Text}>{value?.Text}</Anchorme>);
                    }
                }
            }
            return formattedText
        },
        [clickable, tempMentionLength, tempMentionStart, value?.IndexMentions, value?.Text, value?.UserMentions]
    )

    return <>
        {formattedTextComponents}
    </>
}

export default FormattedText