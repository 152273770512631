import {Timestamp} from "firebase/firestore";

export type GroupType = {
    Title?: string,
    Alias?: string,
    Owner?: string,
    Image?: string,
    Description?: string,
    About?: string,
    Type?: GroupTypes,
    Date?: Date,
    Subscriptions?: GroupSubscription [],
    Statistics?: GroupStatistics,
    _id?: string,
    Section?: string,
    Questionnaire?: QuestionnaireQuestion[],
    Rules?: GroupRule[],
    DefaultFeaturesFlags?:{
        PublishIdea:boolean
    }
    LinkUrl?: string,
}

export type GroupRule = {
    Title?: string,
    Description?: string,
}

export type GroupSubscription = {
    Description?: string,
    Id?: string,
    Name?: string,
    Timeframes?: {
        Yearly?: number,
        Monthly?: number,
        Weekly?: number
    }
}

export type GroupSubscriptionErrors = {
    Yearly?: string,
    Monthly?: string,
    Weekly?: string,
    all?: string
}


export type GroupStatistics = {
    MembersCount?: number,
    MembersChangeCount?: number,
    IdeasCount?: number,
    NewIdeasCount?: number,
    IdeaPartitions?: IdeaPartition[]
}

export type IdeaPartition = {
    Category?: string,
    TotalValue?: number,
    Percentage?: number
}

export type UserGroupsType = {
    _id?: string,
    MemberSince?: Timestamp,
    PremiumUntil?: Timestamp,
    RemovedAt?: Timestamp,
    LeftAt?: Timestamp,
    Roles?: GroupRoles[],
    Questionnaire?: QuestionnaireQuestion[],
    Resolution?: {
        Type?: UserGroupsResolutionType,
        By?: string,
    },
    SubscriptionID?: string,
}

export type UserGroupPendingRequestType = {
    id?: string,
    Questionnaire?: QuestionnaireQuestion[],
    PendingSince?: any,
    IsAccepted?: boolean,
    ResolvedAt?: Timestamp,
    ResolvedBy?: string,
}

export type QuestionnaireQuestion = {
    Question?: string,
    Answer?: string,
    Type?: QuestionnaireQuestionType,
    Choices?: string[],
    Selected?: number,
    Error?: string,
}

export enum QuestionnaireQuestionType {
    essay = "essay",
    multiple_choice = "multiple_choice"
}

export type GroupMemberUserDataType = {
    Name?: string,
    Job?: string,
    SystemAlias?: string
}
export type GroupSectionType = {
    Id: string,
    Icon: string,
    Title: string,
    isDeleted?: boolean,
}

export enum GroupRoles {
    OWNER = 'OWNER',
    MODERATOR = 'MODERATOR',
    MEMBER = 'MEMBER',
    PENDING = 'PENDING'
}

export enum GroupTabsEnum {
    posts = 'posts',
    about = 'about',
    members = 'members',
    manage = 'manage',
    analytics = 'analytics'
}

export const groupTabs = [
    {id: GroupTabsEnum.posts, allowedRoles: []},
    {id: GroupTabsEnum.about, allowedRoles: []},
    {id: GroupTabsEnum.members, allowedRoles: [GroupRoles.OWNER, GroupRoles.MODERATOR]},
    {id: GroupTabsEnum.manage, allowedRoles: [GroupRoles.OWNER]},
    {id: GroupTabsEnum.analytics, allowedRoles: [GroupRoles.OWNER]},
]

export enum GroupTypes {
    private = 'private',
    public = 'public',
    paid = 'paid',
}

export enum GroupSubscriptionTimeframes {
    'Weekly' = 'Weekly',
    'Monthly' = 'Monthly',
    'Yearly' = 'Yearly',
}

export enum UserGroupsResolutionType {
    REMOVED = "REMOVED",
    BANNED = "BANNED"
}

export type OwnerStatistics = {
    RevenueMemberGraph?: {
        [k: string]: {
            member?: {
                premiumBundle?: number,
                natural?: number
            },
            revenue?: number
        }
    },
    TierGraph?: {
        Weekly?: number,
        Monthly?: number,
        Yearly?: number
    },
    TotalEarnings?: number,
}

export enum OwnerStatisticsTimeframes {
    "AllTime" = "AllTime",
    "3M" = "3M",
    "6M" = "6M",
    "12M" = "12M",
}


export const OwnerStatisticsTimeframesIds: OwnerStatisticsTimeframes[] = [
    OwnerStatisticsTimeframes.AllTime,
    OwnerStatisticsTimeframes["3M"],
    OwnerStatisticsTimeframes["6M"],
    OwnerStatisticsTimeframes["12M"],
]