import {FC, RefObject, useEffect, useRef, useState} from "react";
import WaveSurfer from "wavesurfer.js";
import {pauseAudio, playAudioIcon} from "../../../../../assets/images/svgIcons/svgIcons";
import {Button} from "../../../../core/Button";
import {Col, Row} from "../../../../core/Container";
import './AudioDisplay.scss'

type IWaveProps = {
    audioData: any,
    recordState?: any,
    startRecording?: any,
    stopRecording?: any,
    id?: string
}

const AudioDisplay: FC<IWaveProps> = ({audioData, id}) => {
    const [waver, setWaver] = useState<any>(null);
    const [playing, setPlaying] = useState(false);
    const el = useRef() as RefObject<HTMLDivElement>;
    const audioEl = useRef() as RefObject<HTMLAudioElement>;
    const [playbackRate, setPlaybackRate] = useState(1);
    const [currentTime, setCurrentTime] = useState<string>("");

    const str_pad_left = (string: any, pad: any, length: any) => {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    }

    const timeUpdate = (event: any) => {
        const minutes = Math.floor(event.target.currentTime / 60);
        const seconds = Math.floor(event.target.currentTime - minutes * 60);
        const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
        setCurrentTime(currentTime);
    }

    useEffect(() => {
        if (audioEl.current) {
            audioEl.current.playbackRate = playbackRate;
        }
    }, [playbackRate]);

    const setPlayBack = () => {
        let audioplayer: any = 'ad' + id;
        const audioId: any = document?.getElementById(audioplayer)
        if (playbackRate === 2) {
            setPlaybackRate(0.5)
            if (audioId) audioId.playbackRate = 0.5;
        } else {
            setPlaybackRate(playbackRate + 0.5)
            audioId.playbackRate = playbackRate + 0.5;
        }
    };

    useEffect(() => {
        let wavesurfer: any
        if (el.current !== null) {
            wavesurfer = WaveSurfer.create({
                barWidth: 2,
                barGap: 4,
                barMinHeight: 1,
                cursorWidth: 1,
                container: `#waveform${id}`,
                backend: "MediaElement",
                height: 30,
                progressColor: "#01A385",
                responsive: true,
                waveColor: "#E5E5E5",
                cursorColor: "transparent",
                fillParent: true
            });
            wavesurfer.load(audioEl?.current, null, 'auto');
            setWaver(wavesurfer);
        }
        return () => wavesurfer.destroy()

    }, [id]);

    const handlePlay = () => {
        // play()
        setPlaying(!playing);
        waver?.playPause();
    };

    const onAudioEnd = () =>{
        setPlaying(false)
    }

    return (
        <Row className="wb-100 audio-display-wrapper">
            <Button onClick={handlePlay} className="flex aic jcc record-btn">
                {playing ? pauseAudio:playAudioIcon}
            </Button>
            <Row className='audio-c wb-100'>
                <Col className="audio-speed" onClick={setPlayBack}>
                    <h6>{currentTime}</h6>
                </Col>
                <div className="wb-100" ref={el}>
                    <div id={`waveform${id}`}/>
                    <div style={{display: 'none'}}>
                        <audio
                            ref={audioEl}
                            src={audioData}
                            id={`ad${id}`}
                            onTimeUpdate={timeUpdate}
                            onEnded={onAudioEnd}
                        />
                    </div>
                </div>
                <Col className="audio-speed" onClick={setPlayBack}>
                    <h6>{playbackRate}x</h6>
                </Col>
            </Row>
        </Row>
    );
}


export default AudioDisplay;
