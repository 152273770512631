import {FC} from "react";
import {Button} from "../../../../core/Button";
import "./GroupSubscriptionButton.scss"
import {useTranslation} from "react-i18next";

const GroupSubscriptionButton: FC<{ onClick?: () => void, disabled?: boolean, showLoading?: boolean }> = ({
                                                                                                              onClick,
                                                                                                              disabled = false,
                                                                                                              showLoading = false
                                                                                                          }) => {
    const {t} = useTranslation();

    return (
        <Button className='group-subscribe-btn' onClick={onClick} disabled={disabled} isLoading={showLoading}>
            {t<any>('groups.subscription.joinVIP')}
        </Button>
    )
}

export default GroupSubscriptionButton;