export function makeKey(marketObj: any) {
    if (!marketObj) {
      return null;
    }
    const symbol = marketObj.symbol ?? marketObj.Symbol,
      market = marketObj.market ?? marketObj.Market,
      to = marketObj.to ?? marketObj.To;
    if (!symbol || !market || !to) {
      return null;
    }
    return `${symbol}_${market}_${to}`;
  }