import {postPagePath} from "../../routes/Routes";
import {MentionItem} from "react-mentions";

export const getPostPagePath = (postId?: string) => {
    if (postId) {
        const origin = window.location.origin;
        return `${origin}/${postPagePath}/${postId}`;
    }
}

export function separateMentions(mentions: MentionItem[]) {
    const userMentions = mentions.filter((e) => e.display.startsWith("@")).map((mention) => {
        return {
            Id: mention.id,
            Length: mention.display.length,
            Name: mention.display,
            Start: mention.plainTextIndex
        }
    }, [])
    const assetMention = mentions.filter((e) => e.display.startsWith("$")).map((mention) => {
        const item = JSON.parse(mention.id);
        return {
            MarketItem: item,
            Name: `$${item.Symbol}`,
            Start: mention.plainTextIndex,
            Length: mention.display.length,
        }
    }, [])

    return {userMentions, assetMention}
}