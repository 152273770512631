import {useEffect, useState, useRef, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import './MessageDisplay.scss'
import ChatBubble from '../ChatBubble/ChatBubble'
import {deleteMessage, getMoreMessages} from '../../../services/firebase/discussions/messages'
import {useSelector} from 'react-redux'
import {selectMessages} from '../../../services/redux/reducers/discussion/discussion'
import {Spinner} from '../../core/Loading'
import {selectUserMetadata} from '../../../services/redux/reducers/userAuth/user'
import moment from 'moment'
import {Col} from '../../core/Container'
import {IMessage} from '../../../types/discussion.types'
import {selectUsers} from '../../../services/redux/reducers/users/users'
import {discussionConstants} from '../../../utils/constants/discussions'
import ChatConfirmation from '../ChatConfirmation/ChatConfirmation'
import {MODE_DEBUG} from '../../../utils/constants/config'

const MessageDisplay = ({discussionId, setReply, setEdit, newChat}: any) => {

    const {t} = useTranslation();
    const observer = useRef<any>();
    const storeMessages = useSelector((state: any) => selectMessages(state, discussionId));
    const users = useSelector(selectUsers)
    const user = useSelector(selectUserMetadata);

    const [canLoadMoreMessages, setCanLoadMoreMessages] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [messaageIdToDelete, setMessageIdToDelete] = useState<string | null>(null)
    const [messages, setMessages] = useState<any>()

    let prevDay: String = new Date(Date.now()).getDay().toString()

    useEffect(() => {
        setIsLoading(true)
        if (!storeMessages) {
            setIsLoading(false)
            return setMessages(null);
        }
        if (storeMessages) {
            const messagesArray = Object.keys(storeMessages)
                .map(key => storeMessages[key])
                .sort((a, b) => b.Date - a.Date)

            setMessages(messagesArray);
        }
        setIsLoading(false)
    }, [storeMessages, discussionId])

    const loadMore = useCallback(
        async () => {
            const messagesIds = Object.keys(storeMessages ?? {})
            if (
                canLoadMoreMessages &&
                !isLoadingMore &&
                messagesIds.length >= discussionConstants.MaxListenedMessages
            ) {
                setIsLoadingMore(true);
                const newMessages = await getMoreMessages({
                    discussionId,
                    before: messagesIds
                        .map(messagesId => storeMessages[messagesId])
                        .reduce((a, b) => (a.Date < b.Date ? a : b)).Date,
                });
                setCanLoadMoreMessages(
                    newMessages?.length === discussionConstants.MaxFetchedMessages,
                );
                setIsLoadingMore(false);
            }
        },
        [storeMessages, canLoadMoreMessages, discussionId, isLoadingMore]
    )

    const deleteChat = () => {
        if (discussionId && messaageIdToDelete) {
            deleteMessage(discussionId, messaageIdToDelete).then(() => {
                setDeleteModal(false);
                setMessageIdToDelete(null)
            })
        } else {
            if (MODE_DEBUG) {
                console.log("delete Chat was called even tho discussionId is falsy")
            }
        }
    }

    const DeleteModal = () => {
        return deleteModal ?
            <ChatConfirmation confirmationData={{
                heading: t<any>('chat.deleteMessage'),
                text: t<any>('chat.messageDeleteWarning'),
                buttonText: t<any>('chat.delete')
            }
            } resetFn={() => setDeleteModal(false)} confirmFn={deleteChat}/> : <></>
    }

    const lastMessageElementRef = useCallback(
        (node: any) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && canLoadMoreMessages) {
                    loadMore()
                }
            });
            if (node) observer.current.observe(node);
        }, [isLoading, canLoadMoreMessages, loadMore]
    )

    const dayCheck = (seconds: any) => {
        const currentDay = moment(seconds * 1000).format('D');
        if (currentDay === prevDay) {
            return true
        } else {
            prevDay = currentDay
            return false
        }
    }

    const generatedMessage = (message: IMessage) => {
        let text = ''
        const userIds = message?.Data?.UserIds ?? []
        switch (message?.GeneratedType) {
            case 'AdminsAdded':
                text = "Admin Added"
                break;
            case 'AdminsRemoved':
                text = "Admin Removed"
                break;
            case 'MembersAdded':
                if (!message?.Date) {
                    break;
                }
                let contact = users[userIds[0]]
                if (user) {
                    text = `${contact.Name} has joined the discussion`
                }
                break;
            case 'MembersRemoved':
                // TODO:ADD more details to this message
                text = "Member Removed"
                break;
            case 'AvatarChange':
                text = "Avatar Change"
                break;
            case 'NameChange':
                text = "The discussion has been renamed!"
                break;
            default:
                break;
        }
        text += ' ' +
            (message?.Date?.toDate
                ? moment(message.Date.toDate()).fromNow()
                : moment(message.Date).fromNow());

        return text
    }
    return (
        isLoading ? <Spinner/> :
            <div className='message-wrapper jcfs fdcr'>
                {DeleteModal()}
                <>
                    {newChat ?
                        <div className='empty-message'>
                            <span>{t<any>('chat.emptyChat')}</span>
                        </div> :
                        messages?.map((message: any, index: number) => {
                            const isLastElement = messages.length === index + 1;
                            const onEdit = () => {
                                setEdit(message);
                                if (message?.Data?.QuotedMessage) {
                                    setReply(message?.Data?.QuotedMessage)
                                }
                            }
                            const sameDay = dayCheck(message?.Date?.seconds)

                            const showDeleteModal = () => {
                                setDeleteModal(true);
                                setMessageIdToDelete(message.Id);
                            }

                            return <Col key={message.Id}>
                                {!sameDay &&
                                    <span
                                        className='date-header'>{moment(message?.Date?.seconds * 1000).format('MMMM Do YYYY')}</span>
                                }
                                {
                                    message?.GeneratedType ?
                                        <span className='generated-message'>{generatedMessage(message)}</span>
                                        :
                                        (isLastElement ?
                                            <div className='wb-100' ref={lastMessageElementRef}>
                                                <ChatBubble setDelete={showDeleteModal} onEdit={() => {
                                                    onEdit()
                                                }} discussionId={discussionId} setReply={setReply} userId={user?.Id}
                                                            index={index} message={message}/>
                                            </div>
                                            :
                                            <ChatBubble setDelete={showDeleteModal} onEdit={() => {
                                                onEdit()
                                            }} discussionId={discussionId} setReply={setReply} userId={user?.Id}
                                                        index={index} message={message}/>)
                                }
                            </Col>
                        })
                    }

                    {isLoadingMore &&
                        <div className='wb-100 flex jcc aic'>
                            <div className="loading-main-button"/>
                        </div>
                    }
                </>
            </div>
    )
}

export default MessageDisplay