import React, { useState } from 'react'

const useImageUploader = () => {
    const [files, setFiles] = useState<any>([]);
    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if(e.target.files){
          let newFiles = e.target.files
          let newFilesArray:any = Array.from(newFiles)
          for (let i = 0; i < newFiles.length; i++) {
              const imageUrl = URL.createObjectURL(newFiles[i]);
              newFilesArray[i] = {File:newFilesArray[i],url:imageUrl}
          }
          setFiles([...files,...newFilesArray])
        }
    }
    const removeAttachment = (index:number)=>{
        if (files.length===1){
          setFiles([])
          return
        }
        let list = [...files]
        list.splice(index,1)
        setFiles(list)
    }

    return {files,setFiles,onChangeFile,removeAttachment}
}

export default useImageUploader