import authIllust from './auth/auth-1.png'
import authShape from './auth/auth-shape.png'
import authLogo from './auth/auth-logo.png'
import authLoginBg from './auth/auth-login-bg.png'
import userIcon from './auth/user-icon.png'
import passIcon from './auth/user-pass.png'
import phoneIcon from './auth/user-phone.png'
import emailIcon from './auth/user-email.png'
import googlePlay from './auth/google-play.svg'
import appleStore from './auth/appstore.svg'
import defaultAvatar from './auth/tmp-damir.png'
import awardOne from './idea/award1.png'
import awardTwo from './idea/award2.png'
import awardThree from './idea/award3.png'
import filterOutlinedBlackIcon from './svgIcons/filter-outlined-black.svg'
import starOutlined from './market/star-outlined.svg'
import starFilled from './market/star-filled.svg'
import arrowUpBlue from './market/arrow-up-blue.svg'
import arrowDownGrey from './market/arrow-down-grey.svg'
import defaultAssetIcon from './market/defaultAssetIcon.svg'
import ARYACoinIcon from './svgIcons/arya-coin-icon.svg'
import infoIcon from './svgIcons/info-icon.svg'
import subscriptionBox from './svgIcons/subscription-box-blue-outlined.svg'
import diamondOutlinedWhite from './svgIcons/diamond-outlined-white.svg'
import lockGreyOutlined from './svgIcons/lock-grey-outlined.svg'
import ARYACoinLargeImage from './market/ARYA-coin-lg.png'
import poweredByStripe from './wallet/poweredByStripe.svg'
import visaLogo from './wallet/visa-logo.png'
import mastercardLogo from './wallet/mastercard-logo.png'
import paymentSucceeded from './wallet/payment-succeeded.svg'
import defaultUserImage from './auth/userImagePlaceholder.svg'
import defaultGroupImage from './auth/groupImagePlaceholder.svg'
import loginImg from './auth/login-img.svg'

export const images = {
    authIllust,
    authShape,
    authLogo,
    authLoginBg,
    userIcon,
    passIcon,
    phoneIcon,
    emailIcon,
    googlePlay,
    appleStore,
    defaultAvatar,
    awardOne,
    awardTwo,
    awardThree,
    defaultUserImage,
    filterOutlinedBlackIcon,
    ARYACoinIcon,
    infoIcon,
    starOutlined,
    starFilled,
    arrowUpBlue,
    arrowDownGrey,
    defaultAssetIcon,
    subscriptionBox,
    diamondOutlinedWhite,
    lockGreyOutlined,
    ARYACoinLargeImage,
    poweredByStripe,
    visaLogo,
    mastercardLogo,
    paymentSucceeded,
    defaultGroupImage,
    loginImg,
}