export const pricePointsOrderIndex: { [k:string]:number } = {
    "StopLoss": 0,
    "EntryValue": 1,
    "BreakEven": 2,
    "TakeProfit_1": 3,
    "TakeProfit_2": 4,
    "TakeProfit_3": 5,
}

export const pricePointsShortForm = {
    "StopLoss": "SL",
    "EntryValue": "EN",
    "BreakEven": "BE",
    "TakeProfit_1": "TP1",
    "TakeProfit_2": "TP2",
    "TakeProfit_3": "TP3",
}

export const ideaTimeframeInDays = {
    "short": 7,     // 1 weeks
    "middle": 30,   // 2 months
    "long": 30 * 6, // 6 months
}

export const dayInMilliseconds = (24 * 60 * 60 * 1000);