import { supportTicketHttpsCallable } from "./firebaseFunctions";

const supportTimer = "supportTimer"

export const createSupportTicketConstants = {
    sent: 'settings.supportTicket.sent',
    notSent: 'settings.supportTicket.notSent',
    requestDelayInfo: 'settings.supportTicket.requestDelayInfo',
};

/**
 * Creates a chat in hubspot support account
 * @param {object} ticket
 * @param {string} ticket.subject
 * @param {string} ticket.message
 */

export async function createSupportTicket({subject, message}: {subject:string,message:string}) {
    const createSupportTicketTimer:any = localStorage.getItem(supportTimer)?new Date(localStorage.getItem(supportTimer)??''):null
    let lastSubmittedTimePlusFiveMinutes:Date = new Date(
      createSupportTicketTimer?.getTime() + 5 * 60000,
    );
    if (
      !createSupportTicketTimer ||
      (createSupportTicketTimer && lastSubmittedTimePlusFiveMinutes < new Date())
    ) {
      let data = {
        subject: subject,
        message: message,
      };
      const result = await supportTicketHttpsCallable(data).then(
        (response:any) => response.data,
        (err:Error) => err,
      );
      if (result?.status === 'Success') {
        let currentDate = new Date();
        localStorage.setItem(supportTimer,currentDate.toString())
        return createSupportTicketConstants.sent;
      } else {
        throw new Error(createSupportTicketConstants.notSent);
      }
    } else {
        throw new Error( createSupportTicketConstants.requestDelayInfo);
    }
  }