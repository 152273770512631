import { useEffect } from "react"

export const useOutsideAlerter = (ref: any,fn:Function) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
            fn();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref,fn]);
    
}