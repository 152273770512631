import React, {useEffect, useState, useId, useCallback} from 'react'
import {Container, Col, Row} from '../../core/Container'
import {LoadingSpinner} from '../../core/Loading'
import {Button} from '../../core/Button'
import {
    chevronUp,
    closeIcon,
    settingIcon,
    goBackArrow, chatRequestIcon, newChatIcon
} from '../../../assets/images/svgIcons/svgIcons'
import {useDispatch, useSelector} from 'react-redux'
import {selectUserMetadata} from '../../../services/redux/reducers/userAuth/user'
import {
    selectChatView,
    selectCurrentDiscussion,
    selectDiscussions,
    selectNewMessagesIndicator,
    selectOpenDiscussion,
    selectSettingName,
    selectSettingView,
    setChatView,
    setCurrentDiscussion,
    setNewMessageIndicator,
    setSelectedChatSetting,
    setSettingView
} from '../../../services/redux/reducers/discussion/discussion'
import SingleContact from '../SingleContact/SingleContact'
import Avatar from '../../core/Shared/Avatar'
import ChatBox from '../ChatBox/ChatBox'
import './ChatWidget.scss'
import {useTranslation} from 'react-i18next'
import {IAuthor} from '../../../types/user.types'
import {selectUsers} from '../../../services/redux/reducers/users/users'
import {
    addListenerForDiscussion,
    addListenerForInvites,
    getIgnoredDiscussions,
    getIgnoredDiscussionsInvitations,
    groupTitle,
    shouldShowBadge
} from '../../../services/firebase/discussions/discussions'
import {doc, getDoc} from 'firebase/firestore'
import {db} from '../../../services/firebase/auth/auth'
import {MODE_DEBUG} from '../../../utils/constants/config'
import ChatSettings from './ChatSettings/ChatSettings'
import ChatHeader from './ChatHeader/ChatHeader'
import ChatSettingBox from './ChatSettingsBox/ChatSettingBox'
import {listMode} from '../../../utils/constants/discussions'
import {shortNumberFormat} from '../../../utils/helpers'


function ChatWidget() {
    enum Filter {
        ALL = "All",
        DIRECT = "Direct",
        GROUP = "Group"
    }

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const randomId = useId();

    const openDiscussion = useSelector(selectOpenDiscussion)
    const userMetaData = useSelector(selectUserMetadata);
    const storeDiscussions = useSelector(selectDiscussions);
    const chatOpen = useSelector(selectChatView);
    const users = useSelector(selectUsers)
    const settingName = useSelector(selectSettingName);
    const settingView = useSelector(selectSettingView);
    const selectChat = useSelector(selectCurrentDiscussion);
    const newChatIndicator = useSelector(selectNewMessagesIndicator);


    // TODO:Discussions Redux Interface
    const [discussions, setDiscussions] = useState(storeDiscussions);
    const [invitedDiscussions, setInvitedDiscussions] = useState<any>([]);
    const [ignoredDiscussions, setIgnoredDiscussions] = useState<any>([]);
    const [newRequestIndicator, setnewRequestIndicator] = useState<string | null>(null);

    const [filter, setFilter] = useState(Filter.ALL);
    const [currentContact, setcurrentContact] = useState<IAuthor>();
    const [loadingChat, setLoadingChat] = useState<boolean>(false);
    const [loadingSidebar, setLoadingSidebar] = useState<number>(0)
    const [chatMode, setChatMode] = useState<listMode>(listMode.discussion);
    const [ignoredChat, setIgnoredChat] = useState<any>(null)
    useEffect(() => {
        setLoadingChat(true)
        if (userMetaData) {
            addListenerForDiscussion(userMetaData?.Id);
            addListenerForInvites(userMetaData?.Id);
        }
        setLoadingChat(false)
    }, [userMetaData])


    useEffect(() => {
        if (!storeDiscussions) {
            return setDiscussions(null);
        }

        const discussionsArray = Object.values(storeDiscussions)
            .sort((a: any, b: any) => b.LastActivityDate - a.LastActivityDate);

        setDiscussions(
            discussionsArray.filter((discussion: any) => {
                if (
                    [
                        ...(discussion?.Data?.MembersIds ?? []),
                    ].includes(userMetaData?.Id)
                ) {
                    return discussion;
                }
                return false
            })
        );

        setInvitedDiscussions(
            discussionsArray.filter((discussion: any) => {
                if (
                    [
                        ...(discussion?.Data?.InvitedMembersIds ?? []),
                    ].includes(userMetaData?.Id)
                ) {
                    return discussion;
                }
                return false
            }),
        );

        const newMessageCount = discussionsArray.filter((discussion:any)=>
            shouldShowBadge(discussion, userMetaData?.Id) &&
                !discussion?.Data?.IgnoringInvitedMembersIds?.includes(userMetaData?.Id) &&
                !discussion?.Data?.IgnoringMembersIds?.includes(userMetaData?.Id)
        ).map((discussion: any) => {
            return discussion?.Id
        }).length
        if (newMessageCount > 0) {
            dispatch(setNewMessageIndicator({count: shortNumberFormat(newMessageCount)}))
        } else {
            dispatch(setNewMessageIndicator({count: false}))
        }
        userMetaData?.Id && showIgnoredDiscussions(userMetaData?.Id)

    }, [JSON.stringify(storeDiscussions), userMetaData?.Id,dispatch,storeDiscussions]);

    useEffect(() => {
        if (openDiscussion) {
            if (openDiscussion?.listMode) {
                setChatMode(openDiscussion?.listMode)
            }
            if (openDiscussion?.discussionId) {
                dispatch(setCurrentDiscussion({discussionId: openDiscussion?.discussionId}))
            } else if (openDiscussion?.userId) {
                const contactId = openDiscussion?.userId
                const orderedUserIds = [userMetaData?.Id, ...contactId].sort();
                if (openDiscussion?.type === "Direct") {
                    const docId = 'direct.' + orderedUserIds[0] + '.' + orderedUserIds[1];
                    dispatch(setCurrentDiscussion({discussionId: docId}))
                    if (users[contactId[0]]) {
                        setcurrentContact(users[contactId[0]])
                    }
                } else {
                    dispatch(setCurrentDiscussion({discussionId: randomId}))
                    const contacts = contactId.map((id: any) => users[id])
                    const groupName = groupTitle(contacts)
                    setcurrentContact({
                        Name: groupName,
                        Id: "placeholder",
                        Job: "placeholder"
                    })
                }
            }
        } else {
            if (!selectChat && discussions.length > 0) {
                let chat = discussions[0]?.Id;
                dispatch(setCurrentDiscussion({discussionId: chat}))
            }
        }
    }, [userMetaData?.Id, users, selectChat, openDiscussion, discussions, randomId, dispatch])

    useEffect(() => {
        const formattedNumber = invitedDiscussions.length ? shortNumberFormat(invitedDiscussions.length) : null;
        setnewRequestIndicator(formattedNumber)
    }, [invitedDiscussions])

    const openClose = () => {
        dispatch(setChatView())
    }
    const handleFilter = (filter: Filter) => {
        setFilter(filter);
    }
    const handleChatSelect = (discId: string) => {
        dispatch(setCurrentDiscussion({discussionId: discId}))
    }
    const handleIgnoreChatSelect = async (discId: string) => {
        dispatch(setCurrentDiscussion({discussionId: discId}))
        const docRef = doc(db, 'discussions', discId);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setIgnoredChat(docSnap.data())
            }
        } catch (err) {
            if (MODE_DEBUG) {
                console.log(err)
            }
        }
    }
    const showIgnoredDiscussions = async (userId: any) => {
        Promise.allSettled([getIgnoredDiscussions(userId), getIgnoredDiscussionsInvitations(userId)]).then((values) => {
            let dbIgnoredDiscussion: any[] = []
            values.forEach((result) => {
                if (result.status === 'fulfilled' && result.value) {
                    dbIgnoredDiscussion = [...dbIgnoredDiscussion, ...result.value]
                }
            })

            setIgnoredDiscussions(dbIgnoredDiscussion);
        })

    }

    const startNewChat = async (e: any) => {
        e.preventDefault()
        dispatch(setSelectedChatSetting({name: "newChat"}))
    }

    const getChatHeader =  useCallback(
        () => {
            switch (chatMode) {
                case listMode.discussion:
                    return t<any>('chat.chatType.messages')
                case listMode.requests:
                    return t<any>('chat.chatType.messageRequests')
                case listMode.ignored:
                    return t<any>('chat.chatType.ignoredReq')
            }
        }
      ,
      [chatMode,t],
    )

    const getChatList = useCallback(() => {
        switch (chatMode) {
            case listMode.discussion:
                return <Col
                    className={discussions.length ? 'contactlist-wrapper wb-100 aifs jcfs' : 'contactlist-wrapper wb-100 aic'}>
                    {loadingSidebar ? <LoadingSpinner/> :
                        discussions.length ?
                            discussions.filter((discussion: any) => {
                                return filter === Filter.ALL || filter === discussion.Type
                            }).map((discussion: any) => {
                                let selected: boolean = false;
                                if (selectChat === discussion.Id) {
                                    selected = true;
                                }
                                return <SingleContact key={discussion.Id} selected={selected}
                                                        setLoading={setLoadingSidebar} contact={setcurrentContact}
                                                        select={handleChatSelect} discussion={discussion}/>
                            })
                            :
                            <span className="contactlist-placeholder">
                {t<any>('chat.contactListPlaceholder')}
                </span>
                    }
                </Col>
            case listMode.requests:
                return <Col
                    className={invitedDiscussions.length ? 'contactlist-wrapper wb-100 aifs jcfs' : 'contactlist-wrapper wb-100 aic'}>
                    {loadingChat ? <LoadingSpinner/> :
                        (invitedDiscussions.length ?
                            invitedDiscussions.map((discussion: any) => {
                                let selected: boolean = false;
                                if (selectChat === discussion.Id) {
                                    selected = true;
                                }
                                return <SingleContact key={discussion.Id} selected={selected} loading={loadingSidebar}
                                                        setLoading={setLoadingSidebar} contact={setcurrentContact}
                                                        select={handleChatSelect} discussion={discussion}/>
                            })
                            :
                            <span className="contactlist-placeholder">
                {t<any>('chat.contactListPlaceholder')}
                </span>)
                    }
                </Col>
            case listMode.ignored:
                return <Col
                    className={ignoredDiscussions.length ? 'contactlist-wrapper wb-100 aifs jcfs' : 'contactlist-wrapper wb-100 aic'}>
                    {loadingChat ? <LoadingSpinner/> :
                        (ignoredDiscussions.length ?
                            ignoredDiscussions.map((discussion: any) => {
                                let selected: boolean = false;
                                if (selectChat === discussion.Id) {
                                    selected = true;
                                }
                                return <SingleContact key={discussion.Id} selected={selected} loading={loadingSidebar}
                                                        setLoading={setLoadingSidebar} contact={setcurrentContact}
                                                        select={handleIgnoreChatSelect} discussion={discussion}/>
                            })
                            :
                            <span className="contactlist-placeholder">
                                {t<any>('chat.contactListPlaceholder')}
                            </span>)
                    }
                </Col>
        }
    }, [Filter.ALL,chatMode,discussions,filter,handleChatSelect,handleIgnoreChatSelect,ignoredDiscussions,invitedDiscussions,loadingChat,loadingSidebar,selectChat,t])
    
    

    const showSetting = () => {
        dispatch(setSettingView({view: true}))
    }


    return (
        <Container className='chat-container'>
            {
                chatOpen ?
                    <Container className='chat-open'>
                        {settingView && <ChatSettings discussionId={selectChat}/>}
                        <Col className='chat-contactlist jcfs'>
                            <Container className='contactlist-header wb-100 jcsb'>
                                <Row>
                                    {(chatMode !== listMode.discussion) && <button className='return-discussion'
                                                                                   onClick={() => setChatMode(listMode.discussion)}>{goBackArrow}</button>}
                                    <span>
                  {
                      getChatHeader()
                  }
                </span>
                                </Row>
                                {
                                    (chatMode === listMode.discussion) &&
                                    <Row className='chatbox-actions'>
                                        <Button onClick={() => setChatMode(listMode.requests)}
                                                className='text-btn flex jcc aic'>{chatRequestIcon}
                                            {
                                                !!newRequestIndicator &&
                                                <Container className='new-request-indicator'>
                                                    {newRequestIndicator}
                                                </Container>
                                            }
                                        </Button>
                                        <Button onClick={startNewChat}
                                                className='text-btn flex jcc aic'>{newChatIcon}</Button>
                                    </Row>
                                }
                            </Container>
                            <Container className='chat-filter wb-100 jcsb'>

                                {(chatMode === listMode.discussion) &&
                                    <>
                                        <Button onClick={() => handleFilter(Filter.ALL)}
                                                className={filter === Filter.ALL ? "cht-fltr-btn fltr-btn-selected" : "cht-fltr-btn"}>{t<any>('chat.chatFilter.all')}</Button>
                                        <Button onClick={() => handleFilter(Filter.DIRECT)}
                                                className={filter === Filter.DIRECT ? "cht-fltr-btn fltr-btn-selected" : "cht-fltr-btn"}>{t<any>('chat.chatFilter.direct')}</Button>
                                        <Button onClick={() => handleFilter(Filter.GROUP)}
                                                className={filter === Filter.GROUP ? "cht-fltr-btn fltr-btn-selected" : "cht-fltr-btn"}>{t<any>('chat.chatFilter.group')}</Button>
                                    </>
                                }
                                {
                                    (chatMode !== (listMode.discussion)) &&
                                    <>
                                        <Button onClick={() => setChatMode(listMode.requests)}
                                                className={chatMode === listMode.requests ? "cht-fltr-btn fltr-btn-selected" : "cht-fltr-btn"}>
                                            {t<any>('chat.chatType.requests')}
                                            {newRequestIndicator &&
                                                <span className='new-request-counter'>{newRequestIndicator}</span>}
                                        </Button>
                                        <Button onClick={() => setChatMode(listMode.ignored)}
                                                className={chatMode === listMode.ignored ? "cht-fltr-btn fltr-btn-selected" : "cht-fltr-btn"}>{t<any>('chat.chatType.ignored')}</Button>
                                    </>
                                }
                            </Container>
                            {
                                getChatList()
                            }
                        </Col>
                        <Col className='chatview jcfs'>
                            <Container className='chatview-header wb-100 jcsb'>
                                {
                                    (selectChat &&
                                        currentContact) ?
                                        <Row className='contact-info-settings'>
                                            <ChatHeader settingView={settingView} currentContact={currentContact}/>
                                            {settingName !== 'newChat' && <Button onClick={() => showSetting()}
                                                                                  className='action-button'>{settingIcon}</Button>}
                                        </Row>
                                        :
                                        <span className='chatview-placeholder'>
                    {t<any>('chat.startChat')}
                  </span>
                                }
                                <Container className='chatview-actions aic'>
                                    {/* <Button className='action-button'>{sperateIcon}</Button>
                <Button className='action-button expand'>{chevronUp}</Button> */}
                                    <Button onClick={openClose} className='action-button'>{closeIcon}</Button>
                                </Container>
                            </Container>
                            <Col className='chatview-screen wb-100 aifs'>
                                {selectChat ? (
                                        <span className='chatview-wrapper'>
                  {!!settingName && <ChatSettingBox contact={currentContact}/>}
                                            <ChatBox openDiscussion={openDiscussion} discussionId={selectChat}
                                                     ignoredDiscussion={ignoredChat}
                                                     ignoreChat={chatMode === listMode.ignored}/>
                </span>
                                    )
                                    :
                                    <Col className="chatview-empty aifs jcfs">
                  <span className='empty-header'>
                    {t<any>('chat.search')}
                  </span>
                                    </Col>
                                }
                            </Col>
                        </Col>
                    </Container>
                    :
                    <Button onClick={openClose} className='flex aic chat-close jcsb'>
          <span className='flex aic'>
             {userMetaData &&
                 <Avatar id={userMetaData?.Id}/>
             }
              <span className='chat-label'>
                {t<any>('chat.chatType.messages')}
              </span>
          </span>
                        {newChatIndicator ?
                            <span className='new-chat-indicator'>{newChatIndicator}</span> :
                            chevronUp
                        }
                    </Button>

            }
        </Container>
    )
}

export default ChatWidget