import {MODE_DEBUG} from "../../utils/constants/config";
import {auth, storage} from "./auth/auth";
import {shuffle} from "lodash";
import {allAvailableNumber} from "../../utils/helpers";
import {
    getDownloadURL,
    listAll,
    ref,
    StringFormat,
    uploadBytes,
    uploadBytesResumable,
    uploadString
} from "firebase/storage";

// ----- Const
/*
 Limitation :

 This might be outdated if an user is connected on multiples devices & create a post on the other device in the same hour

 In this case, this device may not know the id is already taken, and decide to use it.

 To limit this problem, we applied a shuffle on the ids array.
 */
const availableUserAssetIdsByHour: any = {};


/**
 * Upload the audio in cloudStorage, and get the associated url
 *
 * @param audio
 * @returns {Promise<*>}
 */
export const uploadAudioAttachement = async (audio: any) => {
    const blob = audio.blob;
    const timestampHour = Math.floor(Date.now() / 3600000);
    const userAuth: any = auth.currentUser
    const availableAssetIds = await getAvailableUserAssetIds(userAuth?.uid, timestampHour)
    const path = `user-assets/${userAuth?.uid}/${timestampHour}/${availableAssetIds.shift()}`;
    const audioPath = path + '.mp4';
    const storageRef = ref(storage, audioPath);
    await uploadBytes(storageRef, blob)
    return getDownloadURL(storageRef)
}

export const uploadUserAsset = async (
    asset: any,
    type?: any,
    setProgressValue: any = null,
    setVideoUploadError: any = null) => {
    if (!type) {
        type = 'image'
    }
    if (
        type === 'image' &&
        (!asset) &&
        type === 'video' &&
        !asset.path
    ) {

        if (MODE_DEBUG) {
            console.warn(
                `uploadUserAsset was called but asset:${JSON.stringify(
                    asset,
                )}, are falsy or type:${type} !== 'video' and asset.path is falsy`,
            );
        }
        return null;
    }

    const timestampHour = Math.floor(Date.now() / 3600000);
    const user: any = auth.currentUser
    const userId = user?.uid;

    if (!userId) {
        if (MODE_DEBUG) {
            console.warn('uploadUserAsset called but no user is logged-in !');
        }
        return;
    }

    let availableAssetIds = await getAvailableUserAssetIds(userId, timestampHour);
    if (availableAssetIds.length === 0) {
        const e: any = new Error('Too many asset uploaded this hour');
        e.code = 'hourly-limit-reached';
        throw e;
    }

    const path = `user-assets/${userId}/${timestampHour}/${availableAssetIds.shift()}`;

    switch (type) {
        case 'image':
            const imageRef = ref(storage, path)
            if (asset.dataUrl) {
                return await uploadString(imageRef, asset.dataUrl, 'data_url')
                    .then(() => getAssetUrl(path));
            } else {
                return await uploadBytes(imageRef, asset)
                    .then(() => getAssetUrl(path));
            }
        case 'video':
            const response = await fetch(asset?.path);
            const blob = await response.blob();
            const videoPath = path + '.mp4';

            const videoRef = ref(storage, videoPath)

            const uploadTask = uploadBytesResumable(videoRef, blob)

            uploadTask.on('state_changed', (snapshot: any) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes;
                if (setProgressValue) {
                    setProgressValue(parseFloat(progress.toFixed(1)) * 100);
                }
            })

            return await uploadTask
                .then(() => {
                    return getAssetUrl(videoPath)
                })
                .catch(err =>
                    setVideoUploadError
                        ? setVideoUploadError('upload')
                        : console.debug('An error occurred during video upload error', err),
                );
        default:
            if (MODE_DEBUG) {
                console.warn('uploadUserAsset was called with unknown type :', type);
            }
            return null;
    }
}


export const getAssetUrl = async (path: any) => {
    const storageRef = ref(storage, path)
    return await getDownloadURL(storageRef)
}


// ----- Read
/**
 * Get a list of available Id for a given timestampHour
 *
 * This function take a list of [*int*], and remove all the used Id (the name of the files on cloudStorage)
 *
 * @param userId
 * @param timestampHour
 * @returns {Promise<int[]>}
 */
async function getAvailableUserAssetIds(userId: any, timestampHour: any) {
    if (!availableUserAssetIdsByHour[timestampHour]) {
        // We haven't found the available ids for this hour
        availableUserAssetIdsByHour[timestampHour] = new Promise(
            (resolve, reject) => {
                const userAssetsOfTheHour = ref(storage, `user-assets/${userId}/${timestampHour}`);
                listAll(userAssetsOfTheHour)
                    .then((res: any) =>
                        res.items.map((itemRef: any) => parseInt(itemRef.name.split('.')[0], 10)),
                    )
                    .then((itemsIds: any) => resolve(shuffle(allAvailableNumber(itemsIds))))
                    .catch(reject);
            },
        );
    }

    return availableUserAssetIdsByHour[timestampHour];
}

export async function updateUserAvatar({
                                           userId,
                                           data,
                                           contentType
                                       }: { userId?: string, data?: string, contentType?: string }) {
    if (!contentType || !data) {
        if (MODE_DEBUG) {
            console.warn('setUserPictureUrl was called without an image');
        }
        return;
    }

    const storageRef = ref(storage, `profile-images/${userId}`)

    if (data.includes('base64,')) {
        const base64Data = data.split('base64,')[1];

        return await uploadString(storageRef, base64Data, StringFormat.BASE64, {
            contentType,
        });
    }
}

export const uploadUserImage = async ({
                                          userId,
                                          imageFile,
                                      }: {
                                          userId: string,
                                          imageFile: File,
                                      },
) => {
    const timestampHour = Math.floor(Date.now() / 3600000);
    let availableAssetIds = await getAvailableUserAssetIds(userId, timestampHour);
    if (availableAssetIds.length === 0) {
        const e: any = new Error('Too many asset uploaded this hour');
        e.code = 'hourly-limit-reached';
        throw e;
    }
    const storageRef = ref(storage, `user-assets/${userId}/${timestampHour}/${availableAssetIds.shift()}`)
    const uploadTask = await uploadBytesResumable(storageRef, imageFile);
    return await getDownloadURL(uploadTask.ref);
}

// ----- Create
/**
 * Upload the images in cloudStorage, and get return the associated url
 *
 * @param images
 * @returns {Promise<{Images: any[]}>}
 */
export const uploadImageAttachement = async (images: File[]) => {

    const Images: Array<string> = []
    
    await Promise.all(
        images.map(async (image: any) => {
            const imageUrl: any = await uploadUserAsset(image.File);
            Images.push(imageUrl)
            if (MODE_DEBUG) {
                console.log('resolved')
            }
        })
    );
    return {Images}
}


export async function uploadUserVideos(
    videos: any,
    setProgressValue: any,
    setVideoUploadError: any,
) {
    const videosArray = await Promise.all(
        videos.map(async (video: any) => {
            if (video.path && video.width && video.height && video.thumbnail) {
                const thumbnailUrl = await uploadUserAsset(video.thumbnail);
                const videoUrl = await uploadUserAsset(
                    video,
                    'video',
                    setProgressValue,
                    setVideoUploadError,
                );
                return {
                    width: parseInt(video.width, 10),
                    height: parseInt(video.height, 10),
                    size: video.size,
                    videoUrl,
                    thumbnailUrl,
                };
            } else {
                if (MODE_DEBUG) {
                    console.warn(
                        'uploadUserVideos was called with videos but no path, thumbnail, width or height attribute',
                    );
                }
            }
        }),
    );

    return videosArray ? videosArray[0] : {};
}