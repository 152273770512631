import { useRef, useState } from 'react'
import { forwardIcon, closeIcon, micIcon } from '../../../assets/images/svgIcons/svgIcons'
import { IFlattenedMessage } from '../../../types/discussion.types'
import { Row } from '../../core/Container'
import { Picture } from '../../core/Picture'
import './ReplyBox.scss'

const ReplyBox = ({QuotedMessage,clearQuote,sender}:{QuotedMessage:IFlattenedMessage,clearQuote?:any,sender?:boolean}) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [audioDuration, setaudioDuration] = useState<String>()
    const fancyTimeFormat = (duration:any) =>{   
        // Hours, minutes and seconds
        let hrs = ~~(duration / 3600);
        let mins = ~~((duration % 3600) / 60);
        let secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }
    const onLoadedMetaData = () =>{
        if (audioRef.current){
            let seconds = audioRef.current.duration
            let time = fancyTimeFormat(seconds)
            setaudioDuration(time);
        }
    }
    return (
    <Row className={'reply-wrapper '+(!clearQuote&&(sender?'sender-reply':'reciever-reply'))}>
        <span className='reply-display'>
            <span className='reply-Author'>
                {QuotedMessage?.Author?.Name}
            </span>
            {!QuotedMessage?.Text && <span className='reply-image'>
                {QuotedMessage.Images?.slice(0, 2).map(((image: string, i: number) => {
                return (
                    <Picture key={i} alt='img' src={image} />
                )
                }))}
            </span>}
            {!QuotedMessage?.Text && QuotedMessage?.Audio &&
                <span className='reply-audio'>
                    <audio ref={audioRef} src={QuotedMessage.Audio} onLoadedMetadata={onLoadedMetaData}>
                    </audio>
                    {micIcon}
                    <p>
                        {audioDuration}
                    </p>
                </span>

            }
            {QuotedMessage?.SharedPostId &&
                <span className='reply-post'>
                {forwardIcon} shared post
                </span>
            }
            {QuotedMessage?.Text &&
                <span className='reply-text'>
                    {QuotedMessage.Text}
                </span>
            }
        
        </span>
        {
            clearQuote &&
            <button onClick={clearQuote} className='delete-reply'>
                {closeIcon}
            </button>
        }
    </Row>
  )
}

export default ReplyBox