import React, { useCallback } from 'react'
import { unfollowUser, followUser } from '../../services/firebase/user/user';
import { MODE_DEBUG } from '../../utils/constants/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, mapUserInfos } from '../../services/firebase/auth/auth';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, selectUserMetadata } from '../../services/redux/reducers/userAuth/user';


const useFollowUnfollow = () => {
    const dispatch = useDispatch();
    const [user] = useAuthState(auth);
    const currentUserMetadata = useSelector(selectUserMetadata);

    const getUserMetadata = useCallback(
        async () => {
            if (user) {
                const userMetadata = await mapUserInfos(user?.uid)
                dispatch(authActions.setUserMetadata({userMetadata}));
            }
        },
        [user, dispatch],);

    const onClickUnfollow = (targetUserId:string) =>{
        if(!user?.uid){
            if(MODE_DEBUG) console.error("Follow function was called but userId is falsy")
            return
        }
        const userId:string = user?.uid
        unfollowUser(userId, targetUserId, currentUserMetadata?.FollowingUsers ?? []).then(() => {
            getUserMetadata();
        });
    }
    const onClickFollow = (targetUserId:string)=>{
        if(!user?.uid){
            if(MODE_DEBUG) console.error("Follow function was called but userId is falsy")
            return
        }
        const userId:string = user?.uid
        followUser(userId, targetUserId, currentUserMetadata?.FollowingUsers ?? []).then(() => {
            getUserMetadata();
        });
    }

    return { onClickFollow, onClickUnfollow}
}

export default useFollowUnfollow