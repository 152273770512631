import "./FinancialDataPopup.scss";
import {FC, useMemo} from "react";
import Modal from "../../../../../core/Modal/Modal";
import {Col, Container, Row} from "../../../../../core/Container";
import IdeaChart from "../../../../../core/Chart/IdeaChart/IdeaChart";
import {IMarketItem} from "../../../../../../types/idea.types";
import FinancialDataActionPoints from "../FinancialDataActionPoints/FinancialDataActionPoints";
import PostProgress from "../PostProgress/PostProgress";
import {PostType} from "../../../../../../types/post.types";
import PostHeader from "../../PostHeader/PostHeader";
import AssetImage from "../../../../Market/AssetImage/AssetImage";
import LiveAssetPrice from "../../../../Feed/miscs/Idea/LiveAssetPrice/LiveAssetPrice";
import {Button} from "../../../../../core/Button";
import {closePopupIcon} from "../../../../../../assets/images/svgIcons/svgIcons";

type FinancialDataPopupType = {
    show: boolean,
    onClose: any,
    className?: string,
    header?: any,
    postCreationTime?: Date | null,
    postData: PostType
}

const FinancialDataPopup: FC<FinancialDataPopupType> = ({show, onClose, postCreationTime, postData}) => {


    const ideaChart = useMemo(() => {
        const marketItem = postData?.FinancialData?.Instrument?.MarketItem;
        const hasFinancialData = postData?.FinancialData?.EntryValue || postData?.FinancialData?.BreakEven || postData?.FinancialData?.StopLoss || postData?.FinancialData?.TakeProfits;

        if (marketItem && postCreationTime && hasFinancialData && postData?.FinancialData) {
            return (
                <Container className='tr-chart'>
                    <IdeaChart
                        financialData={postData?.FinancialData}
                        ideaCreationTime={postCreationTime}
                    />
                </Container>
            )
        }
    }, [postData?.FinancialData, postCreationTime]);


    const ideaProgressBar = useMemo(() => {
        const hasFinancialData = postData?.FinancialData?.EntryValue ||
            postData?.FinancialData?.BreakEven || postData?.FinancialData?.StopLoss || postData?.FinancialData?.TakeProfits;
        if (hasFinancialData && postCreationTime) {
            return <PostProgress financialData={postData?.FinancialData} ideaCreationTime={postCreationTime}/>
        }
    }, [postData?.FinancialData, postCreationTime]);


    const postHeader = useMemo(() => {
        return <PostHeader
            postAuthor={postData.Author}
            postCreationDate={postData?.Date}
            postCategory={postData?.Category}
            groupDetail={postData?.GroupDetail}
            groupId={postData?.GroupId}
            postFinancialData={postData?.FinancialData}
            isFinancialPopUp = {true}
        />
    }, [postData.Author, postData?.Category, postData?.Date, postData?.FinancialData, postData?.GroupDetail, postData?.GroupId]);


    const financialData = useMemo(() => {
        if (postData?.FinancialData) {
            const assetStocksWatchlistItem = {
                name: postData?.FinancialData?.Instrument?.MarketItem?.Name,
                symbol: postData?.FinancialData?.Instrument?.MarketItem?.Symbol,
                market: postData?.FinancialData?.Instrument?.MarketItem?.Market,
                to: postData?.FinancialData?.Instrument?.MarketItem?.To,
            };
            let assetLabel = postData?.FinancialData?.Instrument?.MarketItem?.Name;
            if (postData?.FinancialData?.Instrument?.MarketItem?.Symbol && postData?.FinancialData?.Instrument?.MarketItem?.To) {
                assetLabel = `${postData?.FinancialData?.Instrument?.MarketItem?.Symbol}/${postData?.FinancialData?.Instrument?.MarketItem?.To}`
            }

            return (
                <Row className='jcsb fdp-asset'>
                    <Row className="fdp-asset-info">
                        <AssetImage stocksWatchlistItem={assetStocksWatchlistItem} className="fdp-asset-img"/>
                        <h6>{assetLabel}</h6>
                    </Row>
                    {postData?.FinancialData?.Instrument?.MarketItem &&
                        <LiveAssetPrice marketItem={postData?.FinancialData?.Instrument?.MarketItem as IMarketItem}/>}
                </Row>
            )
        }
    }, [postData]);

    return (
        <Modal
            show={show}
            onClose={onClose}
            className="financial-data-popup"
            header={(
                <Row className="wb-100 jcfe">
                    <Button className="text-btn" onClick={onClose}>{closePopupIcon}</Button>
                </Row>
            )}
        >
            <Col className="fdp-body">
                <Row className="fdp-header">
                    <Row className="wb-100 fdp-header-section">
                        {postHeader}
                        {financialData}
                    </Row>
                    <Row className="wb-100 jcsb fdp-header-section">
                        <FinancialDataActionPoints financialData={postData?.FinancialData}/>
                        {ideaProgressBar}
                    </Row>
                </Row>
                {ideaChart}
            </Col>
        </Modal>
    )
}

export default FinancialDataPopup;