import {ChangeEvent, FC, FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Button} from "../../Button";
import {closeIcon, searchIcon} from "../../../../assets/images/svgIcons/svgIcons";
import {TextInput} from "../../Input";
import _ from "lodash";
import "./DebouncedSearchInput.scss";
import clsx from "clsx";
import {useLocation} from "react-router-dom";

const DebouncedSearchInput: FC<{
    onSearch: (term: string) => void,
    className?: string,
    placeholder?: string,
    onSubmit?: (e: FormEvent<HTMLFormElement>) => void
}> = ({
          onSearch,
          className,
          placeholder,
          onSubmit
      }) => {

    const {search} = useLocation();
    const [term, setTerm] = useState<string>("");

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(search);
        const q = urlSearchParams.get('q');
        setTerm(q ?? '');
    }, [search]);

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onSearch(event.target.value);
    }, [onSearch]);

    const handleClear = useCallback(() => {
        onSearch("");
        setTerm("");
    }, [onSearch]);

    const debouncedResults = useMemo(() => {
        return _.debounce(handleSearch, 500);
    }, [handleSearch]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        }
    }, [debouncedResults]);

    return (
        <form onSubmit={onSubmit} className={clsx('flex aifs jcfs si-form', className)} autoComplete="off">
            {!term && <Button className='text-btn flex jcc aic amp-search-btn' type='button'>
                {searchIcon}
            </Button>}
            <TextInput
                name="searchInput"
                type="text"
                className="amp-search-input"
                placeholder={placeholder}
                onChange={(event) => {
                    setTerm(event.target.value);
                    debouncedResults(event);
                }}
                value={term}
            />
            {term && <Button className='text-btn flex jcc aic amp-search-btn' type='button' onClick={handleClear}>
                {closeIcon}
            </Button>}
        </form>
    )
}

export default DebouncedSearchInput;