import { useEffect, useState } from 'react'
import { RecordState } from 'audio-react-recorder'
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import AudioAnalyser from '../audio-miscs/AudioAnalyser';
import Waveform from '../WaveForm'
import MyStopwatch from '../audio-miscs/AudioTimer';
import { MODE_DEBUG } from '../../../../../utils/constants/config'
import './AudioRecorder.scss'
import { Row } from '../../../../core/Container';

const AudioRecorder = ({audioRecorded}:any) => {

    const [recordState, setRecordState] = useState<any>(null);
    const [audioData, setAudioData] = useState<any>(null);
    const [audio, setAudio] = useState<any>(null);
    const [play,setPlay] = useState<boolean>(false);
    const [control, setControl] = useState<any>();

    const recordAudio = () => {
        return new Promise(resolve => {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
              setAudio(stream);
              const mediaRecorder = new MediaRecorder(stream, {
                mimeType: 'audio/wav',
              });
              const audioChunks:any[] = [];
              mediaRecorder.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
              });
      
              const start = () => {
                mediaRecorder.start();
              };
      
              const stop = () => {
                return new Promise(resolve => {
                  mediaRecorder.addEventListener("stop", () => {
                    const blob = new Blob(audioChunks, {
                      type: 'audio/wav',
                    });
                    const url = URL.createObjectURL(blob);
                    resolve({ blob, url, type: "audio/wav" });
                  });
                  mediaRecorder.stop();
                });
              };
      
              resolve({ start, stop });
            });
        });
      };

      useEffect(()=>{
        const setup = async () => {
          await register(await connect());
        };
        setup();
        recordAudio().then(setControl);

        return () => {
          setControl(null);
        }
      }, []);

    const start = async () => {
        if(MODE_DEBUG){
            console.log("This is record state",RecordState)
        }
        control.start();
        setRecordState(RecordState.START)
    }
    const stop = () => {
        setRecordState(RecordState.STOP)
        control.stop().then((data: any) => {
          setAudioData(data)
          audioRecorded(data)
        });

    }
    const reset = () =>{
        setRecordState(null);
        setAudioData(null);
        setAudio(null)
        audioRecorded(null);
        setControl(null);
        setPlay(false);
        recordAudio().then(setControl)
    }
    
    const setAudioDisplay =(state:any)=>{
        if(audio && state === 'start'){
            return <AudioAnalyser audio={audio} startRecording={start} stopRecording={stop} resetRecording={reset}/>
        }
        else if(audioData && state === 'stop'){
            return <Waveform Playing={play} audioData={audioData} />
        }
        else{
            return (<Row className='empty-wave wb-100 aic jcc'>
                <svg height="auto" width="100%">
                    <line x1="0" y1="50%" x2="100%" y2="50%" style={{stroke:"#01A385",strokeWidth:2}} />
                </svg>
            </Row>)
        }
    }

    useEffect(()=>{
      if(recordState ===RecordState.START){
        setTimeout(()=>{
          stop()
        },600000)
      }
    },[recordState])

  return (
        <Row className='wb-100'>
                <Row className='audio-recorder wb-100'>
                    <MyStopwatch play={play} setplay={setPlay}  recordState={recordState} startRecording={start} stopRecording={stop} resetRecording={reset}/>
                    {
                        setAudioDisplay(recordState)
                    }
                </Row>
        </Row>
  )
}

export default AudioRecorder