import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ignoreDiscussionInvitation, joinDiscussion, stopIgnoringDiscussion, stopIgnoringDiscussionInvitation } from '../../../../services/firebase/discussions/discussions'
import { addMessageListener } from '../../../../services/realtime/realtimeChannel'
import { Col } from '../../../core/Container'
import ChatBubble from '../../ChatBubble/ChatBubble'
import './InviteScreen.scss'

const InviteScreen = ({discussion, userId, discussionId}:any) => {
    const {t} = useTranslation()

    const [ignoredInvitation, setignoredInvitation] = useState<boolean>(false)
    const [ignoredMessage, setIgnoredMessage] = useState<boolean>(false)

    const message = discussion.Data.LastMessageData;
    useEffect(() => {
      const {Data} = discussion
      if(Data?.IgnoringInvitedMembersIds?.includes(userId)){
        setignoredInvitation(true)
      }
      else if(Data?.IgnoringMembersIds?.includes(userId)){
        setIgnoredMessage(true)
      }
      else{
        setIgnoredMessage(false)
        setignoredInvitation(false)
      }
    }, [discussion,userId])
    
    // Accept to join a discussion, used for private discussions
    const acceptDiscussionInvitation = useCallback(
      () => {
        if(ignoredMessage){
          stopIgnoringDiscussion(discussionId)
        }else if(ignoredInvitation){
          stopIgnoringDiscussionInvitation(discussionId)
        }
        else{
          joinDiscussion(discussionId)
        }

      },
      [discussionId,ignoredInvitation,ignoredMessage],
    )
     // Reject to join a discussion, used for private discussions
    const onPressIgnoreDiscussionInvitation = useCallback(async () => {
        // setHasJustLeftDiscussion(true);
        await ignoreDiscussionInvitation(discussionId);
    }, [discussionId]);
    
  return (
    <Col className='invite-wrapper jcfe'>
        <ChatBubble message={message} index={0} userId={userId}/>
        <Col className='invite-actions'>
            <button onClick={()=>acceptDiscussionInvitation()} className='invite-accept'>
                {(ignoredInvitation||ignoredMessage)?t<any>("chat.inviteScreen.stopIgnore"):t<any>("chat.inviteScreen.acceptChat")}
            </button>
            {
              (!ignoredInvitation&&!ignoredMessage)&&
              <button onClick={()=>onPressIgnoreDiscussionInvitation()} className='invite-ignore'>
                  {t<any>("chat.inviteScreen.ignoreChat")} 
              </button>
            }
        </Col>
    </Col>
  )
}

export default InviteScreen