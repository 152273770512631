import {FC, useEffect, useMemo, useState} from "react";
import {Row} from "../../../../../core/Container";
import {useSelector} from "react-redux";
import {selectMarketPrices} from "../../../../../../services/redux/reducers/marketPrices/marketPrices";
import {
    addListenerToAssetEvents,
    getAssetPriceKey,
    removeListenerToAssetEvents
} from "../../../../../../services/realtime/realtimeChannel";
import {IMarketItem} from "../../../../../../types/idea.types";
import "./LiveAssetPrice.scss";
import {priceFormat} from "../../../../../../utils/helpers";
import numeral from "numeral";
import {arrowDownRed, arrowUpGreen} from "../../../../../../assets/images/svgIcons/svgIcons";

const LiveAssetPrice: FC<{
    marketItem: IMarketItem,
    showPrice?: boolean,
    showPercentage?: boolean
    showTo?: boolean
}> = ({
          marketItem,
          showPrice = true,
          showPercentage = true,
          showTo = false,
      }) => {

    const marketPrices = useSelector(selectMarketPrices);
    const [assetMarketPrice, setAssetMarketPrice] = useState<{ price: number, key: string, bid: number, ask: number, percentageChange: string }>();

    useEffect(() => {
        if (!marketItem) {
            return;
        }
        addListenerToAssetEvents(marketItem);
        return () => removeListenerToAssetEvents(marketItem);
    }, [marketItem]);

    useEffect(() => {
        if (marketPrices && marketItem) {
            const key = getAssetPriceKey(marketItem);
            if (key) {
                if (Object.hasOwn(marketPrices, key)) {
                    setAssetMarketPrice(marketPrices[key]);
                }
            }
        }
    }, [marketPrices, marketItem]);

    const formattedPercentage = useMemo(() => {
        if (assetMarketPrice?.percentageChange) {
            const parsedPercentage = parseFloat(assetMarketPrice?.percentageChange);

            return (
                <Row className={`jcsb fs-in-mv ${(parsedPercentage ?? 0) < 0 ? 'red' : ''}`}>
                        {parsedPercentage <= 0 ? arrowDownRed : arrowUpGreen}
                        <p>{`${numeral(Math.abs(parsedPercentage)).format('0,0.00')}`}%</p>
                </Row>
            );
        }
    }, [assetMarketPrice?.percentageChange]);

    return (
        <>
            {assetMarketPrice && <Row className={`fs-in-right jcfs`}>
                {showPercentage && formattedPercentage}
                {showPrice && <h5>{priceFormat(assetMarketPrice?.price ?? 0)} {showTo && marketItem?.To}</h5>}
            </Row>}
        </>
    )
}

export default LiveAssetPrice;