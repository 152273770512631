import { betsIcon, clubDealsIcon, commoditiesIcon, cryptoIcon, defaultCategory, forexIcon, moneySeekingIcon, networkingBusinessIcon, nftsIcon, onlineBusinessIcon, realEstateIcon, stocksIcon } from "../../assets/images/svgIcons/svgIcons";

const ideaType = [
    'idea', 'analysis', 'tradingPlan'
]
const ideaExpiration = [{ timeFrame: 'short', expiration: '1' }, { timeFrame: 'middle', expiration: '5' }, { timeFrame: 'long', expiration: '10' }]

export const superAdmin = "SUPERADMIN"

export const filterTypes = 'types',
  filterTimeFrame = 'timeFrame',
  filterAssetTypes = 'assetTypes',
  filterTypeSubscription = 'subscription',
  filterTypeActive = 'active',
  filterTypeInactive = 'inactive',
  filterTypeCategory = 'category';

const ideaAssetType = ['real estate','forex','stocks','crypto','nfts','networking business','online business','bets','commodities','club deals', 'money seeking']
const ideaTimesFrames = ['short', 'middle', 'long']
const ideaStatus = [filterTypeActive,filterTypeInactive]

export const signalPublisher = "signal_publisher"
export const Public = 'public'

export const ideasFilters = {
  [filterTypes]: [
    {labelPath: 'idea.status.active', value: filterTypeActive},
    {labelPath: 'idea.status.inactive', value: filterTypeInactive},
  ],
  [filterTimeFrame]: [
    {labelPath: 'idea.timeFrame.short', value: 'short'},
    {labelPath: 'idea.timeFrame.middle', value: 'middle'},
    {labelPath: 'idea.timeFrame.long', value: 'long'},
  ],
  [filterTypeCategory]: [
    {labelPath: 'idea.categories.STOCKS', value: 'STOCKS'},
    {labelPath: 'idea.categories.FOREX', value: 'FOREX'},
    {labelPath: 'idea.categories.CRYPTO', value: 'CRYPTO'},
    {labelPath: "idea.categories.NFT'S", value: "NFT'S"},
    {
      labelPath: 'idea.categories.NETWORKING_BUSINESS',
      value: 'NETWORKING_BUSINESS'
    },
    {
      labelPath: 'idea.categories.ONLINE_BUSINESS',
      value: 'ONLINE_BUSINESS'
    },
    {labelPath: 'idea.categories.BETS', value: 'BETS'},
    {labelPath: 'idea.categories.REAL_ESTATE', value: 'REAL_ESTATE'},
    {labelPath: 'idea.categories.COMMODITIES', value: 'COMMODITIES'},
    {labelPath: 'idea.categories.MONEY_SEEKING', value: 'MONEY_SEEKING'},
    {labelPath: 'idea.categories.CLUB_DEALS', value: 'CLUB_DEALS'},
  ],
};

export const categoryIcons = {
 'REAL_ESTATE':realEstateIcon,
 'FOREX':forexIcon,
 'STOCKS':stocksIcon,
 'CRYPTO':cryptoIcon,
 "NFT'S":nftsIcon,
 'NETWORKING_BUSINESS':networkingBusinessIcon,
 'ONLINE_BUSINESS':onlineBusinessIcon,
 'BETS':betsIcon,
 'COMMODITIES':commoditiesIcon,
 'CLUB_DEALS':clubDealsIcon,
 'MONEY_SEEKING':moneySeekingIcon,
 "GENERAL":defaultCategory,
  "MINDSET":defaultCategory
}

enum buttonTypes{
    profile = 'profile',
    search= 'search',
    notification = 'notification'
}

export enum AssetFilterType {
  all = "all",
  crypto = "crypto",
  forex = "forex",
  index = "index",
  stock = "stock"
}


export { ideaType, ideaAssetType, ideaTimesFrames, ideaExpiration, ideaStatus, buttonTypes }