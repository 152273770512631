import {awardsImages} from "../../assets/images/awards";

export const awardsData: { [k: string]: { order: number, name: string, icon: string, description: string } } = {
    Tipser: {
        order: 0,
        name: "idea.awardsPopup.awards.Tipser.name",
        icon: awardsImages.Tipser,
        description: "idea.awardsPopup.awards.Tipser.description",
    },
    Tumbup: {
        order: 1,
        name: "idea.awardsPopup.awards.Tumbup.name",
        icon: awardsImages.Tumbup,
        description: "idea.awardsPopup.awards.Tumbup.description",
    },
    Love: {
        order: 2,
        name: "idea.awardsPopup.awards.Love.name",
        icon: awardsImages.Love,
        description: "idea.awardsPopup.awards.Love.description",
    },
    Grateful: {
        order: 3,
        name: "idea.awardsPopup.awards.Grateful.name",
        icon: awardsImages.Grateful,
        description: "idea.awardsPopup.awards.Grateful.description",
    },
    CashMachine: {
        order: 4,
        name: "idea.awardsPopup.awards.CashMachine.name",
        icon: awardsImages.CashMachine,
        description: "idea.awardsPopup.awards.CashMachine.description",
    },
    Bear: {
        order: 5,
        name: "idea.awardsPopup.awards.Bear.name",
        icon: awardsImages.Bear,
        description: "idea.awardsPopup.awards.Bear.description",
    },
    Bull: {
        order: 6,
        name: "idea.awardsPopup.awards.Bull.name",
        icon: awardsImages.Bull,
        description: "idea.awardsPopup.awards.Bull.description",
    },
    KickAssTrade: {
        order: 7,
        name: "idea.awardsPopup.awards.KickAssTrade.name",
        icon: awardsImages.KickAssTrade,
        description: "idea.awardsPopup.awards.KickAssTrade.description",
    },
    IWantYouToBeHappy: {
        order: 8,
        name: "idea.awardsPopup.awards.IWantYouToBeHappy.name",
        icon: awardsImages.IWantYouToBeHappy,
        description: "idea.awardsPopup.awards.IWantYouToBeHappy.description",
    },
    Unicorn: {
        order: 9,
        name: "idea.awardsPopup.awards.Unicorn.name",
        icon: awardsImages.Unicorn,
        description: "idea.awardsPopup.awards.Unicorn.description",
    },
    Diamond: {
        order: 10,
        name: "idea.awardsPopup.awards.Diamond.name",
        icon: awardsImages.Diamond,
        description: "idea.awardsPopup.awards.Diamond.description",
    },
    MulticolorGreenbull: {
        order: 11,
        name: "idea.awardsPopup.awards.MulticolorGreenbull.name",
        icon: awardsImages.MulticolorGreenbull,
        description: "idea.awardsPopup.awards.MulticolorGreenbull.description",
    },
};

export const minimumWithdrawAmountInUSD = 100;