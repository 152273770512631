import {FC} from "react";
import "./ModalWithImage.scss";
import Modal, {ModalPropsType} from "../Modal";
import {Container, Row} from "../../Container";
import clsx from "clsx";
import {Button} from "../../Button";
import {closePopupIcon, goBackArrow} from "../../../../assets/images/svgIcons/svgIcons";
import {Picture} from "../../Picture";

interface ModalWithImagePropsType extends ModalPropsType {
    onGoBack?: () => void,
    imgSrc?: string,
}

const ModalWithImage: FC<ModalWithImagePropsType> = ({show, onClose, className, children, onGoBack, imgSrc}) => {
    return (
        <Modal show={show} onClose={onClose} className={clsx("modal-with-image", className)} header={
            <Row className={`jcfs aifs mwi-header ${!imgSrc?'mwi-without-img-header':''}`}>
                {onGoBack ? <Button className="text-btn mwi-back-btn" onClick={onGoBack}>{goBackArrow}</Button> : <Container/>}
                {imgSrc && <Picture src={imgSrc} alt="" className="mwi-image"></Picture>}
                <Button className="text-btn mwi-close-btn" onClick={onClose}>{closePopupIcon}</Button>
            </Row>}>
                {children}
        </Modal>
    )
}

export default ModalWithImage;