import {analyticsTimeSeriesHttpsCallable} from "../firebaseFunctions";

export const getAnalyticsTimeSeries = (marketItems: any,
                                       requestedFrom: number,
                                       requestedTo: number,
                                       timeframe: string,
                                       resolution?: string,
) => {

    return analyticsTimeSeriesHttpsCallable({
        marketItems,
        "from": requestedFrom,
        "to": requestedTo,
        "resolution": resolution ? resolution : timeframe === "long" ? "1d" : "4h",
        "synchronous": true,
        "forceTimeframe": true
    });
}