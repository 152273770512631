import React, {useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { goBackArrow } from '../../../../assets/images/svgIcons/svgIcons'
import { getUserDiscussionOptions } from '../../../../services/firebase/discussions/discussions'
import { selectNotificationActivated, selectSettingName, selectSingleDiscussion, setConfirmationData, setConfirmationScreen, setDiscussionSetting, setNotificationActivated, setSelectedChatSetting, setSettingView } from '../../../../services/redux/reducers/discussion/discussion'
import { selectUserMetadata } from '../../../../services/redux/reducers/userAuth/user'
import { settingConfirmationModal, settingsActions } from '../../../../utils/constants/discussions'
import { Col, Container, Row } from '../../../core/Container'
import './ChatSettings.scss'

const ChatSettings = ({discussionId}:any) => {
  const user:any = useSelector(selectUserMetadata)
  const discussion:any = useSelector((state:any)=>selectSingleDiscussion(state,discussionId))
  const selected:any = useSelector(selectSettingName)
  const notificationActivated = useSelector(selectNotificationActivated)

  const {t} = useTranslation()
  const dispatch = useDispatch()


  const [buttonData, setbuttonData] = useState<any[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(discussion?.Data?.AdminsIds.includes(user?.Id));

  useEffect(() => {
    if(discussion){
      dispatch(setDiscussionSetting({discussion:discussionId}))
      switch(discussion?.Type){
        case "Direct":
          setbuttonData(settingsActions.direct)
          break;
        case "Group":
          setIsAdmin(discussion?.Data?.AdminsIds.includes(user?.Id))
          setbuttonData(settingsActions.group)
      }
    }
    return(()=>{
      dispatch(setDiscussionSetting({discussion:false}))
    })
  }, [discussion,discussionId,user?.Id])

  
  useEffect(()=>{
    if(buttonData){
      dispatch(setSelectedChatSetting({name:buttonData[0]?.name}))
    }
  },[buttonData])


  // Fetch the discussion options
  useEffect(() => {
    if (notificationActivated === null) {
      getUserDiscussionOptions(discussionId).then((options:any) => {
        let activated:boolean = true;
        if (options?.DisabledNotifications === true) {
          activated = false;
        }
        dispatch(setNotificationActivated({muted:activated}))
      });
    }
  }, [discussionId, notificationActivated]);

  const handleClick = (name:any)=>{
    
    dispatch(setSelectedChatSetting({name}))
    switch (name){
      case settingsActions.direct[3].name:
        dispatch(setConfirmationScreen({view:true}))
        dispatch(setConfirmationData({
        data:{
          name:settingConfirmationModal.ignore,
          heading:t('chat.setting.modal.ignore.heading'),
          text:t('chat.setting.modal.ignore.text'),
          buttonText:t('chat.setting.modal.ignore.buttonText')
        }
      }))
        break;
      case settingsActions.group[4].name:
        dispatch(setConfirmationScreen({view:true}))
        dispatch(setConfirmationData({
          data:{
          name:settingConfirmationModal.leave,
          heading:t('chat.setting.modal.leave.heading'),
          text:t('chat.setting.modal.leave.text'),
          buttonText:t('chat.setting.modal.leave.buttonText')
        }
      }))
        break;
      case settingsActions.direct[1].name:
        dispatch(setConfirmationScreen({view:true}))
        dispatch(setConfirmationData({data:{
          name:settingConfirmationModal.mute,
          heading:t('chat.setting.modal.mute.heading'),
          text:t('chat.setting.modal.mute.text'),
          buttonText:t('chat.setting.modal.mute.buttonText')
        }
        }))
        break;
      case settingsActions.direct[2].name:
        dispatch(setConfirmationScreen({view:true}))
        dispatch(setConfirmationData({data:{
          name:settingConfirmationModal.unmute,
          heading:t('chat.setting.modal.unmute.heading'),
          text:t('chat.setting.modal.unmute.text'),
          buttonText:t('chat.setting.modal.unmute.buttonText')
        }}))
        break;
      default:
        dispatch(setConfirmationScreen({view:false}))
        dispatch(setConfirmationData({data:false}))
    }
  }

  const hideSetting = ()=>{
    dispatch(setSettingView({view:false}))
    dispatch(setSelectedChatSetting({name:false}))
    dispatch(setConfirmationScreen({view:false}))
    dispatch(setConfirmationData({data:false}))
    dispatch(setNotificationActivated({muted:null}))
  }
  
  return (
    <Container className='chat-settings-wrapper'>
        <Col className='setting-sidebar'>
            <Row className='sidebar-header'>
              <button onClick={()=>hideSetting()} className='return-chat'>{goBackArrow}</button>
              <span>{t<any>('chat.setting.manage')}</span>
            </Row>
            <Col className='setting-actions'>
              {
                buttonData.map((data)=>{
                  if((!notificationActivated && data.name !=="mute")||(notificationActivated && data.name!=="unmute")){
                    if(!isAdmin && data.name==='invite'){
                      return false
                    }else{
                        return <button key={data.name} onClick={()=>handleClick(data.name)} className={selected===data.name?"selected":""}>
                            <div id={data.name} className={'button-wrapper'}>
                                {data.icon}
                                <span>{t<any>(`chat.setting.actions.${data.name}`)}</span>
                            </div>
                        </button>
                    }
                  }
                    return false
                })
              }
            </Col>
        </Col>
    </Container>
  )
}

export default ChatSettings