import {searchHttpsCallable} from "../firebaseFunctions";


export const globalSearch = async ({
                                      searchTerm,
                                      modules = ["posts", "groups", "assets", "users"],
                                  }: { searchTerm: string, modules?: ("posts" | "groups" | "assets" | "users")[] }) => {

    const options = {
        search: searchTerm,
        modules: modules
    }

    return searchHttpsCallable(options);
}