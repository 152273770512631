import {Timestamp} from "firebase/firestore";

export type UserMetadataType = {
    Id?: string,
    DisplayName?: string,
    DefaultLanguage?: string,
    PictureDate?: Timestamp,
    SystemAlias?: string,
    TrustScore?: number,
    PostsCount?: number,
    FollowersCount?: number,
    ProfileData?: ProfileData,
    FollowingUsers?: string[],
    Description?: string,
    RegistrationDate?: Timestamp,
    PublicProfile?: boolean,
    Job?: string,
    _id?: string,
    BlockedUsers?: string[],
};


export type UserPrivateType ={
    ActiveSubscriptionName: string,
    ActiveSubscriptionUntil: Timestamp,
    LifetimeSubscriptionName: string,
    LastPurchase: string,
    InAppDisabled: boolean,
    addons: UserPrivateAddons[],
    ApiKey: string,
    AdminLang: string,
    IVTSender: boolean,
    Role: string,
    AllowedAdminLangs: any,
    AdminOptions: Object,
    IsDisabled: boolean,
    DisabledBy: string,
    DisabledOn: any,
    CanReadNewsUntil: any,
    ApiOptions: any,
    CategoriesDefaults: any,
    Wallet?: WalletType,
    Balance?: number,
    ClaimedOffers?: any,
    CoinStatistics?: any,
    SubscriptionDetails?:SubscriptionDetails,
    Referred: string [],
    Id: string,
}

export type SubscriptionDetails={
    GID?:string,
    UID?:string,
    Tier?:string,
    Cancelled:boolean,
    Lifetime:boolean,
    Until:Timestamp,
    SubscribedSinced: Timestamp
}

export type UserPrivateAddons={
    CreatedAt: Date,
    externalId: string,
    origin: string,
    product: string,
}

export type ProfileData = {
    Description?: string,
    Job?: string,
    Website?: string,
}

export type UserProtectedType = {
    NotificationOptions?: NotificationOptions,
    StocksWatchlist?: StocksWatchlistItem[],
    FavoriteIdeas?: string[],
}

export type NotificationOptions = {
    disabledAuthors?: string[],
    disabledSocialNotifications?: string[],
    enabledAuthorsIds?: string[],
}

export type StocksWatchlistItem = {
    market?: string,
    name?: string,
    symbol?: string,
    to?: string,
    logo?: string[],
    percent_change?: number,
}

export type WalletType = {
    Balance?:number,
    ClaimedOffers?: ClaimedOfferType[]
}

export type ClaimedOfferType = {
    Coin?:number,
    Event?: string,
}

export enum ProfilePrivacy {
    public = "public",
    private = "private"
}