
import { FC, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import './WaveForm.scss'

type IWaveProps = {
    audioData: any,
    Playing?:boolean
}

const Player: FC<IWaveProps> = ({ audioData,  Playing }) => {
  
    const waveformRef = useRef<HTMLDivElement|null>(null)
    const [waver, setWaver] = useState<any>(null);

    useEffect(() => {
        let wavesurfer:any
        if(waveformRef.current !== null){
                wavesurfer = WaveSurfer.create(
                    {
                        barWidth: 2,
                        barGap: 4,
                        barMinHeight: 1,
                        cursorWidth: 1,
                        container: waveformRef.current,
                        backend: "WebAudio",
                        height: 30,
                        progressColor: "#32a385",
                        responsive: true,
                        waveColor: "#ACB3BA",
                        cursorColor: "transparent",
                        fillParent:true
                    }
    
            )
            wavesurfer.load(audioData.url)
            setWaver(wavesurfer);
        }
        return () => wavesurfer.destroy()
    }, [audioData.url]);

    useEffect(() => {
      if (Playing){
          waver?.play();
        }else{
          waver?.pause();
      }
    }, [Playing, waver])
    
    return (
            <div className=" wave-container wb-100">
                <div className="wb-100" ref={waveformRef} />
            </div>
    );
}


export default Player;
