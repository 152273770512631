import {doc, getDocs, limit, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {db} from "../auth/auth";
import {collection, updateDoc, writeBatch} from "@firebase/firestore";
import {collections} from "../../../utils/constants/firebase";
import store from "../../redux/store";
import moment from "moment";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {setUser} from '../../redux/reducers/users/users'
import {
    addNotifications,
    deleteNotification,
    readNotifications,
    setHasNewPendingNotification,
    updateNotification
} from "../../redux/reducers/notifications/notifications";
import {setOpenDiscussion} from "../../redux/reducers/discussion/discussion";
import {listMode} from "../../../utils/constants/discussions";
import {NotificationsType} from "../../../types/notifications.types";
import {groupPagePath, postPagePath} from "../../../routes/Routes";


// ----- Const
export const maxListenedNotifications = 25,
    maxAdditionalFetchedNotification = 50;

let oldestNotificationDate: any = null;


// ----- Read
/**
 * Start a listener on the connected user notifications collection, to dispatch any changes in the associated redux store
 *
 * Should be triggered on user login
 */
export function startListeningNotifications() {
    const state = store.getState()
    const {user}: any = state.auth.data

    if (!user || !user.uid) {
        return console.warn(
            'startListeningNotifications was called but no user is logged-in',
        );
    }

    const notificationRef = collection(db, 'users', user.uid, 'notifications')
    const notificationQuery = query(notificationRef, orderBy('Date', 'desc'), limit(maxListenedNotifications), where('Date', '>', moment().subtract(3, 'months').toDate()))
    onSnapshot(notificationQuery,
        (querySnapshot) => {
            const addedDocs: NotificationsType[] = [],
                notificationsToUpdate: { [k: string]: NotificationsType } = {};
            let hasNewPendingNotification = false;

            querySnapshot?.docChanges().forEach(change => {
                const notificationData = change.doc.data() as NotificationsType;
                if (
                    !oldestNotificationDate ||
                    (notificationData.Date &&
                        notificationData.Date < oldestNotificationDate)
                ) {
                    oldestNotificationDate = notificationData.Date;
                }

                switch (change.type) {
                    case 'added':
                        addedDocs.push(notificationData);
                        notificationsToUpdate[change.doc.id] = notificationData;
                        if (!notificationData.Read) {
                            hasNewPendingNotification = true;
                        }
                        break;
                    case 'modified':
                        store.dispatch(
                            updateNotification({id: change.doc.id, notificationData}),
                        );
                        break;
                    case 'removed':
                        store.dispatch(deleteNotification({id: change.doc.id}));
                        break;
                    default:
                        break;
                }
            });

            if (hasNewPendingNotification) {
                // Show the user there is some new notifications to read!
                store.dispatch(setHasNewPendingNotification({value: true}));
            }

            if (addedDocs.length) {
                store.dispatch(addNotifications({notificationsToUpdate}));
            }
        },
        onError => {
            if (MODE_DEBUG) {
                console.warn('listenNotifications error:', onError);
            }
        },
    );
}

export async function getMoreNotifications() {
    const state = store.getState()
    const {user}: any = state.auth.data

    if (!user || !user.uid) {
        if (MODE_DEBUG) {
            console.warn('getMoreNotifications was called but no user is logged-in');
        }
        return;
    }


    if (!oldestNotificationDate) {
        // Notifications not yet ready
        return;
    }
    const notificationRef = collection(db, 'users', user.uid, 'notifications')
    const notificationQuery = query(notificationRef,
        orderBy('Date', 'desc'),
        limit(maxAdditionalFetchedNotification),
        where('Date', '<', oldestNotificationDate)
    )
    const notificationsDocs: any = await getDocs(notificationQuery)
        .catch((e: Error) => {
                if (MODE_DEBUG) {
                    console.warn('getMoreNotifications error : ', e);
                }
            }
        );

    const notifications: any = {};

    notificationsDocs.forEach((doc: any) => {
        const data = doc.data();
        handleIncomingNotificationDoc(data);
        notifications[doc.id] = doc.data();
    });

    store.dispatch(addNotifications({notificationsToUpdate: notifications}));

    return notifications.length;
}


function handleIncomingNotificationDoc(notification: any) {
    if (notification.Data?.Author && notification.Date) {
        const authorData = {...notification.Data.Author};
        if (authorData.PictureDate) {
            authorData.PictureDate = authorData.PictureDate.toDate();
        }
        store.dispatch(
            setUser({users: [authorData], date: notification.Date.toDate()}),
        );
    }
}


export const markNotificationsAsRead = async (notificationsIds: any) => {
    const state = store.getState()
    const {user}: any = state.auth.data

    if (!user?.uid || !notificationsIds?.length) {
        if (MODE_DEBUG) {
            console.warn(
                'markNotificationsAsRead was called with a falsy user id :',
                user?.uid,
                'or an empty notificationsIds array:',
                notificationsIds,
            );
        }
        return;
    }

    if (MODE_DEBUG) {
        console.info('Will mark notifications', notificationsIds, 'as read');
    }

    store.dispatch(readNotifications({notificationsIds}));


    const notificationRef = collection(db, 'users', user.uid, 'notifications')
    const notificationQuery = query(notificationRef, where('Read', '==', false))
    try {
        getDocs(notificationQuery).then((docs: any) => {
            const batch = writeBatch(db)
            docs.forEach((doc: any) => {
                batch.update(doc.ref, {
                    Read: true,
                });
            });
            // Note: batch are limited to 500 updates atm, see : firebase.google.com/docs/firestore/manage-data/transactions
            batch.commit().then(() => {
                if (!MODE_DEBUG) {
                    console.info('Marked the notification as read successfully!');
                }
            });
        })
    } catch (error) {
        if (MODE_DEBUG) {
            console.log(error)
        }
    }
}

export function openNotification(notification: any, navigate: Function) {
    if (!notification || !navigate) {
        if (MODE_DEBUG) {
            console.warn(
                `openNotification was called with a falsy notification=${notification} or navigate=${navigate}`,
            );
        }
        return;
    }

    let targetId,
        postAuthorId,
        postId,
        groupId;

    switch (notification.Type) {
        case 'NewFollower':
            if (navigate && notification.AuthorIds && notification.Data?.Author?.Id) {
                if (notification.AuthorIds.length > 1) {
                    // TODO show a popup with all new followers
                } else {
                    navigate(`/u/${notification.Data.Author.TargetAlias}`);

                }
            }
            break;
        case 'PostMention':
            navigate(`/${postPagePath}/${notification.ObjectIds[0]}`);
            break;
        case 'PostReaction':
        case 'PostComment':
            navigate(`/${postPagePath}/${notification.TargetId}`);
            // TODO loadObjectsAroundDate and highlight comment
            break;
        case 'PostCommentReaction':
        case 'PostCommentMention':
            [postAuthorId, postId] = notification.TargetId.split('/');
            targetId = `${postAuthorId}/${postId}`;
            navigate(`/${postPagePath}/${targetId}`);
            // TODO loadObjectsAroundDate and highlight comment
            break;
        case 'PostCommentReply':
        case 'PostCommentReplyReaction':
            [postAuthorId, postId] = (
                notification.ObjectIds?.[0] || notification.TargetId
            ).split('/');
            targetId = `${postAuthorId}/${postId}`
            navigate(`/${postPagePath}/${targetId}`);
            // TODO loadObjectsAroundDate and highlight comment
            break;
        case 'PostShare':
            targetId = notification?.ObjectIds?.[0];
            navigate(`/${postPagePath}/${targetId}`);
            break;
        case 'Achievement':
            const state = store.getState();
            const {userMetadata}: any = state.auth.data
            if (userMetadata?.SystemAlias) {
                navigate(`/u/${userMetadata?.SystemAlias}`);
            }
            break;
        case 'PostAward':
            [postAuthorId, postId] = notification.TargetId.split('/');
            targetId = `${postAuthorId}/${postId}`;
            navigate(`/${postPagePath}/${targetId}`);
            // TODO highlight award
            break;
        case 'DiscussionInvitation':
            if (
                !Array.isArray(notification?.ObjectIds) ||
                notification.ObjectIds.length <= 0
            ) {
                if (MODE_DEBUG) {
                    console.error(
                        'Trying to open a DiscussionInvitation without any objectId',
                    );
                }
                break;
            }
            // If we have more than 1 chat invite, open the chat list
            if (notification.ObjectIds.length > 1) {
                store.dispatch(setOpenDiscussion({listMode: listMode.requests}))

            } else {
                store.dispatch(setOpenDiscussion({
                    discussionId: notification?.ObjectIds[0],
                    type: notification?.Data?.Object?.Type
                }))
            }
            break;
        case 'ReferralReward':
        case 'ReferralNotice':
            navigate(`/refer-friend`);
            break;
        case 'UserGroupJoinRequest':
            groupId = notification.TargetId.split('/').pop();
            navigate(`/${`${groupPagePath}/${encodeURIComponent(groupId)}/members`}`);
            break;
        case 'UserGroupInvitation':
        case 'UserGroupRequestAccepted':
        case 'UserGroupSubscriptionConfirmed':
        case 'UpcomingUserGroupSubscriptionFailed':
        case 'UpcomingUserGroupSubscription':
        case 'UserGroupNotEnoughBalance':
        case 'UserGroupSubscriptionRenewed':
        case 'UserJoinsGroup':
            groupId = notification.TargetId.split('/').pop();
            navigate(`/${`${groupPagePath}/${encodeURIComponent(groupId)}`}`);
            break;
        default:
            if (MODE_DEBUG) {
                console.warn(
                    'Cannot open unknown notification with type ' + notification.Type,
                    notification,
                );
            }
    }
}


export const disableNotificationsFromUser = async (userId: string, targetUserId: string, oldDisabledAuthorsList: string[]) => {
    const userDocRef = doc(db, collections.userProtected, userId);
    const disabledAuthors = [...oldDisabledAuthorsList, targetUserId];
    return updateDoc(userDocRef, {
        'NotificationOptions.disabledAuthors': disabledAuthors,
    });
}

export const enableNotificationsForUser = async (userId: string, targetUserId: string, oldDisabledAuthorsList: string[]) => {
    const userDocRef = doc(db, collections.userProtected, userId);
    const disabledAuthors = oldDisabledAuthorsList.filter((value) => value !== targetUserId);
    return updateDoc(userDocRef, {
        'NotificationOptions.disabledAuthors': disabledAuthors,
    });
}


