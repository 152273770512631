import "./GlobalSearchInput.scss";
import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "../Container";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {groupPagePath, profilePagePath} from "../../../routes/Routes";
import Avatar from "../Shared/Avatar";
import DebouncedSearchInput from "../Form/DebouncedSearchInput/DebouncedSearchInput";
import {globalSearch} from "../../../services/firebase/search/search";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {useTranslation} from "react-i18next";
import {searchResultsListType, SearchResultType} from "../../../types/search.types";
import {searchHistoryKey} from "../../../utils/constants/search";

const GlobalSearchInput: FC = () => {
    const {search} = useLocation();
    const navigator = useNavigate();
    const {t} = useTranslation();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchResults, setSearchResults] = useState<searchResultsListType>();
    const [searchResultsHistory, setSearchResultsHistory] = useState<searchResultsListType>();
    const [isSearching, setIsSearching] = useState<boolean>(false);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(search);
        const q = urlSearchParams.get('q');
        setSearchTerm(q ?? '');
    }, [search]);

    useEffect(() => {
        if (searchTerm) {
            setIsSearching(true);
            globalSearch({
                searchTerm: searchTerm,
                modules: [SearchResultType.users, SearchResultType.groups]
            }).then((res) => {
                if (res.data) {
                    setSearchResults(res.data as any);
                }
            }).finally(() => {
                setIsSearching(false);
            });
        } else {
            setSearchResults(undefined);
        }
    }, [searchTerm])

    useEffect(() => {
        const localStorageSearchHistory = localStorage.getItem(searchHistoryKey);
        if (localStorageSearchHistory) {
            try {
                const searchHistory: searchResultsListType = JSON.parse(localStorageSearchHistory);
                setSearchResultsHistory({
                    groups: searchHistory.groups.slice(0, 5),
                    users: searchHistory.users.slice(0, 5),
                    queries: searchHistory.queries.slice(0, 5),
                });
            } catch (e) {
                if (MODE_DEBUG) {
                    console.error("Error while getting searchHistory from localhost", e);
                }
                localStorage.setItem(searchHistoryKey, JSON.stringify({groups: [], users: [], queries: []}));
            }
        }
    }, [localStorage?.getItem(searchHistoryKey)]);

    const onResultClicked = useCallback((item: any, type: SearchResultType) => {
        (document.activeElement as HTMLElement).blur();

        if (!localStorage.getItem(searchHistoryKey)) {
            localStorage.setItem(searchHistoryKey, JSON.stringify({groups: [], users: [], queries: []}));
        }

        const localStorageSearchHistory = localStorage.getItem(searchHistoryKey);
        if (localStorageSearchHistory) {
            try {
                const searchHistory: searchResultsListType = JSON.parse(localStorageSearchHistory);
                if (type === SearchResultType.groups) {
                    const oldGroups = searchHistory.groups.filter((group) => (group?._id !== item._id));
                    const newGroups = [item, ...oldGroups];
                    const newSearchHistory = JSON.stringify({...searchHistory, groups: newGroups});
                    localStorage.setItem(searchHistoryKey, newSearchHistory);
                } else if (type === SearchResultType.users) {
                    const oldUsers = searchHistory.users.filter((user) => (user?._id !== item._id));
                    const newUsers = [item, ...oldUsers];
                    const newSearchHistory = JSON.stringify({...searchHistory, users: newUsers});
                    localStorage.setItem(searchHistoryKey, newSearchHistory);
                } else if (type === SearchResultType.queries) {
                    const oldQueries = searchHistory.queries.filter((query) => (query !== item));
                    const newQueries = [item, ...oldQueries];
                    const newSearchHistory = JSON.stringify({...searchHistory, queries: newQueries});
                    localStorage.setItem(searchHistoryKey, newSearchHistory);
                }
            } catch (e) {
                if (MODE_DEBUG) {
                    console.error('Error while parsing searchHistory from localhost', e);
                }
                localStorage.setItem(searchHistoryKey, JSON.stringify({groups: [], users: [], queries: []}));
            }
        }
    }, []);

    const getListItems = useCallback((results: any[], title: string, type: SearchResultType, isHistory?: boolean) => {
        const listItems: any = [];

        if (results.length) {
            listItems.push(
                <Row className="s-autocomplete-dropdown-item s-autocomplete-dropdown-section"
                     key={`${title}-section-title`}>
                    <h6>
                        {isHistory ? `${t<any>('search.recent')} ${title}` : title}
                    </h6>
                </Row>
            );
            results.slice(0, 5).forEach((result) => {
                if (type === SearchResultType.groups) {
                    listItems.push(
                        <Link
                            to={`${groupPagePath}/${result?.Alias}`}
                            className="s-autocomplete-dropdown-item"
                            onClick={() => onResultClicked(result, SearchResultType.groups)}
                            key={`${result?._id}`}
                        >
                            <Avatar url={result?.Image}/>
                            <p>{result?.Title ?? result?.DisplayName}</p>
                        </Link>
                    );
                } else if (type === SearchResultType.users) {
                    listItems.push(
                        <Link
                            to={`${profilePagePath}/${result?.SystemAlias ?? result?._id ?? ''}`}
                            className="s-autocomplete-dropdown-item"
                            onClick={() => onResultClicked(result, SearchResultType.users)}
                            key={`${result?._id}`}
                        >
                            <Avatar id={result?._id}/>
                            <p>{result?.DisplayName}</p>
                        </Link>
                    );
                } else if (type === SearchResultType.queries) {
                    listItems.push(
                        <Link
                            to={`/search/?q=${result}`}
                            className="s-autocomplete-dropdown-item"
                            onClick={() => onResultClicked(result, SearchResultType.queries)}
                            key={`${result}`}
                        >
                            <p>{result}</p>
                        </Link>
                    );
                }
            });
        }
        return listItems;
    }, [onResultClicked, t]);


    const defaultSearchListItem = useMemo(() => {
        if(searchTerm?.trim()){
            return (
                <Col className="wb-100 s-autocomplete-list">
                    <Link
                        to={`/search/?q=${searchTerm}`}
                        className="s-autocomplete-dropdown-item"
                        onClick={() => onResultClicked(searchTerm, SearchResultType.queries)}
                        key={`no-results`}
                    >
                        <Row className="wb-100 jcsb">
                            <p>{t<any>("search.searchFor")} "{searchTerm}"</p>
                        </Row>
                    </Link>
                </Col>
            )
        }
    }, [onResultClicked, searchTerm, t])

    const getSearchResultsDropdownItems = useCallback((searchResults: searchResultsListType, isHistory?: boolean) => {

        const groups: any[] = getListItems(searchResults.groups ?? [], t<any>("search.groups"), SearchResultType.groups, isHistory);
        const users: any[] = getListItems(searchResults.users ?? [], t<any>("search.people"), SearchResultType.users, isHistory);
        const queries: any[] = getListItems(searchResults.queries ?? [], t<any>(""), SearchResultType.queries, isHistory);

        if (groups?.length || users?.length || queries?.length) {
            return (
                <Col className="wb-100 s-autocomplete-dropdown">
                    {(!!queries?.length) ? <Col className="wb-100 s-autocomplete-list">
                            {queries}
                        </Col>
                        : defaultSearchListItem}
                    {(!!groups?.length) && <Col className="wb-100 s-autocomplete-list">
                        {groups}
                    </Col>}
                    {(!!users?.length) && <Col className="wb-100 s-autocomplete-list">
                        {users}
                    </Col>}
                </Col>
            )
        } else {
            return (
                <Col className="wb-100 s-autocomplete-dropdown">
                    {defaultSearchListItem}
                </Col>
            );
        }
    }, [defaultSearchListItem, getListItems, t]);

    const autocompleteDropdown = useMemo(() => {

        if (searchTerm?.trim()) {
            if (searchResults) {
                return getSearchResultsDropdownItems(searchResults);
            }
        } else if (searchResultsHistory) {
            return getSearchResultsDropdownItems(searchResultsHistory, true);
        }

        if (isSearching) {
            return (
                <Col className="wb-100 s-autocomplete-dropdown empty">
                    <small>{t<any>("search.searching")}</small>
                </Col>
            )
        } else {
            return (
                <Col className="wb-100 s-autocomplete-dropdown empty">
                    <small>{t<any>("search.searchGroupsPeoplePostsAssets")}</small>
                </Col>
            )
        }
    }, [getSearchResultsDropdownItems, isSearching, searchResults, searchResultsHistory, searchTerm, t]);

    return (
        <Col className="aifs jcfs global-search-form">
            <DebouncedSearchInput
                className="wb-100 s-debounced-input"
                onSearch={setSearchTerm}
                placeholder={t<any>("search.searchPlaceholder")}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (e.target) {
                        const formData = new FormData(e.currentTarget);
                        const formProps = Object.fromEntries(formData);
                        const searchInputText = formProps.searchInput;
                        if ((searchInputText as string)?.trim()) {
                            navigator(`/search/?q=${searchInputText}`);
                            onResultClicked(searchInputText, SearchResultType.queries)
                        }
                    }
                }}
            />
            {autocompleteDropdown}
            <Container className="s-overlay"/>
        </Col>
    )
}

export default GlobalSearchInput;