import {Manager, Socket} from 'socket.io-client';
import {MODE_DEBUG} from '../../utils/constants/config';
import {auth as fbAuth} from '../firebase/auth/auth';
import {
    deleteMessage,
    setMessageData
} from '../redux/reducers/discussion/discussion';
import store from '../redux/store';
import {marketPricesActions} from "../redux/reducers/marketPrices/marketPrices";

import {query, collection, onSnapshot, orderBy, limit} from 'firebase/firestore';
import {db} from '../firebase/auth/auth';
import {setUser} from '../redux/reducers/users/users';
import {discussionConstants} from '../../utils/constants/discussions';
import { REACTION_CONTENT_TYPE_SOCIAL_POST, REACTION_CONTENT_TYPE_SOCIAL_COMMENT, REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY } from '../../utils/constants/idea';
import { setPostReactions, incrementLikesCount, incrementPostCommentsCount, incrementCommentRepliesCount, incrementPostSharesCount } from '../redux/reducers/reactions/reactions';

// Actual backend limit is 1024 as of May 2021
const MAX_CHANNELS = 128;

const sockets = {
    marketPrice: 'market-prices-socket',
    general: 'general-socket'
};


let listeningChannels: {
    [key: string]: {
        nListeners: number,
        nBackground: number,
        lastListener: number,
        socketType: string,
        item: any,
    }
} = {};
let lastConnected: any = null
let manager: Manager;
let reactionsSocket: Socket | null = null
let marketPriceSocket: Socket | null;
let storeListener = () => {
};

export let messageListener: any = {}

function clearBackgroundChannelsIfNeeded(howManyChannelsToAdd: number) {
    const candidates = Object.keys(listeningChannels),
        nToClear = candidates.length + howManyChannelsToAdd - MAX_CHANNELS;

    // Nothing to do
    if (nToClear <= 0) {
        return;
    }

    if (MODE_DEBUG) {
        console.info(
            `Clearing ${nToClear} channels since we have too many listeners`,
        );
    }

    const channelsToClear = candidates.filter(
        item => listeningChannels[item].nListeners <= 0,
    );

    if (channelsToClear.length < nToClear) {
        if (MODE_DEBUG) {
            console.info(
                'The first round did not return enough candidates. Stripping oldest active listeners now',
            );
        }

        // Find all the other channels and sort them - find the oldest listener
        channelsToClear.push(
            ...candidates
                .filter(item => listeningChannels[item].nListeners > 0)
                .sort((a, b) => {
                    return (
                        listeningChannels[a].lastListener -
                        listeningChannels[b].lastListener
                    );
                })
                .slice(0, nToClear - channelsToClear.length),
        );
    }
    if (MODE_DEBUG) {
        console.info('We will clear the following listeners', channelsToClear);
    }

    channelsToClear.forEach(channel => {
        if (reactionsSocket?.connected) {
            reactionsSocket.emit('leave channel', channel);
        }
        if (marketPriceSocket?.connected) {
            marketPriceSocket.emit('unsubscribe', channel);
        }
        delete listeningChannels[channel];
    });
}

function onStoreChange() {
    const {auth}: any = store.getState();

    if (auth.data?.user === lastConnected) {
        return;
    }
    lastConnected = auth.data?.user;

    if (!auth.data?.user) {
        // On user logout, disconnect the socket
        if (reactionsSocket) {
            reactionsSocket.disconnect();
        }
        if (marketPriceSocket) {
            marketPriceSocket.disconnect();
        }
        listeningChannels = {};
        reactionsSocket = null;
        marketPriceSocket = null;
        return;
    }
    if (reactionsSocket && marketPriceSocket) {
        // All sockets connections started, nothing to do!
        return;
    }

    const host = process.env.REACT_APP_RALTIME_SOCKET_URL;

    if (!manager) {
        manager = new Manager(host, {
            transports: ['websocket'],
        });

        manager.connect(() => {
            if (MODE_DEBUG) {
                console.log('Manager Connected to the realtime engine.');
            }
        });
    }

    // ----- Global namespace sockets
    if (!reactionsSocket) {
        // Define a socket for the global namespace
        reactionsSocket = manager.socket('/', {
            auth: (cb: any) => {
                fbAuth
                    .currentUser?.getIdToken()
                    .then((token: any) =>
                        cb({
                            firebaseToken: token,
                        }),
                    );
            },
        });

        reactionsSocket.on('connect', () => {
            clearBackgroundChannelsIfNeeded(0);
            let channelsToJoin: any[] = [];
            Object.keys(listeningChannels).forEach(listeningChannelKey => {
                if (listeningChannels[listeningChannelKey].socketType === sockets.general) {
                    channelsToJoin.push(listeningChannelKey);
                }
            });

            if (MODE_DEBUG) {
                console.log(
                    `Connected to the realtime engine. Will join ${channelsToJoin.length} channels`,
                );
            }
            // (Re-)join all channels we missed while we were disconnected
            channelsToJoin.forEach(id => reactionsSocket?.emit('join channel', id));
        });

        reactionsSocket.on('connect_error', (e: any) => {
            if (MODE_DEBUG) {
                console.log('Could not connect to the realtime engine', e);
            }
        });

        // Bind events listeners
        reactionsSocket.on('bulk_update', ({content, id, likes, comments, shares}: any) => {
            if (MODE_DEBUG) {
                console.log(
                    `Socket bulk_update reset ! content=${content} id=${id} likes=${likes} comments=${comments} shares=${shares}`,
                );
            }

            if (content === 'post') {
                 store.dispatch(setPostReactions({fullId:id, likeCount:likes, commentCount:comments, shareCount:shares}));
            }
        });

        reactionsSocket.on('like', ({id, delta, content}: any) => {
            if (MODE_DEBUG) {
                console.log(
                    `Socket like update ! id=${id} delta=${delta} content=${content}`,
                );
            }
            // Typical id will look like : 'WsgTQXcnMsVKQthpcCEpB6wKdXF2/cyWjEbITH99ROQNBLq84'
            switch (content) {
                case 'post':
                      store.dispatch(
                        incrementLikesCount({
                            contentType:REACTION_CONTENT_TYPE_SOCIAL_POST, 
                            fullId:id, 
                            delta}),
                      );

                    break;
                case 'comment':
                      store.dispatch(
                        incrementLikesCount({
                            contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT, 
                            fullId:id, 
                            delta}),
                      );
                    break;
                case 'reply':
                      store.dispatch(
                        incrementLikesCount({
                            contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY,
                            fullId:id,
                            delta,
                        }),
                      );
                    break;
                default:
                    if (MODE_DEBUG) {
                        console.info(`Unhandled reaction type: ${content} was received`);
                    }
                    break;
            }
        });
        reactionsSocket.on('comment', ({id, delta, content}: any) => {
            if (MODE_DEBUG) {
                console.debug(
                    `Socket comment update ! id=${id} delta=${delta} content=${content}`,
                );
            }

            if (content === 'post') {
                  store.dispatch(incrementPostCommentsCount({ fullId:id, delta}));
            }
        });
        reactionsSocket.on('reply', ({id, delta, content}: any) => {
            if (MODE_DEBUG) {
                console.debug(
                    `Socket reply update ! id=${id} delta=${delta} content=${content}`,
                );
            }
            if (content === 'comment') {
                 store.dispatch(incrementCommentRepliesCount({fullId:id, delta}));
            }
        });
        reactionsSocket.on('share', ({id, delta, content}: any) => {
            if (MODE_DEBUG) {
                console.debug(
                    `Socket share update ! id=${id} delta=${delta} content=${content}`,
                );
            }

            if (content === 'post') {
                  store.dispatch(incrementPostSharesCount({fullId:id, delta}));
            }
        });
        reactionsSocket.on('awards', ({id, fullData, content}: any) => {
            if (MODE_DEBUG) {
                console.debug(
                    `Socket award update ! id=${id}  content=${content} AwardsCount=${fullData}`,
                );
            }

            if (content === 'post' && fullData?.awardsCount) {
                //   store.dispatch(updatePostAwards(id, fullData.awardsCount));
            }
        });
    }
    // ----- End global namespace sockets

    // ----- Market price namespace sockets
    if (!marketPriceSocket) {
        // Define a socket for the marketPrices namespace
        marketPriceSocket = manager.socket('/marketPrices', {
            auth: (cb: any) => {
                fbAuth
                    .currentUser?.getIdToken()
                    .then((token: any) =>
                        cb({
                            firebaseToken: token,
                        }),
                    );
            },
        });

        marketPriceSocket.on('connect', () => {

            clearBackgroundChannelsIfNeeded(0);
            let marketPriceItemsToGet: any[] = [];
            Object.values(listeningChannels).forEach((listeningChannelValue) => {
                if (listeningChannelValue.socketType === sockets.marketPrice) {
                    marketPriceItemsToGet.push(listeningChannelValue);
                }
            });

            if (MODE_DEBUG) {
                console.log(
                    `MarketPriceSocket Connected to the realtime engine. Will join ${marketPriceItemsToGet.length} channels`,
                );
            }

            // (Re-)join all channels we missed while we were disconnected
            marketPriceItemsToGet.forEach(marketPriceItem => {
                marketPriceSocket?.emit('subscribe', [marketPriceItem.item]);
            })
        });

        marketPriceSocket.on('connect_error', (e: any) => {
            if (MODE_DEBUG) {
                console.info(
                    'Could not connect marketPriceSocket to the realtime engine',
                    e,
                );
            }
        });
        marketPriceSocket.on('update', (item: any) => {
            if (item?.key) {
                store.dispatch(marketPricesActions.setMarketPricesData(item));
            } else if (MODE_DEBUG) {
                console.warn(
                    'MarketPriceSocket on update received an unexpected assetPair:',
                    JSON.stringify(item),
                );
            }
        });
    }
    // ----- End market price namespace sockets
}

export async function init() {
    storeListener = store.subscribe(onStoreChange);
}

export async function destroy() {
    storeListener();
    if (reactionsSocket) {
        reactionsSocket.disconnect();
    }
    if (marketPriceSocket) {
        marketPriceSocket.disconnect();
    }
    listeningChannels = {};
}


function addListenerForChannel(
    channel: any,
    {
        background = false,
        emitEventName = 'join channel',
        socketType = sockets.general,
        socket,
        item = null,
    }: { background?: boolean, emitEventName?: string, socketType?: string, socket: any, item?: any },
) {
    if (!background) {
        clearBackgroundChannelsIfNeeded(1);
    }
    if (!listeningChannels[channel]) {
        listeningChannels[channel] = {
            nListeners: 0,
            nBackground: 0,
            lastListener: 0,
            socketType: socketType,
            item
        };
    }

    if (listeningChannels[channel].nListeners === 0 && socket?.connected) {
        socket.emit(
            emitEventName,
            socketType === sockets.marketPrice ? [item] : channel,
        );
    }
    if (!background) {
        listeningChannels[channel].nListeners++;
        listeningChannels[channel].lastListener = Date.now();
    } else {
        listeningChannels[channel].nBackground++;
    }
}

function removeListenerForChannel(
    channel: any,
    {
        background = false,
        emitEventName = 'join channel',
        socketType = sockets.general,
        socket,
        item = null,
    }: { background?: boolean, emitEventName?: string, socketType?: string, socket: any, item?: any },
) {
    if (!listeningChannels[channel]) {
        return;
    } // Listener removed, maybe due to too many listeners
    if (!background) {
        listeningChannels[channel].nListeners--;
    } else {
        listeningChannels[channel].nBackground--;
    }

    // All background listeners and current listeners have been removed
    if (
        listeningChannels[channel].nListeners <= 0 &&
        listeningChannels[channel].nBackground <= 0
    ) {
        if (socket?.connected) {
            socket.emit(
                emitEventName,
                socketType === sockets.marketPrice ? [item] : channel,
            );
        }
        delete listeningChannels[channel];
    }
}

export function addListenerToPostEvents(fullPostId: string, background = false) {
    addListenerForChannel(`v1/post/${fullPostId}`, {background, socket: reactionsSocket});
}

export function removeListenerToPostEvents(fullPostId: string, background = false) {
    removeListenerForChannel(`v1/post/${fullPostId}`, {background, socket: reactionsSocket});
}

export function addListenerToCommentEvents(fullCommentId: string, background = false) {
    addListenerForChannel(`v1/comment/${fullCommentId}`, {background, socket: reactionsSocket});
}

export function removeListenerToCommentEvents(
    fullCommentId: string,
    background = false,
) {
    removeListenerForChannel(`v1/comment/${fullCommentId}`, {background, socket: reactionsSocket});
}

export function addListenerToCommentReplyEvents(
    fullCommentReplyId: string,
    background = false,
) {
    addListenerForChannel(`v1/reply/${fullCommentReplyId}`, {background, socket: reactionsSocket});
}

export function removeListenerToCommentReplyEvents(
    fullCommentReplyId: string,
    background = false,
) {
    removeListenerForChannel(`v1/reply/${fullCommentReplyId}`, {background, socket: reactionsSocket});
}


// Add a listener for the asset, if none exist already
export function addListenerToAssetEvents(asset: any, background = false) {
    if (!marketPriceSocket) {
        if (MODE_DEBUG) {
            console.warn(
                'addListenerToAssetEvents is called but socket is not instanced!',
            );
        }
        return;
    }

    if (!asset) {
        if (MODE_DEBUG) {
            console.warn(
                'addListenerToAssetEvents is called but asset is not defined!',
            );
        }
        return;
    }

    const assetKey = getAssetPriceKey(asset);
    if (!assetKey) {
        if (MODE_DEBUG) {
            console.warn(
                'addListenerToAssetEvents is called but assetKey is not defined!',
            );
        }
        return;
    }

    addListenerForChannel(assetKey, {
        background,
        emitEventName: 'subscribe',
        socketType: sockets.marketPrice,
        socket: marketPriceSocket,
        item: {
            symbol: asset.Symbol ?? asset.symbol,
            to: asset.To ?? asset.to,
            market: asset.Market ?? asset.market,
        },
    });
}

// Remove the asset listener, if one exist already
export function removeListenerToAssetEvents(asset: any, background = false) {
    if (!asset) {
        if (MODE_DEBUG) {
            console.warn(
                'removeListenerToAssetEvents was called but asset is falsy :',
                asset,
            );
        }
        return;
    }

    let assetKey = getAssetPriceKey(asset);

    if (!assetKey) {
        if (MODE_DEBUG) {
            console.warn(
                'removeListenerToAssetEvents is called but assetKey is not defined!',
            );
        }
        return;
    }

    removeListenerForChannel(assetKey, {
        background,
        emitEventName: 'unsubscribe',
        socketType: sockets.marketPrice,
        socket: marketPriceSocket,
        item: {
            symbol: asset.Symbol ?? asset.symbol,
            to: asset.To ?? asset.to,
            market: asset.Market ?? asset.market,
        },
    });
}

export function getAssetPriceKey(marketObj: any) {
    if (!marketObj) {
        return null;
    }
    const symbol = marketObj.symbol ?? marketObj.Symbol,
        market = marketObj.market ?? marketObj.Market,
        to = marketObj.to ?? marketObj.To;
    if (!symbol || !market || !to) {
        if(MODE_DEBUG){
            console.log(`symbol: ${symbol}, market: ${market}, to: ${to} is falsy`)
        }
        return null;
    }
    return `${symbol}_${market}_${to}`;
}

export const addMessageListener = (discussionId: string) => {
    let isFirstData = true;
    const chatRef = query(collection(db, 'discussions', discussionId, 'messages'), orderBy('Date', 'desc'), limit(discussionConstants.MaxListenedMessages));
    messageListener[discussionId] = onSnapshot(chatRef,
        (querySnapshot) => {
            if (
                querySnapshot.metadata.fromCache ||
                querySnapshot.metadata.hasPendingWrites
            ) {
                return;
            }
            try {
                const users: any = [],
                    sharedPosts: any = [],
                    updatedMessages: any = [],
                    removedMessagesIds: any = [],
                    changes = isFirstData
                        ? querySnapshot.docs.map(doc => ({
                            type: 'added',
                            doc,
                        }))
                        : querySnapshot?.docChanges();

                isFirstData = false;

                changes.forEach(change => {
                    const messageData = change.doc.data();
                    switch (change.type) {
                        case 'added':
                        case 'modified':
                            updatedMessages.push({
                                Id: change.doc.id,
                                ...messageData,
                            });

                            const authorData = messageData?.Data?.Author;
                            if (authorData) {
                                // Dispatch the user
                                if (authorData.PictureDate) {
                                    authorData.PictureDate =
                                        authorData.PictureDate.toDate();
                                }
                                let date = messageData.Date;
                                if (date && date.toDate) {
                                    date = date.toDate();
                                }
                                users.push({
                                    ...authorData,
                                    _date: date,
                                });
                            }

                            const sharedPost = messageData?.Data?.SharedPost;
                            if (sharedPost) {
                                // The post store expect posts from ES, that have an _id field.
                                sharedPost._id = sharedPost.Id;
                                sharedPosts.push(sharedPost);
                            }
                            break;
                        case 'removed':
                            removedMessagesIds.push(change.doc.id);
                            break;
                        default:
                            break;
                    }
                });
                if (users.length) {
                    store.dispatch(setUser({users}));
                }
                if (updatedMessages.length) {
                    store.dispatch(
                        setMessageData({discussionId, updatedMessages}),
                    );
                }
                if (removedMessagesIds.length) {
                    store.dispatch(
                        deleteMessage({discussionId, messagesIds: removedMessagesIds}),
                    );
                }
            } catch (e) {
                if (MODE_DEBUG) {
                    console.error(e);
                }
            }
        })
}

export const stopListeningMessages = (discussionId: string) => {
    messageListener[discussionId] = null;
}



