import {collection, doc, onSnapshot, orderBy, query, QuerySnapshot, deleteField} from "firebase/firestore";
import {db} from "../auth/auth";
import {
    analyticsDetailsHttpsCallable,
    analyticsLogosHttpsCallable,
    analyticsSearchHttpsCallable,
    analyticsTrendingHttpsCallable
} from "../firebaseFunctions";
import {updateDoc} from "@firebase/firestore";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {StocksWatchlistItem} from "../../../types/user";
import {assetDetailsPath} from "../../../routes/Routes";
import store from "../../redux/store";
import {marketPricesActions} from "../../redux/reducers/marketPrices/marketPrices";

export const streamWatchlist = (userId: string, snapshot: (snapshot: QuerySnapshot) => void, error: any) => {
    const postColRef = collection(db, 'users', userId, 'posts');
    const q = query(postColRef, orderBy('Date', 'desc'));
    return onSnapshot(q, snapshot, error);
};


export const getTopGainers = () => {
    return analyticsTrendingHttpsCallable({
        direction: "gainers",
        type: ["stocks"]
    })
}

export const getTopLosers = () => {
    return analyticsTrendingHttpsCallable({
        direction: "losers",
        type: ["stocks"]
    })
}

export const searchAssets = (searchTerm: string, selectedFilter: string) => {

    let markets = ["stock", "crypto", "index", "forex"];
    if (selectedFilter && selectedFilter !== 'all') {
        markets = [selectedFilter];
    }

    return analyticsSearchHttpsCallable({
        searchstr: searchTerm,
        markets
    });
}

export const getMarketKey = (symbol?: string | null, market?: string | null, to?: string | null) => {
    return `${symbol}_${market}_${to}`;
}

export const updateWatchlist = (userId: string, newWatchList: any[],) => {
    const userDocRef = doc(db, 'users-protected', userId);
    const shouldRemoveList = (newWatchList?.length ?? 0) === 0;

    return updateDoc(userDocRef, {
        StocksWatchlist: shouldRemoveList ? deleteField() : newWatchList,
    }).then(() => {
        if (MODE_DEBUG) {
            console.log('Watchlist updated to', newWatchList);
        }
    }).catch(error => {

        if (MODE_DEBUG) {
            console.error(error);
        }
    });
}

export const getAnalyticsDetails = (marketItem: StocksWatchlistItem, lang: string,) => {
    return analyticsDetailsHttpsCallable({
        marketItems: [marketItem],
        lang,
        synchronous: true,
    });
}

export const getAssetDetailsLink = (symbol: string, market: string, to: string, name: string) => {
    return `/${assetDetailsPath}/?s=${symbol}&m=${market}&t=${to}&n=${name}`;
}

const BATCH_REQUEST_DELAY_MS = 10;

const _pendingMarketItemLogos: any[] = [],
    _requestedMarketItemLogos = new Set();

let marketItemLogosCountdown: any = null;

async function fetchPendingMarketItemLogos() {
    marketItemLogosCountdown = null;
    const marketItems = _pendingMarketItemLogos.splice(0, 50);
    await analyticsLogosHttpsCallable({
        marketItems,
    })
        .then(({data}: { data: any }) => {
            if (data) {
                const assetsLogo = Object.keys(data as any).map(assetKey => {
                    const assetLogos = data[assetKey];
                    return {logo: assetLogos[0], Key: assetKey};
                });
                if (assetsLogo?.length) {
                    store.dispatch(marketPricesActions.updateMarketPairs(assetsLogo));
                }
            }
        })
        .catch(e => {
            console.warn('Could not fetch asset icons', e);
        });

    // Looks like there's still some logos to fetch
    if (_pendingMarketItemLogos.length && !marketItemLogosCountdown) {
        marketItemLogosCountdown = setTimeout(
            fetchPendingMarketItemLogos,
            BATCH_REQUEST_DELAY_MS,
        );
    }
}

export function ensureMarketItemLogo(marketItemKey: any) {
    if (!marketItemKey) {
        throw new Error('Invalid market item key - got ' + marketItemKey);
    }
    if (_requestedMarketItemLogos.has(marketItemKey)) {
        return;
    }
    _requestedMarketItemLogos.add(marketItemKey);
    _pendingMarketItemLogos.push(marketItemKey);

    if (marketItemLogosCountdown) {
        clearTimeout(marketItemLogosCountdown);
    }
    marketItemLogosCountdown = setTimeout(
        fetchPendingMarketItemLogos,
        BATCH_REQUEST_DELAY_MS,
    );
}