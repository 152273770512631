import {onIdTokenChanged} from "firebase/auth";
import {useCallback, useEffect} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {useDispatch} from "react-redux";
import {useAuth} from "../../../common/hooks/useAuth";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {
    authActions
} from "../../redux/reducers/userAuth/user";
import {auth} from "./auth";
import {streamUser, streamUserPrivateData, streamUserProtectedData} from "../user/user";
import {startListeningNotifications} from "../notifications/notifications";
import {streamUserGroups} from "../groups/groups";

export const AuthListnerInit = () => {
    const dispatch = useDispatch();
    const [user, loading, error] = useAuthState(auth);
    const {isLoggedIn} = useAuth()

    const checkClaims = useCallback(async () => {
        const claims = await user?.getIdTokenResult()
        dispatch(authActions.loginDataUpdate({user, token: claims}));
    }, [user, dispatch]);

    useEffect(() => {
        checkClaims()
        dispatch(authActions.loginSuccess({user}));
        onIdTokenChanged(auth, user => {
            if (user) {
                user.getIdTokenResult().then((async idTokenResult => {
                    dispatch(authActions.loginDataUpdate({user, token: idTokenResult.claims}));
                    if (MODE_DEBUG) {
                        console.log(idTokenResult)
                        console.log('claims 2FA', idTokenResult.claims.passed2FA)
                        console.log('claims Phone number', idTokenResult.claims.phone_number)
                    }
                }));

            } else {
                if (MODE_DEBUG) {
                    console.log('error')
                }
            }
        })
    }, [user, loading, error, dispatch, checkClaims])


    useEffect(() => {
        if (user) {
            streamUserProtectedData(user?.uid);
            streamUserPrivateData(user?.uid);
            streamUser(user?.uid);
        }
    }, [user, dispatch]);


    useEffect(() => {
        if (user) {
            return streamUserGroups(
                user?.uid,
                snapshot => {
                    const userGroups = snapshot.docs.map((doc) => ({
                        ...doc.data(),
                        _id: doc.id,
                    }));
                    dispatch(authActions.setUserGroups({userGroups}));
                },
                (error: any) => {
                    if (MODE_DEBUG) {
                        console.error(error);
                    }
                }
            );
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (isLoggedIn) {
            startListeningNotifications();
        }
    }, [isLoggedIn])

    return {user, loading, error};
};


