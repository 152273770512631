import {FC} from "react";
import {Row} from "../../components/core/Container";
import "./Navbar.scss";

const MobileNavbar:FC<{children:any}> = ({children})=>{
    return (
        <Row className='jcsb mobile-nav-menu'>
            {children}
        </Row>
    )
}

export default MobileNavbar;