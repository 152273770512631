import { PostType } from '../../types/post.types';
import {app} from './auth/auth';
import {getFunctions, httpsCallable} from "firebase/functions";


const functions = getFunctions(app, 'europe-west3');

/**
 *   @param search  String
 *   @param modules List:valid options: "posts", "groups", "assets", "users" if empty all four will be returned
 */
export const searchHttpsCallable = httpsCallable(functions, 'search', {});

/**
 *   @param marketItems  List of market items
 *   @param from number the start date for the required data in seconds
 *   @param to number the end date for the required data in seconds
 *   @param resolution string, options
 *   @param synchronous boolean
 */
export const analyticsTimeSeriesHttpsCallable = httpsCallable(functions, 'analyticsTimeSeries', {});


/**
 *   @param direction  "gainers" or "losers"
 *   @param type List:valid options: "stocks", "crypto' and "forex" if empty all three will be returned
 */
export const analyticsTrendingHttpsCallable = httpsCallable(functions, 'analyticsTrending', {});


/**
 *   @params direction  "gainers" or "losers"
 *   @param type List:valid options: "stocks", "crypto' and "forex" if empty all three will be returned
 */
export const analyticsSearchHttpsCallable = httpsCallable(functions, 'analyticsSearch', {});


/**
 *   @param marketItems  List of market items
 *   @param lang string
 *   @params synchronous boolean
 */
export const analyticsDetailsHttpsCallable = httpsCallable(functions, 'analyticsDetails', {});


/**
 *   @param search  String
 *   @param modules List:valid options: "posts", "groups", "assets", "users" if empty all four will be returned
 */
export const getGroupsHttpsCallable = httpsCallable(functions, 'getGroups', {});


/**
 * Returns item statistics in bulk
 * @param {Array.<marketItem>} marketItems Market items to query for statistics
 * @param {boolean} fromCacheOnly If set to true, the server may return outdated data from the cache
 * @param synchronous Whether the server shall wait for all data to be fetched before replying
 */
export const analyticsLogosHttpsCallable = httpsCallable(functions, 'analyticsLogos', {});


export const findGroupHttpsCallable = httpsCallable(functions, 'findGroup', {});

/**
 *   @param search  String
 *   @param modules List:valid options: "posts", "groups", "assets", "users" if empty all four will be returned
 */
export const getAllowedGroupsHttpsCallable = httpsCallable(functions, 'allowedGroupPost', {});

/**
 *   @param id  String
 */
export const giveAwardHttpsCallable = httpsCallable(functions, 'giveAward', {});

/**
 *   @param uid  String
 */
export const disableUserHttpsCallable = httpsCallable(functions, 'disableUser', {});

/**
 *   @param id  String
 */
export const ownerStatisticsHttpsCallable = httpsCallable(functions, 'ownerStatistics', {});

/**
 *   @param id  String
 */
export const createPaymentIntentHttpsCallable = httpsCallable(functions, 'createPaymentIntent', {});

export const supportTicketHttpsCallable = httpsCallable(functions, 'createSupportTicket', {});
 /**
  *   @param idList Array
  */
 export const getPostsHttpsCallable = httpsCallable<any,PostType>(functions, 'getPosts', {});


export const createUserAliasHttpsCallable = httpsCallable(functions, 'createUserAlias', {});


