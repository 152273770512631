import moment from "moment";
import numeral from "numeral";
import { IndexMention, UserMention } from "../types/common.types";

export class EnumUtil {
    public static isSomeEnum<T>(e: any) {
        const obj = Object.values(e);
        return (token: unknown): token is T[keyof T] =>
            obj.includes(token as T[keyof T]);
    }

    public static key<T>(E: any) {
        const obj = new Map<T[keyof T], keyof T>();

        Object.entries(E).forEach(([k, v]) => {
            obj.set(v as T[keyof T], k as keyof T);
        });

        return (e: T[keyof T]): keyof T => {
            const res = obj.get(e);
            if (!res) throw Error("error type enum");
            return res;
        };
    }
}

export const isError = (error: unknown): error is Error => {
    return (
        typeof error === "object" &&
        error !== null &&
        "message" in error &&
        typeof (error as Record<string, unknown>).message === "string"
    );
};

export const allAvailableNumber = (except: any, max: number = 100) => {
    const availableIds = [];

    for (let i = 0; i < max; i++) {
        if (!except.includes(i)) {
            availableIds.push(i);
        }
    }

    return availableIds;
}

export const getDateFromDays = async (days: string) => {
    return moment().add(days, 'days').toDate();
}

export const removeEmpty = (obj: any) => {
    Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined) && delete obj[k]);
    return obj;
};

export const firstCap = (string: string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const shortNumberFormat = (number: number) => {
    if (number < 1000) {
        if (number < 1 && number > 0) {
            if (number < 0.001) {
                return numeral(number).format('0.0e-0');
            }
            return numeral(number).format('0.000');
        }
        return numeral(number).format('0[.]00a');
    } else {
        return numeral(number).format('0.0a', (n: number) => {
            return Math.floor(n);
        }).toUpperCase();
    }
}

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export const isValidUrl = (urlString: string) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return urlPattern.test(urlString);
}

export const hasUrl = (str:string) =>{
    return linkRegex.test(str);
}

export const priceFormat = (number: number, isUSD = false) => {
    if (number) {
        if (Math.abs(number) < 1) {
            if (isUSD) {
                return numeral(number).format('0,0[.]00')
            } else if (Math.abs(number) < 0.0001) {
                return numeral(number).format('0,0.00e+0',);
            } else {
                return numeral(number).format('0,0[.]0000');
            }
        }
        return numeral(number).format('0,0[.]00');
    }
    return "0";
}

export const percentageFormat = (number: number) => {
    if (number) {
        if (Math.abs(number) < 1) {
            return numeral(number).format('0a[.]0000');
        }
        return numeral(number).format('0a[.]00');
    }
    return 0;
}

export function makeUrlExternal(url?: string) {
    if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
}

export function generalTextFormat(text: string) {
    const noSpecialCharsText = text.split('_').join(" ");
    return firstCap(noSpecialCharsText);
}

export const formatPlainTextWithMentions = (str:string, userMentions?:UserMention[],indexMentions?:IndexMention[]) => {
    let result = str;
    let offset = 0;
  
    if(userMentions && userMentions.length){
        userMentions.forEach((item) => {
          const { Name, Start, Length, Id } = item;
          const sign ='@';
          const customString = `${sign}[${Name.slice(1)}](${Id})`;
          const startIndex = (Start || 0) + offset;
          const endIndex = startIndex + (Length || Name.length);
      
          result = result.slice(0, startIndex) +
                   customString +
                   result.slice(endIndex);
      
          offset += customString.length - (Length || Name.length);
        });
    }
    if(indexMentions && indexMentions.length){
        indexMentions.forEach((item)=>{
            const {Name,Start,Length,MarketItem} = item
            const sign = '$';
            const customString = `${sign}[${Name.slice(1)}](${JSON.stringify(MarketItem)})`;
            const startIndex = (Start || 0) + offset;
            const endIndex = startIndex + (Length || Name.length);
        
            result = result.slice(0, startIndex) +
                        customString +
                        result.slice(endIndex);
        
            offset += customString.length - (Length || Name.length);
        })
    }
    return result;
  }

export const linkRegex = /\b((https?|ftp|file):\/\/|www\.)?[^\s]+\.[^\s]+/gi;
