import {FC, InputHTMLAttributes, KeyboardEventHandler, useCallback, useEffect, useMemo, useState} from 'react'
import {MentionsInput, Mention, OnChangeHandlerFunc} from 'react-mentions'

import './FormTextHandler.scss'
import {ensureUser, getUsers} from '../../../services/firebase/user/user'
import {useSelector} from 'react-redux'
import {selectUserMetadata} from '../../../services/redux/reducers/userAuth/user'
import {selectUsers} from '../../../services/redux/reducers/users/users'
import {Col, Row} from '../Container'
import Avatar from '../Shared/Avatar'
import {searchAssets} from '../../../services/firebase/market/market'
import AssetImage from '../../App/Market/AssetImage/AssetImage'
import { makeKey } from '../../../utils/createAssetKey'

export type EnsuredUserType = {
    display: string,
    id: string
}

interface IFormTextHandler extends InputHTMLAttributes<HTMLInputElement> {
    onChangeHandler: OnChangeHandlerFunc,
    onKeyDownHandler?: KeyboardEventHandler,
    groupId?: string,
    style?: object,
    chatMembers?: any,
    value: string,
    placeholder?: string,
}

const FormTextHandler: FC<IFormTextHandler> = ({
                                                   groupId,
                                                   style,
                                                   onChangeHandler,
                                                   chatMembers,
                                                   onKeyDownHandler,
                                                   value,
                                                   placeholder,
                                                   ...props
                                               }) => {
    const userMetaData = useSelector(selectUserMetadata);
    const allUsers = useSelector(selectUsers);

    const [groupUsers, setGroupUsers] = useState<EnsuredUserType[]>([]);

    const defaultUsers: EnsuredUserType[] = useMemo(() => {
        if (groupId) {
            return groupUsers;
        } else {
            const newSearchableUsers: EnsuredUserType[] = [];
            userMetaData?.FollowingUsers?.forEach((userId) => {
                const user = allUsers[userId];
                if (user) {
                    newSearchableUsers.push({id: userId, display: user.Name});
                } else {
                    ensureUser(userId);
                }
            });
            return newSearchableUsers;
        }
    }, [allUsers, groupId, groupUsers, userMetaData?.FollowingUsers]);

    const renderUsers = (
        suggestion: any,
        search: any,
        highlightedDisplay: any,
        index: any,
        focused: any
    ) => {
        return (
            <Row className={`jcfs wb-100 ml-list-item ${focused ? ' focused' : ''}`}>
                <Avatar
                    id={suggestion.id}
                ></Avatar>
                <p>{suggestion.display}</p>
            </Row>
        )
    }

    const renderAssets = (
        suggestion: any,
        search: any,
        highlightedDisplay: any,
        index: any,
        focused: any
    ) => {
         const asset = JSON.parse(suggestion.id)
        return (
            <Row className={`jcfs wb-100 ml-list-item ${focused ? ' focused' : ''}`}>
                <AssetImage stocksWatchlistItem={JSON.parse(suggestion.id)} className="assetLogo"/>
                <p>{`${asset.Symbol}/${asset.To}`}</p>
            </Row>
        )
    }


    const parseUserObj = (user: any) => {
        return {
            display: user.DisplayName ?? user.display ?? "",
            id: `${user.Id ?? user._id ?? user.id ?? ""}`,
        }
    }

    const fetchUsers = (query: string, callback: any) => {
        if (!query) {
            return defaultUsers.map((user) => parseUserObj(user));
        }
        getUsers({Search: query, GroupId: groupId})
            .then((newUsers) => {
                if (newUsers?.length) {
                    const newSearchableUsers: EnsuredUserType[] = [];
                    newUsers.forEach((user) => {
                        newSearchableUsers.push(parseUserObj(user))
                    });
                    return newSearchableUsers
                }
            })
            .then(callback)
    }

    const fetchAssets = (query: string, callback: any) => {
        searchAssets(query, '')
            .then((response: any) => {
                const data = response.data.marketItems;
                if (data.length) {
                    const newAssets: any[] = [];
                    data.forEach((asset: any) => {
                        newAssets.push({
                            display: asset.symbol,
                            id: JSON.stringify({
                                Key: asset.key ?? makeKey(asset),
                                Name: asset.name,
                                Market: asset.market,
                                Symbol: asset.symbol,
                                To: asset.to,
                            }),
                        });
                    })
                    return newAssets;
                }
            })
            .then(callback);
    }


    useEffect(() => {
        if (groupId) {
            getUsers({GroupId: groupId}).then((users) => {
                if (users?.length) {
                    const newGroupUsers = users.map((user) => {
                        return ({
                            display: user.DisplayName ?? "",
                            id: `${user.Id ?? user._id ?? ""}`,
                            user
                        })
                    });
                    setGroupUsers(newGroupUsers);
                }
            })
        }
    }, [groupId]);



    return (
        <MentionsInput
            {...props as any}
            value={value}
            placeholder={placeholder}
            onKeyDown={onKeyDownHandler}
            onChange={onChangeHandler}
            allowSuggestionsAboveCursor={true}
            customSuggestionsContainer={(children: any) => <Col
                className='mentions-container aifs jcfs'>{children}</Col>}
            className="mentions-input"
            style={style}
        >
            <Mention
                trigger="$"
                data={fetchAssets}
                renderSuggestion={renderAssets}
                markup="$[__display__](__id__)"
                displayTransform={(_,display)=>{
                    return `$${display}`;
                }}
                appendSpaceOnAdd={true}
                className="mentions-mention"
            />
            <Mention
                trigger="@"
                data={fetchUsers}
                renderSuggestion={renderUsers}
                markup="@[__display__](__id__)"
                displayTransform={(_,display)=>{
                    return `@${display}`;
                }}
                appendSpaceOnAdd={true}
                className="mentions-mention"
            />
        </MentionsInput>
    );
}

export default FormTextHandler