import React, {useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ignoreDiscussion, leaveAdminRoleInDiscussion, leaveDiscussion, updateDiscussionNotifications } from '../../../../services/firebase/discussions/discussions'
import { removeDiscussion, selectNotificationActivated, selectSettingConfirmation, selectSettingConfirmData, selectSettingDiscussion, selectSettingName, selectSingleDiscussion, setConfirmationData, setConfirmationScreen, setCurrentDiscussion, setNotificationActivated, setSelectedChatSetting, settingReset } from '../../../../services/redux/reducers/discussion/discussion'
import { selectUserMetadata } from '../../../../services/redux/reducers/userAuth/user'
import { UserMetadataType } from '../../../../types/user'
import { settingConfirmationModal, settingsActions } from '../../../../utils/constants/discussions'
import { Col } from '../../../core/Container'
import NewChat from '../NewChat/NewChat'
import AboutSetting from './AboutSetting/AboutSetting'
import './ChatSettingBox.scss'
import ChatConfirmation from '../../ChatConfirmation/ChatConfirmation'

const ChatSettingBox = ({contact}:any) => {
  
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const selected = useSelector(selectSettingName)
  const discussionId  = useSelector(selectSettingDiscussion)
  const discussion = useSelector((state:any)=>selectSingleDiscussion(state,discussionId))
  const showModal = useSelector(selectSettingConfirmation)
  const modalProps = useSelector(selectSettingConfirmData)
  const userMetaData:UserMetadataType|null = useSelector(selectUserMetadata)
  const notificationActivated = useSelector(selectNotificationActivated)

  const userIds = useMemo(() => {
    return [...discussion?.Data?.MembersIds??[], ...discussion?.Data?.InvitedMembersIds??[]];
  }, [JSON.stringify(discussion?.Data?.MembersIds), JSON.stringify(discussion?.Data?.InvitedMembersIds)]);

  const Data = useMemo (()=>{
    return discussion?.Data
  },[discussion?.Data])


  const [isAdmin, setIsAdmin] = useState<boolean>(Data?.AdminsIds.includes(userMetaData?.Id))

  useEffect(() => {
    setIsAdmin(Data?.AdminsIds.includes(userMetaData?.Id))
  }, [Data,userMetaData?.Id])

  //Todo:translation for toast
  const onPressLeaveDiscussion = useCallback(async () => {
    if (isAdmin && discussion?.Data?.AdminsIds.length === 1 && userIds.length > 1) {
      // The user is the only admin and there's still others users in the discussion, he may select another admin before leaving the discussion
          return toast("Set someone else as admin before leaving",{type:"error"})
    }

    const success = await leaveDiscussion(discussionId)

    if(success){
      dispatch(settingReset())
      dispatch(removeDiscussion({discussionId}))
    }
    toast(
        (success ? 'Discussion left successfully' : 'An error occurred while leaving the discussion'),{
        type: success ? 'success' : 'error'
      });

    dispatch(setCurrentDiscussion({discussionId:false}))

  }, [discussion?.Data?.AdminsIds.length, discussionId, isAdmin, userIds.length]);

  const onPressIgnoreDiscussion = useCallback(async () => {

    const success = await ignoreDiscussion(discussionId)
    if (success){
      dispatch(settingReset())
      dispatch(removeDiscussion({discussionId}))
    }
    toast(success?"Discussion ignored successfully":"An error occurred while ignoring the discussion",{type:success?"success":"error"})
  }, [ discussionId]);


  const resetFn = ()=>{
    if (discussion.Type ==='Group'){
      dispatch(setSelectedChatSetting({name:settingsActions.group[0].name}))
    }else{
      dispatch(setSelectedChatSetting({name:settingsActions.direct[0].name}))
    }
    dispatch(setConfirmationScreen({view:false}))
    dispatch(setConfirmationData({data:false}))
  }
  

  const onPressLeaveAdmin = useCallback(
    async () => {
      if (isAdmin && discussion?.Data?.AdminsIds.length === 1 && userIds.length > 1) {
        // The user is the only admin and there's still others users in the discussion, he may select another admin before leaving the discussion
          return toast("Set someone else as admin before leaving",{type:"error"})
      }
      const success = await leaveAdminRoleInDiscussion(discussionId)

      if(success){
        toast("Discussion left with success",{type:"success"})
        resetFn()
      }
    },
    [discussion?.Data?.AdminsIds.length, discussionId, isAdmin, userIds.length,resetFn],
  )
  
  const onPressNotificationOption = useCallback(async ()=>{
    const success = await updateDiscussionNotifications(discussionId,{
      disabledNotifications: !notificationActivated
    })
    if (!success){
      toast("An error occurred while updating the notifications preferences", {type: "error"});
    }else{
      dispatch(setNotificationActivated({muted:!notificationActivated}))
      resetFn()
    }
  },[discussionId,notificationActivated])

  const handleConfirmationClick=(e:any)=>{
    e.preventDefault();
    switch(modalProps.name){
      case settingConfirmationModal.leave:
        onPressLeaveDiscussion();
        break;
      case settingConfirmationModal.ignore:
        onPressIgnoreDiscussion();
        break;
      case settingConfirmationModal.leaveAdmin:
        onPressLeaveAdmin()
        break;
      case settingConfirmationModal.mute:
      case settingConfirmationModal.unmute:
        onPressNotificationOption()
        break;
    }
  }


  const settingConfirmation = (confirmationData:any)=>{
    return <ChatConfirmation confirmationData={confirmationData} resetFn={resetFn} confirmFn={handleConfirmationClick} />
  }

  
  const getSetting = useCallback(
    ()=>{
      switch(selected){
        case "newChat":
          return <NewChat/>
        case settingsActions.direct[0].name:
          return <NewChat memberProp={[contact?.Id]}/>
        case settingsActions.group[1].name:
          return <NewChat inviteMode = {{discussionId,members:userIds}}/>
        case settingsActions.group[0].name:
          return <AboutSetting isAdmin={isAdmin} userIds={userIds} discussionId={discussionId}/>
      }
    },
    [isAdmin,selected,contact?.Id,discussionId,userIds],
  )
  
  return (
    <Col className={'setting-wrapper jcfs ' + (showModal?'transparent':'')}>
        {showModal&&settingConfirmation(modalProps)}
       {getSetting()}
    </Col>
  )
}

export default ChatSettingBox