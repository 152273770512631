import {FC, useCallback, useState} from "react";
import Modal from "../../../../core/Modal/Modal";
import "./UpdatePostPopup.scss"
import {Row} from "../../../../core/Container";
import {Button} from "../../../../core/Button";
import {closePopupIcon} from "../../../../../assets/images/svgIcons/svgIcons";
import {AreaInput} from "../../../../core/Input";
import {toast} from "react-toastify";
import {MODE_DEBUG} from "../../../../../utils/constants/config";
import {addPostUpdate} from "../../../../../services/firebase/posts/posts";
import {PostType} from "../../../../../types/post.types";
import {useTranslation} from "react-i18next";

const UpdatePostPopup: FC<{
    show: boolean,
    onClose: () => void,
    fullPostId?: string,
    onNewUpdate?: (newUpdate: PostType["LastUpdate"]) => void,
}> = ({
          show,
          onClose,
          fullPostId,
          onNewUpdate
      }) => {

    const {t} = useTranslation();

    const [updateText, setUpdateText] = useState<string>("");
    const [updateTextError, setUpdateTextError] = useState<string>("");


    const onPostUpdate = useCallback(() => {
        if (updateText.trim() !== '' && fullPostId) {
            addPostUpdate(fullPostId, updateText).then(() => {
                if (onNewUpdate) {
                    onNewUpdate({
                        Date: new Date().toString(),
                        Text: updateText,
                    })
                }
                onClose();
                toast.success(t<any>("feed.postOptions.updateWasAddedSuccessfully"));
            }).catch((reason) => {
                if (MODE_DEBUG) {
                    console.error("Error while adding new post update, in:addPostUpdate", reason);
                }
                toast.error(t<any>("errors.somethingWentWrong"));
            });
        } else {
            setUpdateTextError(t<any>("form.error.thisFieldIsRequired"));
        }
    }, [fullPostId, onClose, onNewUpdate, t, updateText])

    return (
        <Modal show={show} onClose={onClose} className="update-post-popup">
            <Row className="upp-header">
                <h3>{t<any>("post.addANewUpdate")}</h3>
                <Button className='upp-close-icon-btn' onClick={onClose}>{closePopupIcon}</Button>
            </Row>
            <AreaInput
                className="upp-text-area"
                rows={8}
                onChange={event => {
                    setUpdateTextError("");
                    setUpdateText(event.target.value);
                }}
                value={updateText}
                placeholder="What is your update..."
                error={updateTextError}
                touched={true}
            />
            <Button
                onClick={onPostUpdate}
                className="gw-btn upp-post-update-btn"
                disabled={!(updateText.trim())}
            >
                Add update
            </Button>
        </Modal>
    )
}

export default UpdatePostPopup;