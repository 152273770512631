import { createSelector, createSlice } from '@reduxjs/toolkit';
import { MODE_DEBUG } from '../../../../utils/constants/config';
import { REACTION_CONTENT_TYPE_SOCIAL_POST, REACTION_CONTENT_TYPE_SOCIAL_COMMENT, REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY } from '../../../../utils/constants/idea';

const initialState = {
    [REACTION_CONTENT_TYPE_SOCIAL_POST]: {},
    [REACTION_CONTENT_TYPE_SOCIAL_COMMENT]: {},
    [REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY]: {},
};

export const selectDataReactions = (state:any) => state.reactions;
export const selectPostId = (state:any, id: string) => id;

export const selectPostReactions = createSelector([selectDataReactions,selectPostId],(data,id)=>(data?.[REACTION_CONTENT_TYPE_SOCIAL_POST]?.[id]));



export const reactionsReducer = createSlice({
    name:'reactions',
    initialState,
    reducers:{
        setPostReactions: (state,action)=>{
            const { fullId,likeCount,commentCount,shareCount } = action.payload
            return {
                ...state,
                [REACTION_CONTENT_TYPE_SOCIAL_POST]: {
                  ...state[REACTION_CONTENT_TYPE_SOCIAL_POST],
                  [fullId]: {
                    likeCount: Math.max(likeCount || 0, 0),
                    commentCount: Math.max(commentCount || 0, 0),
                    shareCount: Math.max(shareCount || 0, 0),
                  },
                },
              };
        },
        setBatchPostReaction: (state,action) =>{
            const {postsData} = action.payload
            
            Object.keys(postsData).forEach(postId => {
                const postData = postsData[postId];
                // Rewrite the data to match our model, just in case
                postsData[postId] = {
                    likeCount: Math.max(postData.likeCount || 0, 0),
                    commentCount: Math.max(postData.commentCount || 0, 0),
                    shareCount: Math.max(postData.shareCount || 0, 0),
                };
            });
            return {
                ...state,
                [REACTION_CONTENT_TYPE_SOCIAL_POST]: {
                  ...state[REACTION_CONTENT_TYPE_SOCIAL_POST],
                  ...postsData,
                },
            };
        },
        setCommentReactions: (state,action) =>{
            const { fullId,likeCount,replyCount } = action.payload
            return {
                ...state,
                [REACTION_CONTENT_TYPE_SOCIAL_COMMENT]: {
                  ...state[REACTION_CONTENT_TYPE_SOCIAL_COMMENT],
                  [fullId]: {
                    likeCount: Math.max(likeCount || 0, 0),
                    replyCount: Math.max(replyCount || 0, 0),
                  },
                },
            };
        },
        setCommentReplyReaction: (state,action) =>{
            const { fullId,likeCount } = action.payload
            return {
                ...state,
                [REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY]: {
                    ...state[REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY],
                    [fullId]: {
                    likeCount: Math.max(likeCount || 0, 0),
                    },
                },
            };
        },
        incrementLikesCount:(state:any,action)=>{
            const { fullId,contentType,isLocal } = action.payload
            let {delta} = action.payload
            let reactionCount:any = state[contentType]?.[fullId] ?? {};
            if (reactionCount) {
                // Updating the existing postReactionCount
                let likeCount = reactionCount.likeCount;

                if (!likeCount) {
                likeCount = 0;
                }

                let pendingLikeCount = reactionCount.pendingLikeCount || 0;

                if (isLocal) {
                // For local events, update the count unconditionally
                pendingLikeCount += delta;
                } else {
                // For remote events, see if it compensates for a pending like count
                if (pendingLikeCount > 0 && delta > 0) {
                    const localDelta = Math.min(pendingLikeCount, delta);
                    pendingLikeCount -= localDelta;
                    delta -= localDelta;
                } else if (pendingLikeCount < 0 && delta < 0) {
                    const localDelta = Math.max(pendingLikeCount, delta);
                    pendingLikeCount -= localDelta;
                    delta -= localDelta;
                }
                }
                reactionCount = {
                    ...reactionCount,
                    likeCount: Math.max((reactionCount?.likeCount || 0) + delta, 0),
                    pendingLikeCount,
                };

                return {
                    ...state,
                    [contentType]: {
                      ...state[contentType],
                      [fullId]: reactionCount,
                    },
                };
            } else {
                if (MODE_DEBUG) {
                    console.warn(
                        'postReactionCount of fullId',
                        fullId,
                        'is undefined, yet an increment have been triggered !',
                    );
                }
                // Should never get in here, but if so :
                // If the postReactionCount isn't created (yet) we don't want to create one from here, since it would most likely represent a bad count
                // A POST_REACTION_RESET is most likely on it's way anyway !
                return state;
            }
        },
        setUserReactions:(state:any,action) =>{
            const {userReactions,contentType} = action.payload;

            if (!userReactions || !contentType) {
                if (MODE_DEBUG) {
                console.warn('Invalid user reaction setting action');
                }
                return state;
            }

            return {
                ...state,
                userReactions: {
                  ...state.userReactions,
                  [contentType]: {
                    ...state.userReactions?.[contentType],
                    ...userReactions,
                  },
                },
              };
        },
        incrementPostCommentsCount:(state:any,action) =>{
                const {fullId,delta} = action.payload
                let postCommentCount = state[REACTION_CONTENT_TYPE_SOCIAL_POST]?.[fullId];

                postCommentCount = {
                ...postCommentCount,
                commentCount: Math.max(
                    (postCommentCount?.commentCount || 0) + delta,
                    0,
                ),
                };
        
                return {
                    ...state,
                    [REACTION_CONTENT_TYPE_SOCIAL_POST]: {
                      ...state[REACTION_CONTENT_TYPE_SOCIAL_POST],
                      [fullId]: postCommentCount,
                    },
                  };
        },
        incrementPostSharesCount:(state:any,action)=>{
            const {fullId,delta} = action.payload

            let postShareCount = state['socialPost']?.[fullId];

            postShareCount = {
            ...postShareCount,
            shareCount: Math.max((postShareCount?.shareCount || 0) + delta, 0),
            };
    
            return {
                ...state,
                [REACTION_CONTENT_TYPE_SOCIAL_POST]: {
                  ...state[REACTION_CONTENT_TYPE_SOCIAL_POST],
                  [fullId]: postShareCount,
                },
            };
        },
        incrementCommentRepliesCount:(state:any,action)=>{
            const {fullId,delta} = action.payload
            let commentReactionsCount =
            state[REACTION_CONTENT_TYPE_SOCIAL_COMMENT]?.[fullId];
            commentReactionsCount = {
                ...commentReactionsCount,
                repliesCount: Math.max(
                (commentReactionsCount?.repliesCount || 0) + delta,
                0,
                ),
            };

            return {
                ...state,
                [REACTION_CONTENT_TYPE_SOCIAL_COMMENT]: {
                  ...state[REACTION_CONTENT_TYPE_SOCIAL_COMMENT],
                  [fullId]: commentReactionsCount,
                },
            };
        }
    }
})

export const { setPostReactions, setBatchPostReaction, setCommentReactions, setCommentReplyReaction, incrementLikesCount, setUserReactions, incrementPostCommentsCount, incrementPostSharesCount, incrementCommentRepliesCount } = reactionsReducer.actions;

export default reactionsReducer.reducer