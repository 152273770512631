import {FC, useMemo} from "react";
import {Row} from "../../../../core/Container";
import {Button} from "../../../../core/Button";
import {generalTextFormat} from "../../../../../utils/helpers";
import {FinancialDataType} from "../../../../../types/post.types";
import {useTranslation} from "react-i18next";
import "./PostTags.scss";
import {buyArrowIcon, sellArrowIcon} from "../../../../../assets/images/svgIcons/svgIcons";

const PostTags: FC<{ financialData?: FinancialDataType, category?: string }> = ({financialData, category}) => {

    const {t} = useTranslation();

    return useMemo(() => {
        return (
            <Row className='post-tags jcfs'>
                {financialData && <>
                    {financialData?.Sell ? <Button className='tag sell'>
                            {sellArrowIcon}
                            {t<any>('idea.sell')}
                        </Button> :
                        <Button className='tag buy'>
                            {buyArrowIcon}
                            {t<any>('idea.buy')}
                        </Button>}
                    {financialData.Instrument && <Button className='tag index'>
                        {financialData.Instrument.Name}
                    </Button>}
                    {financialData.Timeframe && <Button className='tag'>
                        {generalTextFormat(financialData.Timeframe) + ' Term'}
                    </Button>}
                    {financialData.AssetType && <Button className='tag'>
                        {generalTextFormat(financialData.AssetType)}
                    </Button>}
                </>}
                {category && <Button className='tag'>
                    {generalTextFormat(category)}
                </Button>}
            </Row>
        )
    }, [category, financialData, t]);
}

export default PostTags;