import {createSelector, createSlice} from "@reduxjs/toolkit";
import {IAuthor} from "../../../../types/user.types";
import {MODE_DEBUG} from "../../../../utils/constants/config";

const initialState: any = {
    data: {
        users: {}
    },
    error: null
}

export const selectDataUsers = (state: any) => state.users?.data;
export const selectDataContact = (state: any, id: IAuthor["Id"]) => id;
export const selectUsers = createSelector([selectDataUsers], (data) => (data?.users))
export const selectContact = createSelector([selectDataUsers, selectDataContact], (data, id) => (data?.users[id]))
export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUser: (state, action: any) => {

            const {users: actionUsers, date} = action.payload;
            if (!actionUsers || actionUsers.length === 0) {
                if (MODE_DEBUG) {
                    console.warn(
                        `setUser action dispatched without data`,
                        action,
                    );
                }
                return;
            }

            let currentState = state.data.users;
            let updatedUsersState: any;

            
            actionUsers.forEach((user: any) => {
                if(!user?.Id){
                    return;
                }
                const {Id, Name, PictureDate, Job, Verified, _date, FeaturedPosts, DisplayName, SystemAlias} = user;

                const localDate = _date || date;
                if (!(localDate instanceof Date)) {
                    if (MODE_DEBUG) {
                        console.warn(
                            "setUser" +
                            'action dispatched without proper date for user ' +
                            Id +
                            " (looked into action's date field and user _date)",
                        );
                    }
                    return;
                }

                let userToUpdate = currentState[Id];

                if (
                    (!userToUpdate?.date || userToUpdate.date < date) && // Current store data is older than our current value
                    (!updatedUsersState?.[Id]?.date || // And updated user data is older than our curent value
                        updatedUsersState?.[Id].date < date)
                ) {
                    userToUpdate = {...userToUpdate, ...user,date: localDate, Name: Name ?? DisplayName};

                    if (Job) {
                        userToUpdate.Job = Job;
                    }
                    if (Verified) {
                        userToUpdate.Verified = Verified;
                    }
                    if (FeaturedPosts) {
                        userToUpdate.FeaturedPosts = FeaturedPosts;
                    }
                    if (SystemAlias) {
                        userToUpdate.SystemAlias = SystemAlias;
                    }

                    // If there is a PictureDate, but we don't have one or it's outdated
                    // if (MODE_DEBUG && PictureDate && !(PictureDate instanceof Date)) {
                    //     console.warn('Got a user with a non-native date PictureDate', user);
                    // }
                    if (
                        PictureDate &&
                        (!currentState[Id]?.PictureDate ||
                            (currentState[Id]?.PictureDate ?? 0) < PictureDate)
                    ) {
                        userToUpdate.PictureDate = PictureDate;
                        userToUpdate.PictureUrl = null;
                    }

                    // Something was changed in the object, update the state
                    updatedUsersState = {...updatedUsersState, [Id]: userToUpdate};
                }
            });

            if (updatedUsersState) {

                state.data.users = {
                    ...state.data.users,
                    ...updatedUsersState
                }
            }
        }
    }
});

export const usersActions = usersSlice.actions;
export const {
    setUser
} = usersSlice.actions;

export default usersSlice.reducer
