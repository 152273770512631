import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import fr from "./locales/fr.json"

const resources = {
    en,
    fr,
}

export const availableLanguages = [
    {key: "en", name: "English"},
    {key: "fr", name: "Français"},
]

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        defaultNS: "common",
        fallbackLng: "en",
    });