import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    deleteField,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {t} from "i18next";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {discussionConstants} from "../../../utils/constants/discussions";
import {addMessageListener} from "../../realtime/realtimeChannel";
import {
    partiallyUpdateDiscussion,
    removeDiscussion,
    setDiscussionData,
    setOpenDiscussion
} from "../../redux/reducers/discussion/discussion";
import {setUser} from "../../redux/reducers/users/users";
import store from "../../redux/store";
import {uploadImageAttachement} from "../assets";
import {db} from "../auth/auth";
import {ensureUser, getCurrentUserDataAsDbType} from "../user/user";
import {createMessage} from "./messages";

export let discussionListener: any = null
export let inviteListener: any = null

export const addDiscussionUserstoDB = (discussionData: any) => {
    const membersDictionary = discussionData?.Data?.MembersData;
    const {InvitedMembersIds} = discussionData?.Data
    if (membersDictionary) {
        const members = Object.keys(membersDictionary).map(
            memberId => membersDictionary[memberId],
        );
        if (members && Array.isArray(members)) {
            const users = members.map(member => {
                if (member.PictureDate) {
                    try {
                        if(typeof member.PictureDate === "string"){
                            member = {...member, PictureDate:new Date(member.PictureDate??"")}
                        }
                        else{
                            member = {...member, PictureDate: member.PictureDate.toDate()};
                        }
                    } catch (error) {
                        if (MODE_DEBUG) {
                            console.error("error when converting picture timestamp ", error,member)
                        }
                    }
                }
                return member;
            });

            let date = discussionData.Date;
            if (date && date.toDate) {
                date = date.toDate();
            }
            store.dispatch(setUser({users, date}));
        }
    }
    if (InvitedMembersIds.length > 0) {
        InvitedMembersIds.forEach((id: any) => {
            ensureUser(id);
        })
    }
}

export const addListenerForDiscussion = (userId: any) => {

    const discRef = query(collection(db, 'discussions'), where('Data.MembersIds', 'array-contains', userId), orderBy('LastActivityDate', 'desc'), limit(discussionConstants.MaxListenedDiscussions));

    discussionListener = onSnapshot(discRef,
        (querySnapshot) => {
            querySnapshot.docs.forEach((docSnapShot: any) => {
                    const discussionData = docSnapShot.data()
                    store.dispatch(setDiscussionData({discussionId: docSnapShot.id, discussionData: docSnapShot.data()}))
                    addDiscussionUserstoDB(discussionData);
                }
            );
        })
}

export const addListenerForInvites = (userId: any) => {
    const discRef = query(collection(db, 'discussions'), where('Data.InvitedMembersIds', 'array-contains', userId), orderBy('LastActivityDate', 'desc'), limit(discussionConstants.MaxListenedDiscussions));
    inviteListener = onSnapshot(discRef,
        (querySnapshot) => {
            querySnapshot?.docChanges().forEach(change => {
                const discussionData = change.doc.data();

                switch (change.type) {
                    case 'added':
                    case 'modified':
                        store.dispatch(setDiscussionData({discussionId: change.doc.id, discussionData: discussionData}))
                        addDiscussionUserstoDB(discussionData)
                        break;
                    case 'removed':
                        store.dispatch(removeDiscussion({discussionId: change.doc.id}));
                        break;
                    default:
                        break;
                }
            });
        },
        (onError) => {
            if (MODE_DEBUG) {
                console.warn('discussionsInvitationsListener error:', onError);
            }
        })
}

export async function ignoreDiscussion(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `ignoreDiscussion was called but discussionId:${discussionId} is falsy `,
            );
        }
        return;
    }

    const user = getCurrentUserDataAsDbType();
    const userId = user?.Id;

    if (!userId) {
        return console.warn('ignoreDiscussion was called but no user is logged-in');
    }

    const discRef = doc(db, 'discussions', discussionId)

    return await setDoc(discRef,
        {
            Data: {
                IgnoringMembersIds: arrayUnion(userId),
                MembersIds: arrayRemove(userId),
            },
        },
        {merge: true},
    )
        .then(() => true)
        .catch((error: any) => {
            if (MODE_DEBUG) {
                console.warn('An error occurred in ignoreDiscussion:', error);
            }
            return false;
        });
}

export async function getIgnoredDiscussionsInvitations(userId: any) {

    if (!userId) {
        return console.warn(
            'getIgnoredDiscussions was called but no user is logged-in',
        );
    }

    const q = query(collection(db, 'discussions'), where('Data.IgnoringInvitedMembersIds', 'array-contains', userId), orderBy('LastActivityDate', 'desc'));

    return await getDocs(q)
        .then(querySnapshot => {
            const invitations: any = [];
            querySnapshot?.forEach(doc => {
                    const discussionData = doc.data();
                    store.dispatch(setDiscussionData({discussionId: doc.id, discussionData: discussionData}))
                    addDiscussionUserstoDB(discussionData)
                    invitations.push({
                        ...discussionData,
                        Id: doc.id,
                    })
                }
                ,
            );
            return invitations;
        })
        .catch((error: any) => {
            if (MODE_DEBUG) {
                console.warn('getIgnoredDiscussions ignoredInvitations error:', error);
            }
            return [];
        });
}

export async function getIgnoredDiscussions(userId: any) {


    if (!userId) {
        return console.warn(
            'getIgnoredDiscussions was called but no user is logged-in',
        );
    }

    const q = query(collection(db, 'discussions'), where('Data.IgnoringMembersIds', 'array-contains', userId), orderBy('LastActivityDate', 'desc'));

    return await getDocs(q)
        .then(querySnapshot => {
            const discussions: any = [];
            querySnapshot?.forEach(doc => {
                    const discussionData = doc.data();
                    store.dispatch(setDiscussionData({discussionId: doc.id, discussionData: discussionData}));
                    addDiscussionUserstoDB(discussionData)
                    discussions.push({
                        ...discussionData,
                        Id: doc.id,
                    })

                }
                ,
            );
            return discussions;
        })
        .catch(error => {
            if (MODE_DEBUG) {
                console.warn('getIgnoredDiscussions ignoredDiscussions error:', error);
            }
            return [];
        });
}

export async function createDiscussion({userIds, name, description, avatar}: any) {
    const maxUserInDiscussion = 10;
    if (!userIds || userIds.length < 1 || userIds.length > 10) {
        if (MODE_DEBUG) {
            console.warn(
                `createDiscussion was called but userIds:${userIds} is falsy, empty or greater than ${maxUserInDiscussion}`,
            );
        }
        return;
    }

    const connectedUser = getCurrentUserDataAsDbType();
    
    if (!connectedUser) {
        if (MODE_DEBUG) {
            console.warn(
                'connectedUser is falsy, yet createDiscussion have been called !',
            );
        }
        return;
    }

    const Type = userIds.length === 1 ? 'Direct' : 'Group';
    
    if(connectedUser.PictureDate){
        if(typeof connectedUser.PictureDate === "string"){
            connectedUser.PictureDate = new Date(connectedUser.PictureDate??"")
        }
        else if (connectedUser.PictureDate !instanceof Date){
            connectedUser.PictureDate = new Date()
            if(MODE_DEBUG){
                console.warn('user Picture date is not date or string',connectedUser.PictureDate)
            }
        }
    }

    let Data: any = {
        MembersIds: [connectedUser.Id],
        MembersData: {
            [connectedUser.Id]:connectedUser,
        },
        InvitedMembersIds: userIds,
        AdminsIds:
            Type === 'Direct' ? [connectedUser.Id, userIds[0]] : [connectedUser.Id],
    };

    if (name) {
        Data.Name = name;
    }

    if (description) {
        Data.Description = description;
    }

    if (avatar) {
        Data.Avatar = await uploadImageAttachement([avatar]).then(
            result => result.Images[0],
        );
    }

    const discussionDoc = {
        Type,
        Date: serverTimestamp(),
        LastActivityDate: serverTimestamp(),
        Data,
    };

    if (MODE_DEBUG) {
        console.debug('createDiscussion doc:', discussionDoc);
    }

    const orderedUserIds = [connectedUser.Id, userIds[0]].sort();
    const docId =
        userIds.length === 1
            ? 'direct.' + orderedUserIds[0] + '.' + orderedUserIds[1]
            : null;

    if (docId) {
        const discRef = doc(db, 'discussions', docId)
        await setDoc(discRef, discussionDoc).catch((e) => {
            if (MODE_DEBUG)
                console.error(e);
        });
        return docId;
    } else {
        const discRef = collection(db, 'discussions')
        const createdDiscussion = await addDoc(discRef, discussionDoc);
        return createdDiscussion?.id;
    }

}

export async function sendDiscussionInviteToUser({discussionId, id}: any) {
    const connectedUser = getCurrentUserDataAsDbType();
    if (!connectedUser) {
        if (MODE_DEBUG) {
            console.warn(
                'connectedUser is falsy, yet createGroup have been called !',
            );
        }
        return;
    }
    const discRef = doc(db, 'discussions', discussionId)

    return await setDoc(discRef,
        {
            Data: {
                InvitedMembersIds: arrayUnion(id)
            },
        },
        {merge: true}
    ).then(() => {
        return true
    })
        .catch((error) => {
            if (MODE_DEBUG) {
                console.log(error)
            }
            return error
        })
}

export async function joinDiscussion(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `joinDiscussion was called but discussionId:${discussionId} is falsy `,
            );
        }
        return;
    }

    const formattedUser = getCurrentUserDataAsDbType();

    if (!formattedUser || !formattedUser?.Id) {
        return console.warn('joinDiscussion was called but no user is logged-in');
    }
    const discRef = doc(db, 'discussions', discussionId)
    await updateDoc(discRef, {
        'Data.MembersIds': arrayUnion(formattedUser.Id),
        ['Data.MembersData.' + formattedUser.Id]: formattedUser,
        'Data.InvitedMembersIds': arrayRemove(
            formattedUser.Id,
        ),
    }).then(() => {
        addMessageListener(discussionId)
    });
}

export async function leaveDiscussion(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `leaveDiscussion was called but discussionId:${discussionId} is falsy `,
            );
        }
        return;
    }

    const user = getCurrentUserDataAsDbType();

    if (!user || !user.Id) {

        return console.warn('leaveDiscussion was called but no user is logged-in');
    }
    const discRef = doc(db, 'discussions', discussionId)

    return await updateDoc(discRef, {
        'Data.InvitedMembersIds': arrayRemove(user.Id),
        'Data.MembersIds': arrayRemove(user.Id),
        ['Data.MembersData.' + user.Id]: deleteField(),
        'Data.AdminsIds': arrayRemove(user.Id),
    })
        .catch((error: any) => {
            if (MODE_DEBUG) {
                console.warn('leaveDiscussion error : ', error);
            }
            return false;
        })
        .then(() => {
            return true;
        });
}

export async function ignoreDiscussionInvitation(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `ignoreDiscussionInvitation was called but discussionId:${discussionId} is falsy `,
            );
        }
        return;
    }

    const user = getCurrentUserDataAsDbType();
    const userId = user?.Id;

    if (!userId) {
        return console.warn(
            'ignoreDiscussionInvitation was called but no user is logged-in',
        );
    }

    const discRef = doc(db, 'discussions', discussionId)

    return await setDoc(discRef,
        {
            Data: {
                IgnoringInvitedMembersIds: arrayUnion(userId),
                InvitedMembersIds: arrayRemove(userId),
            },
        },
        {merge: true},
    )
        .then(async () => {
                await getIgnoredDiscussionsInvitations(userId)
                return true
            }
        )
        .catch(error => {
            if (MODE_DEBUG) {
                console.warn('An error occurred in ignoreDiscussionInvitation:', error);
            }
            return false;
        });
}

export async function stopIgnoringDiscussionInvitation(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `stopIgnoringDiscussionInvitation was called but discussionId:${discussionId} is falsy `,
            );
        }
        return;
    }

    const user = getCurrentUserDataAsDbType();
    const userId = user?.Id;

    if (!userId) {
        return console.warn(
            'stopIgnoringDiscussionInvitation was called but no user is logged-in',
        );
    }
    const discRef = doc(db, 'discussions', discussionId)

    return await setDoc(
        discRef,
        {
            Data: {
                IgnoringInvitedMembersIds: arrayRemove(userId),
                InvitedMembersIds: arrayUnion(userId),
            },
        },
        {merge: true},
    )
        .then(() => true)
        .catch(() => false);
}

export async function stopIgnoringDiscussion(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `stopIgnoringDiscussion was called but discussionId:${discussionId} is falsy `,
            );
        }
        return;
    }

    const user = getCurrentUserDataAsDbType();
    const userId = user?.Id;

    if (!userId) {
        return console.warn(
            'stopIgnoringDiscussion was called but no user is logged-in',
        );
    }

    //TODO: Update Member data
    const discRef = doc(db, 'discussions', discussionId)

    return await setDoc(
        discRef,
        {
            Data: {
                IgnoringMembersIds: arrayRemove(userId),
                MembersIds: arrayUnion(userId),
            },
        },
        {merge: true},
    )
        .then(() => {
            addMessageListener(discussionId)
            return true
        })
        .catch(() => false);
}

export const groupTitle = (arr: any) => {
    return `${arr[0]?.Name ?? arr[0]?.DisplayName},${arr[1]?.Name ?? arr[1]?.DisplayName} and ${arr.length - 2} others`
};

export const getDiscussionName = (discussion: any) => {
    let discussionName = discussion?.Data?.Name;

    if (discussionName) {
        return discussionName;
    }

    const state = store.getState();
    const {users} = state.users.data
    const {user}: any = state.auth.data

    if (!user || !user.uid) {
        return console.warn(
            'getDiscussionName was called but no user is logged-in',
        );
    }

    const contactIds = [
        ...(discussion?.Data?.MembersIds ?? []),
        ...(discussion?.Data?.InvitedMembersIds ?? []),
        ...(discussion?.Data?.IgnoringMembersIds ?? []),
        ...(discussion?.Data?.IgnoringInvitedMembersIds ?? []),
    ].filter(id => id !== user.uid);

    const contacts: any = {};
    Object.keys(users).forEach(userId => {
        if (contactIds.includes(userId)) {
            contacts[userId] = users[userId];
        }
    });

    discussionName = contacts[contactIds[0]]?.Name ?? '';

    if (contacts[contactIds[1]]?.Name) {
        discussionName += ', ' + contacts[contactIds[1]].Name;
    }

    if (contactIds.length > 2) {
        discussionName +=
            ' ' +
            t('chat.moreUsers.other', {count: contactIds.length - 2});
    }

    return discussionName.length > 0
        ? discussionName
        : "Empty discussion";
};

export async function updateDiscussion({
                                           discussionId,
                                           avatar,
                                           name,
                                           description,
                                       }: any) {
    if (!discussionId || (!avatar && !name && !description)) {
        if (MODE_DEBUG) {
            console.warn(
                `updateDiscussion was called but discussionId:${discussionId} is falsy or avatar:${avatar}, name:${name} and desc:${description} are falsy`,
            );
        }
        return;
    }

    let update: any = {};
    if (name) {
        update = {
            ...update,
            'Data.Name': name,
        };
    }

    if (description) {
        update = {
            ...update,
            'Data.Description': description,
        };
    }

    if (avatar) {
        let uploadedAvatarUrl;

        await uploadImageAttachement(avatar).then(result => {
            uploadedAvatarUrl = result.Images[0];
        });

        if (uploadedAvatarUrl) {
            update = {
                ...update,
                'Data.Avatar': uploadedAvatarUrl,
            };
        }
    }
    const discRef = doc(db, 'discussions', discussionId)

    await updateDoc(
        discRef,
        update,
        {merge: true},
    )

    const transformedUpdate: any = {};
    // Strip "Data." if it exists
    Object.keys(update)
        .filter(v => v.startsWith('Data.'))
        .forEach(key => (transformedUpdate[key.substr(5)] = update[key]));
    store.dispatch(partiallyUpdateDiscussion({discussionId, update}));
}

export async function setUserAdminInDiscussion(discussionId: any, userId: any) {
    if (!discussionId || !userId) {
        if (MODE_DEBUG) {
            console.warn(
                `setUserAdminInDiscussion was called but discussionId:${discussionId} or userId:${userId} is falsy`,
            );
        }
        return;
    }

    const discRef = doc(db, 'discussions', discussionId)

    await updateDoc(discRef, {
        'Data.AdminsIds': arrayUnion(userId),
    });
}

export async function leaveAdminRoleInDiscussion(discussionId: any) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `leaveAdminRoleInDiscussion was called but discussionId:${discussionId} is falsy`,
            );
        }
        return false;
    }

    const user = getCurrentUserDataAsDbType();
    const userId = user?.Id;

    if (!userId) {
        return console.warn(
            'leaveAdminRoleInDiscussion was called but no user is logged-in',
        );
    }

    const discRef = doc(db, 'discussions', discussionId)

    await
        updateDoc(discRef, {
            'Data.AdminsIds': arrayRemove(userId),
        }).then(() => {
            return true;
        }).catch((error) => {
            if (MODE_DEBUG) {
                console.log(error);
            }
            return false
        });
}

export async function removeUserFromDiscussion(discussionId: any, userId: any) {
    if (!discussionId || !userId) {
        if (MODE_DEBUG) {
            console.warn(
                `removeUserFromDiscussion was called but discussionId:${discussionId} or userId:${userId} is falsy`,
            );
        }
        return;
    }

    const discRef = doc(db, 'discussions', discussionId)


    await updateDoc(discRef, {
        'Data.InvitedMembersIds': arrayRemove(userId),
        'Data.MembersIds': arrayRemove(userId),
        ['Data.MembersData.' + userId]: deleteField(),
    });
}

export async function updateDiscussionNotifications(
    discussionId: string,
    {disabledNotifications}: { disabledNotifications: boolean },
) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `updateDiscussionNotifications was called but discussionId:${discussionId} is falsy`,
            );
        }
        return;
    }

    const state = store.getState();
    const {user}: any = state.auth.data

    if (!user || !user.uid) {
        return console.warn(
            'updateDiscussionNotifications was called but no user is logged-in',
        );
    }

    let success = true;
    const discRef = doc(db, 'discussions', discussionId, 'users-options', user.uid)
    await setDoc(
        discRef,
        {
            DisabledNotifications: disabledNotifications
                ? deleteField()
                : true,
        },
        {merge: true},
    )
        .catch((error: Error) => {
            if (MODE_DEBUG) {
                console.info('updateDiscussionNotifications error : ', error);
            }
            success = false;
        });

    return success;
}

export async function getUserDiscussionOptions(discussionId: string) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `getUserDiscussionOptions was called but discussionId:${discussionId} is falsy`,
            );
        }
        return;
    }

    const state = store.getState();
    const {user}: any = state.auth.data

    if (!user || !user.uid) {
        return console.warn(
            'getUserDiscussionOptions was called but no user is logged-in',
        );
    }

    const discRef = doc(db, 'discussions', discussionId, 'users-options', user.uid)
    try {
        const discussionOptionsDoc = await getDoc(discRef)

        let discussionOptions = null;

        if (discussionOptionsDoc.exists()) {
            discussionOptions = discussionOptionsDoc.data();
        }

        return discussionOptions;
    } catch (error) {
        if (MODE_DEBUG) {
            console.info('getUserDiscussionOptions error : ', error);
        }
        return false;
    }
}

export async function setLastViewedDateForDiscussion(discussionId: string) {
    if (!discussionId) {
        if (MODE_DEBUG) {
            console.warn(
                `setLastViewedDateForDiscussion was called but discussionId:${discussionId} is falsy`,
            );
        }
        return;
    }

    const user = getCurrentUserDataAsDbType();

    if (!user || !user.Id) {
        return console.warn(
            'setLastViewedDateForDiscussion was called but no user is logged-in',
        );
    }

    // Since we're updating the last viewed date, might as well update the user data
    const discRef = doc(db, 'discussions', discussionId)

    await updateDoc(discRef, {
        ['Data.LastViewedDates.' + user.Id]:
            serverTimestamp(),
        ['Data.MembersData.' + user.Id]: user,
    });

    if (MODE_DEBUG) {
        console.info(
            'The discussion',
            discussionId,
            'last viewed date is updated for user:',
            user.Id,
            '!',
        );
    }
}

export function shouldShowBadge(discussion: any, userId: any) {
    let showBadge = false;

    if (discussion && discussion.Data.InvitedMembersIds.includes(userId)) {
        showBadge = true;
    } else if (
        discussion &&
        discussion.Data?.LastMessageData?.Author?.Id !== userId
    ) {
        if (discussion.Data && !discussion.Data.LastViewedDates) {
            // No one viewed the discussion, should not happen
            showBadge = true;
        } else if (!discussion.Data?.LastViewedDates[userId]) {
            // The connected user never read the discussion, and is a member
            if (discussion.Data?.MembersIds?.includes(userId)) {
                showBadge = true;
            }
        } else {
            let lastViewedDate = discussion.Data?.LastViewedDates[userId],
                lastActivityDate = discussion.LastActivityDate;
            // The connected user read the messages before the last activity on this discussion, and is a member
            if (
                lastViewedDate < lastActivityDate &&
                discussion.Data?.MembersIds?.includes(userId)
            ) {
                showBadge = true;
            }
        }
    }

    return showBadge;
}


export async function sendMessage(message: any, userId: string) {
    if (message) {
        const state = store.getState();
        const {discussions}: any = state.discussion.data

        let discussionId: string = "";
        const oldDiscussions = Object.values(discussions)

        if (oldDiscussions.length) {
            const targetDiscussion: any = oldDiscussions.find((discussion: any) => {
                const MembersIds = discussion.Data.MembersIds ?? [];
                const InvitedMembersIds = discussion.Data.InvitedMembersIds ?? [];
                return discussion.Type === "Direct" && (MembersIds.includes(userId) || InvitedMembersIds.includes(userId));
            });

            if (targetDiscussion) {
                discussionId = targetDiscussion.Id;
            }
        }

        if (!discussionId) {
            const newDiscussionId = await createDiscussion({userIds: [userId]});
            if (newDiscussionId) {
                discussionId = newDiscussionId;
            }
        }

        if (discussionId) {
            addMessageListener(discussionId)
            store.dispatch(setOpenDiscussion({discussionId}))
            return await createMessage(discussionId, message);
        }
    }
}