import React, {useCallback, useEffect, useState} from 'react'
import './Avatar.scss'
import {useSelector} from 'react-redux'
import {selectUsers} from '../../../services/redux/reducers/users/users'
import {ensureUser, userPic} from '../../../services/firebase/user/user'
import {IAuthor} from '../../../types/user.types'
import clsx from "clsx";
import { Link } from 'react-router-dom'
import { groupPagePath } from '../../../routes/Routes'

const Avatar = ({
                    id,
                    height,
                    width,
                    url,
                    className,
                    groupId
                }: { id?: string, height?: string, width?: string, url?: string, className?: string, groupId?:string }) => {
    const style = {
        width: width,
        height: height
    }
    const users = useSelector(selectUsers)

    useEffect(() => {
        if (id) {
            const contact = users[id]
            if (!contact && id) {
                ensureUser(id);
            } else {
                setDetails({...contact, Id: id})
            }
        }
    }, [users, id])

    const [details, setDetails] = useState<IAuthor>()
    
    const getAvatarLink = useCallback(() =>{
        if(id){
            return `/u/${id}`
        }
        if(groupId){
            return `/${groupPagePath}/${groupId}`
        }
        return '#'
    }, 
    [groupId, id]) 

    return (
        <Link to={getAvatarLink()} className={clsx('contact-avatar', className)}>
            <img className='chat-avatar' style={style} alt='avatar' src={url ? url : userPic(details)}></img>
        </Link>
    )
}

export default Avatar