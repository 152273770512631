import {FC, useMemo, useState} from 'react'
import {
    buyArrowIcon,
    chartIcon,
    sellArrowIcon,
    triangleDown,
    triangleUp
} from '../../../../../assets/images/svgIcons/svgIcons'
import {Col, Row} from '../../../../core/Container'
import {IFinancialData, IMarketItem} from '../../../../../types/idea.types';
import PostProgress from "./PostProgress/PostProgress";
import LiveAssetPrice from "../../../Feed/miscs/Idea/LiveAssetPrice/LiveAssetPrice";
import {priceFormat} from "../../../../../utils/helpers";
import {useTranslation} from "react-i18next";
import "./PostFinancialData.scss";
import AssetImage from "../../../Market/AssetImage/AssetImage";
import {Button} from "../../../../core/Button";
import "../PostTags/PostTags.scss";
import FinancialDataPopup from "./FinancialDataPopup/FinancialDataPopup";
import FinancialDataActionPoints from "./FinancialDataActionPoints/FinancialDataActionPoints";
import {PostType} from "../../../../../types/post.types";

const PostFinancialData: FC<{
    financialData?: IFinancialData,
    ideaCreationTime?: Date | null,
    postData: PostType,
}> = ({financialData, ideaCreationTime, postData}) => {

    const {t} = useTranslation();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);


    const ideaProgressBar = useMemo(() => {
        const hasFinancialData = financialData?.EntryValue ||
            financialData?.BreakEven || financialData?.StopLoss || financialData?.TakeProfits;
        if (hasFinancialData && ideaCreationTime) {
            return <PostProgress financialData={financialData} ideaCreationTime={ideaCreationTime}/>
        }
    }, [financialData, ideaCreationTime]);


    return useMemo(() => {
        const hasFinancialData = (financialData?.EntryValue ||
            financialData?.BreakEven || financialData?.StopLoss || financialData?.TakeProfits) && !postData._locked;
        if (financialData?.Instrument) {
            const assetStocksWatchlistItem = {
                name: financialData?.Instrument?.MarketItem?.Name,
                symbol: financialData?.Instrument?.MarketItem?.Symbol,
                market: financialData?.Instrument?.MarketItem?.Market,
                to: financialData?.Instrument?.MarketItem?.To,
            };
            let assetLabel = financialData?.Instrument?.MarketItem ? financialData?.Instrument?.MarketItem.Name : financialData?.Instrument?.Name;
            if (financialData?.Instrument?.MarketItem?.Symbol && financialData?.Instrument?.MarketItem?.To) {
                assetLabel = `${financialData?.Instrument?.MarketItem?.Symbol}/${financialData?.Instrument?.MarketItem?.To}`
            }

            return (
                <Col className='fs-data'>
                    {financialData?.Instrument &&
                        <Button
                            onClick={() => {
                                if (hasFinancialData)
                                    setIsExpanded(oldState => !oldState)
                            }}
                            disabled={!hasFinancialData}
                            className="wb-100 text-btn">
                            <Row className='jcsb fs-instrument'>
                                <Row className='fs-in-left jcsb'>
                                    <Row className='fs-image-wrapper'>
                                        <Button disabled={!hasFinancialData} className="text-btn" onClick={() => {
                                            if (hasFinancialData)
                                                setShowPopup(true)
                                        }}>
                                            <AssetImage stocksWatchlistItem={assetStocksWatchlistItem}
                                                        className="fs-icon"/>
                                        </Button>
                                        <Row className='fs-in-infos aifs jcsb'>
                                            <Button className="text-btn" onClick={() => {
                                                if (hasFinancialData)
                                                    setShowPopup(true)
                                            }}>
                                                <h6>{assetLabel}</h6>
                                            </Button>
                                            {financialData?.Instrument?.MarketItem && <LiveAssetPrice
                                                marketItem={financialData?.Instrument?.MarketItem as IMarketItem}
                                                showPercentage={false}/>}
                                        </Row>
                                    </Row>
                                    {financialData?.EntryValue && <Row className='fs-in-infos jcsb'>
                                        {financialData?.Sell ? <Button className='tag sell'>
                                                {sellArrowIcon}
                                                {t<any>('idea.sell')}
                                            </Button> :
                                            <Button className='tag buy'>
                                                {buyArrowIcon}
                                                {t<any>('idea.buy')}
                                            </Button>}
                                        <h5>ENTRY: {priceFormat(financialData?.EntryValue)}</h5>
                                    </Row>}
                                </Row>
                                {(hasFinancialData) ?
                                    <Row className="fs-actions">
                                        <Button
                                            onClick={() => {
                                                setIsExpanded(oldState => !oldState)
                                            }}
                                            disabled={postData._locked}
                                            className="flex aic text-btn expand-btn">{isExpanded ? triangleUp : triangleDown}</Button>
                                    </Row>
                                    : <LiveAssetPrice marketItem={assetStocksWatchlistItem} showPrice={false}/>
                                }
                            </Row>
                        </Button>
                    }
                    {isExpanded && <Col className="fs-expandable">
                        <FinancialDataActionPoints financialData={financialData}/>
                        {ideaProgressBar}

                        <Button className="text-btn fs-view-chart-btn" onClick={() => {
                            if (hasFinancialData)
                                setShowPopup(true)
                        }}>
                            {chartIcon}
                            <h6>{t<any>("idea.financialData.viewChart")}</h6>
                        </Button>
                    </Col>}

                    <FinancialDataPopup
                        show={showPopup}
                        onClose={() => {
                            setShowPopup(false)
                        }}
                        postCreationTime={ideaCreationTime}
                        postData={postData}
                    />
                </Col>
            )
        } else {
            return <></>
        }
    }, [financialData, ideaCreationTime, ideaProgressBar, isExpanded, postData, showPopup, t])
}

export default PostFinancialData;