import { ChangeEventHandler } from 'react'
import './CheckBox.scss'
const CheckBox = ({checked, handleChange}:{checked:boolean, handleChange:ChangeEventHandler<HTMLInputElement>}) => {
  return (
    <label className='check-box-container'>
        <input checked={checked} onChange={handleChange} type="checkbox"/>
        <span className="check-mark"></span>
    </label>
  )
}

export default CheckBox