import {FC, RefObject, useCallback, useEffect} from 'react'
import {Col, Container} from "../Container";
import {Button} from "../Button";
import './OptionsMenu.scss'
import clsx from "clsx";

const OptionsMenu: FC<{
    children: any,
    className?: string,
    onClose: () => void,
    showMenu: boolean,
    parentRef: RefObject<HTMLDivElement>,
}> = ({children, className, onClose, showMenu, parentRef}) => {

    const handleClickOutside = useCallback((event: any) => {
        if (parentRef.current && !parentRef.current.contains(event.target)) {
            onClose();
        }
    }, [parentRef, onClose]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <Container className={clsx('options-menu', className)}>
            {showMenu && <Container className='fdc options-container'>
                <Col className='options-col'>
                    {children}
                </Col>
            </Container>}
        </Container>
    );
}


type OptionType = { children: any, icon?: any, onClick: () => void, disabled?:boolean };

export const Option: FC<OptionType> = ({children, icon, onClick, disabled}) => {
    return (
        <Button className={`option-row flex jcfs aic ${!!disabled ? 'option-row-disabled':''}`} onClick={onClick} disabled={disabled}>
            {icon&&<Col className='option-icon'>
                {icon}
            </Col>}
            <Col className='aifs'>
                {children}
            </Col>
        </Button>
    );
};


export default OptionsMenu;