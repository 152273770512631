import React, { useRef } from 'react'
import { useOutsideAlerter } from '../../common/hooks/useOutsideAlerter';

const OutsideAlerter = ({children,fn,fullWidth=false}:any) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef,fn);

  return (
    <div className={fullWidth?'wb-100':''} ref ={wrapperRef}>{children}</div>
  )
}

export default OutsideAlerter