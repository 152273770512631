export interface IFinancialProps {
    financialData?: IFinancialData,
    ideaCreationTime?: Date | null,
}

export interface IFinancialData {
    Title?: string,
    Instrument?: {
        "Length"?: number,
        "Start"?: number,
        "MarketItemKey"?: string,
        "MarketItem"?: {
            "Symbol"?: string,
            "To"?: string,
            "Market"?: string,
            "Key"?: string,
            "Name"?: string
        },
        "Name"?: string
    },
    EntryValue?: number,
    BreakEven?: number,
    StopLoss?: number,
    Sell?: boolean,
    TakeProfits?: Array<ITakeProfits>,
    PriceEvaluations?: IPriceEvaluations[],
    Timeframe?: "short" | "middle" | "long",
}

export interface IPriceEvaluations {
    Price: number,
    ReachedAt: any,
    Type: "EntryValue" | "StopLoss" | "BreakEven" | "TakeProfit_1" | "TakeProfit_2" | "TakeProfit_3",
}

export type ITakeProfits = {
    Value?: number
}

export interface IPostReactionProps {
    postAuthorId: string,
    postId: string,
    commentId?: string,
    commentReplyId?: string,
    limit: number,
    before: Date,
}

export interface ISetReactionProps {
    authorId: string,
    postId: string,
    type: string | any,
    user: any
}

export interface IMarketItem {
    Key?: string,
    Market?: string,
    Name?: string,
    Symbol?: string,
    To?: string,
    key?: string,
    market?: string,
    name?: string,
    symbol?: string,
    to?: string,
}

export const reactionsLimit:number = 50
export enum PostReactions {
    REACTION_CONTENT_TYPE_SOCIAL_COMMENT = 'comment',
    REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY = 'commentReply',
    REACTION_CONTENT_TYPE_SOCIAL_POST = 'post'
}