import { chatNotificaton, exitIcon, ignoreChat, informationIcon, newChat, unmuteIcon } from "../../assets/images/svgIcons/svgIcons"

export const discussionConstants = {
    MaxListenedMessages: 25,
    MaxFetchedMessages: 50,
    MaxListenedDiscussions: 20,
} 
// TODO:Translations for text
export const settingsActions = {
    direct:[
        {   
            name:"createGroup",
            icon:newChat
        },
        {   name:"mute",
            icon:chatNotificaton
        },
        {
            name:"unmute",
            icon:unmuteIcon
        },
        {   
            name:"ignoreChat",
            icon:ignoreChat
        }
    ],
    group:[
        {   
            name:"about",
            icon:informationIcon
        },
        {
            name:"invite",
            icon:newChat
        },
        {
            name:"unmute",
            icon:unmuteIcon
        },
        {   name:"mute",
            icon:chatNotificaton
        },
        {   
            name:"leave",
            icon:exitIcon
        }
    ]
}

export const searchBarConstants = {
  maxUsers : 5,
  maxPosts : 2,
  numberOfSavedSearches : 10,
  // Searches are saved as string, therefore the array is join/splitter for writing/reading
  savedSearchesSplitterCharacter : '//'
}

export enum chatType {
    DIRECT="Direct",
    GROUP="Group"
}

export enum listMode {
    discussion="Discussion",
    requests="Requests",
    ignored="Ignored"
}

export enum settingConfirmationModal {
    ignore = 'ignore',
    leave = 'leave',
    mute = 'mute',
    unmute = 'unmute',
    leaveAdmin = 'leaveAdmin'
}

export const LeaveAdminConfirmation = {
    name:"leaveAdmin",
    heading:"Leave Admin Role?",
    text:"Are you sure you want to stop being admin for this group discussion?",
    buttonText:"Leave Admin"
}