
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectSettingName } from '../../../../services/redux/reducers/discussion/discussion'
import { Container } from '../../../core/Container'
import Avatar from '../../../core/Shared/Avatar'
import './ChatHeader.scss'

const ChatHeader = ({currentContact = false}:any) => {
  const {t} = useTranslation()
  const settingName = useSelector(selectSettingName)
  const getHeader = ()=>{
    switch(settingName){
      case "createGroup":
      case "newChat":
        return <Container className='chatview-contact-details'>
                <span>{t<any>('chat.header.newChat')}</span>
               </Container>
      case "about":
        return <Container className='chatview-contact-details'>
                <span>{t<any>('chat.header.about')}</span>
               </Container>
      case "invite":
        return <Container className='chatview-contact-details'>
                <span>{t<any>('chat.header.inviteMembers')}</span>
               </Container>
      default:
        return <Container className='chatview-contact-details'>
                  <Avatar height="28px" width="28px" url={currentContact.Avatar?currentContact.Avatar:false} id={currentContact?.Id} key={currentContact?.Id} />
                  <span className='header-contact-name'>{currentContact?.Name}</span>
              </Container>
    }
  }
  return (
    getHeader()
  )
}

export default ChatHeader