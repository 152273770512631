import {
    aryaCoinOutline,
    aryaCrypto,
    bellIcon,
    chatIcon,
    groupsIcon,
    statsIcon,
    navbarSetting,
    navbarUser,
    shopIcon,
    walletIcon,
    saveColoredIcon,
    groupsIconSelected,
    homeIconSelected,
    homeIcon,
    statsIconSelected,
    chatIconSelected,
    bellIconSelected,
} from "../../assets/images/svgIcons/svgIcons";

export const menu = [
    {
        id: 'posts',
        name: 'Ideas',
        to: '/feed',
        icon: homeIcon,
        iconSelected: homeIconSelected
    },
    {
        id: 'groups',
        name: 'Groups',
        to: '/groups',
        icon: groupsIcon,
        iconSelected: groupsIconSelected
    },
    {
        id: 'market',
        name: 'Market',
        to: '/market',
        icon: statsIcon,
        iconSelected: statsIconSelected
    },
    {
        id: 'chat',
        name: 'Chat',
        to: '#',
        icon: chatIcon,
        iconSelected: chatIconSelected
    },
    {
        id: 'notifications',
        name: 'Notifications',
        to: '#',
        icon: bellIcon,
        iconSelected: bellIconSelected
    },
]

export const dropdownLinks = [
    {
        id: 1,
        name: 'Wallet',
        to: '/wallet',
        icon: walletIcon
    },
    {
        id: 2,
        name: 'Settings',
        to: '/settings',
        icon: navbarSetting
    },
    {
        id: 3,
        name: 'Buy Coins',
        to: '#',
        icon: aryaCoinOutline
    },
    {
        id: 4,
        name: 'Read Later',
        to: '/save-for-later',
        icon: saveColoredIcon
    },
    {
        id: 5,
        name: 'Refer a friend',
        to: '/refer-friend',
        icon: navbarUser
    },
    {
        id: 6,
        name: 'Shop',
        to: '#',
        icon: shopIcon
    },
    {
        id: 7,
        name: 'Discover ARYA Crypto',
        to: '#',
        icon: aryaCrypto
    }
]