
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { markNotificationsAsRead } from '../../../../services/firebase/notifications/notifications'
import { selectNewNotificationFlag, selectNotifications, selectSortedNotificationIds } from '../../../../services/redux/reducers/notifications/notifications'
import { Col, Row } from '../../../core/Container'
import SingleNotification from '../SingleNotification/SingleNotification'
import './NotificationDropdown.scss'

const NotificationDropdown = ({setView}:any) => {
  
  const notifications = useSelector(selectNotifications);
  const orderedIdsMostRecentFirst = useSelector(selectSortedNotificationIds)
  const hasNewPendingNotification = useSelector(selectNewNotificationFlag)
  const [newNotifications, setNewNotifications] = useState<boolean>(false);
  const navigate = useNavigate()
  const {t} = useTranslation()
  useEffect(() => {
    return () => {
      const notificationIdsToUpdate = orderedIdsMostRecentFirst.slice(0,6)?.filter(
        (id:any) => !notifications?.[id]?.Read,
      );
      if (notificationIdsToUpdate?.length) {
        markNotificationsAsRead(notificationIdsToUpdate);
      }
    }
  }, [notifications,orderedIdsMostRecentFirst])
  
  const routeToNotifications = (e:React.MouseEvent<HTMLElement>) =>{
    e.preventDefault()
    setView(false)
    navigate('/notifications')
  }

  return (
    <Col className='notification-dropdown jcfs aic'>
      <span className='notification-header'>{t('notifications.notifications')}</span>
      <Col className='notification-list jcfs'>
        {
          orderedIdsMostRecentFirst.slice(0,6).map((notificationId:any)=>{
            return <SingleNotification setNewNotification={setNewNotifications} key={notificationId} notificationId={notificationId} />
          })
        }
      </Col>
      <Row>
        <button onClick={routeToNotifications} className='more-notifications'>
          {t('notifications.seeMore')}
          {
            !newNotifications&&!!hasNewPendingNotification&&<span className='unread-notification-indicator'></span>
          }
        </button>
      </Row>
    </Col>
  )
}

export default NotificationDropdown