import {FC, useCallback, useMemo, useState} from 'react'
import {closePopupIcon, playVideoIcon} from '../../../../assets/images/svgIcons/svgIcons'
import {Button} from '../../../core/Button'
import {Col, Container, Row} from '../../../core/Container'
import {Picture} from '../../../core/Picture'
import ReactDOM from "react-dom";
import './ImageFullScreen.scss'
import {PostType} from "../../../../types/post.types";

interface IProps {
    closePopup?: any
    images: Array<string>
    video?: PostType["Video"],
    selectedMediaIndex?: number,
}

const ImageFullScreen: FC<IProps> = ({closePopup, images, video, selectedMediaIndex}) => {
    const modalRoot = document.getElementById("modal-root") as HTMLElement;

    const videoPlayer = useMemo(() => {
        if (video) {
            return (
                <Container className="image-ds">
                    <video
                        muted
                        controls
                        autoPlay
                        className="image-ds-media"
                    >
                        <source src={video.videoUrl}/>
                    </video>
                </Container>
            )
        }
    }, [video]);

    const [activeComponent, setActiveComponent] = useState<any>(
        () => {
            if (video) {
                if (!selectedMediaIndex || selectedMediaIndex === 0)
                    return videoPlayer;
            }
            if (images.length) {
                const selectedImageIndex = video ? (selectedMediaIndex ?? 1) - 1 : (selectedMediaIndex ?? 0);
                return (
                    <Col className='image-ds'>
                        <Picture alt='img' src={images[selectedImageIndex]} className="image-ds-media"/>
                    </Col>
                );
            }
        })
    const [activeComponentUrl, setActiveComponentUrl] = useState<string>();

    const changeActiveImage = (src: string) => {
        setActiveComponent((
            <Col className='image-ds'>
                <Picture alt='img' src={src} className="image-ds-media"/>
            </Col>
        ))
        setActiveComponentUrl(src)
    }


    const setVideoAsActive = useCallback(() => {
        setActiveComponent(videoPlayer);
        setActiveComponentUrl(video?.videoUrl)
    }, [video?.videoUrl, videoPlayer])

    return ReactDOM.createPortal(
        <Container className='popup-container-main'>
            <Col className='image-fs-popup'>
                <Col className='top-bar aife'>
                    <Button onClick={closePopup}>
                        {closePopupIcon}
                    </Button>
                </Col>
                {activeComponent}
                {images && (images.length + (!!video ? 1 : 0)) > 1 && <Row className='images-row jcfs'>
                    {video &&
                        <Container
                            className={`image-card ${activeComponentUrl === video.videoUrl ? 'active-component' : ''}`}>
                            <video
                                muted
                                onClick={setVideoAsActive}
                                src={video.videoUrl}
                            />
                            <Button className="text-btn image-fs-video-overlay"
                                    onClick={setVideoAsActive}>{playVideoIcon}</Button>
                        </Container>}
                    {images.map((image: string, i: number) => {
                        return (<Col key={i}
                                     className={`image-card ${activeComponentUrl === image ? 'active-component' : ''}`}
                                     onClick={() => changeActiveImage(image)}>
                            <Picture alt='img' src={image}/>
                        </Col>)
                    })}
                </Row>}
            </Col>
        </Container>, modalRoot
    )
}

export default ImageFullScreen