import React, { SetStateAction, useEffect, useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next';

import './ChatBubble.scss'
import { Col, Row } from '../../core/Container';
import Idea from '../../App/Feed/miscs/Idea';
import AudioDisplay from '../../generic/Popups/PostIdea/audio-miscs/AudioDisplay';
import Avatar from '../../core/Shared/Avatar';
import { Picture } from '../../core/Picture';
import ImageFullScreen from '../../generic/Popups/AssetDisplay/ImageFullScreen';
import { deleteIcon, editIcon, replyIcon } from '../../../assets/images/svgIcons/svgIcons';
import { deleteMessage, flattenMessage } from '../../../services/firebase/discussions/messages';
import { IMessageData } from '../../../types/discussion.types';
import { IAuthor } from '../../../types/user.types';
import { UserMetadataType } from '../../../types/user';
import ReplyBox from '../ReplyBox/ReplyBox';
import moment from 'moment';
import FormattedText from '../../core/FormattedText/FormattedText';

interface IChatBubbleProps  {
  message:any,
  index:number,
  userId:UserMetadataType['Id'],
  setReply?:Function,
  discussionId?:any,
  onEdit?:any,
  enableOptions?:boolean,
  setDelete?:Function
}
const ChatBubble = ({message,setDelete,index, userId, setReply=()=>{},discussionId, onEdit, enableOptions =true}:IChatBubbleProps) => {
  const [imageDisplay, setImageDisplay] = useState<boolean>(false)
  const [dateString, setDateString] = useState<String|null>(null)
  const {t} = useTranslation()
  
  useEffect(() => {
    if(message.Date){
      let formatedTime = moment(message.Date.seconds*1000).format('LT');
      setDateString(formatedTime)
    }
  }, [message.Date])  
    
  return useMemo(() => {
   let Data:IMessageData,Author:IAuthor={} as IAuthor, SharedPost:IMessageData["SharedPost"],Text:IMessageData["Text"],Audio:IMessageData["Audio"],Images:IMessageData["Images"]=[],QuotedMessage:IMessageData["QuotedMessage"],UserMentions:IMessageData['UserMentions'], IndexMentions:IMessageData['IndexMentions']
   if (message?.Data){
    Data = message?.Data
    Author = Data?.Author
    SharedPost = Data?.SharedPost
    Text = Data?.Text
    Audio = Data?.Audio
    Images = Data?.Images
    QuotedMessage = Data?.QuotedMessage
    UserMentions = Data?.UserMentions
    IndexMentions = Data?.IndexMentions
  }
  else{
    Author = message?.Author
    SharedPost = message?.SharedPost
    Text = message?.Text
    Audio = message?.Audio
    Images = message?.Images
    QuotedMessage = message?.QuotedMessage
    UserMentions = message?.UserMentions
    IndexMentions = message?.IndexMentions
  }


  const style = {
    width:"18px",
    height:"18px"
  }
  const setReplyState = () =>{
    setReply(flattenMessage(message?.Id,message))
  }
  const closePopup = () => {
    setImageDisplay(!imageDisplay)
  }
  let sender:boolean = false;
  if (Author && Author.Id === userId) sender = true
      return (
          <div className={'chatBubble-wrapper aife '+(sender?'sender-wrapper':'reciever-wrapper')}>
            {Images && imageDisplay && <ImageFullScreen closePopup={closePopup} images={Images} />}
            {Author&& <Avatar width={style.width} height={style.height} id={Author.Id}/> }
            <Col className={sender?'aife':'aifs'}>
              <Row className={sender?'fdrr':''}>
                <Col className={'aifs '+ (sender?'sender':'reciever')}>
                  {QuotedMessage && 
                    <ReplyBox QuotedMessage={QuotedMessage} sender={sender}/>
                  }
                  {Images && <Row className='images-display jcc'>
                        {Images?.slice(0, 2).map(((image: string, i: number) => {
                            if ( Images?.length && Images.length> 2 && i === 1) {
                                return (
                                  <Col className='img-c' key={i} onClick={closePopup}>
                                      <Col className='img-overlay'>
                                          <h5>+{Images?.length - 2}</h5>
                                      </Col>
                                      <Picture alt='img' src={image} />
                                  </Col>
                                )
                            } else {
                                return (
                                  <Col className='img-c' key={i} onClick={closePopup}>
                                      <Picture alt='img' src={image} />
                                  </Col>
                                )
                            }
                        }))}
                    </Row>}
                  {SharedPost&&<div className={'post '+ (sender?'post-sender':'post-reciever')}><Idea isMessage={true} index={index} idea={SharedPost}/></div>}
                  {Audio&&<div className={'audio-wrapper '+(sender?'audio-sender':'audio-receiver')}><AudioDisplay id={`${index}`} audioData={Audio}/></div>}
                  {Text&&<div className={'text '+(sender?'text-sender':'text-reciever')}>{<FormattedText value={{Text,UserMentions,IndexMentions}}/>}</div>}
                </Col>
                { enableOptions &&
                  <Row className={'chat-options ' + (sender?'fdrr':'')}>
                    {
                      !sender&&
                      <button onClick={()=>{setReplyState()}}>{replyIcon}</button>
                    }
                    {
                      sender&&
                      <button onClick={()=>onEdit()}>{editIcon}</button>
                    }
                    {
                      sender&&setDelete&&<button onClick={()=>{setDelete()}}>{deleteIcon}</button>
                    }
                  </Row>
                }
              </Row>
              <span className='chat-timestamp'>{dateString}</span>
            </Col>
          </div>
      )

  }, [dateString, discussionId, imageDisplay, index, message, onEdit, setReply, userId,enableOptions,t])
}

export default ChatBubble