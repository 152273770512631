import {FC, useMemo} from "react";
import {Button} from "../../../core/Button";
import {auth} from "../../../../services/firebase/auth/auth";
import {selectUserMetadata} from "../../../../services/redux/reducers/userAuth/user";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useAuthState} from "react-firebase-hooks/auth";
import "./FollowButton.scss";
import { buttonTypes } from "../../../../utils/constants/constants";
import useFollowUnfollow from "../../../../common/hooks/useFollowUnfollow";


const FollowButton: FC<{
    targetUserId: string,
    buttonVersion?: buttonTypes,
}> = ({targetUserId, buttonVersion= buttonTypes.profile}) => {
    
    const {t} = useTranslation();

    const [user] = useAuthState(auth);
    const currentUserMetadata = useSelector(selectUserMetadata);
    
    const {onClickUnfollow,onClickFollow} = useFollowUnfollow();

    return useMemo(() => {
        if (user?.uid && targetUserId) {
            if (currentUserMetadata?.FollowingUsers?.includes(targetUserId)) {
                return (
                    <Button className={`gg-btn unfollow-btn unfollow-btn-${buttonVersion}`} onClick={(e)=> {
                        e.stopPropagation();
                        onClickUnfollow(targetUserId)
                    }}>{buttonVersion===buttonTypes.notification?t<any>('general.following'):t<any>('general.unfollow')}</Button>
                )
            } else {
                return (
                    <Button className={`follow-btn follow-btn-${buttonVersion}`} onClick={(e)=> {
                        e.stopPropagation();
                        onClickFollow(targetUserId)
                    }}>{t<any>('general.follow')}</Button>
                )
            }
        } else {
            return <></>;
        }
    }, [user, targetUserId, currentUserMetadata, t, buttonVersion]);
}

export default FollowButton;