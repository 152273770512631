import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from '../../../core/Container'
import { InputForm } from '../../../core/Input'
import { chatType } from '../../../../utils/constants/discussions'
import { MODE_DEBUG } from '../../../../utils/constants/config'
import { getUsers } from '../../../../services/firebase/user/user'
import Avatar from '../../../core/Shared/Avatar'
import CheckBox from '../../../core/Shared/CheckBox/CheckBox'
import { minusIcon } from '../../../../assets/images/svgIcons/svgIcons'
import { selectUsers } from '../../../../services/redux/reducers/users/users'
import './NewChat.scss'
import { setOpenDiscussion, settingReset } from '../../../../services/redux/reducers/discussion/discussion'
import { sendDiscussionInviteToUser } from '../../../../services/firebase/discussions/discussions'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '../../../core/Loading'
import { selectUserMetadata } from '../../../../services/redux/reducers/userAuth/user'


const NewChat = ({memberProp=[], inviteMode = false}:any) => {

  const {t} = useTranslation()
  const dispatch = useDispatch()

  const [members, setMembers] = useState<any>(memberProp)
  const [input, setInput] = useState("")
  const [userResults, setUserResults] = useState<any>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [followingUsers,setFollowingUsers] = useState([])
  const [chatButton, setChatButton] = useState<boolean>(false)

  const users = useSelector(selectUsers)
  const userMetadata = useSelector(selectUserMetadata)

  useEffect(() => {
    if (memberProp.length>0){
      if (members.length>1){
        setChatButton(true)
      } else{
        setChatButton(false)
      }
    }
    else{
      if (members.length>0){
        setChatButton(true)
      }
      else{
        setChatButton(false)
      }
    }
  }, [memberProp.length,members])

  useEffect(()=>{
    if(userMetadata){
      setIsLoadingUsers(true);
      getUsers({Ids: userMetadata.FollowingUsers}).then((users:any) => (users || []).map((user:any) => user._id),
      (e:Error) => {
        if (MODE_DEBUG) {
          console.warn(e);
        }
      setIsLoadingUsers(false);
        return [
          {
            _type: 'error',
            _error: e,
          },
        ];
      }).then((users:any) => {
        if(inviteMode?.members){
          users = users.filter((id:any)=>!inviteMode?.members.includes(id))
        }
        setFollowingUsers(users);
        setIsLoadingUsers(false);
        if (userResults === null){
          setUserResults(users)
        }
      });
    }
  },[inviteMode?.members, userMetadata, userResults])

  const startChat = ()=>{
    if (memberProp.length>0||members.length>1){
      dispatch(setOpenDiscussion({userId:[...members],type:chatType.GROUP}));
    }
    else {
      dispatch(setOpenDiscussion({userId:[...members],type:chatType.DIRECT}));
    }
    dispatch(settingReset())
  }

  const sendInvite = useCallback(async (id:any) => {
    let response = await sendDiscussionInviteToUser({
      discussionId: inviteMode?.discussionId,
      id,
    });
    if (response) {
      if(MODE_DEBUG){
        console.log("Invitation send succesfully")
      }
    } else {
      if(MODE_DEBUG){
        console.log("some error in sendDiscussionInviteToUser Function",response)
      }
    }
  }, [inviteMode]);

  const performSearch = useCallback(
    async () => {
      const terms = input;
      if (!terms||terms === '') {
        setUserResults(followingUsers)
        return;
      }

      if (terms!=='') {
        setIsLoadingUsers(true);
        getUsers({Search : terms})
          .then(
            (users:any) => (users || []).map((user:any) => user._id),
            (e:Error) => {
              if (MODE_DEBUG) {
                console.warn(e);
              }
            setIsLoadingUsers(false);
              return [
                {
                  _type: 'error',
                  _error: e,
                },
              ];
            },
          )
          .then((users:any) => {
            if(inviteMode?.members){
              users = users.filter((id:any)=>!inviteMode?.members.includes(id))
            }
            setUserResults(users);
            setIsLoadingUsers(false);
          });
      }
    },
    [JSON.stringify(inviteMode?.members),input],
  );

  const handleInputChange = (event:any) =>{
    const input = event.target as HTMLInputElement
    setInput(input.value);
  }

  const debouncedSearch = useMemo(()=>{
    return debounce(handleInputChange, 500);
  },[])

  useEffect(()=>{
    performSearch()
  },[performSearch])


  const addRemoveMember = useCallback((Id:any)=>{
    if (members.includes(Id)){
      const oldMembers = members
      const index = oldMembers.indexOf(Id)
      oldMembers.splice(index,1);

      setMembers([...oldMembers])
      return
    }else{

      setMembers([...members,Id])
      return
    }
  },[members])


  return (
    <Col className='create-chat-wrapper jcfs'>
      <InputForm onChange={debouncedSearch}  className='newchat-search jcfs' childrenAfter={false} name='search' type="search">
        <span>{t<any>('chat.newChat.to')}:</span>
        {
          members.map((id:any)=>{
            const member = users[id];
            return <span key={id} className='member-name'>
              <button onClick={()=>addRemoveMember(id)} className='remove-member'>{minusIcon}</button>
              { member?.Name}
            </span>
          })
        }
      </InputForm>
      {isLoadingUsers?<LoadingSpinner/>:
      <Col className='user-list jcfs'>
        { userResults&&
          (userResults).filter((id:any)=>inviteMode?!inviteMode.members.includes(id):id).map((id:any)=>{
            const user = users[id];
            const checked:boolean = members.includes(id)
            return <Row key={id} className='user-box jcsb aic'>
                      <Row className='chat-user-name'>
                        <Avatar height="25px" width="25px" id={id}></Avatar>
                        <span>{user.Name}</span>
                      </Row>

                      {
                        inviteMode?
                        <button className='invite-button' onClick={()=>sendInvite(id)}>{t<any>('chat.newChat.invite')}</button>
                        :
                        <CheckBox checked={checked} handleChange={()=>addRemoveMember(id)}/>
                      }
                  </Row>
          })
        }
      </Col>
      }
      {
        chatButton&&
        (
          <button onClick={()=>startChat()} className='start-chat'>{t<any>('chat.newChat.startChat')}</button>
        )
      }
    </Col>
  )
}

export default NewChat
