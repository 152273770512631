import {FC, useEffect, useState} from 'react'
import {Row} from "../../components/core/Container";
import {useLocation} from "react-router";
import "./Footer.scss";
import {Link} from "react-router-dom";
import {menu} from "../../utils/constants/menu";
import {scrollToTop} from "../../utils/UI";

const Footer: FC = () => {
    return (
        <Row className='footer-menu'>
            {menu.map((menuItem) => {
                return (
                    <FooterMenuItems icon={menuItem.icon} label={menuItem.name} path={menuItem.to} key={menuItem.id}/>
                )
            })}
        </Row>
    )
}

const FooterMenuItems: FC<{ icon: any, label: string, path: string }> = ({icon, label, path}) => {

    const {pathname} = useLocation();
    const [isSelected, setIsSelected] = useState<boolean>(false);

    useEffect(() => {
        if (pathname.startsWith(path)) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }, [pathname, path]);

    return (
        <Link
            to={path}
            className={`flex fdc aic jcsb text-btn footer-menu-item ${isSelected ? 'footer-menu-item-active' : ''}`}
            onClick={scrollToTop}
        >
            {icon}
            <p className='fmi-label'>{label}</p>
        </Link>
    )
}

export default Footer