import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './AboutSetting.scss'
import { useDispatch, useSelector } from 'react-redux'
import { cameraIcon, moreOptionsIcon } from '../../../../../assets/images/svgIcons/svgIcons'
import { removeUserFromDiscussion, setUserAdminInDiscussion, updateDiscussion } from '../../../../../services/firebase/discussions/discussions'
import { selectSingleDiscussion, setConfirmationData, setConfirmationScreen } from '../../../../../services/redux/reducers/discussion/discussion'
import { selectUserMetadata } from '../../../../../services/redux/reducers/userAuth/user'
import { selectUsers } from '../../../../../services/redux/reducers/users/users'
import { UserMetadataType } from '../../../../../types/user'
import { MODE_DEBUG } from '../../../../../utils/constants/config'
import { Col, Row } from '../../../../core/Container'
import { TextInput } from '../../../../core/Input'
import OutsideAlerter from '../../../../core/OutsideAlerter'
import { Picture } from '../../../../core/Picture'
import Avatar from '../../../../core/Shared/Avatar'
import { settingConfirmationModal } from '../../../../../utils/constants/discussions'
import { useTranslation } from 'react-i18next'

const AboutSetting = ({discussionId, userIds,isAdmin}:any) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const discussion = useSelector((state:any)=>selectSingleDiscussion(state,discussionId))
  const userMetaData:UserMetadataType|null = useSelector(selectUserMetadata)
  const users = useSelector(selectUsers);
  
  const Data = useMemo (()=>{
    return discussion?.Data
  },[discussion?.Data])

  

  const [members, setMembers] = useState<any>(userIds.map((id:any)=>{return {...users[id],id}}))
  const [nameInputValue,setNameInputvalue] = useState(Data?.Name)
  const [isUploadingAvatar,setIsUploadingAvatar] = useState(false)
//   const [SavingChanges, setSavingChanges] = useState(false)
//   const [isDisabled, setIsDisabled] = useState(false)
  const [filteredList, setfilteredList] = useState<any>(members)
  const [filterInput, setFilterInput] = useState<any>()
  const [showOptions, setShowOptions] = useState<any>(false)

  useEffect(()=>{
    setMembers(userIds.map((id:any)=>{return {...users[id],id}}))
  },[userIds,users])
  
    const onChangeAvatar = useCallback(
        async (e:any)=>{
            if(isUploadingAvatar){
              return
            }
            e.preventDefault();
            try{
              let newFiles = e.target.files
              let newFilesArray:any = Array.from(newFiles)
              for (let i = 0; i < newFiles.length; i++) {
                  const imageUrl = URL.createObjectURL(newFiles[i]);
                  newFilesArray[i] = {File:newFilesArray[i],url:imageUrl}
              }
              setIsUploadingAvatar(true);
              await updateDiscussion({
                  discussionId,
                  avatar:newFilesArray
              })
            } 
            catch(e){
            if(MODE_DEBUG){
                console.log(e);
            }
            }
            setIsUploadingAvatar(false);
        },[discussionId,isUploadingAvatar]
    ) 

    const savePendingChanges = useCallback(async () => {
        try {
            // setSavingChanges(true);
            await updateDiscussion({
            discussionId,
            name: nameInputValue,
            })
        } catch (e) {
            if(MODE_DEBUG){
            console.log(e)
            }
        }
        // setSavingChanges(false);
    }, [discussionId, nameInputValue]);

    const filterHandler = useCallback(
        (input:any)=>{
            if(!input){
                return
            }
            if(filterInput !== input){
                setFilterInput(input)
            }
            if(input===''){
                setfilteredList(members)
                return
            }
            const filteredData = members.filter((member:any)=>{
                return member.Name.toLowerCase().includes(input)
            })
            setfilteredList(filteredData)
        }
    ,[filterInput,members])


    const leaveAdminInDiscussion = async () =>{
        
        dispatch(setConfirmationScreen({view:true}));
        dispatch(setConfirmationData({data:{
          name:settingConfirmationModal.leaveAdmin,
          heading:t('chat.setting.modal.leaveAdmin.heading'),
          text:t('chat.setting.modal.leaveAdmin.text'),
          buttonText:t('chat.setting.modal.leaveAdmin.buttonText')
        }}))
    }

    useEffect(()=>{
    if(filterInput&&filterInput !==''){
        filterHandler(filterInput)
    }
    else{
        setfilteredList(members)
    }
    },[filterInput,filterHandler,members])

  return (
    <Col className='edit-group-wrapper jcfs'>
    <Row className={'edit-group-avatar '+(isAdmin?'jcsb':'')}>
    <input
          accept="image/png, image/jpeg, image/jpg"
          type='file'
          id='imageInput'
          onChange={onChangeAvatar}
          style={{ display: "none" }}
      />
      <Row>
      {
        !Data?.Avatar?
        <span className='group-Avatar empty'>{cameraIcon}</span>
        :
        <Picture className="group-Avatar" alt="group-avatar" src={Data?.Avatar}/>
      }
      {

       isAdmin&&<button  onClick={() => { document.getElementById("imageInput")?.click()}} className='edit-avatar-button'>{t<any>('chat.setting.editAvatar')}</button>
      }
      </Row>
      {
        nameInputValue!==Data?.Name&&
        <button onClick={()=>savePendingChanges()} className='save-group-edit jsfe'>{t<any>('chat.setting.save')}</button>
      }
    </Row>
    { isAdmin?
      
      <TextInput 
        type="text"
        className='group-name'
        name='fullName'
        placeholder={"Group Name"}
        value={nameInputValue}
        onChange={(e)=>setNameInputvalue(e.target.value)}
        // disabled={isDisabled}
      />:
      <span>{nameInputValue}</span>

    }

    {
      isAdmin&&
      <Col className='aifs wb-100'>
        <span className='member-count'>{members.length} {t<any>('chat.setting.members')}</span>
        <TextInput
          placeholder="Search members" 
          className='member-search jcfs'
          name='search' 
          type="search"
          onChange={(e)=>filterHandler(e.target.value)}
          />

        <Col className='members-list jcfs'>
          {filteredList&&filteredList.map((member:any)=>{
            return<Row key={member?.id} className='user-box jcsb'>
                      <Row>
                        <Avatar height="25px" width="25px" id={member?.id}></Avatar>
                        <span className={'member-name '+(Data?.AdminsIds.includes(member?.id)?'custom-width':'')}>{member?.Name}</span>
                        {Data?.AdminsIds.includes(member?.id)&&<span className='admin-stamp'>∙ {t<any>('chat.setting.admin')}</span>}
                      </Row>
                        <Row className='member-options'>
                          <button onClick={()=>setShowOptions(member?.id)} className='options-icon jsfe'>{moreOptionsIcon}</button>    
                            {
                              (showOptions===member?.id)&&
                              <OutsideAlerter fn={()=>setShowOptions(false)}>
                                <span className='options-box'>
                                    {Data?.InvitedMembersIds.includes(member?.id)&&<button onClick={async(e)=>{ e.stopPropagation();await removeUserFromDiscussion(discussionId,member?.id)}}>{t<any>('chat.setting.removeInvite')}</button>}
                                    {Data?.AdminsIds.includes(member?.id)&&(member?.id===userMetaData?.Id)&&
                                      <button onClick={()=>{leaveAdminInDiscussion()}}>{t<any>('chat.setting.leaveAdmin')}</button> 
                                    }
                                    {
                                      Data?.MembersIds.includes(member?.id)&&!Data?.AdminsIds.includes(member?.id)&&
                                      <button onClick={async(e)=>{ e.stopPropagation(); await setUserAdminInDiscussion(discussionId,member?.id)}}>{t<any>('chat.setting.makeAdmin')}</button>
                                    }
                                    {Data?.MembersIds.includes(member?.id)&&!Data?.AdminsIds.includes(member?.id)&&<button onClick={async(e)=>{ e.stopPropagation(); await removeUserFromDiscussion(discussionId,member?.id)}}>{t<any>('chat.setting.remove')}</button>}
                                </span>
                            </OutsideAlerter>
                            }
                        </Row>
                  </Row>
          })}
        </Col>
      </Col>
    }
    
  </Col>
  )
}

export default AboutSetting