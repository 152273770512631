import {FC, ReactNode} from 'react'
import AppContainer from './AppContainer'
import AppLayout from './AppLayout'

interface IProps {
    children?: ReactNode
}

export const AuthLayout: FC<IProps> = ({children}) => {


    return (
        <AppLayout>
            <AppContainer>
                {children}
            </AppContainer>
        </AppLayout>
    )
}

export default AuthLayout
