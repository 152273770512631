import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';
import {PostType} from "../../../../types/post.types";

const initialState = {
  status: StatusAsync.IDLE,
  data: { ideaData: null, feedPosts: [], filters: null, reactions: null },
  error: null,
};

export const selectDataHome = (state: any) => state.home.data;
export const selectFeedPosts = createSelector<any,(PostType[] | null)>([selectDataHome], (data:any) => (data.feedPosts));
export const selectFilters = createSelector([selectDataHome], (data) => (data.filters));
export const selectedReations = createSelector([selectDataHome], (data) => (data.reactions));
export const homeReducer = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setPostIdeaData: (state, action) => {
      state.data.ideaData = action.payload?.ideaData
    },
    setFeedPosts: (state, action) => {
      state.data.feedPosts = action.payload?.feedPosts
    },
    setFilters: (state, action) => {
      state.data.filters = action.payload?.filters
    },
    setReactions: (state, action) => {
      state.data.reactions = action.payload?.reactions
    },
    incrementLikes: (state, action) => {
      let post: any = state.data.feedPosts.find(((e: any) => e?._id === action.payload?._id))
      if (post) {
        if (post?.ReactionsCount?.Likes) { post.ReactionsCount.Likes += 1 } else {
          post['ReactionsCount'] = { Likes: 1 }
        }
      }
    },
    decrementLikes: (state, action) => {
      let post: any = state.data.feedPosts.find(((e: any) => e?._id === action.payload?._id))
      if (post) { post.ReactionsCount.Likes -= 1 }
    }
  }
});

export const homeActions = homeReducer.actions;
export const { setPostIdeaData, setFeedPosts, setFilters, setReactions, incrementLikes, decrementLikes } = homeReducer.actions;

export default homeReducer.reducer;
