import {FC, useCallback, useEffect, useMemo, useState} from "react";
import "./PostProgress.scss";
import {Col, Container, Row} from "../../../../../core/Container";
import {IFinancialData, IPriceEvaluations} from "../../../../../../types/idea.types";
import {pricePointsOrderIndex, pricePointsShortForm} from "../../../../../../utils/constants/financialData";
import {PriceEvaluationTypes} from "../../../../../../utils/constants/idea";

const PostProgress: FC<{ financialData?: IFinancialData, ideaCreationTime: Date }> = ({
                                                                                         financialData,
                                                                                         ideaCreationTime
                                                                                     }) => {

    const [priceEvaluations, setPriceEvaluations] = useState<IPriceEvaluations[]>([]);
    const [farthestReachedPoint, setFarthestReachedPoint] = useState<IPriceEvaluations | null>(null);
    const [reachedStopLoss, setReachedStopLoss] = useState<boolean>(false);

    // Check if key exists in financialData?.PriceEvaluations and return its value
    // or create a new progress point if it doesn't exit
    const getPricePointObj = useCallback((key: IPriceEvaluations["Type"], value?: number) => {

        if (value || value === 0) {
            const populatedPriceEvaluation = financialData?.PriceEvaluations?.find(priceEvaluation => priceEvaluation.Type === key);
            if (!populatedPriceEvaluation) {
                if (key === PriceEvaluationTypes.entryValue) {
                    return {
                        Type: PriceEvaluationTypes.entryValue,
                        Price: financialData?.EntryValue,
                        ReachedAt: ideaCreationTime
                    } as IPriceEvaluations;
                } else {
                    return {
                        Type: key,
                        Price: value,
                        ReachedAt: null
                    } as IPriceEvaluations;
                }
            } else {
                if (key === PriceEvaluationTypes.stopLoss) {
                    setReachedStopLoss(true);
                }
                return populatedPriceEvaluation;
            }
        }

    }, [financialData, ideaCreationTime]);

    /*
    * This useEffect populate both priceEvaluations and farthestReachedPoint state variables
    * by going through all the financial data values (EntryValue, StopLoss, BreakEven, and take profit points [TakeProfit_1, TakeProfit_2, and TakeProfit_3])
    * and create progress points for every value by calling getPricePointObj and then adding the progress points to priceEvaluations, also keeps track of
    * the farthest reached progress point and save the value in farthestReachedPoint state
    */

    useEffect(() => {
        if (financialData) {
            let priceEvaluations: IPriceEvaluations[] = [];
            const entryValuePoint = getPricePointObj(PriceEvaluationTypes.entryValue, financialData.EntryValue);
            if (entryValuePoint) {
                priceEvaluations.push(entryValuePoint);
                let farthestReached: IPriceEvaluations = entryValuePoint;
                const stopLossPoint = getPricePointObj(PriceEvaluationTypes.stopLoss, financialData.StopLoss);
                if (stopLossPoint) {
                    if (stopLossPoint.ReachedAt) {
                        farthestReached = stopLossPoint;
                    }
                    priceEvaluations.push(stopLossPoint);
                }
                const breakEvenPoint = getPricePointObj(PriceEvaluationTypes.breakEven, financialData.BreakEven);
                if (breakEvenPoint) {
                    if (breakEvenPoint.ReachedAt) {
                        farthestReached = breakEvenPoint;
                    }
                    priceEvaluations.push(breakEvenPoint);
                }
                if (financialData?.TakeProfits?.length) {
                    financialData.TakeProfits.forEach((takeProfit, index) => {
                        const profitPoint = getPricePointObj(`TakeProfit_${index + 1}` as any, takeProfit.Value);
                        if (profitPoint) {
                            if (profitPoint.ReachedAt) {
                                if (pricePointsOrderIndex[profitPoint.Type] > pricePointsOrderIndex[farthestReached?.Type]) {
                                    farthestReached = profitPoint;
                                }
                            }
                            priceEvaluations.push(profitPoint);
                        }
                    });
                }
                setFarthestReachedPoint(farthestReached);
            }
            priceEvaluations.sort((a: IPriceEvaluations, b: IPriceEvaluations) => {
                return pricePointsOrderIndex[a.Type] - pricePointsOrderIndex[b.Type];
            });
            setPriceEvaluations(priceEvaluations);
        } else {
            setPriceEvaluations([]);
            setFarthestReachedPoint(null);
        }
    }, [financialData, getPricePointObj]);


    const getIsPricePointReached = useCallback((pricePointType: IPriceEvaluations["Type"]) => {
        if (pricePointType === PriceEvaluationTypes.entryValue) {
            return true;
        } else if (farthestReachedPoint) {
            if (farthestReachedPoint.Type === PriceEvaluationTypes.entryValue) {
                return false;
            } else if (pricePointType === PriceEvaluationTypes.stopLoss) {
                return farthestReachedPoint.Type === PriceEvaluationTypes.stopLoss;
            } else if (farthestReachedPoint.Type === PriceEvaluationTypes.breakEven) {
                return pricePointType === PriceEvaluationTypes.breakEven;
            } else {
                return pricePointsOrderIndex[farthestReachedPoint.Type] >= pricePointsOrderIndex[pricePointType];
            }
        } else {
            return false
        }
    }, [farthestReachedPoint]);


    const getIsPricePointPassed = useCallback((pricePointType: IPriceEvaluations["Type"], referencePricePointType?: IPriceEvaluations["Type"]) => {
        if (referencePricePointType) {
            const farthestReachedPointOrder = pricePointsOrderIndex[referencePricePointType];
            if (pricePointType === PriceEvaluationTypes.entryValue) {
                return referencePricePointType !== PriceEvaluationTypes.entryValue;
            } else if (pricePointType === PriceEvaluationTypes.stopLoss) {
                return false;
            } else {
                return farthestReachedPointOrder > pricePointsOrderIndex[pricePointType];
            }
        } else {
            return false;
        }
    }, []);

    const getProgressPoint = useCallback((pointId: IPriceEvaluations["Type"], pointPriceValue: number) => {
        if (pointPriceValue) {
            const isReached = getIsPricePointReached(pointId);
            const isPassed = getIsPricePointPassed(pointId, farthestReachedPoint?.Type);
            return (
                <Row key={pointId + pointPriceValue} className={`p-progress-point ${pointId} ${isReached ? 'active-progress-point' : ''} ${reachedStopLoss ? 'red' : ''} ${isPassed ? 'progress-passed-point' : ''}`}>
                    {pointId !== "EntryValue" && <Container className="p-line"></Container>}
                    <Container className="p-circle"></Container>
                </Row>
            )
        }
    }, [reachedStopLoss, farthestReachedPoint, getIsPricePointPassed, getIsPricePointReached]);

    const progressPointsBar = useMemo(() => {
        return priceEvaluations.map((priceEvaluation) => {
            return getProgressPoint(priceEvaluation.Type, priceEvaluation.Price);
        })
    }, [priceEvaluations, getProgressPoint]);

    return (
        <Col className='progress-fd aifs'>
            <Row className='jcsb p-grey'>
                {progressPointsBar}
            </Row>
            <Row className='jcsb p-texts'>
                {priceEvaluations.map((priceEvaluation) => {
                    return <h5
                        key={priceEvaluation.Type}
                        className={priceEvaluation.Type === PriceEvaluationTypes.stopLoss ? 'fst' : ''}>{pricePointsShortForm[priceEvaluation.Type]}</h5>
                })}
            </Row>
        </Col>
    )
}

export default PostProgress;