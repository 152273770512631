import {GroupSectionType} from "../../types/group.types";

export const timeframesOrder = {
    'Weekly': 0,
    'Monthly': 1,
    'Yearly': 2,
};

export enum WhoCanPostInGroup {
    anyone = 'anyone',
    admins = 'admins'
}

export const mainFeedSection: GroupSectionType = {Id: "main_feed", Icon: "😀", Title: "Main Feed"};
export const ARYACoachesSubscriptionID = 'arya_coaches';
export const defaultIconName = "DEFAULT_ICON";

export enum GroupNotificationsSettingsTypes {
    push = "push",
    email = "email",
}