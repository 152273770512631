import { createSelector, createSlice } from "@reduxjs/toolkit";
import { MODE_DEBUG } from "../../../../utils/constants/config";
const initialState:any ={
    notifications: {},
    orderedIdsMostRecentFirst: [],
    hasNewPendingNotification:false
}

function sortByNotificationDate({notifications: n}:any) {
    return (a:any, b:any) => {
        return n[b]?.Date?.toDate?.() - n[a]?.Date?.toDate?.();
    };
}

export const selectDataNotification = (state:any) => state.notifications;
export const selectDataId = (state:any, id: string) => id;

export const selectNotifications = createSelector([selectDataNotification], (data)=>(data?.notifications));
export const selectSortedNotificationIds = createSelector([selectDataNotification], (data)=>(data?.orderedIdsMostRecentFirst));
export const selectNewNotificationFlag = createSelector([selectDataNotification], (data)=>(data?.hasNewPendingNotification));
export const selectSingleNotification = createSelector([selectDataNotification,selectDataId],(data,id)=>(data?.notifications[id]));


export const notificationSlice = createSlice({
    name:'notifications',
    initialState,
    reducers:{
        addNotifications:(state,action)=>{
            const {notificationsToUpdate:notificationDocuments} = action.payload
            
            let newState
            newState = {
                ...state,
                notifications: {
                  ...state.notifications,
                  ...notificationDocuments,
                },
            };
            try{
                newState.orderedIdsMostRecentFirst = [
                    ...newState.orderedIdsMostRecentFirst,
                    ...Object.keys(notificationDocuments).filter(
                        id => !state.notifications[id],
                    ),
                ];
                
                // Sort the orderedIdsMostRecentFirst, newest first
                newState.orderedIdsMostRecentFirst.sort(sortByNotificationDate(newState));
                
                state.notifications = newState.notifications
                state.orderedIdsMostRecentFirst = newState.orderedIdsMostRecentFirst
            }
            catch(error){
                if(MODE_DEBUG){
                    console.log("Add Notification Redux Error",error)
                }
            }
        },
        setHasNewPendingNotification:(state,action)=>{
            const {value:hasNewPendingNotification} = action.payload
            

            state.hasNewPendingNotification = hasNewPendingNotification;
        },
        updateNotification:(state,action)=>{
            const {id,notificationData} = action.payload

            state = {
                ...state,
                notifications: {
                  ...state.notifications,
                  [id]: {
                    ...state.notifications[id],
                    ...notificationData,
                  },
                },
              };
              // Did the notification date change? If so, update our sorted IDs
              if (
                state.notifications[id]?.Date?.toDate?.()?.getTime?.() !==
                notificationData?.Date?.toDate?.()?.getTime?.()
              ) {
                state.orderedIdsMostRecentFirst.sort(
                  sortByNotificationDate(state),
                );
              }
        },
        readNotifications:(state,action) =>{
            const {notificationsIds:ids} = action.payload ?? {};
            
            if (!state?.notifications?.[ids]) {
                if (MODE_DEBUG) {
                console.warn(
                    'Notification read action dispatched for a non-existing notification',
                );
                }
                return state;
            }

            ids?.forEach((id:any) => {
                 state.notifications[id].Read = true
            });
        }
        ,
        deleteNotification:(state,action)=>{
            const {id} = action.payload
            delete state.notifications[id];
            state.orderedIdsMostRecentFirst = [...state.orderedIdsMostRecentFirst.filter(
                (v:any) => v !== id,
            )];
        }
    }
})


export const {addNotifications,setHasNewPendingNotification,deleteNotification,updateNotification, readNotifications} = notificationSlice.actions
export default notificationSlice.reducer