import { selectAuthToken, selectEmailPassAuth, selectUser } from "../../services/redux/reducers/userAuth/user";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useAuth = () => {
  const userInfos = useSelector(selectUser);
  const tokens = useSelector(selectAuthToken);
  const authEmailPass = useSelector(selectEmailPassAuth);
  const isLoggedIn = useMemo(() => userInfos && !authEmailPass && ((tokens?.phone_number && userInfos?.phoneNumber) || tokens?.passed2FA), [userInfos, tokens, authEmailPass]);
  return { isLoggedIn };
};


