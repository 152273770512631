import { collection, deleteDoc, doc, getDoc, getDocs, limitToLast, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { IPostReactionProps, ISetReactionProps, reactionsLimit } from "../../../types/idea.types"
import { MODE_DEBUG } from "../../../utils/constants/config";
import { setReactions } from "../../redux/reducers/home/home";
import store from "../../redux/store";
import { auth, db } from "../auth/auth";
import { getCurrentUserDataAsDbType } from "../user/user";
import { REACTION_CONTENT_TYPE_SOCIAL_COMMENT, REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY, REACTION_CONTENT_TYPE_SOCIAL_POST } from "../../../utils/constants/idea";
import { incrementLikesCount, setUserReactions } from "../../redux/reducers/reactions/reactions";
import { getCommentCollectionPath } from "./comments";

// ----- Read
let fetchedReactionHistory:any = {};
let completedReactionsFetched:any = {};

/**
 * (Cached) Fetch and analyze the reactions history document for a given date ID
 * @param userId
 * @param formattedDateId
 * @returns {Promise}
 */
function getReactionHistory(userId:string, formattedDateId:any): Promise<any> {
    if (!fetchedReactionHistory[formattedDateId]) {
        const docRef = doc(db,'users',userId,'reactions',formattedDateId)
      fetchedReactionHistory[formattedDateId] =
        getDoc(docRef)
        .then(doc => (doc.exists() ? doc.data() : {}))
        .then(data => {
          completedReactionsFetched[formattedDateId] = true;

          const postIdsToReaction:any = {},
            commentIdsToReaction:any = {},
            replyIdsToReaction:any = {};
          // Data to userPostReactions
          if (data.Posts) {
            Object.keys(data.Posts).forEach(fullId => {
              postIdsToReaction[fullId] = data.Posts[fullId];
            });
          }
          if (data.Comments) {
            console.log("This is comments Data",data)
            Object.keys(data.Comments).forEach(fullId => {
              commentIdsToReaction[fullId] = data.Comments[fullId];
            });
          }
          if (data.CommentReplies) {
            Object.keys(data.CommentReplies).forEach(fullId => {
              replyIdsToReaction[fullId] = data.CommentReplies[fullId];
            });
          }
          store.dispatch(
            setUserReactions({
                contentType:REACTION_CONTENT_TYPE_SOCIAL_POST,
                userReactions:postIdsToReaction}
            ),
          );
          store.dispatch(
            setUserReactions({
                contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT,
                userReactions:commentIdsToReaction
            }),
          );
          store.dispatch(
            setUserReactions({
                contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY,
                userReactions:replyIdsToReaction
            }),
          );

          return {postIdsToReaction, commentIdsToReaction, replyIdsToReaction};
        });
    }
    return fetchedReactionHistory[formattedDateId];
  }


export const getPostReactions = async ({
    postAuthorId,
    postId,
    commentId,
    commentReplyId,
    limit = reactionsLimit,
    before = new Date(),
}: IPostReactionProps) => {
    if (!postAuthorId || !postId) {
        if (MODE_DEBUG) {
            console.warn(
                `getReaction was called, but postAuthorId:${postAuthorId} or postId:${postId} are falsy`,
            );
        }
        return;
    }
    let getReactionPath: any = doc(db, "users", postAuthorId, 'posts', postId);

    if (commentId) {
        getReactionPath = collection(getReactionPath, "comments", commentId);

        if (commentReplyId) {

            getReactionPath = collection(getReactionPath, "replies", commentReplyId)
        }
    }

    getReactionPath = collection(getReactionPath, "reactions")

    const reactionData: any = query(getReactionPath,
        orderBy('Date', 'desc')
        , where('Date', '<', before)
        , limitToLast(limit));

    const reactionDocs = await getDocs(reactionData)
    store.dispatch(setReactions({ reactions: reactionDocs?.docs }))
    return (reactionDocs?.docs || []).map((reactionDoc: any) => {
        const reactionData = reactionDoc.data();
        return {
            Date: reactionData.Date,
            UserId: reactionData.User?.Id || reactionDoc.ref.id,
            Type: reactionData.Type,
        };
    });

}

/**
 * Get a list of reaction document we don't have yet, listing the possibles reaction type and values.
 *
 * The response is then used to dispatch the reactions counts in the redux store
 *
 * @param date
 * @param fullCommentId
 * @returns {Promise<void>}
 */
export async function ensureCommentReactionForDate(date:any, fullCommentId:string): Promise<void> {
    const {auth, reactions}:any = store.getState();
    const {user} = auth.data
    const userId = user.uid;
  
    // Reaction already known
    if (
      reactions?.userReactions?.[REACTION_CONTENT_TYPE_SOCIAL_COMMENT]?.[
        fullCommentId
      ] !== void 0
    ) {
      return;
    }
  
    if (!userId) {
      if (MODE_DEBUG) {
        console.warn('ensureReactionHistory was called, but userId is falsy');
      }
      return;
    }
  
    // Fill an array with formatted ids, matching the needed api docs ids
    date = new Date(date);
  
    const year = date.getUTCFullYear(),
      month = date.getUTCMonth() + 1; // Months are 0-indexed in JS
    const formattedDateId =
      year.toString() + (month < 10 ? '0' + month.toString() : month.toString());
  
    // If we already fetched the document for the period of this post
    if (completedReactionsFetched[formattedDateId]) {
      store.dispatch(
        setUserReactions(
            {
                contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT, 
                userReactions:{
                    [fullCommentId]: false,
                }
            }
        ),
      );
    } else {
      // The reaction fetching started but is not yet complete
      const {commentIdsToReaction} = await getReactionHistory(
        userId,
        formattedDateId,
      );
      if (!commentIdsToReaction[fullCommentId]) {
        // No need to send many times the document reactions in the store
        store.dispatch(

          setUserReactions(
            {
                contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT, 
                userReactions:{
                    [fullCommentId]: false,
                }
            }
          ),
        );
      }
    }
  }
  
  /**
   * Get a list of reaction document we don't have yet, listing the possibles reaction type and values.
   *
   * The response is then used to dispatch the reactions counts in the redux store
   *
   * @param date
   * @param fullReplyId
   * @returns {Promise<void>}
   */
  export async function ensureCommentReplyReactionForDate(date:any, fullReplyId:string) {
    const {auth, reactions}:any = store.getState();
    const {user} = auth.data;
    const userId = user.uid;
  
    // Reaction already known
    if (
      reactions?.userReactions?.[
        REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY
      ]?.[fullReplyId] !== void 0
    ) {
      return;
    }
  
    if (!userId) {
      if (MODE_DEBUG) {
        console.warn('ensureReactionHistory was called, but userId is falsy');
      }
      return;
    }
  
    // Fill an array with formatted ids, matching the needed api docs ids
    date = new Date(date);
  
    const year = date.getUTCFullYear(),
      month = date.getUTCMonth() + 1; // Months are 0-indexed in JS
    const formattedDateId =
      year.toString() + (month < 10 ? '0' + month.toString() : month.toString());
  
    // If we already fetched the document for the period of this post
    if (completedReactionsFetched[formattedDateId]) {
      store.dispatch(
        setUserReactions(
           {contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY, 
            userReactions:{
                [fullReplyId]: false,
            }}
        ),
      );
    } else {
      // The reaction fetching started but is not yet complete
      const {replyIdsToReaction} = await getReactionHistory(
        userId,
        formattedDateId,
      );
      if (!replyIdsToReaction[fullReplyId]) {
        // No need to send many times the document reactions in the store
        store.dispatch(
          setUserReactions(
            {contentType:REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY, 
                userReactions:{
                [fullReplyId]: false,
            }}
          ),
        );
      }
    }
  }


export const setPostReaction = async ({ authorId, postId, type, user }: ISetReactionProps) => {
    // ----- Update

    if (!authorId || !postId) {
        if (MODE_DEBUG) {
            console.warn('addReaction was called without a author or a postId !');
        }
        return;
    }
    const userAuth: any = auth.currentUser?.uid
    let getReactionPath: any = doc(db, "users", authorId, 'posts', postId);

    getReactionPath = collection(getReactionPath, "reactions")
    const userDb = getCurrentUserDataAsDbType();
    if (!type) {
        return deleteDoc(doc(getReactionPath, userAuth)).catch(error => {
            if (MODE_DEBUG) {
                console.warn('removeReaction error : ', error);
            }
        });
    } else {
        const likeDoc = {
            Type: 'Like',
            Date: serverTimestamp(),
            User: userDb,
        };

        return await setDoc(doc(getReactionPath, userAuth), likeDoc).catch(error => {
            if (MODE_DEBUG) {
                console.warn('addReaction error : ', error);
            }
        });
    }

}

export async function addReactionForComment({
    postAuthorId,
    postId,
    parentCommentId,
    commentId,
    type,
  }:any) {
    if (!postAuthorId || !postId || !commentId) {
      if (MODE_DEBUG) {
        console.warn(
          `addReactionForComment was called without postAuthorId: ${postAuthorId}, postId: ${postId} or commentId: ${commentId} !`,
        );
      }
      return;
    }
  
    const {reactions}:any = store.getState();
  
    let fullId = `${postAuthorId}/${postId}`;
    if (parentCommentId) {
      fullId += `/${parentCommentId}`;
    }
    fullId += `/${commentId}`;
  
    const user = getCurrentUserDataAsDbType(),
      contentType = parentCommentId
        ? REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY
        : REACTION_CONTENT_TYPE_SOCIAL_COMMENT;
  
    if (!user) {
      if (MODE_DEBUG) {
        console.warn(
          'userId is falsy, yet addReactionForComment have been called !',
        );
      }
      return;
    }
  
    // Already set to the wanted value
    if (reactions?.userReactions?.[contentType]?.[fullId] === type) {
      return;
    }
  
    // -- Redux stuff
    // Remove the reaction in the count
    switch (reactions?.userReactions?.[contentType]?.[fullId]) {
      case 'Like':
        store.dispatch(incrementLikesCount({contentType, fullId, delta:-1, isLocal:true}));
        break;
    }
  
    // Add the new reaction type
    switch (type) {
      case 'Like':
        store.dispatch(incrementLikesCount({contentType, fullId, delta:1, isLocal:true}));
        break;
    }
  
    store.dispatch(
      setUserReactions(
        {
            contentType, 
            userReactions:{
                [fullId]: type,
            }
        }
      ),
    );
  
    let commentPath = getCommentCollectionPath(postAuthorId, postId);
    let path = `/users/${postAuthorId}/posts/${postId}/comments/`;
  
    if (parentCommentId) {
    
      commentPath = collection(commentPath,parentCommentId,'replies')
  
      path += `${parentCommentId}/replies/`;
    }
  
    path += `${commentId}/reactions/`;
  
    if (MODE_DEBUG) {
      console.debug(`setReactionForComment path: ${path}`);
    }
    const docPath = doc(commentPath,commentId,'reactions',user.Id)
  
    if (!type) {
      return deleteDoc(docPath).catch(error => {
        if (MODE_DEBUG) {
          console.warn('removeReaction error : ', error);
        }
      });
    }
  
    const reactionDoc = {
      Type: type,
      Date: serverTimestamp(),
      User: user,
    };
  
    return setDoc(docPath,reactionDoc)
      .then(() => {
        return true;
      })
      .catch(error => {
        if (MODE_DEBUG) {
          console.warn('addReactionForComment error : ', error);
        }
        return false;
      });
  }