import {dayInMilliseconds, ideaTimeframeInDays} from "../../utils/constants/financialData";
import {PostType} from "../../types/post.types";

export const getIsIdeaActive = (creationDate: Date, timeframe: "short" | "middle" | "long") => {
    const now = new Date();
    const expiryDateOffset = ideaTimeframeInDays[timeframe] * dayInMilliseconds;
    const expiryDate = new Date(creationDate.getTime() + expiryDateOffset);
    return now <= expiryDate;
}

export const getTranslatedText = (TextLocalizations: PostType["TextLocalizations"], language: string) => {
    if (language === 'fr' && TextLocalizations?.fr) {
        return TextLocalizations.fr;
    } else if (language !== 'fr' && TextLocalizations?.en) {
        return TextLocalizations.en;
    }
}

export const getCanTranslate = (Text: PostType["Text"], TextLocalizations: PostType["TextLocalizations"], language: string) => {

    let canTranslate = (!!TextLocalizations?.en) || (!!TextLocalizations?.fr);

    if (language === 'fr') {
        if (!TextLocalizations?.fr || TextLocalizations?.fr === Text) {
            canTranslate = false;
        }
    } else {
        if (!TextLocalizations?.en || TextLocalizations?.en === Text) {
            canTranslate = false;
        }
    }
    return canTranslate;
}