import React, {FC, ReactNode, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundry';
import {AuthListnerInit} from '../services/firebase/auth/auth.services';
import {selectAuthToken, selectEmailPassAuth, selectIsAuth, selectUser} from '../services/redux/reducers/userAuth/user';
import {MODE_DEBUG} from '../utils/constants/config';
import {init} from "../services/realtime/realtimeChannel";
import ARYACoinsPopup from "../components/App/Wallet/ARYACoinsPopup/ARYACoinsPopup";
import {selectShowCoinsPopup, setShowCoinsPopup} from "../services/redux/reducers/general/general";


type IAppProps = {
    children?: ReactNode
}
const AppLayout: FC<IAppProps> = ({children}) => {
    AuthListnerInit()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = useSelector(selectIsAuth);
    const userInfos = useSelector(selectUser);
    const tokens = useSelector(selectAuthToken);
    const authEmailPass = useSelector(selectEmailPassAuth);
    const showCoinsPopup = useSelector(selectShowCoinsPopup);
    const mustUpdatePhone = useMemo(() => isLoggedIn && userInfos && !userInfos?.phoneNumber && !tokens?.phone_number && !tokens?.passed2FA, [isLoggedIn, userInfos, tokens]);
    const phoneNumberExists = useMemo(() => userInfos && userInfos?.phoneNumber && !authEmailPass && (tokens?.phone_number || tokens?.passed2FA), [userInfos, tokens, authEmailPass]);
    const isEmailPasswordAuth = useMemo(() => userInfos && authEmailPass, [userInfos, authEmailPass]);
    const goSetting = useCallback(async () => {
        navigate('/verify-phone');
    }, [navigate]);

    useEffect(() => {

        if (phoneNumberExists && location.pathname === '/verify-phone') {
            navigate('/feed')
        }
    }, [phoneNumberExists, location, navigate]);

    useEffect(() => {
        if (MODE_DEBUG) {
            console.log('emailAuthPass', isEmailPasswordAuth)
        }
        if (mustUpdatePhone && !isEmailPasswordAuth) {
            goSetting();
        }

    }, [mustUpdatePhone, goSetting, isEmailPasswordAuth]);


    useEffect(() => {
        init();
    }, []);

    const coinsPopup = useMemo(() => {
        if (showCoinsPopup) {
            return (
                <ARYACoinsPopup
                    show={showCoinsPopup}
                    onClose={() => {
                        dispatch(setShowCoinsPopup(false));
                    }}
                />
            );
        }
    }, [dispatch, showCoinsPopup])


    return (
        <>
            <ErrorBoundary>
                {children}
                {coinsPopup}
            </ErrorBoundary>
        </>
    );
};

export default AppLayout;

