import {db} from "../auth/auth";
import {Timestamp, deleteField, updateDoc, doc} from "firebase/firestore";
import { toast } from "react-toastify";
import { MODE_DEBUG } from "../../../utils/constants/config";

interface post {
    pin: boolean,
    post: any,
    authorId: string,
}

export const pinUnpinPost = ({pin, post, authorId}: post, setMoreOptions: (x: boolean) => void, setPosts: (x: any) => void) => {
    const postId = post._id?.split('/')[1];
    const pinDate = new Date();
    updateDoc(doc(db, 'users', authorId, 'posts', postId), {
        PinnedAt: pin ? Timestamp.fromDate(pinDate) : deleteField(),
    })
    .then(() => {
        if (pin) {
        const data = post;
        data.Id = post._id;
        data.PinnedAt = pinDate;
        setPosts((st: any) => st.filter((e: any) => e._id === post._id ? data : e));
        toast.success('Post pinned successfully!');
        setMoreOptions(false);
    } else {
        const data = post;
        data.Id = post._id;
        delete data.PinnedAt;
        setPosts((st: any) => st.filter((e: any) => e._id === post._id ? data : e));
        toast.success('Post unpinned successfully!');
        setMoreOptions(false);
    }
    })
    .catch(err => {
        toast.error('Something went wrong!');
        if (MODE_DEBUG) {
            console.error(err);
        }
    });
};