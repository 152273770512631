import React, { FC, useCallback, useMemo } from 'react';
import { useStopwatch } from 'react-timer-hook';
import {  micIcon, pauseAudio, playAudioIcon,retryButton,stopRecorder } from '../../../../../assets/images/svgIcons/svgIcons';

import './AudioTimer.scss'
import { Row } from '../../../../core/Container';

type ITimerProps = {
    recordState: any,
    startRecording: Function,
    stopRecording: Function,
    resetRecording:Function,
    play?:boolean,
    setplay?:any,
}

const MyStopwatch: FC<ITimerProps> = ({ recordState, startRecording, stopRecording,resetRecording, play, setplay }) => {
    const {
        seconds,
        minutes,
        start,
        pause,
        reset
    } = useStopwatch({autoStart:false});

    const startTimer = useCallback(
        () => {
            start()
            startRecording()
        },
      [start,startRecording],
    )
    
    const pauseTimer = useCallback(() => {
        pause()
        stopRecording()
    },[pause,stopRecording])

    const playerContorl = useCallback(() =>{
        setplay(!play)
    },[setplay,play])

    const resetTimer = useCallback(() => {
        const time = new Date();
        time.setSeconds(time.getSeconds());
        reset(time,false)
        resetRecording()
    },[reset,resetRecording])

    const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
    
    // UI for chat box audio recorder
    const recording = useMemo(() => {
        return <button type='button' onClick={pauseTimer} className='record-control stop'>
            {stopRecorder}
        </button>
    }, [pauseTimer])
        
    const playback = useMemo(() => {
        return <Row className='playback-button'>
            <button type='button' onClick={resetTimer} className='record-control delete'>
                {retryButton}
            </button>
            <button type='button' onClick={playerContorl} className='record-control initial'>
                {play?pauseAudio:playAudioIcon}
            </button>
        </Row>
    }, [playerContorl,resetTimer,play])
    const record = useMemo(()=>{
        return <button type='button' onClick={startTimer} className='record-control initial'>
            {micIcon}
        </button>
    },[startTimer])

    const setButton = ()=>{
        switch (recordState){
            case 'start':
                return recording
            case 'stop':
                return playback
            default:
                return record
        }
    }

    return (
        <Row className='jcsb recording-details'>
            { 
                setButton()
                
            }
            {
            recordState!=='stop'?<h5 className='audio-timer'>
                    <span>{minuteTime}</span>:<span>{secondTime}</span>
                </h5>:''
            }
        </Row>
        

    );
}

export default MyStopwatch