import { addDoc, collection, deleteField, doc, getDocs, limit, orderBy, query, serverTimestamp, Timestamp, updateDoc, where } from "firebase/firestore";
import { db } from '../auth/auth'
import { IFlattenedMessage, IMessage } from "../../../types/discussion.types";
import { MODE_DEBUG } from "../../../utils/constants/config";
import store from "../../redux/store";
import { prependMessage, addMessages } from "../../redux/reducers/discussion/discussion";
import { setUser } from "../../redux/reducers/users/users";
import { getCurrentUserDataAsDbType, mapDbUserToUser } from "../user/user";
import { uploadImageAttachement } from "../assets";
import { discussionConstants } from "../../../utils/constants/discussions";




export const createMessage = async (
  discussionId:string,
  {
    Body,
    UserMentions,
    IndexMentions,
    Attachments,
    Audio,
    QuotedMessage,
    sharedFullPostId,
    userData
  }:any,
) => {

  if (!discussionId || (!Body && !Attachments && !Audio && !sharedFullPostId)) {
    if (MODE_DEBUG) {
      console.warn(
        `createMessage was called but discussionId:${discussionId} or Body:${Body} or Attachments:${Attachments} or Audio:${Audio} and sharedFullPostId:${sharedFullPostId} is falsy`,
      );
    }
    return;
  }

  const Author = getCurrentUserDataAsDbType();
  if (!Author) {
    if (MODE_DEBUG) {
      console.warn(
        'connectedUser is falsy, yet createMessage have been called !',
      );
    }
    return;
  }

  if(Author.PictureDate){
    const date = Date.parse(Author.PictureDate)
    if(!isNaN(date)){
        Author.PictureDate = new Date(Author.PictureDate);
    }
    else if (Author.PictureDate !instanceof Date){
        Author.PictureDate = new Date()
        if(MODE_DEBUG){
          console.warn('user Picture date is not date or string',Author.PictureDate)
        }
    }
  }
  const Data:any = {
    Author,
  };

  if (Body) {
    Data.Text = Body;
  }

  if (UserMentions?.length) {
    Data.UserMentions = UserMentions;
  }

  if (IndexMentions?.length) {
    Data.IndexMentions = IndexMentions;
  }

 if (Attachments &&
    Attachments.length > 0
  ) {

    const attachments:any = {
      Images:[]
    }
    const Files:File[] = []
    await Attachments.forEach((file:any)=>{
      if(file.File){
        Files.push(file);
      }
      else{
        attachments?.Images?.push(file.url);
      }
    })
    if(Files.length>0){
      await uploadImageAttachement(Files).then((res)=>{
        attachments.Images=[...attachments?.Images,...res?.Images]
      })
    }
    Data.Images = attachments.Images
  }

  if (Audio) {
    Data.Audio = Audio;
  }

  if (QuotedMessage) {
    Data.QuotedMessage = QuotedMessage;
  }

  const messageDoc = {
    Date: serverTimestamp(),
    Data,
  };

  if (MODE_DEBUG) {
    console.log('createMessage doc:', messageDoc,' for discussion id: ',discussionId);
  }
  const chatRef = collection(db, 'discussions',discussionId,'messages') ;
  const createdMessage = await addDoc(chatRef,messageDoc);

  messageDoc.Date = Timestamp.now();
  store.dispatch(prependMessage({discussionId, messageId:createdMessage.id, messageData:messageDoc}));
}

export const updateMessage = async(
  discussionId:any,
  messageId:any,
  {Body, UserMentions, IndexMentions, Attachments, Audio, QuotedMessage}:any,
) => {
  if (!discussionId || !messageId || (!Body && !Attachments && !Audio)) {
    if (MODE_DEBUG) {
      console.warn(
        `updateMessage was called but discussionId:${discussionId}, messageId:${messageId} or Body:${Body} or Audio:${Audio} and Attachments:${Attachments} is falsy`,
      );
    }
    return;
  }

  const currentUser = getCurrentUserDataAsDbType();
  if (!currentUser) {
    if (MODE_DEBUG) {
      console.warn('updateMessage was called the user is not logged in');
    }
    return;
  }

  const Data:any={};

  if (Body) {
    Data.Text = Body;
  }

  if (UserMentions?.length) {
    Data.UserMentions = UserMentions;
  }

  if (IndexMentions?.length) {
    Data.IndexMentions = IndexMentions;
  }

  if (Attachments &&
    Attachments.length > 0
  ) {

    const attachments:any = {
      Images:[]
    }
    const Files:File[] = []
    await Attachments.forEach((file:any)=>{
      if(file.File){
        Files.push(file);
      }
      else{
        attachments?.Images?.push(file.url);
      }
    })
    if(Files.length>0){
      await uploadImageAttachement(Files).then((res)=>{
        attachments.Images=[...attachments?.Images,...res?.Images]
      })
    }
    Data.Images = attachments.Images
  }

  if (Audio) {
    Data.Audio = Audio;
  }

  if (QuotedMessage) {
    Data.QuotedMessage = QuotedMessage;
  }

  if (MODE_DEBUG) {
    console.info('update message', messageId, 'with data:', Data);
  }
  const messageRef = doc(db, 'discussions', discussionId, 'messages', messageId)
  return await updateDoc(messageRef,{
      'Data.Text': Data.Text || deleteField(),
      'Data.UserMentions': Data.UserMentions || deleteField(),
      'Data.IndexMentions': Data.IndexMentions || deleteField(),
      'Data.Images': Data.Images || deleteField(),
      'Data.Audio': Data.Audio || deleteField(),
      'Data.QuotedMessage': Data.QuotedMessage || deleteField(),
      'Data.LastEditDate': serverTimestamp(),
      'Data.LastEditBy': currentUser.Id,
      'Data.Author': currentUser,
    })
    .catch((error:Error) => {
      if (MODE_DEBUG) {
        console.warn('updateMessage error : ', error);
      }
      return false;
    })
    .then(() => {
      return true;
    });
}


export async function getMoreMessages({discussionId, before, after}:{discussionId:string,before:any,after?:any}) {
  if (!discussionId || !!before === !!after) {
    if (MODE_DEBUG) {
      console.warn(
        `getMoreMessages was called but discussionId:${discussionId} or before:${before} and after:${after} are either all falsy or all specified`,
      );
    }
    return;
  }
  const chatRef = collection(db, 'discussions',discussionId,'messages') ;
  const moreChatQuery = query(chatRef,orderBy('Date', 'desc'),where('Date', after ? '>=' : '<', after || before),limit(discussionConstants.MaxFetchedMessages));
  const messageDocs = await getDocs(moreChatQuery)

  if (!messageDocs) {
    return;
  }

  const messagesData:any = [],
  users:any = [];
  messageDocs.forEach((messageDoc:any) => {
    const message = messageDoc.data();
    messagesData.push({
      ...message,
      Id: messageDoc.id,
    });

    const authorData = message?.Data?.Author;
    if (authorData) {
      // Dispatch the user
      if (authorData.PictureDate) {
        authorData.PictureDate = authorData.PictureDate.toDate();
      }
      let date = message.Date;
      if (date && date.toDate) {
        date = date.toDate();
      }
      users.push({
        ...authorData,
        _date: date,
      });
    }
  });

  //Users Store Implementation
  if (users.length) {
    store.dispatch(setUser(users));
  }

  store.dispatch(addMessages({discussionId:discussionId, messages:messagesData}));

  if (MODE_DEBUG) {
    console.debug('getMoreMessages got', messagesData.length, 'older messages');
  }

  return messagesData;
}

 export function flattenMessage(messageId:IMessage["Id"], message:IMessage) {
  if (
    !messageId ||
    !message?.Data ||
    !message?.Date ||
    !message?.Data?.Author
  ) {
    if (MODE_DEBUG) {
      console.warn(
        `flattenMessage was called but a messageId:${messageId}, message.Data:${message?.Data}, message.Data.Author:${message?.Data?.Author} or message.Date:${message?.Date} are falsy`,
      );
    }
    return null;
  }

  const flattenedMessage:IFlattenedMessage = {
    Id: messageId,
    Date: message.Date,
    Author: mapDbUserToUser(message.Data.Author.Id, message.Data.Author, true),
  };

  if (message.Data.Text) {
    flattenedMessage.Text = message.Data.Text;

    if (message.Data.UserMentions) {
      flattenedMessage.UserMentions = message.Data.UserMentions;
    }

    if (message.Data.IndexMentions) {
      flattenedMessage.IndexMentions = message.Data.IndexMentions;
    }
  }

  if (message.Data.Images && message.Data.ImageSizes) {
    flattenedMessage.Images = message.Data.Images;
  }

  if (message.Data.Audio) {
    flattenedMessage.Audio = message.Data.Audio;
  }

  if (message.Data.SharedPost?.Id) {
    flattenedMessage.SharedPostId = message.Data.SharedPost.Id;
  }

  return flattenedMessage;
}



export async function deleteMessage(discussionId:string, messageId:IMessage["Id"]) {
  if (!discussionId || !messageId) {
    if (MODE_DEBUG) {
      console.warn(
        `deleteMessage was called but discussionId:${discussionId} or messageId:${messageId} are falsy`,
      );
    }
    return;
  }

  const {auth,discussion} = store.getState();
  const user:any = auth.data.userMetadata;
  const messages = discussion.data.messages

  const currentUserId = user?.Id;
  if (!currentUserId) {
    if (MODE_DEBUG) {
      console.warn('deleteMessage was called the user is not logged in');
    }
    return;
  }

  const message = messages[discussionId][messageId];
  if (!message || !message.Date) {
    if (MODE_DEBUG) {
      console.warn(
        `deleteMessage was called but the store message:${message} or message.Date:${message.Date} are falsy`,
      );
    }
    return;
  }

  let deleteMessagePath = doc(db,'discussions',discussionId,'messages',messageId)

  return await updateDoc(deleteMessagePath, {
    'Data.DeletionDate': serverTimestamp(),
      'Data.DeletedBy': currentUserId,
      CreationDate:
        message.Date instanceof Timestamp
          ? message.Date
          : new Date(message.Date),
      Date: deleteField(),
  }).catch((error:Error) => {
    if (MODE_DEBUG) {
      console.warn('deleteMessage error : ', error);
    }
    return false;
  })
  .then(() => {
    return true;
  });

}
