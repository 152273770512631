import {FC, useCallback} from "react";
import Grid from "../../../../../core/Grid";
import {Col} from "../../../../../core/Container";
import {priceFormat} from "../../../../../../utils/helpers";
import {IFinancialData} from "../../../../../../types/idea.types";
import {useTranslation} from "react-i18next";
import "./FinancialDataActionPoints.scss";

const FinancialDataActionPoints: FC<{ financialData?: IFinancialData }> = ({financialData}) => {

    const {t} = useTranslation();


    const actionPoint = useCallback((label: string, value?: number) => {
        if (value) {
            return (
                <Col className="aifs fs-action-point">
                    <h5> {label} </h5>
                    <span> {priceFormat(value ?? 0).replace(",", " ")}</span>
                </Col>
            )
        }
    }, [])

    return (
        <>
            {(financialData?.EntryValue ||
                    financialData?.BreakEven || financialData?.StopLoss || financialData?.TakeProfits) &&
                <Grid className='fs-sell'>
                    {actionPoint(t<any>("idea.financialData.entry"), financialData?.EntryValue)}
                    {actionPoint(t<any>("idea.financialData.breakeven"), financialData?.BreakEven)}
                    {actionPoint(t<any>("idea.financialData.stoploss"), financialData?.StopLoss)}
                    {financialData?.TakeProfits && <>
                        {actionPoint(t<any>("idea.financialData.TP1"), financialData?.TakeProfits[0]?.Value)}
                        {actionPoint(t<any>("idea.financialData.TP2"), financialData?.TakeProfits[1]?.Value)}
                        {actionPoint(t<any>("idea.financialData.TP3"), financialData?.TakeProfits[2]?.Value)}
                    </>}
                </Grid>}
        </>
    )
}

export default FinancialDataActionPoints;