import clsx from "clsx";
import {Col, Row} from "./Container";
import {ChangeEvent, ChangeEventHandler, FocusEventHandler, useRef} from "react";
import {Picture} from "./Picture";
import {images} from "../../assets/images/images";
import {Button} from "./Button";
import {editIcon} from "../../assets/images/svgIcons/svgIcons";
import {maxImageSize} from "../../utils/constants/firebase";
import {toast} from "react-toastify";
import React from "react";
import "./Inputs/Inputs.scss";

export const InputForm = ({
                              children,
                              className,
                              placeholder,
                              onChange,
                              handleBlur,
                              name,
                              type = "",
                              value,
                              childrenAfter = true
                          }: {
    children: any;
    className?: string;
    placeholder?: string,
    name?: string,
    type?: string,
    value?: string,
    childrenAfter?: boolean,
    handleBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onChange?: ChangeEventHandler<HTMLInputElement>;

}) => {
    return (
        childrenAfter ?
            <div className={clsx('flex aic jcc', className)}>
                <input autoComplete="off" placeholder={placeholder} onChange={onChange} name={name} type={type}
                       value={value}
                       onBlur={handleBlur}/>
                {children}
            </div> :
            <div className={clsx('flex aic jcc', className)}>
                {children}
                <input autoComplete="off" placeholder={placeholder} onChange={onChange} name={name} type={type}
                       value={value}
                       onBlur={handleBlur}/>
            </div>
    )
}

export const TextArea = ({
                             placeholder,
                             className,
                             onChange,
                             name,
                             value,
                             rows,
                         }: {
    placeholder?: string,
    className?: string,
    name?: string,
    value?: string,
    rows?: number,
    onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;

}) => <textarea className={clsx(className)} placeholder={placeholder} onChange={onChange} name={name} value={value}
                rows={rows}/>


export const Input = ({
                          placeholder,
                          onChange,
                          name,
                          value,
                          type,
                          onClick,
                      }: {
    placeholder?: string,
    name?: string,
    value?: string,
    type?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
}) => <input onClick={onClick} placeholder={placeholder} name={name} value={value} type={type} onChange={onChange}/>


export const TextInput = ({
                              className,
                              type,
                              name,
                              placeholder,
                              value,
                              onChange,
                              onBlur,
                              error,
                              touched,
                              disabled,
                              maxLength,
                              infoMessage,
                              rightComponent,
                          }: {
    className?: string,
    type?: string,
    name?: string,
    value?: any,
    placeholder?: string,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    touched?: boolean,
    error?: any,
    disabled?: boolean,
    maxLength?: number,
    infoMessage?: string,
    rightComponent?: any,
}) => {
    return (
        <>
            <Col className={clsx('text-input-container aifs jcc', className)}>
                <input type={type}
                       name={name}
                       placeholder={placeholder}
                       value={value}
                       onChange={onChange}
                       onBlur={onBlur}
                       disabled={disabled}
                       maxLength={maxLength}
                       max={maxLength}
                />
                <Row className='text-input-actions'>
                    {maxLength && <p className='text-input-max-length'>{value?.length ?? 0}/{maxLength}</p>}
                    {infoMessage && <Picture src={images.infoIcon} alt={''} className='text-input-info'/>}
                    {rightComponent}
                </Row>
            </Col>
            {touched && error && <p className='input-error'>{error}</p>}
        </>
    );
}


export const AreaInput = React.forwardRef(({
                                               className,
                                               name,
                                               placeholder,
                                               value,
                                               onChange,
                                               onBlur,
                                               error,
                                               touched,
                                               rows,
                                               disabled,
                                               onKeyDown,
                                               maxLength
                                           }: {
        className?: string,
        name?: string,
        value?: any,
        placeholder?: string,
        onChange?: ChangeEventHandler<HTMLTextAreaElement>,
        onBlur?: FocusEventHandler<HTMLTextAreaElement>,
        touched?: boolean,
        error?: any,
        rows?: number,
        disabled?: boolean,
        onKeyDown?: any,
        maxLength?: number,
    }, ref: any) => {
        return (
            <Col className={clsx('aifs jcc textarea-input', className)}>
            <textarea
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                rows={rows}
                disabled={disabled}
                onKeyDown={onKeyDown}
                ref={ref}
                maxLength={maxLength}
            />
                {touched && error && <p className='input-error'>{error}</p>}
            </Col>
        );
    }
)

export const ImageInput = ({
                               className,
                               name,
                               placeholder,
                               value,
                               onChange,
                               onBlur,
                               error,
                               touched,
                               disabled,
                               defaultImage
                           }: {
    className?: string,
    name?: string,
    value: File | null,
    placeholder?: string,
    onChange?: (file: File, e: ChangeEvent<HTMLInputElement>) => void,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    touched?: boolean,
    error?: any,
    disabled?: boolean,
    maxLength?: number,
    infoMessage?: string,
    rightComponent?: any,
    defaultImage?: string,
}) => {


    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            const fileUploaded = event.target.files[0];
            if (fileUploaded.size > maxImageSize) {
                toast.error(`Selected image size is too big, maximum allowed size is 1MB`);
            } else {
                if (onChange) {
                    onChange(fileUploaded, event);
                }
            }
        }
    };

    return (
        <Col className={clsx('image-input-container aifs jcc', className)}>
            <input
                type="file"
                ref={fileInputRef}
                hidden={true}
                onChange={handleChange}
                accept='image/png, image/jpeg, image/jpg'
                name={name}
                disabled={disabled}
                value={value?.webkitRelativePath}
                onBlur={onBlur}
            />
            <Col className='img-container'>
                {(value || defaultImage) &&
                    <Picture src={(value ? URL.createObjectURL(value) : null) ?? defaultImage ?? ''} alt=''
                             className='selected-image'
                             hideIfError/>}
                {(!value) && <p className='description-text'>{placeholder}</p>}
                {(!disabled) && <Button
                    className='gg-icon-btn upload-file-btn'
                    type='button'
                    disabled={disabled}
                    onClick={() => {
                        if (fileInputRef.current) {
                            fileInputRef.current.click();
                        }
                    }}>
                    {editIcon}
                </Button>}
            </Col>
            {touched && error && <p className='input-error'>{error}</p>}
        </Col>
    );
}


export const ToggleInput = ({
                                label,
                                className,
                                name,
                                value,
                                onChange,
                                onBlur,
                                error,
                                touched,
                                disabled
                            }: {
    label?: string,
    className?: string,
    name?: string,
    value?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onBlur?: FocusEventHandler<HTMLInputElement>,
    touched?: boolean,
    error?: any,
    disabled?: boolean
}) => {
    return (
        <Col className={clsx('toggle-input-container aifs jcc', className)}>
            <Row className='wb-100 jcfs tic-input'>
                <p className='toggle-input-label'>{label}</p>
                <label className='switch'>
                    <input
                        type="checkbox"
                        name={name}
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled}
                    />
                    <span className='slider round'></span>
                </label>
            </Row>
            {touched && error && <p className='input-error'>{error}</p>}
        </Col>
    );
}