import {FC, useEffect, useMemo, useState} from "react";
import Modal from "../../../core/Modal/Modal";
import "./AwardsListPopup.scss";
import {Col, Row} from "../../../core/Container";
import {Button} from "../../../core/Button";
import {closePopupIcon} from "../../../../assets/images/svgIcons/svgIcons";
import {Picture} from "../../../core/Picture";
import {awardsData} from "../../../../utils/constants/wallet";
import {getPostAwards} from "../../../../services/firebase/coins/coins";
import Avatar from "../../../core/Shared/Avatar";
import {IAuthor} from "../../../../types/user.types";
import moment from "moment";
import {useTranslation} from "react-i18next";

const AwardsListPopup: FC<{
    show: boolean,
    onClose: () => void,
    postId: string,
    awardType: string
}> = ({
          show,
          onClose,
          postId,
          awardType
      }) => {

    const {t} = useTranslation();

    const [awardedByUsers, setAwardedByUsers] = useState<IAuthor[]>([]);

    useEffect(() => {
        if (postId && awardType) {
            getPostAwards(postId, awardType).then((res) => {
                setAwardedByUsers(res);
            })
        }
    }, [postId, awardType]);

    const awardedBy = useMemo(() => {
        if (awardedByUsers.length) {
            return (
                <Col className="ap-awarded-by">
                    <h5>{t<any>("idea.awardsPopup.awardedBy")}</h5>
                    <Col className="jcfs wb-100 ap-awarded-by-list">
                        {awardedByUsers.map((userAward) => {
                            if (userAward) {
                                return (
                                    <Row key={userAward._date?.toTimeString()}
                                         className="wb-100 jcsb ap-awarded-by-list-item">
                                        <Row>
                                            <Avatar id={userAward.Id}/>
                                            <p className="ap-li-user-name">{userAward.Name}</p>
                                        </Row>
                                        <p>{moment(userAward?._date)?.fromNow()}</p>
                                    </Row>
                                )
                            } else {
                                return <></>
                            }
                        })}
                    </Col>
                </Col>
            )
        }
    }, [awardedByUsers, t]);

    return (
        <Modal show={show} onClose={onClose} className="flex fdc awards-popup">
            <Row className="wb-100 jcfe">
                <Button className="text-btn" onClick={onClose}>{closePopupIcon}</Button>
            </Row>
            <Col className="wb-100 ap-content">
                <Picture src={awardsData[awardType].icon} alt={t<any>(awardsData[awardType]?.name ?? "")}
                         className="ap-img"/>
                <h4>{t<any>(awardsData[awardType]?.name ?? "")}</h4>
                <p className="">{t<any>(awardsData[awardType].description)}</p>
                {awardedBy}
            </Col>
        </Modal>
    )
}

export default AwardsListPopup;