import {deleteField, doc, getDoc, Timestamp} from "firebase/firestore";
import moment from "moment";
import {images} from "../../../assets/images/images"
import {app, db} from "../auth/auth";
import {updateDoc, onSnapshot, arrayRemove} from "@firebase/firestore";
import {MODE_DEBUG} from "../../../utils/constants/config";
import store from "../../redux/store";
import {setUser} from "../../redux/reducers/users/users";
import {getFunctions, httpsCallable} from "firebase/functions";
import {ProfilePrivacy, UserMetadataType} from "../../../types/user";
import {authActions} from "../../redux/reducers/userAuth/user";
import {createUserAliasHttpsCallable, disableUserHttpsCallable} from "../firebaseFunctions";

const pendingEnsureUserRequests: any = {};
const functions = getFunctions(app, 'europe-west3');
const getUsersCallable = httpsCallable(functions, 'getUsers', {});


export const userPic = (user: any) => {
    if (!user?.PictureDate || !user?.Id) return images.defaultUserImage
    return `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/profile-images%2F${user.Id}?alt=media`
}

export const unsafeGetLastFeedFetchDateFromDocument = (lastFetchedProtectedDocument: any) => {
    let value = lastFetchedProtectedDocument?.LastFeedFetchDate;
    if (value instanceof Timestamp && value.toDate() < new Date()) {
        return value;
    }
    return Timestamp.fromDate(moment().subtract(1, 'month').toDate());
}

export const getUserLevel = (trustScore?: number) => {
    if (trustScore) {
        if (trustScore >= 50000 * 1e3) {
            return 'Money Master';
        } else if (trustScore >= 5000 * 1e3) {
            return 'Rising Star';
        } else if (trustScore >= 500 * 1e3) {
            return 'Investor';
        }
    }
    return null;
}

export const getUserAliasDoc = async (alias: string,) => {
    const userAliasDocRef = doc(db, 'user-aliases', alias,);
    const useAlias = await getDoc(userAliasDocRef);

    if (useAlias.exists()) {
        return useAlias;
    } else {
        if (MODE_DEBUG) {
            console.log("getUserAliasDoc didn't returned any document for", alias);
        }
    }
    return useAlias;
};

export const getUserDocById = async (userId: string) => {
    const userDocRef = doc(db, 'users', userId,);

    const user = await getDoc(userDocRef);

    if (user.exists()) {
        return user;
    } else {
        if (MODE_DEBUG) {
            console.log("getUserDocById didn't returned any document for", userId);
        }
    }
    return user;
};

export const followUser = (userId: string, followedUserId: string, oldFollowingUsers: string[]) => {
    const userDocRef = doc(db, 'users', userId,);
    return updateDoc(userDocRef, {
        'FollowingUsers': [...oldFollowingUsers, followedUserId],
    });
}

export const unfollowUser = (userId: string, followedUserId: string, oldFollowingUsers: string[]) => {
    const userDocRef = doc(db, 'users', userId,);
    const FollowingUsers = oldFollowingUsers.filter((value) => value !== followedUserId);
    return updateDoc(userDocRef, {
        'FollowingUsers': FollowingUsers,
    });
}

export const streamUserProtectedData = (userId: string) => {
    const userDocRef = doc(db, 'users-protected', userId,);
    onSnapshot(userDocRef, snapshot => {
            const userProtected = snapshot.data();
            store.dispatch(authActions.setUserProtected({userProtected}));
        },
        (error: any) => {
            if (MODE_DEBUG) {
                console.error(error);
            }
        })

};

export const streamUserPrivateData = (userId: string) => {
    const userDocRef = doc(db, 'users-private', userId);
    return onSnapshot(userDocRef, snapshot => {
        const userPrivate = {...snapshot.data(), Id: userDocRef.id};
        store.dispatch(authActions.setUserPrivate({userPrivate}))
    }, (error: any) => {
        if (MODE_DEBUG) {
            console.error(error);
        }
    });

}
export const streamUser = (userId: string) => {
    const userDocRef = doc(db, 'users', userId);
    return onSnapshot(userDocRef, snapshot => {
        if (snapshot.exists()) {
            const userData = snapshot.data();

            if (userData?.BlockedUsers) {
                userData?.BlockedUsers.forEach((userId: string) => {
                    ensureUser(userId);
                });
            }
            store.dispatch(authActions.setUserMetadata({userMetadata: {Id: snapshot.id, ...userData}}));
        } else {
            if (MODE_DEBUG) {
                console.log("No such document!");
            }
        }
    }, (error: any) => {
        if (MODE_DEBUG) {
            console.error(error);
        }
    });
}


/**
 * Ensure the specified userId got a associated user in the users redux store
 *
 * Otherwise, get the userName from firestore and dispatch them in the users store.
 *
 * @param userId
 */
export function ensureUser(userId: any) {
    if (!userId) {
        if (MODE_DEBUG) {
            console.warn(`ensureUser was called but userId:${userId} is falsy`);
        }
        return;
    }

    const {users} = store.getState();
    const usersList = users.data.users;

    if (usersList?.[userId]?.Name || pendingEnsureUserRequests[userId]) {
        return;
    }

    if (MODE_DEBUG) {
        console.debug('User', userId, 'is not in store, fetching...');
    }

    const docRef = doc(db, 'users', userId);

    pendingEnsureUserRequests[userId] = getDoc(docRef).then(doc => {
        const user = mapDbUserToUser(doc.id, doc.data());
        let users = [user]
        store.dispatch(setUser({users, date: new Date()}));
    })

}


export function mapDbUserToUser(userId: any, userData: any, parseToNativeDate = true) {
    if (!userId || !userData) {
        if (MODE_DEBUG) {
            console.log(
                'mapDbUserToUser called with a falsy userId : ',
                userId,
                'or a falsy userData : ',
                userData,
            );
        }
        return null;
    }

    // Note : If a date is added in userData in the futur, be sure to convert it to a js-native date, it'll avoid some bad dates comparaisons.
    const user = {...userData};

    // Perform any additional conversions you need on the user. Keep in mind all data at the root of the profile document are still here
    user.Id = userId;

    if (userData.Name || userData.DisplayName) {
        user.Name = userData.Name ?? userData.DisplayName;
    }

    if (parseToNativeDate && user.PictureDate) {
        if (user.PictureDate.toDate) {
            // If PictureDate is convertible to date, convert it (we might have received a firestore timestamp object here)
            user.PictureDate = user.PictureDate.toDate();
        }
        // Convert/parse it to a native JS date
        user.PictureDate = new Date(user.PictureDate);
    }

    // If a ProfileData key is present, we'll need to flatten the object
    if (userData.ProfileData) {
        if (userData.ProfileData.Job) {
            user.Job = userData.ProfileData.Job;
        }

        // Here, if you want to flatten other ProfileData keys later on, it will be there.
    }

    return user;
}

// Get the current user data as a user type object (see docs/social_database.md)
export function getCurrentUserDataAsDbType() {

    // Ask the store
    const {auth} = store.getState();
    const user: any = auth.data.userMetadata;
    
    if (!user) {
        return;
    }

    const connectedUser = mapDbUserToUser(user?.Id, user, false);
    if (!connectedUser) {
        throw new Error('The current user data is not yet available');
    }
    // Those are the only allowed attributes, see firestore/firestore.rules: validateAuthorData()
    let mappedConnectedUser: any = {Id: user.Id};
    [
        'Id',
        'Name',
        'Job',
        'PictureDate',
        'Verified',
        'SystemAlias',
        'VanityAlias'
    ].forEach(key => {
        if (connectedUser[key]) {
            mappedConnectedUser[key] = connectedUser[key];
        }
    });

    return mappedConnectedUser;
}

export const getUsers = async (queryData: { Ids?: string[], Search?: string, FollowingUser?: string, isSuggestedPeopleQuery?: boolean, GroupId?:string}) => {
    const res = await getUsersCallable(
        queryData
    );
    if (res.data) {
        const users = (res.data as any[]).map((user) => {
            return {
                Id: user._id,
                ProfileData: {
                    Description: user.Description,
                    Job: user.Job,
                    Website: user.Website,
                },
                ...user
            } as UserMetadataType;
        });

        if (users.length) {
            store.dispatch(setUser({users, date: new Date()}))
        }
        return users
    }
}

export async function deleteUser(uid: string) {
    try {
        const {data}: any = await disableUserHttpsCallable({uid});
        return data?.success;
    } catch (err) {
        throw err
    }
}

export const blockUser = (userId: string, targetUserId: string) => {
    const state = store.getState()
    const {userMetadata}: any = state.auth.data
    const userDocRef = doc(db, 'users', userId,);
    return updateDoc(userDocRef, {
        BlockedUsers: [...userMetadata.BlockedUsers, targetUserId],
    });
}

export const unblockUser = (userId: string, targetUserId: string) => {
    const userDocRef = doc(db, 'users', userId,);
    return updateDoc(userDocRef, {
        BlockedUsers: arrayRemove(targetUserId),
    });
}

export const getUserReferralLink = (userAlias: string) => {
    const baseURL = `${process.env.REACT_APP_REFERAL_SIGNUP_URL}/?ref=`;
    return `${baseURL}${userAlias}`;
}

export async function updateUserData(
    userId: string,
    {
        displayName,
        job,
        link,
        description,
        userAlias,
        privacy
    }: { displayName: string, job: string, link: string, description: string, userAlias: string, privacy: ProfilePrivacy },
) {
    if (!userId) {
        if (MODE_DEBUG) {
            console.warn('Tried to update user data without an userId');
        }
        return;
    }
    const data: any = {};

    if (displayName) {
        data.DisplayName = displayName;
    }
    // For facultative fields, remove values if they are not set
    if (job !== void 0) {
        data['ProfileData.Job'] =
            job?.replace(/\s/g, '').length > 0 ? job : deleteField();
    }
    if (link !== void 0) {
        data['ProfileData.Website'] =
            link?.replace(/\s/g, '').length > 0
                ? link
                : deleteField();
    }
    if (description !== void 0) {
        data['ProfileData.Description'] =
            description?.replace(/\s/g, '').length > 0
                ? description
                : deleteField();
    }

    if (privacy) {
        data['PublicProfile'] = privacy === ProfilePrivacy.public ? true : deleteField();
    }

    if (MODE_DEBUG) {
        console.info('Updating user profile:', data);
    }

    const userDocRef = doc(db, 'users', userId,);


    return updateDoc(userDocRef, data).then(() => {
        if (userAlias) {
            return updateAlias(userId, userAlias);
        }
    });
}

export async function updateAlias(userId: string, userAlias: string) {
    if (!userAlias) {
        if (MODE_DEBUG) {
            console.warn(
                `updateAlias have been called, yet userAlias:${userAlias} is falsy!`,
            );
        }
        return;
    }

    if (!userId) {
        if (MODE_DEBUG) {
            console.warn(
                'updateAlias have been called, yet the user is not logged in!',
            );
        }
        return;
    }

    return createUserAliasHttpsCallable(userAlias)
        .then(res => res.data)
        .catch(e => {
            if (MODE_DEBUG) {
                console.info('createUserAlias returned an error:', e);
            }
            return e;
        });
}