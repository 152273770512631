import {auth, db} from "../auth/auth";
import {
    addDoc,
    collection,
    doc,
    serverTimestamp,
    Timestamp,
    deleteField,
    runTransaction,
    getDoc,
    QuerySnapshot,
    onSnapshot,
    FirestoreError, query, orderBy
} from "firebase/firestore";
import {updateDoc} from "@firebase/firestore";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {PostType} from "../../../types/post.types";
import {getCurrentUserDataAsDbType} from "../user/user";
import {uploadAudioAttachement, uploadImageAttachement, uploadUserVideos} from "../assets";
import {mainFeedSection} from "../../../utils/constants/groups";
import {getPostsHttpsCallable} from "../firebaseFunctions";
import {setBatchPostReaction} from "../../redux/reducers/reactions/reactions";
import {setUser} from "../../redux/reducers/users/users";
import store from "../../redux/store";
import moment from "moment";
import {t} from "i18next";
import {isEqual} from "lodash";
import { isValidUrl } from "../../../utils/helpers";


export const FEED = 'feed'
export const IDEAS_FILTERED = 'filtered_ideas';
export const minSecondsForFeedRefresh = 300
export const minSecondsForCustomFeedRefresh = 600
export const minSecondsForForceRefresh = 10;
// const POST_LIMIT_PER_PAGE = 50
export const maxImagesPerPost = 6;
export const maxVideoSize = 5e+8;


const postPath = (authorId: string) =>
    collection(db, 'users', authorId, 'posts')


export const getFeedName = ({
                                marketItem,
                                userId,
                                ideaFilters
                            }: { marketItem: object, userId: string, ideaFilters: object }) => {
    if (userId) {
        return 'user_' + userId;
    }
    if (marketItem) {
        //   return 'market_' + makeKey(marketItem);
    }
    if (ideaFilters) {
        return IDEAS_FILTERED;
    }
    return FEED;
}

export const getPosts = async (
    {
        userId,
        groupId,
        marketItem,
        ids,
        ideaFilters,
        search,
        sectionId,
        limit,
        offset
    }: any
) => {
    let data: any = {userId};
    if (ideaFilters) {
        data.ideaFilters = ideaFilters;
    }
    if (marketItem) {
        data.marketItem = marketItem;
    }
    if (ids) {
        data.ids = ids;
    }
    if (groupId) {
        data.groupId = groupId;
        if (sectionId && sectionId !== mainFeedSection.Id) {
            data.sectionId = sectionId;
        }
    }
    if (search) {
        data.search = search;
    }
    if (limit) {
        data.limit = limit;
    }
    if (offset) {
        data.offset = offset;
    }
    try {
        const serverResponse: any = await getPostsHttpsCallable(data)
        let fetchedPosts = serverResponse?.data;
        if (Array.isArray(fetchedPosts)) {
            // Added a filter on deleted, just in case
            fetchedPosts = fetchedPosts.filter(post => !post.IsDeleted);

            if (fetchedPosts.length) {
                handleIncomingPostDocuments(fetchedPosts);
                return fetchedPosts
            }
            return []
        } else {
            throw new Error('Unexpected return value from getPosts');
        }

    } catch (error: any) {
        if (MODE_DEBUG) {
            console.error(error.message);
        }
    }
}

/**
 * Get posts from ES, and dispatch them in redux
 *
 * @param idsList
 * @returns The fetched post length || Error
 */
export async function getPostsByIds(idsList: string[]) {

    // All posts loaded, or no post to load in the first place?
    if (idsList.length === 0) {
        if (MODE_DEBUG) {
            console.error("getPostsByIds called with empty Post Id List");
        }
        return;
    }

    if (idsList.length >= 1000) {
        throw new Error('Too many posts to fetch');
    }


    let fetchedPosts: PostType | PostType[] = await getPostsHttpsCallable({
        ids: idsList,
    }).then((response) => {
        return response.data
    });
    if (Array.isArray(fetchedPosts)) {
        // Added a filter on deleted, just in case
        fetchedPosts = fetchedPosts.filter(post => !post.IsDeleted);

        if (fetchedPosts.length) {
            handleIncomingPostDocuments(fetchedPosts);
            return fetchedPosts
        }
        return []
    } else {
        throw new Error('Unexpected return value from getPosts');
    }
}

export const parseElasticSearchPost = (post: any) => {
    let parsedPost: PostType = {
        id: post._id,
        ...post
    };
    return parsedPost;
}

// ----- Create
/**
 * Create a post in api
 *
 * If there is at least an image, this function will also upload the image(s) in cloudStorage, then create a post with the associated url/sizes
 * The same behaviour occurs with audio files
 */
export async function createPost({
                                     text,
                                     category,
                                     userMentions,
                                     indexMentions,
                                     images,
                                     audio,
                                     financialData,
                                     sharedPost,
                                     previewData,
                                     GroupId,
                                     GroupName,
                                     SectionId,
                                     SubscriptionId,
                                     setIsVideoUploading,
                                     setProgressValue,
                                     setVideoUploadError,
                                     video,
                                     gif
                                 }: any): Promise<string | boolean> {
    if (!text && !images?.length && !audio && !video?.path) {
        if (MODE_DEBUG) {
            console.warn(
                `createPost was called without text: ${text}, images: ${images} or audio: ${audio}`,
            );
        }
        return false;
    }

    const author = getCurrentUserDataAsDbType();

    if (!author) {
        if (MODE_DEBUG) {
            console.warn(
                'createPost was called but the user is not logged in, author is falsy',
            );
        }
        return false;
    }

    const postToCreate: any = {
        Date: serverTimestamp(),
        Data: {
            Author: author,
        },
    };

    if (GroupId) {
        postToCreate.Data.GroupId = GroupId;
    }

    if (SectionId) {
        postToCreate.Data.SectionId = SectionId;
    }

    if (text) {
        postToCreate.Data.Text = text;
        if (userMentions?.length) {
            postToCreate.Data.UserMentions = userMentions;
        }
        if (indexMentions?.length) {
            postToCreate.Data.IndexMentions = indexMentions;
        }
    }

    if (category) {
        postToCreate.Data.Category = category;
    }

    if (gif||(images && images.length > 0 && images.length <= maxImagesPerPost)) {
        let urls:string[] = []
        const Files:File[] = []
        if(gif){
          urls.push(gif)
        }
        await images.forEach((file:any)=>{
            if(file.File){
                Files.push(file);
            }
            else if(isValidUrl(file.url)){
                urls.push(file.url);
            }
        })
        if(Files.length>0){
            await uploadImageAttachement(Files).then((res)=>{
                urls=[...urls,...res?.Images]
            }).catch((error)=>{
                if(MODE_DEBUG){
                    console.log('image upload error')
                }
                throw error;
            });
        }
        postToCreate.Data.Images = urls
    }

    if (video.path) {
        setIsVideoUploading(true);
        await uploadUserVideos(
            [video],
            setProgressValue,
            setVideoUploadError,
        ).then((result: any) => {
            postToCreate.Data.Video = result;
        }).catch((error)=>{
            if(MODE_DEBUG){
                console.log('video upload error')
            }
            throw error;
        });
        setIsVideoUploading(false);
    }

    if (audio) {
        await uploadAudioAttachement(audio).then((url)=>{
            postToCreate.Data.Audio = url
        }).catch((error)=>{
            if(MODE_DEBUG){
                console.log('audio upload error')
            }
            throw error;
        })
    }

    if (sharedPost) {
        if (sharedPost.RestrictedTo?.length) {
            postToCreate.Data.SharedPost = {
                Id: sharedPost._id,
                Date: Timestamp.fromDate(moment(sharedPost.Date).toDate()),
                Author: sharedPost.Author,
                RestrictedTo: sharedPost.RestrictedTo,
            };
            if (postToCreate.Data.SharedPost.Author?.PictureDate) {
                postToCreate.Data.SharedPost.Author.PictureDate =
                    Timestamp.fromDate(
                        moment(postToCreate.Data.SharedPost.Author.PictureDate).toDate(),
                    );
            }
        } else {
            postToCreate.Data.SharedPost = storedPostToFirebasePost(sharedPost);
        }
    }

    if (financialData) {
        postToCreate.FinancialData = financialData;

        if (financialData.ExpirationDate?.toDate) {
            postToCreate.FinancialData.ExpirationDate = Timestamp.fromDate(
                financialData.ExpirationDate,
            );
        }
    }

    if (MODE_DEBUG) {
        console.debug(
            'will create a post with postToCreate:',
            postToCreate,
        );
    }

    if (GroupId && SubscriptionId) {
        postToCreate.RestrictedTo = [`@@${GroupId}/${SubscriptionId}`];
    }

    let createdPostId;
    if (sharedPost) {
        // Handle the creation in a transaction, since we'll check if the sharedPost data changed in the meantime
        const [sharedPostAuthorId, sharedPostId] =
            postToCreate.Data.SharedPost.Id.split('/');
        let sharedPostDocPath = doc(postPath(sharedPostAuthorId), sharedPostId);
        await runTransaction(db, async transaction => {
            // Note: This code may get re-run multiple times if there are conflicts.
            const sharedPostDoc = await transaction.get(sharedPostDocPath);
            if (!sharedPostDoc.exists()) {
                throw t('share.originalPostDeleted');
            }

            const sharedPostData = sharedPostDoc.data(),
                sharedPostIsRestricted = sharedPostData.RestrictedTo?.length;

            if (sharedPostData.Data?.IsDeleted) {
                throw t('share.originalPostDeleted');
            }

            let contentError;
            if (sharedPostIsRestricted) {
                // You can't set this on a shared post
                if (
                    postToCreate.Data.SharedPost?.Text ||
                    postToCreate.Data.SharedPost?.Images ||
                    postToCreate.Data.SharedPost?.Audio ||
                    postToCreate.Data.SharedPost?.FinancialData ||
                    postToCreate.Data.SharedPost.RestrictedTo.join('\n') !==
                    sharedPostData.RestrictedTo.join('\n')
                ) {
                    contentError = t('share.originalContentDidChange');
                }
            } else {
                if (sharedPostData.Data?.Text !== postToCreate.Data.SharedPost.Text) {
                    contentError = t('share.originalTextDidChange');
                }

                if (
                    !imageObjectsEqual(
                        sharedPostData.Data?.Images,
                        postToCreate.Data.SharedPost.Images,
                    )
                ) {
                    if (contentError) {
                        contentError = t('share.originalContentDidChange');
                    } else {
                        contentError = t('share.originalImagesDidChange');
                    }
                }

                if (
                    postToCreate.Data.SharedPost.Audio !== sharedPostData.Data?.Audio
                    // || postToCreate.Data.SharedPost?.FinancialData !== sharedPostData.Data?.FinancialData - TODO Compare all values
                ) {
                    contentError = t('share.originalContentDidChange');
                }
            }

            if (contentError) {
                throw contentError;
            }

            createdPostId =
                'share_' +
                sharedPostData.Data?.Author?.Id +
                '_' +
                sharedPostDocPath.id;

            if (MODE_DEBUG) {
                console.info(
                    'create post with a sharedPost ! :',postToCreate,
                );
            }

            return transaction.set(
                doc(postPath(author.Id), createdPostId),
                postToCreate,
            );
        })
            .then(() => {
                if (MODE_DEBUG) {
                    console.log('createPost transaction successfully committed!');
                }
                return true;
            })
            .catch((error: Error) => {
                if (MODE_DEBUG) {
                    console.log('createPost transaction failed: ', error);
                }

                throw error;
            });
    } else {
        if (MODE_DEBUG) {
            console.info('create post :', postToCreate);
        }
        createdPostId = await addDoc(postPath(author.Id), postToCreate)
            .then(post => post.id);
    }

    const createdPost: any = {
        ...postToCreate.Data,
        Text: postToCreate.Data.Text,
        _id: `${author.Id}/${createdPostId}`,
        Date: Date.now(),
        Preview: previewData,
    };
    if (postToCreate.FinancialData) {
        createdPost.FinancialData = postToCreate.FinancialData;
    }

    if (GroupName) {
        createdPost.GroupDetail = {
            Title: GroupName,
        };
    }

    if (createdPost.Author?.PictureDate?.toDate) {
        createdPost.Author.PictureDate = createdPost.Author.PictureDate.toDate();
    }
    if (createdPost.FinancialData?.ExpirationDate?.toDate) {
        createdPost.FinancialData.ExpirationDate =
            createdPost.FinancialData.ExpirationDate.toDate();
    }
    handleIncomingPostDocuments([createdPost]);
    return createdPost
}

/**
 * Update a post
 */
export async function updatePost({
    text,
    userMentions,
    indexMentions,
    images,
    gif,
    fullPostId,
    audio,
    previousAudio,
    financialData,
    previewData,
    SectionId,
    setIsVideoUploading,
    setProgressValue,
    setVideoUploadError,
    category,
    GroupId,
    SubscriptionId,
    GroupName,
    role,
    postAuthor,
    video,
    date
  }:any) {
    if (!text && !images?.length && !audio && !category && !video.path) {
      if (MODE_DEBUG) {
        console.warn(
          `updatePost was called without text: ${text}, images: ${images} or audio:${audio}`,
        );
      }
      return;
    }
    delete postAuthor.date;
    const author = postAuthor;
  
    if (!author) {
      if (MODE_DEBUG) {
        console.warn(
          'updatePost was called but the user is not logged in, author is falsy',
        );
      }
      return;
    }
    const [authorId, postId] = fullPostId?.split('/');
  
    const postToUpdate:any = {
      Author: author,
    };
  
    if (text) {
      postToUpdate.Text = text;
      if (userMentions?.length) {
        postToUpdate.UserMentions = userMentions;
      }
      if (indexMentions?.length) {
        postToUpdate.IndexMentions = indexMentions;
      }
    }
  
    if (gif||(images && images.length > 0 && images.length <= maxImagesPerPost)) {
        let urls:string[] = []
        const Files:File[] = []
        if(gif){
            urls.push(gif)
        }
        await images.forEach((file:any)=>{
            if(file.File){
                Files.push(file);
            }
            else if(isValidUrl(file.url)){
                urls.push(file.url);
            }
        })
        if(Files.length>0){
            await uploadImageAttachement(Files).then((res)=>{
                urls=[...urls,...res?.Images]
            })
        }
        if(urls.length){
            postToUpdate.Images = urls
        }
    }

    if (video.path) {
        if(!video.path.includes('fire')){
            setIsVideoUploading(true);
            await uploadUserVideos(
                [video],
                setProgressValue,
                setVideoUploadError,
            ).then((result: any) => {
                postToUpdate.Video = result;
            });
            setIsVideoUploading(false);
        }
        else{
            postToUpdate.Video = {
                width: video.width,
                height: video.height,
                size: video.size,
                videoUrl:video.path,
                thumbnailUrl:video.thumbnail,
            }
        }
    }
  
    if (
      (audio && !previousAudio) ||
      (previousAudio && audio && previousAudio !== audio)
    ) {
      postToUpdate.Audio = await uploadAudioAttachement(audio);
    } else if (previousAudio && audio && previousAudio === audio) {
      postToUpdate.Audio = audio;
    }
  
    if (financialData) {
      postToUpdate.FinancialData = financialData;
    }
  
    if (SectionId) {
      postToUpdate.SectionId = SectionId;
    }
  
    if (category) {
      postToUpdate.Category = category;
    }
  
    if (MODE_DEBUG) {
      console.info('Updating post:', postToUpdate);
    }
  
    if (GroupId) {
      postToUpdate.GroupId = GroupId;
    }
  
    if (GroupId && SubscriptionId) {
      postToUpdate.RestrictedTo = [`@@${GroupId}/${SubscriptionId}`];
    }
  
    const {auth}:any = store.getState();
    const currentUserId = auth?.data?.user?.uid;
    const isSuperAdmin = auth?.data?.userPrivate?.SuperAdmin;
  
    return updateDoc(doc(postPath(authorId),postId),{
        'Data.Text': postToUpdate.Text || deleteField(),
        'Data.Audio': postToUpdate.Audio || deleteField(),
        'Data.Video':postToUpdate.Video||deleteField(),
        'Data.UserMentions':
          postToUpdate.UserMentions || deleteField(),
        'Data.IndexMentions':
          postToUpdate.IndexMentions || deleteField(),
        'Data.Images': postToUpdate.Images || deleteField(),
        'Data.ImageSizes':
          postToUpdate.ImageSizes || deleteField(),
        'Data.SectionId': postToUpdate.SectionId || deleteField(),
        'Data.Category': postToUpdate.Category || deleteField(),
        'FinancialData':
          postToUpdate.FinancialData || deleteField(),
        'Data.LastEditDate': serverTimestamp(),
        'Data.LastEditBy': isSuperAdmin ? currentUserId : postToUpdate.Author.Id,
        'Data.Author': postToUpdate.Author,
        'Data.GroupId': postToUpdate?.GroupId || deleteField(),
        RestrictedTo: postToUpdate?.RestrictedTo || deleteField(),
      })
      .then(() => {
        const updatedPost = {
          ...postToUpdate,
          id: `${authorId}/${postId}`,
          Text: postToUpdate.Text,
          Preview: previewData,
          Date:date,
          TextLocalizations: {}, // Reset this
        };
  
        if (GroupName) {
          updatedPost.GroupDetail = {Title: GroupName, postAuthorRole: role};
        } else {
          updatedPost.GroupId = null;
          updatedPost.GroupDetail = {};
        }
  
        return updatedPost;
      });
  }

export const deletePost = async (fullPostId: string, postDate: any) => {
    if (!fullPostId || !postDate) {
        return;
    }
    const userAuth: any = auth.currentUser
    const [authorId, postId] = fullPostId.split('/');
    const docRef = doc(db, "users", authorId, 'posts', postId);
    await updateDoc(docRef, {
        'Data.IsDeleted': true,
        'Data.DeletionDate': serverTimestamp(),
        'Data.DeletedBy': userAuth?.uid,
        CreationDate: new Date(postDate),
        Date: deleteField(),
    }).catch((error)=>{
        if(MODE_DEBUG){
            console.log('Error deleting post',error)
        }
        throw error
    })
    return true
}

// ----- Helper
/**
 * Standard actions dispatched when a post document is retrieved
 *
 * @param posts
 */
export function handleIncomingPostDocuments(posts: any) {
    if (posts.length === 0) {
        if (MODE_DEBUG) {
            console.warn(
                'handleIncomingPostDocuments was called with an empty posts array. Discarding',
            );
        }
        return;
    }
    // Build our batch actions
    const postReactions: any = {},
        userData: any = {};

    if (!Array.isArray(posts)) {
        const e = new Error(
            'handleIncomingPostDocuments must be called with an array of posts.',
        );
        if (MODE_DEBUG) {
            console.error(e);
        }
        return;
    }

    function handleAuthorData({Author, Date: date}: any) {
        const authorData = {...Author};
        if (typeof authorData.PictureDate === 'string') {
            authorData.PictureDate = new Date(authorData.PictureDate);
        }
        authorData._date = new Date(date);

        if ((userData[authorData.Id]?._date ?? 0) < authorData._date) {
            userData[authorData.Id] = authorData;
        }
    }

    posts.forEach((post: any) => {
        postReactions[post._id] = {
            likeCount: post.ReactionsCount?.Likes ?? 0,
            commentCount: post.CommentsCount ?? 0,
            shareCount: post.SharesCount ?? 0,
        };

        // Post author
        if (post.Author && post.Date) {
            handleAuthorData(post);
        }

        // Shared post author
        if (post.SharedPost?.Author && post.SharedPost?.Date) {
            handleAuthorData(post.SharedPost);
        }

        // Last comment author
        if (post.LastComment?.Author && post?.LastComment?.Date) {
            handleAuthorData(post.LastComment);
        }
    });
    store.dispatch(setBatchPostReaction({postsData: postReactions}));
    store.dispatch(
        setUser({users: Object.keys(userData).map(id => userData[id])}),
    );
}

/**
 * Map a stored (redux) post to the expected api format
 *
 * @param post
 */
export function storedPostToFirebasePost(post: any) {
    const {Author, Date: date, Text, Images, Audio, _id, Video} = post;

    if (!Author || !date || !_id || !(Text || Images || Audio || Video)) {
        if (MODE_DEBUG) {
            console.warn(
                `storedPostToFirebasePost was called with unexpected post:${JSON.stringify(
                    post,
                )}`,
            );
        }
        return;
    }

    let formattedPost: any = {
        Id: _id,
        Date: Timestamp.fromDate(moment(date).toDate()),
        Author,
    };

    if (
        formattedPost.Author.PictureDate &&
        !(formattedPost.Author.PictureDate instanceof Timestamp)
    ) {
        formattedPost.Author.PictureDate = Timestamp.fromDate(
            moment(formattedPost.Author.PictureDate).toDate(),
        );
    }

    [
        'Text',
        'Images',
        'Video',
        'Audio',
        'InternalNewsData',
        'FinancialData',
    ].forEach(k => {
        if (post[k] !== void 0) {
            // If the key is defined
            formattedPost[k] = post[k];
        }
    });

    if (post.FinancialData?.ExpirationDate) {
        formattedPost.FinancialData.ExpirationDate = Timestamp.fromDate(
            moment(post.FinancialData.ExpirationDate).toDate(),
        );
    }

    return formattedPost;
}

/**
 * Compare two array of object and their attributes
 *
 * @param a1
 * @param a2
 * @returns {boolean}
 */
function imageObjectsEqual(a1: any, a2: any) {
    if (a1 === a2) {
        return true;
    }
    if (typeof a1 !== typeof a2 || a1.length !== a2.length) {
        return false;
    }
    if (!Array.isArray(a1)) {
        throw new Error('Unexpected data type');
    }

    for (let i = 0; i < a1.length; i++) {
        const v1 = a1[i],
            v2 = a2[i];
        if (typeof a1 === 'object') {
            // This assumes v2 shares the same keys as v1. Compare if needed
            for (const k of Object.keys(a1)) {
                if (v1[k] !== v2[k]) {
                    return false;
                }
            }
        } else if (v1 !== v2) {
            return false;
        }
    }
    return true;
}

/**
 * check whether the post is already present in user's post collection
 * @param {*} fullPostId
 * @returns
 */
export async function isPostAlreadySharedByUser(fullPostId: string) {
    try {
        const author = getCurrentUserDataAsDbType();
        const [sharedPostAuthorId, sharedPostId] = fullPostId?.split('/');
        const sharedPostIdToCheck =
            'share_' + sharedPostAuthorId + '_' + sharedPostId;
        let post = await getDoc(doc(postPath(author.Id), sharedPostIdToCheck));
        return post.exists();
    } catch (err) {
        if (MODE_DEBUG) {
            console.debug('error in checkIfPostIsAlreadyPresent', err);
        }
        return false;
    }
}

/**
 * check if post data has been changed
 * @param {*} sharedFullPostId
 * @returns
 */
export async function isPostToShareUpToDate(sharedFullPostId: string, sharedPost: PostType) {
    try {
        if (sharedFullPostId) {
            if (sharedPost) {
                let postDetails = sharedFullPostId?.split('/');
                const dbPost = await getDoc(doc(postPath(postDetails[0]), postDetails[1]));

                let dbData = dbPost.data();

                // removing the unnecessary fields before comparison
                if (dbData?.FinancialData?.TitleLocalizations) {
                    delete dbData?.FinancialData?.TitleLocalizations;
                }
                if (dbData?.FinancialData?.TitleLang) {
                    delete dbData?.FinancialData?.TitleLang;
                }
                if (dbData?.FinancialData?.ExpirationDate) {
                    delete dbData?.FinancialData?.ExpirationDate;
                }
                if (sharedPost?.FinancialData?.TitleLocalizations) {
                    delete sharedPost?.FinancialData?.TitleLocalizations;
                }
                if (sharedPost?.FinancialData?.TitleLang) {
                    delete sharedPost?.FinancialData?.TitleLang;
                }
                if (sharedPost?.FinancialData?.ExpirationDate) {
                    delete sharedPost?.FinancialData?.ExpirationDate;
                }
                //

                return !!(
                    dbData?.FinancialData &&
                    sharedPost?.FinancialData &&
                    !isEqual(dbData?.FinancialData, sharedPost?.FinancialData) &&
                    !isEqual(dbData?.Data?.Text, sharedPost?.Text) &&
                    !isEqual(dbData?.RestrictedTo, sharedPost?.RestrictedTo)
                );
            }
        } else {
            return false;
        }
    } catch (err) {
        if (MODE_DEBUG) {
            console.debug('error in checkIfContentHaschanged', err);
        }
        return false;
    }
}


/**
 * addPostUpdate is a function that add new updates to an existing post.
 * @param {string} fullPostId - The unique ID of the post starting from the author doc in format {authorId}/{postId}.
 * @param {string} updateText - The new text that will be used in the update.
 * @returns {void}
 */
export async function addPostUpdate(fullPostId: string, updateText: string) {
    const [authorId, postId] = fullPostId.split('/');
    if (!authorId || !postId) {
        if (MODE_DEBUG) {
            console.warn('updateIdea was called but either post or author is falsy');
        }
        return;
    }
    const postUpdateObj = {
        Text: updateText,
        Date: serverTimestamp(),
    };
    const collectionRef = collection(db, "users", authorId, 'posts', postId, 'updates');
    return addDoc(collectionRef, postUpdateObj);
}


export const streamPostUpdates = (fullPostId: string, snapshot: (snapshot: QuerySnapshot) => void, error: (error: FirestoreError) => void) => {
    const [authorId, postId] = fullPostId.split('/');
    const collectionRef = collection(db, "users", authorId, 'posts', postId, 'updates');
    const q = query(collectionRef, orderBy('Date', 'desc'));
    return onSnapshot(q, snapshot, error);
};
