import {MODE_DEBUG} from "../../../utils/constants/config";
import {
    getAllowedGroupsHttpsCallable,
    getGroupsHttpsCallable,
    ownerStatisticsHttpsCallable
} from "../firebaseFunctions";
import {
    addDoc,
    collection,
    deleteDoc,
    deleteField,
    doc,
    DocumentSnapshot,
    getDocs,
    onSnapshot,
    query,
    QuerySnapshot,
    serverTimestamp,
    setDoc,
    where,
    writeBatch,
    updateDoc, getDoc
} from "firebase/firestore";
import {auth, db, remoteConfig} from "../auth/auth";
import {getCurrentUserDataAsDbType} from "../user/user";
import {FIRESTORE_COINS_MULTIPLIER} from "../../../utils/constants/prices";
import {
    GroupRule,
    GroupSectionType,
    GroupSubscription,
    GroupSubscriptionTimeframes,
    OwnerStatisticsTimeframes,
    QuestionnaireQuestion
} from "../../../types/group.types";
import {uploadUserImage} from "../assets";
import {getValue} from "firebase/remote-config";
import {GroupNotificationsSettingsTypes, mainFeedSection} from "../../../utils/constants/groups";

export const groupsCollectionName = "groups";

export enum groupSectionTypes {
    managed = 'managed',
    joined = 'joined',
    discover = 'discover'
}

// The groups path
export const getGroupsPath = () => collection(db, groupsCollectionName);

// The group members path
export const getGroupsMembersPath = (memberId: string, groupId: string) => {
    return doc(db, groupsCollectionName, groupId, 'members', memberId);
};

// The group pending members path
export const getGroupsPendingMembersPath = (groupId: string) => {
    return collection(db, groupsCollectionName, groupId, 'pending_members');
};

// The group sections path
export const getGroupsSectionsPath = (groupId: string) => {
    return collection(db, groupsCollectionName, groupId, 'sections');
};


export const getGroups = async (
    {
        limit = 100,
        offset = 0,
        sectionTypes,
        search,
        managed,
        userID,
        ignoreLimit,
    }: {
        limit?: number,
        offset?: number,
        sectionTypes?: groupSectionTypes[],
        search?: string,
        managed?: string,
        userID?: string,
        ignoreLimit?: boolean,
    }
) => {
    let data: any = {};

    if (sectionTypes) {
        data.sectionTypes = sectionTypes;
    }

    if (userID) {
        data.userID = userID;
    }

    if (search) {
        data.search = search;
    }

    if (limit && !ignoreLimit) {
        data.limit = limit;
    }

    if (offset) {
        data.offset = offset;
    }

    if (managed) {
        data.managed = managed;
    }

    try {
        const serverResponse: any = await getGroupsHttpsCallable(data)
        return (serverResponse?.data)
    } catch (error: any) {
        const message = error.message;
        if (MODE_DEBUG) {
            console.error("getGroups - error while fetching groups:\n", data, "\nis\n", message);
        }
    }
}

// Allowed groups to post in
export const getAllowedGroups = async (groupId: string | null) => {
    return await getAllowedGroupsHttpsCallable({id: groupId}).then(
        (response: any) => {
            return response.data?.map((subscription: any) => {
                return {
                    ...subscription,
                    Name: 'VIP', // Force all sub name to VIP, remove this map later when we'll use the name again
                };
            });
        },
        e => {
            console.warn('Errow while getting the allowed groups list: ', e);
        },
    );
};

export const streamUserGroups = (userId: string, snapshot: (snapshot: QuerySnapshot) => void, error: any) => {
    const userGroupsCollectionRef = collection(db, 'users-protected', userId, groupsCollectionName);
    return onSnapshot(userGroupsCollectionRef, snapshot, error);
};

export async function createGroup({
                                      groupType,
                                      name,
                                      groupLink,
                                      description,
                                      avatar,
                                      hasPaidContent,
                                      subscriptionPriceConfiguration,
                                      defaultFeaturesFlag,
                                  }: {
    groupType: string,
    name: string,
    groupLink: string,
    description: string,
    avatar: File | null,
    hasPaidContent: boolean,
    subscriptionPriceConfiguration: GroupSubscription["Timeframes"],
    defaultFeaturesFlag?: { PublishIdea: boolean },
}) {
    if (!name || !groupType) {
        if (MODE_DEBUG) {
            console.warn(`createGroup was called but name:${name} is falsy`);
        }
        return;
    }

    const connectedUser = getCurrentUserDataAsDbType();
    if (!connectedUser) {
        if (MODE_DEBUG) {
            console.warn(
                'connectedUser is falsy, yet createGroup have been called !',
            );
        }
        return;
    }

    const Type = groupType;
    const Owner = connectedUser.Id;

    let Data: any = {};

    if (hasPaidContent) {
        if (subscriptionPriceConfiguration) {
            Data.Subscriptions = [
                {
                    Id: doc(getGroupsPath()).id,
                    Timeframes: mapSubscriptionTimeframes(subscriptionPriceConfiguration),
                },
            ];
        }
    }

    if (name) {
        Data.Title = name;
    }

    if (groupLink) {
        Data.LinkUrl = groupLink;
    }

    if (description) {
        Data.Description = description;
    }

    if (avatar) {
        Data.Image = await uploadUserImage({userId: Owner, imageFile: avatar})
            .catch(() => {
                if (MODE_DEBUG) {
                    console.warn('uploadUserImages is falsy!');
                }
            });
    }

    if (defaultFeaturesFlag) {
        let defaultFeatureFromConfig = JSON.parse(getValue(remoteConfig, 'default_feature_flags').asString());
        Data.DefaultFeaturesFlags = {
            ...defaultFeatureFromConfig,
            ...defaultFeaturesFlag,
        };
    }

    const groupDoc = {
        Type,
        Date: serverTimestamp(),
        Data,
        Owner,
    };

    if (MODE_DEBUG) {
        console.log('createGroup doc:', groupDoc);
    }

    const createdGroup = await addDoc(getGroupsPath(), groupDoc);
    if (createdGroup?.id) {
        await setDoc(getGroupsMembersPath(Owner, createdGroup.id), {
            Roles: ['OWNER'],
            MemberSince: serverTimestamp(),
        });
    }

    return createdGroup?.id;
}

const mapSubscriptionTimeframes = (
    subscriptionPriceConfiguration: GroupSubscription["Timeframes"]) => {
    const res: GroupSubscription["Timeframes"] = {};
    if (subscriptionPriceConfiguration?.Yearly) {
        res.Yearly = displayedCoinsToFirestoreCoins(subscriptionPriceConfiguration.Yearly);
    }
    if (subscriptionPriceConfiguration?.Monthly) {
        res.Monthly = displayedCoinsToFirestoreCoins(subscriptionPriceConfiguration.Monthly);
    }
    if (subscriptionPriceConfiguration?.Weekly) {
        res.Weekly = displayedCoinsToFirestoreCoins(subscriptionPriceConfiguration.Weekly);
    }
    return res;
}

export function displayedCoinsToFirestoreCoins(amount: number) {
    return amount * FIRESTORE_COINS_MULTIPLIER;
}


export const getGroupByAlias = async (groupAliasId: string,) => {
    const groupsColRef = collection(db, groupsCollectionName,);
    const q = query(groupsColRef, where('Alias', '==', groupAliasId));
    return getDocs(q);
};

export const getGroupById = async (groupId: string,) => {
    const groupsColRef = collection(db, groupsCollectionName,);
    const q = doc(groupsColRef, groupId);
    return getDoc(q);
};

export function remoteGroupsToLocalGroups(group: any) {
    const mappedGroup = group;
    if (mappedGroup?.Subscriptions?.length) {
        mappedGroup.Subscriptions = mappedGroup.Subscriptions.map(
            (subscription: any) => {
                const mappedSub = subscription;
                Object.keys(subscription.Timeframes ?? {}).forEach(timeframe => {
                    // The firestore price is saved at 1e3, let this helper handle the conversion
                    mappedSub.Timeframes[timeframe] = subscription.Timeframes[timeframe];
                });
                return mappedSub;
            },
        );
    }
    return mappedGroup;
}

export async function getSingleGroup(groupDoc: any) {

    const groupData = groupDoc.data();
    if (groupDoc.exists() && groupData) {
        return remoteGroupsToLocalGroups(
            {
                ...groupData.Data,
                Type: groupData.Type,
                Owner: groupData.Owner,
                Alias: groupData.Alias,
                _id: groupDoc.id,
            }
        )
    } else {
        return null;
    }
}


export const streamGroupById = (groupId: string, snapshot: (snapshot: DocumentSnapshot) => void, error: any) => {
    const groupsDocRef = doc(getGroupsPath(), groupId);
    return onSnapshot(groupsDocRef, snapshot, error);
};


export const editGroupInfo = async ({
                                        groupId,
                                        groupTitle,
                                        groupLink,
                                        description,
                                        about,
                                        avatar,
                                        userId,
                                        subscriptionPriceConfiguration,
                                        questionnaire,
                                        rules,
                                    }: {
    groupId: string,
    groupTitle?: string,
    groupLink?: string,
    description?: string,
    about?: string,
    avatar?: File | null,
    userId?: string,
    subscriptionName?: string,
    subscriptionDescription?: string,
    subscriptionPriceConfiguration?: GroupSubscription["Timeframes"],
    questionnaire?: QuestionnaireQuestion[],
    rules?: GroupRule[],
}) => {

    if (groupId) {
        const docRef = doc(db, groupsCollectionName, groupId);
        const data: any = {};

        if (groupTitle) {
            if ((groupTitle ?? '') !== '') {
                data['Data.Title'] = groupTitle;
            }
        }

        if (groupLink || groupLink === '') {
            if (groupLink) {
                data['Data.LinkUrl'] = groupLink;
            } else {
                data['Data.LinkUrl'] = deleteField();
            }
        }

        if (description) {
            data['Data.Description'] = description;
        }

        if (about) {
            if ((about ?? '') !== '') {
                data['Data.About'] = about;
            } else {
                data['Data.About'] = deleteField();
            }
        }

        if (subscriptionPriceConfiguration) {
            data['Data.Subscriptions'] = [
                {
                    Id: doc(getGroupsPath()).id,
                    Timeframes: mapSubscriptionTimeframes(subscriptionPriceConfiguration),
                },
            ];
        }

        if (questionnaire) {
            data['Data.Questionnaire'] = questionnaire;
        }

        if (rules) {
            data['Data.Rules'] = rules;
        }

        if (avatar && userId) {
            data['Data.Image'] = await uploadUserImage({userId: userId, imageFile: avatar})
                .catch(() => {
                    if (MODE_DEBUG) {
                        console.warn('uploadUserImages is falsy!');
                    }
                });
        }

        if (MODE_DEBUG) {
            console.log(`editGroupInfo: editing group with id:${groupId} with this data:`, data);
        }

        return await updateDoc(docRef, data);
    }
}

export const getSections = async (groupId: string) => {
    const sectionsQuery = getGroupsSectionsPath(groupId);
    return await getDocs(sectionsQuery)
        .then((querySnapshot) => {
            const sectionsArray: GroupSectionType[] = [];
            querySnapshot.docs.forEach((sectionDoc) => {
                const sectionDocData = sectionDoc.data();
                if (sectionDocData) {
                    sectionsArray.push({
                        Id: sectionDoc.id,
                        Icon: sectionDocData.Icon,
                        Title: sectionDocData.Title,
                    });
                }
            });
            return sectionsArray;
        }).catch(e => {
            if (MODE_DEBUG) {
                console.warn('getPendingMembersRequests failed with error:', e);
            }
        });
}

export const addGroupSection = async (groupId: string, Icon: string, Title: string) => {
    return addDoc(getGroupsSectionsPath(groupId), {
        Icon,
        Title,
    });
}

export const setGroupSections = async (groupId: string, sections: GroupSectionType[]) => {
    return sections.map((section) => {
        if (section.Id !== mainFeedSection.Id) {
            const docPath = doc(getGroupsSectionsPath(groupId), section.Id);
            if (section.isDeleted) {
                return deleteDoc(docPath);
            } else {
                return setDoc(docPath, {
                    Icon: section.Icon,
                    Title: section.Title,
                });
            }
        } else {
            // eslint-disable-next-line array-callback-return
            return;
        }
    });
}

export async function subscribeToGroup({
                                           groupId,
                                           subscriptionId,
                                           timeframe
                                       }: { groupId: string, subscriptionId: string, timeframe: GroupSubscriptionTimeframes }) {


    if (!groupId || !subscriptionId || !timeframe) {
        if (MODE_DEBUG) {
            console.warn(
                `createGroup have been called but groupId:${groupId}, subscriptionId:${subscriptionId} or timeframe:${timeframe} is falsy`,
            );
        }
        return;
    }

    const connectedUser = getCurrentUserDataAsDbType();
    if (!connectedUser) {
        if (MODE_DEBUG) {
            console.warn(
                'connectedUser is falsy, yet subscribeToGroup have been called !',
            );
        }
        return;
    }

    const SubscriptionID = subscriptionId + '/' + timeframe;

    await updateDoc(getGroupsMembersPath(connectedUser.Id, groupId),
        {
            SubscriptionID,
        },
    );

    return true;
}

export async function cancelSubscription({groupId}: { groupId: string }) {

    const connectedUser = getCurrentUserDataAsDbType();

    if (!connectedUser) {
        if (MODE_DEBUG) {
            console.warn(
                'connectedUser is falsy, yet createGroup have been called !',
            );
        }
        return;
    }

    return updateDoc(
        getGroupsMembersPath(connectedUser.Id, groupId),
        {
            SubscriptionID: deleteField(),
        },
    ).then(() => {
        return true;
    }).catch((e) => {
        if (MODE_DEBUG) {
            console.warn('cancel subscription error:', e);
        }
        return false;
    });
}

export async function sendInviteToUser({groupId, selectedUsers}: { groupId: string, selectedUsers: string[] }) {
    try {
        const connectedUser = getCurrentUserDataAsDbType();
        if (!connectedUser) {
            if (MODE_DEBUG) {
                console.warn(
                    'connectedUser is falsy, yet createGroup have been called !',
                );
            }
            return;
        }

        let inviteObject = {
            InvitedAt: serverTimestamp(),
            Roles: [],
        };

        const batch = writeBatch(db);
        selectedUsers.forEach(userId => {
            let inviteRef = doc(getGroupsPendingMembersPath(groupId), userId, 'invites', connectedUser.Id);
            batch.set(inviteRef, inviteObject)
        });

        await batch.commit();

        return true;
    } catch (err) {
        return false;
    }
}


export function getDefaultGroupOwnerStatistics(groupId: string) {
    return ownerStatisticsHttpsCallable({groupId});
}


export function getGroupOwnerStatisticsByGraph(groupId: string, graphType: "RevenueMemberGraph" | "TierGraph", timeFrame: OwnerStatisticsTimeframes) {
    const data: any = {groupId, components: [graphType]};
    if (timeFrame !== OwnerStatisticsTimeframes.AllTime) {
        data.timeFilter = timeFrame;
    }
    return ownerStatisticsHttpsCallable(data);
}

export async function updateGroupNotificationsSettings(groupId: string, {
    emailNotificationsDisabled,
    pushNotificationsDisabled
}: { emailNotificationsDisabled: boolean, pushNotificationsDisabled: boolean }) {
    const userAuth = auth.currentUser;

    if (userAuth?.uid) {
        const disabledSocialNotifications = [];
        if (emailNotificationsDisabled) {
            disabledSocialNotifications.push(`${GroupNotificationsSettingsTypes.email}@${groupId}`);
        }
        if (pushNotificationsDisabled) {
            disabledSocialNotifications.push(`${GroupNotificationsSettingsTypes.push}@${groupId}`);
        }
        const userProtectedDocRef = doc(db, "users-protected", userAuth?.uid);
        return updateDoc(userProtectedDocRef, {
            'NotificationOptions.disabledSocialNotifications': disabledSocialNotifications
        });
    }
}