import {FC, useEffect, useState} from "react";
import "./AssetImage.scss";
import {StocksWatchlistItem} from "../../../../types/user";
import {Picture} from "../../../core/Picture";
import {useSelector} from "react-redux";
import {selectMarketPairs} from "../../../../services/redux/reducers/marketPrices/marketPrices";
import {ensureMarketItemLogo, getMarketKey,} from "../../../../services/firebase/market/market";
import clsx from "clsx";
import {images} from "../../../../assets/images/images";

const AssetImage: FC<{ stocksWatchlistItem: StocksWatchlistItem, className?: string }> = ({
                                                                                              stocksWatchlistItem,
                                                                                              className
                                                                                          }) => {

    const marketPairs = useSelector(selectMarketPairs);

    const [imageURL, setImageURL] = useState<string>(() => {
        if (stocksWatchlistItem?.logo?.length) {
            return stocksWatchlistItem.logo[0];
        } else {
            return images.defaultAssetIcon;
        }
    });

    useEffect(() => {
        if (stocksWatchlistItem?.logo?.length) {
            setImageURL(stocksWatchlistItem?.logo[0]);
        } else {
            const marketKey = getMarketKey(stocksWatchlistItem.symbol, stocksWatchlistItem.market, stocksWatchlistItem.to);
            ensureMarketItemLogo(marketKey);
            if (marketPairs[marketKey] && marketPairs[marketKey].logo) {
                setImageURL(marketPairs[marketKey].logo);
            }
        }
    }, [stocksWatchlistItem, marketPairs]);

    return (
        <Picture src={imageURL} alt='' className={clsx('asset-image', className)} defaultImageIfError={images?.defaultAssetIcon}/>
    );
}

export default AssetImage;