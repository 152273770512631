import {FC, RefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, ContainerWithRef, Row} from "../../../../core/Container";
import AuthorIdea from "../../../Feed/miscs/Idea/Author";
import PostTags from "../PostTags/PostTags";
import {FinancialDataType, PostType} from "../../../../../types/post.types";
import "./PostHeader.scss";
import {moreOptionsIcon} from "../../../../../assets/images/svgIcons/svgIcons";
import {superAdmin} from "../../../../../utils/constants/constants";
import {
    deletePostOption,
    othersPostOptions,
    ownPostOptions,
    pinPostOption,
    postOptionConstants,
    postOptions,
    savePostOption,
    unpinPostOption,
    unSavePostOption
} from "../../../../../utils/constants/idea";
import {Button} from "../../../../core/Button";
import OptionsMenu, {Option} from "../../../../core/OptionsMenu/OptionsMenu";
import {useSelector} from "react-redux";
import {
    selectUser,
    selectUserMetadata,
    selectUserProtected,
    selectUserRole
} from "../../../../../services/redux/reducers/userAuth/user";
import useFollowUnfollow from "../../../../../common/hooks/useFollowUnfollow";
import ConfirmationPopup from "../../../../core/ConfirmationPopup/ConfirmationPopup";
import {deletePost} from "../../../../../services/firebase/posts/posts";
import {MODE_DEBUG} from "../../../../../utils/constants/config";
import {toast} from "react-toastify";
import {createSupportTicket, createSupportTicketConstants} from "../../../../../services/firebase/support";
import {Spinner} from "../../../../core/Loading";
import {blockUser} from "../../../../../services/firebase/user/user";
import {
    disableNotificationsFromUser,
    enableNotificationsForUser
} from "../../../../../services/firebase/notifications/notifications";
import {pinUnpinPost} from "../../../../../services/firebase/posts/pinUnpinPost";
import UpdatePostPopup from "../../../../generic/Popups/PostIdea/UpdatePost/UpdatePostPopup";
import {savePost, unSavePost} from "../../../../../services/firebase/posts/saveForLater";
import {useTranslation} from "react-i18next";

const PostHeader: FC<{
    postAuthor: PostType["Author"],
    postCreationDate: PostType["Date"],
    groupId?: string,
    groupDetail: PostType["GroupDetail"],
    postFinancialData?: FinancialDataType,
    postCategory?: PostType["Category"],
    postId?: PostType['id']
    isSharedPost?: boolean,
    post?: PostType,
    canPinUnpinPost?: boolean,
    setPosts?: any,
    inGroup?: boolean,
    onEdit?:Function,
    onNewUpdate?: (newUpdate: PostType["LastUpdate"]) => void,
    isFinancialPopUp?:boolean,
    moderator?:boolean
}> = ({
          postAuthor,
          postCreationDate,
          groupId,
          groupDetail,
          postFinancialData,
          postCategory,
          postId,
          isSharedPost = false,
          post,
          canPinUnpinPost,
          setPosts,
          inGroup,
          onNewUpdate,
          onEdit,
          isFinancialPopUp = false,
          moderator
      }) => {

    const userData = useSelector(selectUser);
    const userRole = useSelector(selectUserRole);
    const userProtected = useSelector(selectUserProtected);
    const currentUserMetadata = useSelector(selectUserMetadata);

    const optionRef = useRef() as RefObject<HTMLDivElement>;
    const {onClickUnfollow, onClickFollow} = useFollowUnfollow();
    const {t} = useTranslation()

    const [deletePostPopup, setDeletePostPopup] = useState<boolean>(false);
    const [showReport, setShowReport] = useState<boolean>(false);
    const [showBlock, setShowBlock] = useState<boolean>(false);
    const [notificationsDisabled, setNotificationsDisabled] = useState<boolean>(false);
    const [followingUser, setFollowingUser] = useState<boolean>();
    const [requesting, setRequesting] = useState<boolean>(false);
    const [showUpdatePostPopup, setShowUpdatePostPopup] = useState<boolean>(false);
    const [moreOptions, setMoreOptions] = useState<boolean>(false);


    const onDeletePost = useCallback(
        async () => {
            try {
                if (!postId) {
                    return
                }
                deletePost(postId, postCreationDate).then((response:any)=>{
                    if(response){
                        toast.success(t<any>('post.deletionSuccess'))
                        setPosts((postsList: PostType[]) => {
                            return postsList.filter((post: PostType) => post._id !== postId)
                        });            
                    }
                    else{
                        toast.error(t<any>('errors.somethingWentWrong'))
                    }
                })
            } catch (error) {
                if (MODE_DEBUG) {
                    console.log('post deleted')
                }
                toast.error(t<any>('post.deletionError'))
            } finally {
                setDeletePostPopup(false)
            }
        },
        [postCreationDate, postId, setPosts, t]
    )

    const onPin = useCallback(() => {
        pinUnpinPost({authorId: post?.Author?.Id ?? "", pin: true, post}, setMoreOptions, setPosts);
    }, [post, setPosts]);
    const onUnpin = useCallback(() => {
        pinUnpinPost({authorId: post?.Author?.Id ?? "", pin: false, post}, setMoreOptions, setPosts);
    }, [post, setPosts]);

    const onReport = async () => {

        if (!userData?.uid || !postId) {
            if (MODE_DEBUG) {
                console.log(`User ID ${userData?.uid} or Post Id ${postId} is falsy`)
            }
            toast.error(t<any>("groups.about.error.somethingWentWrong"))
            return
        }

        const message: string = `Post ID ${postId} is reported by user ID ${userData?.uid}`
        setRequesting(true)
        createSupportTicket({subject: 'Post reported by user', message}).then((response) => {
            if (response === createSupportTicketConstants.requestDelayInfo) {
                toast.warning(t<any>(response))
            } else {
                toast.success(t<any>(response))
            }
            setShowReport(false)
        })
            .catch((err) => {
                toast.error(err)
            })
            .finally(() => {
                setRequesting(false)
            })
    }

    const onBlock = async () => {
        if (userData?.uid && postAuthor) {
            setRequesting(true)
            blockUser(userData?.uid, postAuthor.Id).then(() => {
                toast.success(t<any>('settings.block.sent'));
                setShowBlock(false);
            })
                .catch((err) => {
                    if (MODE_DEBUG) {
                        console.log("Error when calling Block User", err)
                    }
                })
                .finally(() => {
                    setRequesting(false)
                })
        } else {
            toast.error('No user Id found')
        }
    }

    const handelTogglingNotificationsButton = useCallback(() => {
        if (userData?.uid && postAuthor) {
            if (notificationsDisabled) {
                if (!requesting) {
                    setRequesting(true);
                    enableNotificationsForUser(userData?.uid, postAuthor.Id, userProtected?.NotificationOptions?.disabledAuthors ?? []).finally(() => {
                        setRequesting(false);
                    });
                }
            } else {
                if (!requesting) {
                    setRequesting(true);
                    disableNotificationsFromUser(userData?.uid, postAuthor.Id, userProtected?.NotificationOptions?.disabledAuthors ?? []).finally(() => {
                        setRequesting(false);
                    });
                }
            }
        }
    }, [notificationsDisabled, requesting, userData, userProtected, postAuthor]);

    const handleSavingPost = useCallback(() => {
        if (userData?.uid && postId) {
            const isFavorite = userProtected?.FavoriteIdeas?.includes(postId);
            if (isFavorite) {
                unSavePost(userData?.uid, postId)
            } else {
                savePost(userData?.uid, postId)
            }
        }
    }, [postId, userData?.uid, userProtected?.FavoriteIdeas])

    const handleOptionClick = useCallback(
        (option: string) => {
            switch (option) {
                case postOptionConstants.deletePost:
                    setDeletePostPopup(true);
                    break;
                case postOptionConstants.followAuthor:
                    postAuthor && onClickFollow(postAuthor.Id);
                    break;
                case postOptionConstants.unfollowAuthor:
                    postAuthor && onClickUnfollow(postAuthor.Id);
                    break;
                case postOptionConstants.reportPost:
                    setShowReport(true);
                    break;
                case postOptionConstants.blockPost:
                    setShowBlock(true);
                    break;
                case postOptionConstants.turnOnNotification:
                case postOptionConstants.turnOffNotification:
                    handelTogglingNotificationsButton();
                    break;
                case pinPostOption:
                    onPin();
                    break;
                case unpinPostOption:
                    onUnpin();
                    break;
                case postOptionConstants.updatePost:
                    setShowUpdatePostPopup(true);
                    break;
                case postOptionConstants.savePost:
                case postOptionConstants.unSavePost:
                    handleSavingPost();
                    break;
                case postOptionConstants.editPost:
                    if(onEdit){
                        onEdit();
                    }
                    break;
            }
        },
        [handelTogglingNotificationsButton,onEdit, onClickFollow, onClickUnfollow, onPin, onUnpin, postAuthor, handleSavingPost],
    )

    const author = useMemo(() => {
        if (postAuthor && postCreationDate) {
            return <AuthorIdea
                isMessage={false}
                authorData={postAuthor}
                dateIdea={new Date(postCreationDate).getTime()}
                groupId={groupId}
                groupData={groupDetail}
                fullPostId={postId}
            />;
        }
    }, [groupDetail, groupId, postAuthor, postCreationDate, postId]);

    const tags = useMemo(() => {
        return (
            <PostTags
                financialData={postFinancialData}
                category={postCategory}
            />
        )
    }, [postCategory, postFinancialData]);


    const postOption = useMemo(() => {
        let options: any[] = [];
        if (userData?.uid !== postAuthor?.Id) {
            const isFavorite = userProtected?.FavoriteIdeas?.includes(post?._id ?? "");
            options.push(isFavorite ? unSavePostOption : savePostOption)
            if (canPinUnpinPost && inGroup) {
                options.push(post?.PinnedAt ? unpinPostOption : pinPostOption);
                if(moderator){
                    options.push(deletePostOption)
                }
            }
            options.push(...othersPostOptions);
        } else if (userRole === superAdmin) {
            options = Object.keys(postOptions);
        } else {
            options = ownPostOptions;
            if (canPinUnpinPost && inGroup) {
                options = [...ownPostOptions, post?.PinnedAt ? unpinPostOption : pinPostOption];
            }
        }
        return (
            <ContainerWithRef reference={optionRef}>
                <Button onClick={() => {
                    setMoreOptions(!moreOptions);
                }}>{moreOptionsIcon}</Button>
                <OptionsMenu className="options-list" parentRef={optionRef} showMenu={moreOptions} onClose={() => {
                    setMoreOptions(false);
                }}>
                    {
                        options.filter((option) => {
                            const renderNotifications = !(option === postOptionConstants.turnOffNotification && notificationsDisabled) && !(option === postOptionConstants.turnOnNotification && !notificationsDisabled)
                            const renderFollowing = !(option === postOptionConstants.followAuthor && followingUser) && !(option === postOptionConstants.unfollowAuthor && !followingUser)
                            return renderNotifications && renderFollowing;
                        }).map((option) => {
                            return <Option key={option} icon={postOptions[option].icon}
                                           onClick={() => handleOptionClick(option)}>
                                {
                                    <Col className='op-text aifs'>
                                        <h4> {t<any>(postOptions[option].title)}</h4>
                                        <p>{t<any>(postOptions[option].subTitle)}</p>
                                    </Col>
                                }
                            </Option>
                        })
                    }
                </OptionsMenu>
            </ContainerWithRef>
        )
    }, [userData?.uid, postAuthor?.Id, userRole, moreOptions, userProtected?.FavoriteIdeas, post?._id, post?.PinnedAt, canPinUnpinPost, inGroup, moderator, notificationsDisabled, followingUser, t, handleOptionClick])


    useEffect(() => {
        if (userProtected && postAuthor?.Id) {
            setNotificationsDisabled(userProtected?.NotificationOptions?.disabledAuthors?.includes(postAuthor?.Id) ?? false);
        }
    }, [userProtected, postAuthor?.Id]);

    useEffect(() => {
        if (postAuthor) {
            setFollowingUser(
                !!currentUserMetadata?.FollowingUsers?.includes(postAuthor.Id)
            )
        }
    }, [postAuthor, currentUserMetadata?.FollowingUsers])

    return (
        <Col className="post-header">
            <ConfirmationPopup title={t<any>('idea.confirmDelete.title')} message={t<any>('idea.confirmDelete.message')}
                               confirmButtonText={requesting ? <Spinner/> : t<any>('feed.postOptions.delete')}
                               cancelButtonText={t<any>('cancel')} show={deletePostPopup}
                               onConfirmButtonClick={onDeletePost} onCancelButtonClick={() => setDeletePostPopup(false)}
                               onClose={() => setDeletePostPopup(false)}/>
            <ConfirmationPopup title={t<any>('idea.confirmReport.title')} message={t<any>('idea.confirmReport.message')}
                               confirmButtonText={requesting ? <Spinner/> : t<any>('feed.postOptions.report')}
                               cancelButtonText={t<any>('cancel')} show={showReport} onConfirmButtonClick={onReport}
                               onCancelButtonClick={() => setShowReport(false)} onClose={() => setShowReport(false)}/>
            <ConfirmationPopup title={t<any>('settings.block.confirmationTitle', {name: postAuthor?.Name})}
                               message={t<any>('settings.block.confirmationContent')}
                               confirmButtonText={requesting ? <Spinner/> : t<any>('settings.block.title')}
                               cancelButtonText={t<any>('cancel')} show={showBlock} onConfirmButtonClick={onBlock}
                               onCancelButtonClick={() => setShowBlock(false)} onClose={() => setShowBlock(false)}/>
            {showUpdatePostPopup && <UpdatePostPopup
                show={showUpdatePostPopup}
                fullPostId={postId}
                onClose={() => setShowUpdatePostPopup(false)}
                onNewUpdate={onNewUpdate}
            />}
            <Row className="wb-100 jcsb aifs">
                {author}
                {
                    (!isFinancialPopUp&&!isSharedPost) &&
                    <Container className="more-options">
                        {!!postAuthor && postOption}
                    </Container>
                }
            </Row>
            {tags}
        </Col>
    )
}

export default PostHeader;