import { combineReducers } from 'redux';
import auth from './userAuth/user';
import home from './home/home';
import discussion from './discussion/discussion';
import users from './users/users';
import notifications from './notifications/notifications';
import marketPrices from './marketPrices/marketPrices';
import general from './general/general';
import reactions from './reactions/reactions';

const appReducer = combineReducers({
  auth,
  home,
  discussion,
  users,
  notifications,
  marketPrices,
  reactions,
  general
})

const rootReducer = (state:any,action:any) =>{
  if(action.type === 'USER_LOGOUT') {
    return appReducer(undefined,action)
  }
  return appReducer(state, action)
}

export default rootReducer;
