import {collection, getDocs, limit, orderBy, query, startAfter, where} from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import store from "../../redux/store";
import {app, db} from "../auth/auth";
import {giveAwardHttpsCallable, createPaymentIntentHttpsCallable} from "../firebaseFunctions";
import {toast} from "react-toastify";
import {MODE_DEBUG} from "../../../utils/constants/config";

const TRANSACTIONS_PER_PAGE = 25;

const functions = getFunctions(app, 'europe-west3');
const cashoutCoinsCallable = httpsCallable(functions, 'cashoutCoins', {});


export async function getTransactions(nextKey?: any, withdrawals: boolean = false) {
    const state = store.getState()
    const {user}: any = state.auth.data
    const currentUserId = user?.uid;

    const transactionRef = collection(db, 'transactions')

    let lastKey
    let transactionQuery

    if (withdrawals) {
        if (nextKey) {
            transactionQuery = query(transactionRef,
                where('Participants', 'array-contains', currentUserId),
                where('Type', "==", "cashout"),
                orderBy('TransactionTime', 'desc'),
                limit(TRANSACTIONS_PER_PAGE), startAfter(nextKey))
        } else {
            transactionQuery = query(transactionRef,
                where('Participants', 'array-contains', currentUserId),
                where('Type', "==", "cashout"),
                orderBy('TransactionTime', 'desc'),
                limit(TRANSACTIONS_PER_PAGE))
        }
    } else {
        if (nextKey) {
            transactionQuery = query(transactionRef,
                where('Participants', 'array-contains', currentUserId),
                orderBy('TransactionTime', 'desc'),
                limit(TRANSACTIONS_PER_PAGE), startAfter(nextKey))
        } else {
            transactionQuery = query(transactionRef,
                where('Participants', 'array-contains', currentUserId),
                orderBy('TransactionTime', 'desc'),
                limit(TRANSACTIONS_PER_PAGE))
        }
    }

    const transactionDocs = await getDocs(transactionQuery)

    const userTransactions = transactionDocs.docs.map((doc: any) => {
        lastKey = doc.data().TransactionTime;

        return {
            ...doc.data(),
            Id: doc.id,
        };
    });

    // Say we reached the end
    if (transactionDocs.size < TRANSACTIONS_PER_PAGE) {
        lastKey = false;
    }

    return {userTransactions, lastKey};
}


export const sendCashoutRequest = async ({amount, cashoutAccount}: any) => {
    return await cashoutCoinsCallable({
        type: "paypal",
        amount,
        cashoutAccount
    })
}

export const getLastPaymentEmail = async ({type}: { type: string }) => {
    const state = store.getState()
    const {user}: any = state.auth.data
    const currentUserId = user?.uid;
    const cashoutAccountsRef = collection(db, 'users-protected', currentUserId, 'cashout-accounts')
    const paymentDetailsQuery = query(cashoutAccountsRef, where("Type", "==", type), orderBy("CreatedTime", "desc"), limit(1))

    return await getDocs(paymentDetailsQuery)
}

export async function giveAwardOnPost(fullPostId: string, awardName: string) {
    const data = {fullPostId, award: awardName};
    return giveAwardHttpsCallable(data)
        .catch(err => {
            if (MODE_DEBUG) {
                console.log(err.message);
            }
            toast.error(`Something went wrong, ${err.message}`);
            throw err;
        });
}

export async function getPostAwards(fullPostId: string, type: string) {
    const [authorId, postId] = fullPostId.split('/');

    const awardsCollection = collection(db, "users", authorId, "posts", postId, "awards");
    let awardsQuery = query(awardsCollection, where('Type', '==', type), orderBy('Time', 'desc'));

    const awardsDocs = await getDocs(awardsQuery);

    // Store the attached user data
    return awardsDocs.docs
        .map(docSnapshot => {
            const data = docSnapshot.data();
            const res = data?.Author;

            if (!res) {
                return null;
            }

            if (data?.Time?.toDate) {
                res._date = data.Time.toDate();
            }

            return res;
        })
        .filter(v => !!v);
}

export const createPaymentIntent = async (coinPackId: string) => {

    return await createPaymentIntentHttpsCallable({
        coinPackId,
    });
};