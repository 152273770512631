import clsx from "clsx";
import {images} from "../../assets/images/images";
import { useMemo } from "react";

export const Picture = ({
                            className,
                            src,
                            alt = "",
                            hideIfError = false,
                            defaultImageIfError = "",
                            styles
                        }: {
    className?: string;
    alt: string;
    src: string;
    styles?: object;
    hideIfError?: boolean;
    defaultImageIfError?: string;
}) => {
    return useMemo(() => <img style={styles} className={clsx(className)} src={src} alt={alt} onError={(event) => {
        if (defaultImageIfError) {
            event.currentTarget.src = images.defaultAssetIcon;
        } else if (hideIfError) {
            event.currentTarget.style.display = 'none';
        }
    }}/>, [alt, className, defaultImageIfError, hideIfError, src, styles])
    
};
