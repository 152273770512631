import {FC} from "react";
import clsx from "clsx";

const Grid: FC<{ className?: string, children: any }> = ({className, children}) => {
    return (
        <div className={clsx("grid", className)}>
            {children}
        </div>
    )
}

export default Grid;