import clsx from "clsx";

export const Container = ({
                              children,
                              className,
                              onClick,
                          }: {
    children?: any;
    className?: string;
    onClick?: () => void;
}) => <div onClick={onClick} className={clsx('flex aic jcc', className)}>{children}</div>;

export const FormContainer = ({
                                  children,
                                  className,
                                  onSubmit
                              }: {
    children: React.ReactNode;
    className?: string;
    onSubmit?: () => void;
}) => <form onSubmit={onSubmit} className={clsx('flex aic jcc', className)}>{children}</form>

export const ContainerWithRef = ({
                                     children,
                                     className,
                                     reference
                                 }: {
    children?: any;
    className?: string;
    reference: any,

}) => <div ref={reference} className={clsx('flex aic jcc', className)}>{children}</div>;

export const Wrapper = ({
                            children,
                            className,
                            onClick,
                        }: {
    children: any;
    className?: string;
    onClick?: any;
}) => (
    <div className={clsx(className)} onClick={onClick}>
        {children}
    </div>
);

export const Row = ({
                        children,
                        className,
                    }: {
    children: any;
    className?: string;
}) => <div className={clsx("flex row jcc aic", className)}>{children}</div>;

export const Col = ({
                        children,
                        className,
                        onClick,
                        id
                    }: {
    children: any;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
    id?: string

}) => <div id={id} className={clsx('flex fdc aic jcc', className)} onClick={onClick}>{children}</div>;