import React,{FC, useState} from "react";
import Modal from "../Modal/Modal";
import clsx from "clsx";
import {Row} from "../Container";
import {closeIcon} from "../../../assets/images/svgIcons/svgIcons";
import {Button} from "../Button";
import "./ConfirmationPopup.scss";
import {Spinner} from "../Loading";

type ConfirmationPopupParamsType = {
    title: string,
    message: string,
    confirmButtonText: string,
    onConfirmButtonClick: () => Promise<void>,
    cancelButtonText: string,
    onCancelButtonClick: () => void,
    show: boolean,
    onClose: any,
    className?: string,
};

const ConfirmationPopup: FC<ConfirmationPopupParamsType> = ({
                                                                title,
                                                                message,
                                                                confirmButtonText,
                                                                onConfirmButtonClick,
                                                                cancelButtonText,
                                                                onCancelButtonClick,
                                                                show,
                                                                onClose,
                                                                className,
                                                            }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <Modal show={show} onClose={onClose} className={clsx('confirmation-popup', className)}>
            <Row className='cp-header'>
                <p>{title}</p>
                <Button className='flex aic jcc text-btn cp-close-container' onClick={onClose}>
                    {closeIcon}
                </Button>
            </Row>
            <p className='description-text cp-message'>{message}</p>
            <Button className='gw-btn  cp-confirm-btn' onClick={() => {
                setIsLoading(true);
                onConfirmButtonClick().then().finally(() => {
                    setIsLoading(false);
                });
            }}>{isLoading ? <Spinner/> : confirmButtonText}</Button>
            <Button className='text-btn cp-cancel-btn' onClick={onCancelButtonClick}>{cancelButtonText}</Button>
        </Modal>
    )
}

export default ConfirmationPopup;