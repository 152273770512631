import React, {FC, useCallback, useEffect} from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import clsx from "clsx";
import {Col} from "../Container";


export type ModalPropsType = { show: boolean, onClose: any, className?: string, children: any, header?: any };

const Modal: FC<ModalPropsType> = ({
                                       show,
                                       onClose,
                                       className,
                                       children,
                                       header
                                   }) => {
    const closeOnEscapeKeyDown = useCallback((e: any) => {
        if ((e.charCode || e.keyCode) === 27) {
            onClose();
        }
    }, [onClose]);


    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, [closeOnEscapeKeyDown]);

    const modalRoot = document.getElementById("modal-root") as HTMLElement;

    return ReactDOM.createPortal(<>
            {show && <div className="modal" onClick={onClose}>
                <Col className={clsx("aifs jcfs modal-content", className)} onClick={e => e.stopPropagation()}>
                    <Col className="modal-header aic jcsb">{header}</Col>
                    <Col className="modal-body aic jcsb">{children}</Col>
                </Col>
            </div>}
        </>,
        modalRoot,
    );
};

export default Modal;
