import {FC, useCallback, useMemo, useState} from "react";
import {getValue} from "firebase/remote-config";
import {remoteConfig} from "../../../../services/firebase/auth/auth";
import Modal from "../../../core/Modal/Modal";
import {Col, Container, Row} from "../../../core/Container";
import {Button} from "../../../core/Button";
import {aryaCoin, closePopupIcon} from "../../../../assets/images/svgIcons/svgIcons";
import {AwardType} from "../../../../types/wallet.types";
import Grid from "../../../core/Grid";
import {Picture} from "../../../core/Picture";
import {priceFormat} from "../../../../utils/helpers";
import {FIRESTORE_COINS_MULTIPLIER} from "../../../../utils/constants/prices";
import "./GiveAwardPopup.scss";
import {awardsImages} from "../../../../assets/images/awards";
import {useSelector} from "react-redux";
import {selectUserWallet} from "../../../../services/redux/reducers/userAuth/user";
import {awardsData} from "../../../../utils/constants/wallet";
import {Spinner} from "../../../core/Loading";
import {giveAwardOnPost} from "../../../../services/firebase/coins/coins";
import {PostType} from "../../../../types/post.types";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const GiveAwardPopup: FC<{ show: boolean, onClose: () => void, post?: PostType }> = ({show, onClose, post}) => {

    const walletData = useSelector(selectUserWallet)
    const {t} = useTranslation();


    const [selectedAward, setSelectedAward] = useState<AwardType>();
    const [isGivingAward, setIsGivingAward] = useState<boolean>(false);
    const [givingAwardError, setGivingAwardError] = useState<string>("");

    const onGiveAward = useCallback(() => {
        if (selectedAward?.value && selectedAward.name && walletData?.Balance) {
            setIsGivingAward(true);
            const hasEnoughBalance = walletData.Balance >= selectedAward.value;
            if (hasEnoughBalance) {
                giveAwardOnPost(post?._id ?? "", selectedAward.name).then(() => {
                    toast.success(t<any>("idea.awardsPopup.awardedSuccessfully"));
                    onClose();
                }).finally(() => {
                    setIsGivingAward(false);
                });
            } else {
                setGivingAwardError(t<any>("idea.awardsPopup.youDontHaveEnoughBalance"));
                setIsGivingAward(false);
            }
        }
    }, [selectedAward, walletData, onClose, post?._id, t])

    const awardListItem = useCallback((award: AwardType) => {
        if (award.name) {
            const selected = award.name === selectedAward?.name;
            return (
                <Button
                    className="text-btn"
                    onClick={() => {
                        setSelectedAward(award)
                    }}
                    disabled={isGivingAward}
                    key={award.name}
                >
                    <Col className={`jcsb gap-award-container ${selected && "gap-award-selected"}`}>
                        <Picture src={awardsImages[award.name]} alt=""></Picture>
                        <h4 className="arya-coin-text">{priceFormat((award?.value ?? 0) / FIRESTORE_COINS_MULTIPLIER)}</h4>
                    </Col>
                </Button>
            )
        }
    }, [selectedAward, isGivingAward]);

    const awardsList = useMemo(() => {
        const postsAwards = JSON.parse(getValue(remoteConfig, "posts_awards").asString());
        const awards = [];
        if (postsAwards.awards && Array.isArray(postsAwards.awards)) {
            awards.push(...postsAwards.awards);
        }

        return (
            <Grid className="gap-awards-grid">
                {awards.map((award) => awardListItem(award))}
            </Grid>
        )
    }, [awardListItem]);

    return (
        <Modal show={show} onClose={(!isGivingAward) ? onClose : null} className="flex jcc aifs give-award-popup">
            <Row className="jcsb gap-header">
                <Container/>
                <Button className="text-btn" onClick={onClose} disabled={isGivingAward}>{closePopupIcon}</Button>
            </Row>
            <h2>Choose a reward</h2>
            <Col className="jcfs gap-content">
                {awardsList}
            </Col>
            <Col className="jcsb gap-footer">
                <Row className="wb-100 jcsb gap-available-coins">
                    <Row className="gap-footer-row">
                        {aryaCoin}
                        <p>{t<any>("idea.awardsPopup.availableCoins")}</p>
                        <h3 className="arya-coin-text">
                            {priceFormat((walletData?.Balance??0) / FIRESTORE_COINS_MULTIPLIER)}
                        </h3>
                    </Row>
                    <Row className="gap-footer-row">
                        <Button className="green-outlined-btn" disabled={isGivingAward}>
                            {t<any>("idea.awardsPopup.buyMore")}
                        </Button>
                        <Button className="text-btn what-are-arya-coins-btn"
                                disabled={isGivingAward}>{t<any>("idea.awardsPopup.whatAreARYACoins")}</Button>
                    </Row>
                </Row>

                {selectedAward &&
                    <Row className="wb-100 jcsb gap-selected-award">
                        <Row className="gap-selected-award-details">
                            <Picture src={awardsImages[selectedAward.name ?? '']} alt=""
                                     className="gap-award-img"></Picture>
                            <Col className="aifs">
                                <Row className="wb-100 jcfs gap-award-name-container">
                                    <h4 className="gap-award-name">{t<any>(awardsData[selectedAward?.name ?? '']?.name ?? "")}</h4>
                                    {aryaCoin}
                                    <p className="arya-coin-text">{priceFormat((selectedAward.value ?? 0) / FIRESTORE_COINS_MULTIPLIER)}</p>
                                </Row>
                                <p>{t<any>(awardsData[selectedAward?.name ?? ''].description)}</p>
                            </Col>
                        </Row>
                        {isGivingAward ?
                            <Container className="gap-spinner-container">
                                <Spinner/>
                            </Container>
                            : <Button className="bw-btn" onClick={onGiveAward} disabled={isGivingAward}>Give
                                Award</Button>
                        }
                    </Row>}

                {givingAwardError ?
                    <p className="input-error">{givingAwardError}</p>
                    : <></>}
            </Col>
        </Modal>
    )
}

export default GiveAwardPopup;