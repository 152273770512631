import firebase from "firebase/compat/app";
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
    signOut,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithPhoneNumber,
    PhoneAuthProvider,
    updatePassword,
    User,
    updatePhoneNumber
} from "firebase/auth";
import {
    doc,
    getDoc,
    getFirestore,
    setDoc,
    deleteField
} from "firebase/firestore";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {firebaseConfig} from "../config";
import {authErrorHandler} from "./helpers";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {updateDoc} from "@firebase/firestore";
import store from "../../redux/store";
import {fetchAndActivate, getRemoteConfig} from "firebase/remote-config";


let emulatorHost: any = null;
let emulatorPort: any = null;

export function setEmulatorHost(value: any) {
    emulatorHost = value;
}

export function setEmulatorPort(value:any){
    emulatorPort = value;
}

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);

/**
 *
 * @param name
 * @param region
 * @return FirebaseFunctionsTypes.HttpsCallable
 */
export function getHttpsCallable(name: string, region = 'europe-west3') {
    const functions = app.functions(region)
    if (MODE_DEBUG && emulatorHost && emulatorPort) {
        functions.useEmulator(emulatorHost,emulatorPort);
    }
    return functions.httpsCallable(name);
}

fetchAndActivate(remoteConfig)
    .then((fetched) => {
        if (MODE_DEBUG) {
            if (fetched) {
                console.log("Successfully fetched remote config");
            }
        }
    })
    .catch((error) => {
        if (MODE_DEBUG) {
            console.error("Error while fetching remote config");
            console.error(error);
        }
    });


const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');


declare global {
    interface Window {
        recaptchaVerifier?: RecaptchaVerifier
        confirmationResult?: any
    }
}


export {auth, db, storage, app, remoteConfig}

export const googleAuth = async () => {

    try {
        await signInWithPopup(auth, provider)
    } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        if (MODE_DEBUG) {
            console.log(errorCode, errorMessage, email, credential)
        }
        throw Error(authErrorHandler(error))
    }
}

export const appleAuth = async () => {
    try {

        const result = await signInWithPopup(auth, appleProvider)
        const user = result.user;
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential?.accessToken;
        const idToken = credential?.idToken;
        if (MODE_DEBUG) {
            console.log(user, accessToken, idToken, credential)
        }

    } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = OAuthProvider.credentialFromError(error);
        if (MODE_DEBUG) {
            console.log(errorCode, errorMessage, email, credential)
        }
        throw Error(authErrorHandler(error))

    }
}
export const signOutUser = async () => {
    await signOut(auth).then(() => {
        store.dispatch({type: 'USER_LOGOUT'});
    }).catch((error) => {
        if (MODE_DEBUG) {
            console.log(error);
        }
    });
}

export const registerUser = async (user: any) => {
    const {email, password} = user

    try {
        await createUserWithEmailAndPassword(auth, email, password)
    } catch (error: any) {
        if (MODE_DEBUG) {
            console.log(error.code)
        }
        throw Error(authErrorHandler(error))
    }
}

export const loginUserEmailPassword = async (user: any) => {
    const {email, password} = user

    try {
        await signInWithEmailAndPassword(auth, email, password)

    } catch (error: any) {
        throw Error(authErrorHandler(error))
    }
}

export const resetPassword = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email)

    } catch (error: any) {
        throw Error(authErrorHandler(error))
    }
}

export const updateUserPassword = async (user: User, newPassword: string) => {
    try {
        await updatePassword(user, newPassword)
    } catch (error: any) {
        throw Error(authErrorHandler(error))
    }
}

export const initCaptcha = (element: string) => {
    return window.recaptchaVerifier = new RecaptchaVerifier(element, {
        'size': 'invisible',
        'callback': (response: any) => {
        }
    }, auth);

}

export const verifyPhoneNumber = async (appVerifier: RecaptchaVerifier, phoneNumber: string) => {
    try {
        await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                return confirmationResult
            })
    } catch (error) {
        console.error(error);
        throw Error(authErrorHandler(error))
    }
}

export const applyPhoneOTP = async (otp: string, user: any, valuePhone: string) => {

    const authCredential = PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        otp,
    );
    const userAuth: any = auth.currentUser
    await updatePhoneNumber(userAuth, authCredential).then(() => {
        addPhoneNumber(valuePhone)
    }).catch((error) => {
        if (MODE_DEBUG) {
            console.log(error)
        }
        throw Error(authErrorHandler(error))
    })
}

export const addPhoneNumber = async (phoneNumber: string) => {
    const userAuth: any = auth.currentUser
    const docRef = doc(db, "users-protected", userAuth?.uid);
    const res = await setDoc(docRef, {
        PhoneNumber: phoneNumber,
    }, {
        merge: true
    })
    if (MODE_DEBUG) {
        console.log(res)
    }
}


export const mapUserInfos = async (uid: string) => {

    const docRef = doc(db, "users", uid,);
    const user = await getDoc(docRef)

    if (user.exists()) {
        return {Id: uid, ...user.data()}

    } else {
        if (MODE_DEBUG) {
            console.log("No such document!");
        }
    }
    return user
}

export const ensureUserPictureUrl = async (user: any, userId: string) => {
    if (!user?.PictureDate) return;
    if (user?.PictureDate) {
        const path = `profile-images/${userId}`;
        const storageRef = ref(storage, path);
        const imageUrl = await getDownloadURL(storageRef);
        return imageUrl
    }
}


export const editUserInfo = async (displayName: string, job: string, description: string) => {
    if ((displayName ?? '') !== '') {
        const userAuth: any = auth.currentUser
        const docRef = doc(db, "users", userAuth?.uid);
        const data: any = {
            'DisplayName': displayName,
        };
        if ((job ?? '') !== '') {
            data['ProfileData.Job'] = job;
        } else {
            data['ProfileData.Job'] = deleteField();
        }
        if ((description ?? '') !== '') {
            data['ProfileData.Description'] = description;
        } else {
            data['ProfileData.Description'] = deleteField();
        }

        return await updateDoc(docRef, data);
    }
}

export const changeUserLanguage = async (newLanguage: string) => {
    if (newLanguage) {
        const userAuth: any = auth.currentUser;
        const docRef = doc(db, "users", userAuth?.uid);
        const data: any = {
            "DefaultLanguage": newLanguage
        };
        return await updateDoc(docRef, data);
    }
}


export const verifyCurrentUserPassword = async (password: string) => {
    try {
        if (auth.currentUser?.email) {
            return await signInWithEmailAndPassword(auth, auth.currentUser?.email, password)
                .then((userC) => {
                    return true;
                }).catch(() => {
                    return false;
                });
        } else {
            if (MODE_DEBUG) {
                console.error("No Email");
            }
        }
    } catch (error) {
        if (MODE_DEBUG) {
            console.error(error);
        }
        throw Error(authErrorHandler(error))
    }
    return false;
}

