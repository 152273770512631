import {FC, useMemo, useState} from "react";
import ModalWithImage from "../../../core/Modal/ModalWithImage/ModalWithImage";
import {Col, Row} from "../../../core/Container";
import {Spinner} from "../../../core/Loading";
import {priceFormat} from "../../../../utils/helpers";
import {useTranslation} from "react-i18next";
import {loadStripe} from "@stripe/stripe-js";
import {MODE_DEBUG, STRIP_PUBLISHABLE_API_KEY} from "../../../../utils/constants/config";
import {ARYACoinToUSDRation, FIRESTORE_COINS_MULTIPLIER} from "../../../../utils/constants/prices";
import {CoinProductType} from "../../../../types/wallet.types";
import {Elements} from "@stripe/react-stripe-js";
import BuyARYACoinsForm from "../BuyARYACoinsForm/BuyARYACoinsForm";
import {getValue} from "firebase/remote-config";
import {remoteConfig} from "../../../../services/firebase/auth/auth";
import {Button} from "../../../core/Button";
import "./ARYACoinsPopup.scss";
import Grid from "../../../core/Grid";
import {createPaymentIntent} from "../../../../services/firebase/coins/coins";

const ARYACoinsPopup: FC<{ show: boolean, onClose: () => void }> = ({show, onClose}) => {

    const {t} = useTranslation();

    const stripePromise = loadStripe(STRIP_PUBLISHABLE_API_KEY ?? "");

    const [clientSecret, setClientSecret] = useState<string>("");
    const [showForm, setShowForm] = useState<boolean>(false);
    const [paymentSucceeded, setPaymentSucceeded] = useState<boolean>(false);
    const [isLoadingClientSecret, setIsLoadingClientSecret] = useState<boolean>(false);
    const [coinPack, setCoinPack] = useState<CoinProductType | null>(() => {
        try {
            const packages: CoinProductType[] = JSON.parse(getValue(remoteConfig, "coin_products").asString());
            return packages[0];
        } catch (e) {
            if (MODE_DEBUG) {
                console.error("Error while setting default coinPack in ARYACoinsPopup", e);
            }
            return null
        }
    });


    const paymentElements = useMemo(() => {
        if (coinPack) {
            return (
                <Elements
                    options={{
                        clientSecret,
                        appearance: {theme: "stripe"},
                    }}
                    stripe={stripePromise}
                >
                    <BuyARYACoinsForm
                        onSubmit={(paymentIntent) => {
                            if (paymentIntent?.status === "succeeded") {
                                setPaymentSucceeded(true);
                            }
                        }}
                        coinPack={coinPack}
                        paymentSucceeded={paymentSucceeded}
                    />
                </Elements>
            )
        }
    }, [clientSecret, coinPack, paymentSucceeded, stripePromise]);


    const packagesList = useMemo(() => {

        const packages: CoinProductType[] = JSON.parse(getValue(remoteConfig, "coin_products").asString());

        return packages.map((coinProduct: CoinProductType) => {
            const coinProductAmount = coinProduct.amount / FIRESTORE_COINS_MULTIPLIER;
            const originalUSDAmount = coinProductAmount * ARYACoinToUSDRation;
            let USDAmount = originalUSDAmount;
            if (coinProduct.discount) {
                USDAmount -= USDAmount * coinProduct.discount / 100;
            }
            const hasDiscount = USDAmount !== originalUSDAmount;
            return (
                <Button
                    key={coinProduct.id}
                    className={`flex fdc aic jcc wb-100 text-btn acp-package ${(coinProduct.id === coinPack?.id) ? 'acp-selected-package' : ''}`}
                    onClick={() => {
                        setCoinPack(coinProduct);
                    }}>
                    <h3 className="arya-coin-text acp-coins-amount">{priceFormat(coinProductAmount)}</h3>
                    <h6 className="arya-coin-text">{t<any>("wallet.coins")}</h6>
                    <h6 className={`description-text acp-usd-value ${hasDiscount ? 'acc-arya-coin-discount' : ''}`}>
                        ${priceFormat(USDAmount)} {(hasDiscount) &&
                        <span>${priceFormat(originalUSDAmount)}</span>}
                    </h6>
                </Button>
            )
        });
    }, [coinPack?.id, t]);

    const content = useMemo(() => {
        if (showForm) {
            const coinProductAmount = (coinPack?.amount ?? 0) / FIRESTORE_COINS_MULTIPLIER;
            return (
                <>
                    {!paymentSucceeded ? <Row className="acp-header">
                            {t<any>("checkout.buyARYACoins", {formattedCoinsCount: priceFormat(coinProductAmount)})}
                        </Row>
                        : <></>
                    }
                    <Col className='acp-content aifs'>
                        {paymentElements}
                    </Col>
                </>
            );
        } else {
            return (
                <>
                    <Row className="acp-header">
                        <h3>{t<any>("wallet.buyARYACoins")}</h3>
                    </Row>
                    <Col className='acp-content aifs'>
                        <Col className="wb-100 acp-packages-wrapper">
                            <Grid className="acp-packages-grid">
                                {packagesList}
                            </Grid>
                            <Button
                                className="gw-btn acp-complete-purchase-btn"
                                onClick={() => {
                                    if (coinPack) {
                                        setIsLoadingClientSecret(true);
                                        createPaymentIntent(coinPack.id).then((res) => {
                                            const data = res.data as any;
                                            setClientSecret(data.clientSecret);
                                            setShowForm(true);
                                        }).finally(() => {
                                            setIsLoadingClientSecret(false);
                                        })
                                    }
                                }}
                                disabled={isLoadingClientSecret}
                            >
                                {isLoadingClientSecret ? <Spinner/> : t<any>("wallet.selectPackage")}
                            </Button>
                        </Col>
                    </Col>
                </>
            );
        }
    }, [coinPack, isLoadingClientSecret, packagesList, paymentElements, paymentSucceeded, showForm, t]);

    return useMemo(() => {
        return (
            <ModalWithImage
                show={show}
                onClose={onClose}
                className="flex fdc arya-coins-popup"
                onGoBack={showForm ? () => {
                    setShowForm(false);
                    setIsLoadingClientSecret(false);
                    setPaymentSucceeded(false);
                    setClientSecret("");
                } : undefined}
            >
                {content}
            </ModalWithImage>
        )
    }, [content, onClose, show, showForm]);
}

export default ARYACoinsPopup;