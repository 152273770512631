import React, { MouseEventHandler } from 'react'
import { t } from 'i18next'
import { Col } from '../../core/Container'
import './ChatConfirmation.scss'
import OutsideAlerter from '../../core/OutsideAlerter'


const ChatConfirmation = ({confirmationData,confirmFn,resetFn}:{confirmationData:any,confirmFn:MouseEventHandler<HTMLButtonElement>,resetFn:MouseEventHandler<HTMLButtonElement>}) => {
  return (
    <Col className='setting-confirmation-wrapper'>
        <OutsideAlerter fn={resetFn}>
          <Col className='setting-confirmation'>
            <span className='setting-confirmation-heading'>{confirmationData.heading}</span>
            <p>{confirmationData.text}</p>
            <Col className='confirmation-actions'>
              <button onClick={confirmFn} className='confirm-action'>{confirmationData.buttonText}</button>
              <button onClick={resetFn} className='cancel-action'>{t<any>('chat.cancel')}</button>
            </Col>
          </Col>
        </OutsideAlerter>
    </Col>
  )
}

export default ChatConfirmation