import {FC, useCallback, useEffect, useRef, useState} from "react";
import {
    createChart,
    CrosshairMode,
    LineStyle,
    SeriesMarkerPosition,
    SeriesMarkerShape
} from 'lightweight-charts';
import {ContainerWithRef} from "../../Container";
import {getAnalyticsTimeSeries} from "../../../../services/firebase/charts/charts";
import {IFinancialData, IPriceEvaluations} from "../../../../types/idea.types";
import {getIsIdeaActive} from "../../../../helpers/idea/idea.helpers";
import {dayInMilliseconds, ideaTimeframeInDays, pricePointsShortForm} from "../../../../utils/constants/financialData";
import "./IdeaChart.scss";
import {shortNumberFormat} from "../../../../utils/helpers";
import {Spinner} from "../../Loading";
import {FinancialDataTimeFrames} from "../../../../utils/constants/idea";


const IdeaChart: FC<{ financialData: IFinancialData, ideaCreationTime: Date }> = ({
                                                                                      financialData,
                                                                                      ideaCreationTime
                                                                                  }) => {

    const chartContainerRef = useRef<HTMLDivElement>();


    const [chartData, setChartData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isIdeaActive] = useState<boolean>(() => {
        if (financialData.Timeframe) {
            return getIsIdeaActive(ideaCreationTime, financialData.Timeframe);
        }
        return false;
    });


    const getIdeaExpirationDateOffset = useCallback(() => {
        let expirationDate = 0;

        if (financialData?.PriceEvaluations?.length) {
            financialData?.PriceEvaluations.forEach((PriceEvaluation) => {
                if (PriceEvaluation.ReachedAt) {
                    const reachedAt = new Date(PriceEvaluation.ReachedAt);
                    if (reachedAt.getTime() > expirationDate) {
                        expirationDate = reachedAt.getTime();
                    }
                }
            });
        }

        let expiryDateOffset = ideaTimeframeInDays[financialData?.Timeframe ?? FinancialDataTimeFrames.long] * dayInMilliseconds; // in milliseconds
        const timeframeExpirationDate = ideaCreationTime.getTime() + expiryDateOffset

        if (expirationDate === 0) {
            expirationDate = timeframeExpirationDate;
        }

        return expirationDate - ideaCreationTime.getTime();

    }, [ideaCreationTime, financialData]);


    useEffect(() => {
        const marketItem = financialData?.Instrument?.MarketItem;
        const formattedMarketItem = {
            key: marketItem?.Key,
            symbol: marketItem?.Symbol,
            market: marketItem?.Market,
            to: marketItem?.To,
        };
        const expiryDateOffset = getIdeaExpirationDateOffset(); // in milliseconds
        const now = new Date().getTime();
        const from = ideaCreationTime.getTime() - dayInMilliseconds * 2;
        const to = isIdeaActive ? now : (ideaCreationTime.getTime() + (expiryDateOffset + dayInMilliseconds));
        setLoading(true);
        getAnalyticsTimeSeries([formattedMarketItem], from / 1000, to / 1000, financialData.Timeframe ?? FinancialDataTimeFrames.long).then(res => {
            const data = res.data as any;
            if (data?.resolved) {
                if (Object.hasOwn(data?.resolved, formattedMarketItem?.key ?? "")) {
                    setChartData(data?.resolved[formattedMarketItem?.key ?? ""]?.bars);
                }
            }
            setLoading(false);
        });
    }, [financialData, isIdeaActive, getIdeaExpirationDateOffset, ideaCreationTime]);


    useEffect(
        () => {
            if (!chartContainerRef?.current || !chartData) {
                return;
            }

            const handleResize = () => {
                chart.applyOptions({width: chartContainerRef?.current?.clientWidth});
            };

            const chart = createChart(chartContainerRef?.current, {
                width: chartContainerRef?.current?.clientWidth,
                height: chartContainerRef?.current?.clientHeight,
                layout: {
                    backgroundColor: '#000000',
                    textColor: 'rgba(255, 255, 255, 0.9)',
                    fontSize: 12,
                },
                grid: {
                    vertLines: {
                        color: 'rgba(197, 203, 206, 0.5)',
                    },
                    horzLines: {
                        color: 'rgba(197, 203, 206, 0.5)',
                    },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                rightPriceScale: {
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                    autoScale: false,
                },
                timeScale: {
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                    visible: true,
                    timeVisible: true,
                    secondsVisible: false,
                },
            });

            chart.timeScale().fitContent();

            const series = chart.addCandlestickSeries({
                upColor: 'rgb(38,166,154)',
                downColor: 'rgb(255,82,82)',
                wickUpColor: 'rgb(38,166,154)',
                wickDownColor: 'rgb(255,82,82)',
                borderVisible: false,
                priceLineVisible: false,
                lastValueVisible: isIdeaActive,
            });

            series.setData(chartData);
            const markers: any[] = [];
            const reachedPriceEvaluationsTypes: string[] = [];

            // Markers

            // EntryValue marker
            if (financialData.EntryValue || financialData.EntryValue === 0) {
                if (!(financialData?.PriceEvaluations?.find(priceEvaluation => priceEvaluation.Type === 'EntryValue') ?? false)) {
                    reachedPriceEvaluationsTypes.push('EntryValue');
                    markers.push({
                        time: ideaCreationTime.toISOString(),
                        position: 'aboveBar',
                        color: 'white',
                        shape: 'arrowDown',
                        text: `${pricePointsShortForm['EntryValue']} @ ` + shortNumberFormat(financialData.EntryValue)
                    })
                }
            }

            // PriceEvaluations markers
            if (financialData.PriceEvaluations) {
                financialData.PriceEvaluations.forEach((priceEvaluation: IPriceEvaluations) => {
                    if (priceEvaluation.ReachedAt && priceEvaluation.Type) {
                        reachedPriceEvaluationsTypes.push(priceEvaluation.Type);
                        let color = 'white';
                        let shape = 'arrowDown' as SeriesMarkerShape;
                        let position = 'aboveBar' as SeriesMarkerPosition;
                        if (priceEvaluation.Type === 'StopLoss') {
                            shape = 'arrowUp';
                            position = 'belowBar';
                        }
                        markers.push({
                            time: priceEvaluation.ReachedAt,
                            position,
                            color,
                            shape,
                            text: `${pricePointsShortForm[priceEvaluation.Type]} @ ` + shortNumberFormat(priceEvaluation.Price)
                        });
                    }
                });
            }

            series.setMarkers(markers);


            // Price lines
            if (financialData.EntryValue) {
                if (!reachedPriceEvaluationsTypes.includes('EntryValue')) {
                    series.createPriceLine({
                        price: financialData.EntryValue,
                        title: pricePointsShortForm['EntryValue'],
                        axisLabelVisible: true,
                        color: '#578DF5',
                        lineWidth: 1,
                        lineStyle: LineStyle.Solid,
                        lineVisible: true,
                    })
                }
            }

            if (financialData.BreakEven) {
                if (!reachedPriceEvaluationsTypes.includes('BreakEven')) {
                    series.createPriceLine({
                        price: financialData.BreakEven,
                        title: pricePointsShortForm['BreakEven'],
                        axisLabelVisible: true,
                        color: '#89939F',
                        lineWidth: 1,
                        lineStyle: LineStyle.Solid,
                        lineVisible: true,
                    })
                }
            }

            if (financialData.StopLoss) {
                if (!reachedPriceEvaluationsTypes.includes('StopLoss')) {
                    series.createPriceLine({
                        price: financialData.StopLoss,
                        title: pricePointsShortForm['StopLoss'],
                        axisLabelVisible: true,
                        color: '#D4062E',
                        lineWidth: 1,
                        lineStyle: LineStyle.Solid,
                        lineVisible: true,
                    })
                }
            }

            if (financialData.TakeProfits) {
                financialData.TakeProfits.forEach((takeProfitPoint, index) => {
                    if (takeProfitPoint.Value) {
                        if (!reachedPriceEvaluationsTypes.includes(`TakeProfit_${index + 1}`)) {
                            series.createPriceLine({
                                price: takeProfitPoint.Value,
                                title: `TP${index + 1}`,
                                axisLabelVisible: true,
                                color: '#02A383',
                                lineWidth: 1,
                                lineStyle: LineStyle.Solid,
                                lineVisible: true,
                            })
                        }
                    }
                });
            }


            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [chartData, financialData, ideaCreationTime, isIdeaActive]
    );

    return (
        <>
            {
                chartData ?
                    <ContainerWithRef
                        reference={chartContainerRef}
                        className='idea-chart-container'
                    >
                        {/*{isIdeaActive ?*/}
                        {/*    <Row className='active-idea-chart'>*/}
                        {/*        <Container className='blink'>{dotIcon}</Container> <p>Active Idea</p>*/}
                        {/*    </Row>*/}
                        {/*    : <Row className='active-idea-chart not-active-idea-chart'>*/}
                        {/*        {dotIcon} <p>Inactive Idea</p>*/}
                        {/*    </Row>}*/}
                    </ContainerWithRef>
                    : loading ? <Spinner></Spinner> : <></>
            }
        </>
    );
}

export default IdeaChart;