import { useCallback, useEffect, useMemo, useState } from 'react'
import { Col } from '../../core/Container'
import ChatInput from '../ChatInput/ChatInput'
import MessageDisplay from '../MessageDisplay/MessageDisplay'
import './ChatBox.scss'
import { addMessageListener, stopListeningMessages } from '../../../services/realtime/realtimeChannel'
import { Spinner } from '../../core/Loading'
import { IFlattenedMessage, IMessage } from '../../../types/discussion.types'
import { MODE_DEBUG } from '../../../utils/constants/config'
import { createMessage, updateMessage } from '../../../services/firebase/discussions/messages'
import { db } from '../../../services/firebase/auth/auth'
import { doc, getDoc } from 'firebase/firestore'
import { createDiscussion, setLastViewedDateForDiscussion } from '../../../services/firebase/discussions/discussions'
import { useDispatch, useSelector } from 'react-redux'
import { selectSingleDiscussion, setOpenDiscussion } from '../../../services/redux/reducers/discussion/discussion'
import InviteScreen from './InviteScreen/InviteScreen'
import { selectUserMetadata } from '../../../services/redux/reducers/userAuth/user'



const ChatBox = ({discussionId, openDiscussion }:any) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [reply, setReply] = useState<IFlattenedMessage|boolean>()
  const [messageToEdit, setMessageToEdit] = useState<IMessage|null>(null)
  const [newChat,setNewChat] = useState<boolean>(false)
  const [isUpdatingLastViewedDate, setIsUpdatingLastViewedDate] = useState<boolean>(false)

  const discussion = useSelector((state:any)=>selectSingleDiscussion(state,discussionId))
  const userMetaData = useSelector(selectUserMetadata)
  const dispatch = useDispatch()

  

  useEffect(() => {
    const validDiscussion = async () =>{
      setLoading(true)
      const docRef = doc(db, 'discussions',discussionId) ;
      try{
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
          setNewChat(false)
          const discussionDoc = docSnap.data()
          const{Data} = discussionDoc
          if (Data.MembersIds.includes(userMetaData?.Id)){
            addMessageListener(discussionId)
          }
        }
      }
      catch(err){
        setNewChat(true)
      }
      setLoading(false)
    }

    validDiscussion()

    return ()=>{
      if(!newChat){
        stopListeningMessages(discussionId);
      }
      clearQuote();
    }
  }, [discussionId,newChat, userMetaData])
  

  useEffect(() => {
    if (discussion?.Data){
      const {Data} = discussion
      const {MembersIds} = Data
      const userId = userMetaData?.Id
      const isMemberOfDiscussion = MembersIds.includes(userId)
      if (
        userId&&
        isMemberOfDiscussion &&
        !isUpdatingLastViewedDate &&
        discussion?.Data?.LastMessageData?.Author?.Id !== userId &&
        discussion?.LastActivityDate?.toDate() >
          (discussion?.Data?.LastViewedDates?.[userId] === void 0
            ? 0
            : discussion?.Data?.LastViewedDates?.[userId]?.toDate())
      ){
        setIsUpdatingLastViewedDate(true);
        setLastViewedDateForDiscussion(discussionId).then(() =>
          setIsUpdatingLastViewedDate(false),
        );
      }
    }
  }, [JSON.stringify(discussion)])
  
  
  const clearQuote = () =>{
    setReply(false);
  }
  const clearEdit = ()=>{
    setMessageToEdit(null)
  }
  const editedData = useMemo(() => {
    if (!messageToEdit) {
      return null;
    }

    return {
      body: messageToEdit.Data?.Text,
      userMentions: messageToEdit.Data?.UserMentions,
      indexMentions: messageToEdit.Data?.IndexMentions,
      attachments: messageToEdit.Data?.Images || [],
      audio: messageToEdit.Data?.Audio || [],
      quotedMessage: messageToEdit.Data?.QuotedMessage,
    };
  }, [messageToEdit]);

  

  const discussionMessages = useMemo(() => {
    const sendMessage = async(message:any)=>{
      if (!message && MODE_DEBUG){
        console.warn(`sendMessage was called but message:${message} is falsy`)
        return
      }
      let createdDiscussionId
      if(newChat){
        createdDiscussionId = await createDiscussion({userIds: [...openDiscussion?.userId]});
        if(createdDiscussionId){
          setNewChat(false)
          addMessageListener(createdDiscussionId)
          dispatch(setOpenDiscussion({discussionId:createdDiscussionId}))
        }
      }
      await createMessage(createdDiscussionId??discussionId, message);
      return true
    }
  
  
    const editMessage = async(message:any) => {
      if (!message) {
        if (MODE_DEBUG) {
          console.warn(
            "editMessage was called but message: "+message+"is falsy",
          );
        }
        return;
      }
    
      const success = await updateMessage(
        discussionId,
        messageToEdit?.Id,
        message,
      );
      if (success) {
        clearEdit()
        return true;
      } else {
        //TODO: Implement Toast to return error
        if(MODE_DEBUG){
          console.log("Edit is unsuccessfull")
        }
      }
    }
    return <Col className='chatbox-wrapper jcfe'>
              <MessageDisplay newChat={newChat} setEdit={setMessageToEdit} setReply={setReply} discussionId={discussionId}/>
              <ChatInput editedData={editedData} clearEdit={clearEdit} clearQuote ={clearQuote} reply = {reply} discussionId={discussionId} onSubmit = {messageToEdit? editMessage:sendMessage}/>
            </Col>

  }, [newChat,discussionId,editedData, messageToEdit,reply,openDiscussion?.userId])
                    

  const screenType = useCallback(() => {
    if (discussion){
      const {Data} = discussion
      const {MembersIds} = Data
      if (!newChat&&!MembersIds?.includes(userMetaData?.Id)){
        return <InviteScreen discussionId={discussionId} userId={userMetaData?.Id} discussion ={discussion}/>
      }
    }
    return discussionMessages
  }, [discussion, discussionId, discussionMessages,userMetaData?.Id, newChat])
  return (
    loading?<Spinner/>:
    screenType()
  )
}

export default ChatBox