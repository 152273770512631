import {FC, useMemo, useState} from "react";
import {Col, Row} from "../../../../../core/Container";
import {PostType} from "../../../../../../types/post.types";
import "./PostLastUpdate.scss";
import moment from "moment";
import {Link} from "react-router-dom";
import {postPagePath} from "../../../../../../routes/Routes";
import {useTranslation} from "react-i18next";
import {getCanTranslate, getTranslatedText} from "../../../../../../helpers/idea/idea.helpers";
import {Button} from "../../../../../core/Button";

const PostLastUpdate: FC<{ lastUpdate?: PostType["LastUpdate"], postId?: string }> = ({lastUpdate, postId}) => {

    const {t, i18n} = useTranslation();
    const [shouldShowOriginalText, setShouldShowOriginalText] = useState<boolean>(false);

    const updateText = useMemo(() => {
        if (lastUpdate) {

            let translatedText = lastUpdate.Text;
            if (lastUpdate?.TextLocalizations && !shouldShowOriginalText) {
                translatedText = getTranslatedText(lastUpdate.TextLocalizations, i18n.language);
            }
            const canTranslate = getCanTranslate(lastUpdate.Text, lastUpdate.TextLocalizations, i18n.language);

            return (
                <Col className="aifs wb-100">
                    <p>{translatedText ?? lastUpdate.Text}</p>
                    {canTranslate && <Button className="text-btn show-original-text-btn" onClick={() => {
                        setShouldShowOriginalText(oldState => !oldState);
                    }}>
                        <i>{shouldShowOriginalText ? "Translate" : "Translated content - show original?"}</i>
                    </Button>}
                </Col>
            );
        }

    }, [i18n.language, lastUpdate, shouldShowOriginalText])

    return (
        <Row className="aifs jcfs last-update">
            <Col className="jcsb aifs lu-content-wrapper">
                <Col className="aifs lu-content">
                    <Link to={`/${postPagePath}/${postId ?? ""}`}>
                        {lastUpdate?.Date &&
                            <p className="lu-time-ago">{t<any>('post.updated')} {moment(lastUpdate?.Date).fromNow()}:</p>}
                    </Link>
                    {updateText}
                </Col>
            </Col>
        </Row>
    )
}

export default PostLastUpdate;