import clsx from "clsx";
import React from "react";
import {Spinner} from "./Loading";

export const Button = ({
                           children,
                           className,
                           onClick,
                           type = 'button',
                           disabled = false,
                           form,
                           id,
                           isLoading
                       }: {
    children: any;
    className?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    onClick?: (event: React.MouseEvent) => void;
    form?: string,
    id?: string,
    isLoading?: boolean,
}) => (
    <button id={id} className={clsx(className)} onClick={onClick} disabled={disabled} type={type} form={form}>
        {isLoading ? <Spinner/> : children}
    </button>
);