import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';
import {UserMetadataType, UserPrivateType, UserProtectedType, WalletType} from "../../../../types/user";
import {UserGroupsType} from "../../../../types/group.types";

const initialState = {
    status: StatusAsync.IDLE,
    data: {
        user: null,
        authPassword: null,
        token: null,
        userClaims: null,
        customData: null,
        emailAuthPass: null,
        lang: null,
        userMetadata: null,
        userProtected: null,
        userPrivate: null,
        userGroups: null,
    },
    error: null,
};

export const selectDataAuth = (state: any) => state.auth.data;
export const selectIsAuth = createSelector([selectDataAuth], (data) => !!(data.user));
export const selectUser = createSelector([selectDataAuth], (data) => (data.user));
export const selectAuthToken = createSelector([selectDataAuth], (data) => (data.token));
export const selectEmailPassAuth = createSelector([selectDataAuth], (data) => (data.emailAuthPass));
export const selectCustomData = createSelector([selectDataAuth], (data) => (data.customData));
export const selectUserMetadata = createSelector<any, UserMetadataType | null>([selectDataAuth], (data: any) => (data.userMetadata));
export const selectUserProtected = createSelector<any, UserProtectedType | null>([selectDataAuth], (data: any) => (data.userProtected));
export const selectUserWallet = createSelector<any, WalletType | null>([selectDataAuth], (data: any) => (data?.userPrivate?.Wallet));
export const selectUserGroups = createSelector<any, UserGroupsType[] | null>([selectDataAuth], (data: any) => (data.userGroups));
export const selectUserRole = createSelector([selectDataAuth],(data:any) => (data?.userPrivate?.Role))
export const selectUserPrivate = createSelector<any, UserPrivateType | null>([selectDataAuth], (data: any) => (data.userPrivate));

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.status = StatusAsync.PENDING;
        },
        loginSuccess: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.user = action.payload?.user;
        },
        loginDataUpdate: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.user = action.payload?.user;
            state.data.token = action.payload?.token;
        },
        setEmailAuthPass: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.emailAuthPass = action.payload?.emailAuthPass;
        },
        setUserCustomData: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.customData = action.payload?.customData;
        },
        setUserMetadata: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.userMetadata = action.payload?.userMetadata;
        },
        setUserProtected: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.userProtected = action.payload?.userProtected;
        },
        setUserPrivate: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.userPrivate = action.payload?.userPrivate;
        },
        setUserGroups: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.userGroups = action.payload?.userGroups;
        },

    },
});

export const authActions = authSlice.actions;
export const {
    login,
    loginSuccess,
    loginDataUpdate,
    setEmailAuthPass,
    setUserCustomData,
    setUserMetadata,
    setUserProtected,
    setUserGroups,
} = authSlice.actions;

export default authSlice.reducer;
