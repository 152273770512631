import {FC, useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {images} from '../../assets/images/images'
import {dropdownIcon, logout} from '../../assets/images/svgIcons/svgIcons'
import {Col, Container, Row} from '../../components/core/Container'
import {Picture} from '../../components/core/Picture'
import {selectUserMetadata, selectUserWallet} from '../../services/redux/reducers/userAuth/user'
import {menu, dropdownLinks} from '../../utils/constants/menu'
import {Button} from "../../components/core/Button";
import {profilePagePath} from "../../routes/Routes";
import "./Navbar.scss";
import MediaQuery from "react-responsive";
import MobileNavbar from "./MobileNavbar";
import {breakPoints} from "../../utils/constants/ui";
import Avatar from "../../components/core/Shared/Avatar";
import {t} from 'i18next'
import {signOutUser} from '../../services/firebase/auth/auth'
import OutsideAlerter from '../../components/core/OutsideAlerter'
import NotificationDropdown from '../../components/App/Notifications/NotificationDropdown/NotificationDropdown'
import {shortNumberFormat} from '../../utils/helpers'
import {
    selectNewNotificationFlag,
    selectNotifications,
    selectSortedNotificationIds,
    setHasNewPendingNotification
} from '../../services/redux/reducers/notifications/notifications'
import {
    selectChatView,
    selectNewMessagesIndicator,
    setChatView
} from '../../services/redux/reducers/discussion/discussion'
import GlobalSearchInput from "../../components/core/GlobalSearchInput/GlobalSearchInput";
import {setShowCoinsPopup} from "../../services/redux/reducers/general/general";
import {ARYACoinToUSDRation, FIRESTORE_COINS_MULTIPLIER} from "../../utils/constants/prices";
import {scrollToTop} from "../../utils/UI";

const AuthedNavbar: FC = () => {
    const location = useLocation()

    const navigator = useNavigate()
    const dispatch = useDispatch()

    const userMetadata = useSelector(selectUserMetadata)
    const WalletData = useSelector(selectUserWallet)
    const notifications = useSelector(selectNotifications);
    const orderedIdsMostRecentFirst = useSelector(selectSortedNotificationIds)
    const hasNewPendingNotification = useSelector(selectNewNotificationFlag)
    const newChatIndicator = useSelector(selectNewMessagesIndicator);
    const chatIsOpen = useSelector(selectChatView);

    const [balance, setBalance] = useState<string>('');
    const [usdValue, setUSDValue] = useState<string>('');
    const [dropdown, setdropdown] = useState<boolean>(false);
    const [notification, setNotification] = useState<boolean>(false);
    const [notificationCount, setNotificationCount] = useState<string | null>(null);


    useEffect(() => {
        const notificationsNotRead: string[] = orderedIdsMostRecentFirst?.filter(
            (id: any) => !notifications?.[id]?.Read,
        );
        if (notificationsNotRead?.length) {
            setNotificationCount(
                shortNumberFormat(
                    notificationsNotRead.length
                )
            )
        } else {
            dispatch(setHasNewPendingNotification({value: false}));
            setNotificationCount(null)
        }
    }, [notifications, orderedIdsMostRecentFirst, dispatch])


    useEffect(() => {
        if (WalletData) {
            const balance = (WalletData?.Balance ?? 0) / FIRESTORE_COINS_MULTIPLIER
            setBalance(shortNumberFormat(balance))
            setUSDValue(shortNumberFormat(balance * ARYACoinToUSDRation))
        }
    }, [WalletData])

    const openUserProfile = useCallback(() => {
        if (userMetadata?.SystemAlias) {
            navigator(`/${profilePagePath}/${userMetadata.SystemAlias}`);
        } else if (userMetadata?.Id) {
            navigator(`/${profilePagePath}/${userMetadata?.Id}`);
        }
    }, [userMetadata, navigator]);

    const handleClick = (name: any) => {

        switch (name) {
            case "Notifications":
                setNotification(!notification);
                return
            default:
                return
        }
    }
    const handleDropdown = () => {
        setdropdown(!dropdown)
    }
    const hideDropdown = () => {
        setdropdown(false)
    }
    const avatarSize = {
        width: "29px",
        height: "29px"
    }
    return (
        <>
            <MediaQuery minWidth={breakPoints.tablet + 1}>
                <Container className='nv-main'>
                    <Row className='navbar-main'>
                        <Row className='search jcfs'>
                            <Link to='/feed'><Picture className="flex aic jcc navbar-logo" alt='logo'
                                                      src={images.authLogo}/></Link>
                            <GlobalSearchInput/>
                        </Row>
                        <Row className='m-right jcfe'>
                            <Row className='menu jcfe'>
                                {menu.map((menuItem, i) => {
                                    switch (menuItem.id) {
                                        case 'chat':
                                            return <Link to={menuItem.to}
                                                         className="menu-icon"
                                                         key={menuItem.id}
                                                         onClick={() => dispatch(setChatView())}
                                            >
                                                {chatIsOpen ? menuItem.iconSelected : menuItem.icon}
                                                {newChatIndicator && <span
                                                    className='unread-notification-counter'>{newChatIndicator}</span>}
                                            </Link>
                                        case 'notifications':
                                            return <OutsideAlerter key={menuItem.id} fn={() => setNotification(false)}>
                                                <div className='notification-dropdown-wrapper'>
                                                    <Link onClick={() => {
                                                        handleClick(menuItem.name)
                                                    }} className={notification ? 'menu-icon m-active' : 'menu-icon'}
                                                          key={i} to={menuItem.to}>
                                                        {notification ? menuItem.iconSelected : menuItem.icon}
                                                        {hasNewPendingNotification && <span
                                                            className='unread-notification-counter'>{notificationCount}</span>}
                                                    </Link>
                                                    {notification && <NotificationDropdown setView={setNotification}/>}
                                                </div>
                                            </OutsideAlerter>
                                        default:
                                            return (
                                                <Link
                                                    to={menuItem.to}
                                                    className={'menu-icon'}
                                                    key={menuItem.id}
                                                    onClick={scrollToTop}
                                                >{location.pathname === menuItem.to ? menuItem.iconSelected : menuItem.icon}</Link>
                                            )
                                    }
                                })}
                            </Row>
                            <Row className='profile jcfe'>
                                <Col className='avatar'>
                                    <Button className='text-btn' onClick={openUserProfile}>
                                        <Avatar height={avatarSize.height} width={avatarSize.width}
                                                id={userMetadata?.Id}/>
                                    </Button>
                                </Col>
                                <Col className='p-infos aifs'>
                                    <Button className='text-btn' onClick={openUserProfile}>
                                        <h3>{userMetadata?.DisplayName ? userMetadata?.DisplayName : ''}</h3>
                                    </Button>
                                    <p>{balance} C</p>
                                </Col>
                                <OutsideAlerter fn={hideDropdown}>
                                    <Col className='nav-dropdown-wrapper'>
                                        <button onClick={() => handleDropdown()}
                                                className='dropdown-button'>{dropdownIcon}</button>
                                        {/* TODO: Translations */}
                                        {
                                            dropdown &&
                                            <Col className='nav-dropdown jcfs'>
                                                <Row className='balance-widget jcsb wb-100'>
                                                    <p className='wallet-text'>ARYA coin balance</p>
                                                    <Row>
                                                        <p className='coin-value'>{balance + ' C '}</p>
                                                        <p className='fiat-value'>{`(${usdValue} USD)`}</p>
                                                    </Row>
                                                </Row>
                                                <Col className='dropdown-links aifs wb-100'>
                                                    {dropdownLinks.map((link) => {
                                                        if (link.id === 3) {
                                                            return (
                                                                <Button key={link.id}
                                                                        onClick={() => {
                                                                            setdropdown(false);
                                                                            dispatch(setShowCoinsPopup(true));
                                                                        }}
                                                                        className='text-btn dropdown-link'
                                                                >
                                                                    {link.icon}<span>{link.name}</span>
                                                                </Button>
                                                            )
                                                        } else {
                                                            return (
                                                                <Link key={link.id}
                                                                      onClick={() => setdropdown(false)}
                                                                      to={link.to}
                                                                      className='dropdown-link'
                                                                >
                                                                    {link.icon}<span>{link.name}</span>
                                                                </Link>
                                                            )
                                                        }
                                                    })}
                                                    <Button onClick={signOutUser}
                                                            className='text-btn dropdown-link'>{logout}<span>{t<any>('general.signout')}</span></Button>
                                                </Col>
                                            </Col>
                                        }
                                    </Col>
                                </OutsideAlerter>
                            </Row>
                        </Row>

                    </Row>
                </Container>
            </MediaQuery>
            <MediaQuery maxWidth={breakPoints.tablet}>
                <MobileNavbar>
                    <Row className='mobile-nav-left jcfs'>
                        <Link to='/feed' className='mn-logo-link'><Picture alt='logo' src={images.authLogo}/></Link>
                        <GlobalSearchInput/>
                    </Row>
                    <Row>
                        {dropdownIcon}
                    </Row>
                </MobileNavbar>
            </MediaQuery>
        </>
    )
}

export default AuthedNavbar