import {FC, lazy, Suspense, useEffect} from 'react';
import {BrowserRouter, Route, Routes, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {LoadingSpinner} from '../components/core/Loading';
import AuthLayout from '../layout/AuthLayout';
import {auth} from '../services/firebase/auth/auth';
import {useAuthState} from "react-firebase-hooks/auth";
import AuthedNavbar from '../layout/Navbar/AuthedNavbar';
import {useAuth} from '../common/hooks/useAuth';
import {MODE_DEBUG} from '../utils/constants/config';
import ChatWidget from '../components/Chat/ChatWidget/ChatWidget';
import Footer from "../layout/Footer/Footer";
import MediaQuery from "react-responsive";
import {breakPoints} from "../utils/constants/ui";

const LoginPage = lazy(() => import('../pages/auth/Login'));
const ForgotPasswordPage = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('../pages/auth/ResetPassword'));
const SignupPage = lazy(() => import('../pages/auth/Signup'));
const FeedPage = lazy(() => import('../pages/app/feed/Feed'));
const PhoneVerificationPage = lazy(() => import('../pages/app/onboarding/PhoneVerification'));
const UserProfilePage = lazy(() => import('../pages/app/profile/UserProfile'));
const SearchPage = lazy(() => import('../pages/app/SearchPage'));
const MarketPage = lazy(() => import('../pages/app/market/MarketPage'));
const AssetDetailsPage = lazy(() => import('../pages/app/market/AssetDetailsPage'));
const WalletPage = lazy(()=> import('../pages/app/wallet/Wallet'))
const NotificationsPage = lazy(()=>import('../pages/app/notifications/UserNotifications'))
const DiscoverGroupsPage = lazy(() => import('../pages/app/DiscoverGroupsPage'));
const CreateGroupPage = lazy(() => import('../pages/app/CreateGroupPage'));
const GroupPage = lazy(() => import('../pages/app/groups/GroupPage'));
const PostPage = lazy(()=>import('../pages/app/post/Post'))
const CreatePostPage = lazy(()=>import('../pages/app/feed/createPost'))
const SettingsPage = lazy(() => import('../pages/app/settings/Settings'));
const PaymentConfirmationPage = lazy(() => import('../pages/app/paymentConfirmation/PaymentConfirmationPage'));
const SuggestedPeopleFullPage = lazy(() => import('../pages/app/suggestedPeopleFullPage/SuggestedPeopleFullPage'));
const ReferAFriendPage = lazy(() => import('../pages/app/referAFriend/ReferAFriendPage'));
const NotFound = lazy(() => import('../pages/notFound/NotFound'));
const SaveForLaterPage = lazy(() => import('../pages/app/saveForLaterPage/SaveForLaterPage'));

export const profilePagePath = 'u';
export const assetDetailsPath = 'asset';
export const groupPagePath = 'groups';
export const suggestedPeoplePath = 'suggested-people';
export const postPagePath = 'post';

const PrivateRoutes = ({redirectTo = '/login', redirectToVerifyPhone = '/verify-phone'}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const [user, loading,] = useAuthState(auth);

    useEffect(() => {
        if (!loading) {
            if (!user) {
                return navigate(redirectTo);
            }
        }

    }, [loading, user, navigate, redirectTo, redirectToVerifyPhone]);

    if (!loading) {
        return (
            <AuthLayout>
                {location.pathname !== '/verify-phone' && <AuthedNavbar/>}
                <Suspense fallback={<LoadingSpinner/>}>
                    <Outlet/>
                </Suspense>
                {location.pathname !== '/verify-phone' &&
                    <MediaQuery minWidth={breakPoints.tablet + 1}>
                        <ChatWidget/>
                    </MediaQuery>}
                <Footer/>
            </AuthLayout>
        );
    } else {
        return (
            <AuthLayout>
                <Suspense fallback={<LoadingSpinner/>}/>
            </AuthLayout>
        );
    }

}
const PublicRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);
    const {isLoggedIn} = useAuth();

    useEffect(() => {

        if (loading) {
            if (MODE_DEBUG) {
                console.log('loading')
            }
        }
        if (isLoggedIn) return navigate('/feed');

    }, [user, loading, isLoggedIn, location, navigate]);
    if (!loading) {
        return (

            <AuthLayout>
                <Suspense fallback={<LoadingSpinner/>}>
                    <Outlet/>
                </Suspense>
            </AuthLayout>
        );
    } else {
        return (
            <AuthLayout>
                <Suspense fallback={<LoadingSpinner/>}/>
            </AuthLayout>
        );
    }

};

const Routing: FC = () => (

    <BrowserRouter>
        <Routes>
            <Route path='*' element={<NotFound/>}/>
            <Route element={<PublicRoutes/>}>
                <Route index element={<LoginPage/>}/>
                <Route path="signup" element={<SignupPage/>}/>
                <Route path="login" element={<LoginPage/>}/>
                <Route path="forgot-password" element={<ForgotPasswordPage/>}/>
                <Route path="reset-password" element={<ResetPasswordPage/>}/>
            </Route>
            <Route element={<PrivateRoutes/>}>
                <Route path="verify-phone" element={<PhoneVerificationPage/>}/>
                <Route path="feed" element={<FeedPage/>}/>
                <Route path="market" element={<MarketPage/>}/>
                <Route path={assetDetailsPath} element={<AssetDetailsPage/>}/>
                <Route path={`${profilePagePath}/:userAlias`} element={<UserProfilePage/>}/>
                <Route path="search" element={<SearchPage/>}/>
                <Route path="wallet" element={<WalletPage/>}/>
                <Route path="notifications" element={<NotificationsPage/>}/>
                <Route path={groupPagePath} element={<DiscoverGroupsPage/>}/>
                <Route path="creat-group" element={<CreateGroupPage/>}/>
                <Route path="discover-groups" element={<DiscoverGroupsPage/>}/>
                <Route path={`${groupPagePath}/:groupAlias`}>
                    <Route index element={<GroupPage/>}/>
                    <Route path=":paramsTabId" element={<GroupPage/>}/>
                </Route>
                <Route path={`${postPagePath}/:postAuthorId/:postId`} element={<PostPage/>}/>
                <Route path='create-post' element={<CreatePostPage/>}/>
                <Route path="settings" element={<SettingsPage/>}/>
                <Route path="payment-confirmation" element={<PaymentConfirmationPage/>}/>
                <Route path={suggestedPeoplePath} element={<SuggestedPeopleFullPage/>}/>
                <Route path="refer-friend" element={<ReferAFriendPage/>}/>
                <Route path="save-for-later" element={<SaveForLaterPage/>}/>
            </Route>
        </Routes>
    </BrowserRouter>

);

export default Routing
