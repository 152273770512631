import {FC, Suspense} from 'react'
import {Provider} from 'react-redux';
import {LoadingSpinner} from './components/core/Loading';
import Routing from './routes/Routes'
import store from './services/redux/store';
import {ToastContainer} from "react-toastify";
import "./i18n/i18n";
import "react-toastify/dist/ReactToastify.css";

const App: FC = () => {
    return (
        <Suspense fallback={<LoadingSpinner/>}>
            <Provider store={store}>
                <ToastContainer/>
                <Routing/>
            </Provider>
        </Suspense>
    )
}

export default App