import {getOrCreateElementById} from "./htmlElementHelpers";

export const changePageTitle = (newPageTitle?: string) => {
    if (newPageTitle) {
        document.title = `${newPageTitle} - ARYA APP`
    } else {
        document.title = 'ARYA APP'
    }
}

export function scrollToTop() {
    getOrCreateElementById("page-container").scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
    })
}
