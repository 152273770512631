import {
    followIcon,
    pinIcon,
    unpinIcon,
    unfollowIcon,
    greenBellOffIcon,
    greenBellIcon,
    greenFavouriteIcon,
    reportIcon,
    blockIcon,
    editIconGreen,
    deleteIconGreen,
    updateIcon,
} from "../../assets/images/svgIcons/svgIcons";
import {IPriceEvaluations} from "../../types/idea.types";
import axios from "axios";

export const FinancialDataTimeFrames: { [p: string]: "short" | "middle" | "long" } = {
    short: "short",
    middle: "middle",
    long: "long",
}

export const PriceEvaluationTypes: { [p: string]: IPriceEvaluations["Type"] } = {
    entryValue: "EntryValue",
    stopLoss: "StopLoss",
    breakEven: "BreakEven",
    takeProfit1: "TakeProfit_1",
    takeProfit2: "TakeProfit_2",
    takeProfit3: "TakeProfit_3",
}

export enum postOptionConstants {
    removePost = "removePost",
    editPost = "editPost",
    updatePost = "updatePost",
    deletePost = "deletePost",
    followAuthor = "followAuthor",
    unfollowAuthor = "unfollowAuthor",
    turnOffNotification = "turnOffNotification",
    turnOnNotification = "turnOnNotification",
    savePost = "savePost",
    reportPost = "reportPost",
    blockPost = "blockPost",
    pinPost = "pinPost",
    unpinPost = "unpinPost",
    unSavePost = "unSavePost",
}

export const postOptions:any = {
    [postOptionConstants.followAuthor]:{
      icon:followIcon,
      title:'feed.postOptions.follow',
      subTitle:'feed.postOptions.followText'
    },
    [postOptionConstants.unfollowAuthor]:{
      icon:unfollowIcon,
      title: 'feed.postOptions.unfollow',
      subTitle:'feed.postOptions.unfollowText',
    },
    [postOptionConstants.turnOffNotification]:{
      icon:greenBellOffIcon,
      title:'feed.postOptions.disableNotifications',
      subTitle:'feed.postOptions.disableNotificationsText'
    },
    [postOptionConstants.turnOnNotification]:{
      icon:greenBellIcon,
      title:'feed.postOptions.enableNotifications',
      subTitle:'feed.postOptions.enableNotificationsText'
    },
    [postOptionConstants.savePost]:{
      icon:greenFavouriteIcon,
      title:'feed.postOptions.save',
      subTitle: 'feed.postOptions.saveText'
    },
    [postOptionConstants.reportPost]:{
      icon:reportIcon,
      title:'feed.postOptions.report',
      subTitle: 'feed.postOptions.reportText'
    },
    [postOptionConstants.blockPost]:{
      icon:blockIcon,
      title:'feed.postOptions.block',
      subTitle: 'feed.postOptions.blockTest'
    },
    [postOptionConstants.editPost]:{
      icon:editIconGreen,
      title: 'feed.postOptions.edit',
      subTitle: 'feed.postOptions.editText'
    },
    [postOptionConstants.deletePost]:{
      icon:deleteIconGreen,
      title: 'feed.postOptions.delete',
      subTitle: 'feed.postOptions.deleteText'
    },
    [postOptionConstants.updatePost]:{
      icon: updateIcon,
      title: 'feed.postOptions.update',
      subTitle:'feed.postOptions.updateText'
    },
    [postOptionConstants.pinPost]:{
      icon: pinIcon,
      title: 'feed.postOptions.pinPost',
      subTitle:'feed.postOptions.pinPostText'
    },
    [postOptionConstants.unpinPost]:{
      icon: unpinIcon,
      title: 'feed.postOptions.unpinPost',
      subTitle:'feed.postOptions.unpinPostText'
    },
    [postOptionConstants.unSavePost]:{
      icon: deleteIconGreen,
      title: 'feed.postOptions.unSave',
      subTitle:'feed.postOptions.unSaveText'
    },
}

export const ownPostOptions = [postOptionConstants.editPost,postOptionConstants.updatePost,postOptionConstants.deletePost]
export const othersPostOptions = [postOptionConstants.unfollowAuthor,postOptionConstants.followAuthor,postOptionConstants.turnOffNotification,postOptionConstants.turnOnNotification,postOptionConstants.reportPost,postOptionConstants.blockPost]
export const pinPostOption = postOptionConstants.pinPost
export const unpinPostOption = postOptionConstants.unpinPost
export const savePostOption = postOptionConstants.savePost
export const unSavePostOption = postOptionConstants.unSavePost
export const deletePostOption = postOptionConstants.deletePost
export const REACTION_CONTENT_TYPE_SOCIAL_POST:string = 'post';
export const REACTION_CONTENT_TYPE_SOCIAL_COMMENT:string = 'comment';
export const REACTION_CONTENT_TYPE_SOCIAL_COMMENT_REPLY:string = 'commentReply';

export const postsInitialLimit = 10;
export const postsLoadMoreLimit = 10;


export const getVimeoEmbeddedUrl = async (videoUrl: string) => {
    if (videoUrl) {
        const res = await axios.get(`https://vimeo.com/api/oembed.json?url=${videoUrl}`);
        if (res.status === 200) {
            const data = res.data;
            const html = data.html;
            if (html) {
                const temp = document.createElement("div");
                temp.innerHTML = html;
                const attrs = temp?.firstElementChild?.attributes;
                if (attrs) {
                    return attrs?.getNamedItem('src')?.nodeValue;
                }
            }
        }
        return null;
    } else {
        return null;
    }
}
