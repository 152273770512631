import {
    addDoc,
    collection,
    deleteField,
    doc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    Timestamp,
    updateDoc,
    where
} from "firebase/firestore";
import {IComment} from "../../../types/comment.types";
import {IAuthor} from "../../../types/user.types";
import {MODE_DEBUG} from "../../../utils/constants/config";
import {setCommentReactions, setCommentReplyReaction} from "../../redux/reducers/reactions/reactions";
import store from "../../redux/store";
import {db} from "../auth/auth";
import {getCurrentUserDataAsDbType} from "../user/user";
import {IndexMention, UserMention} from "../../../types/common.types";


// ----- Const
export const getCommentCollectionPath = (authorId: IAuthor['Id'], postId: string) =>
    collection(db, 'users', authorId, 'posts', postId, 'comments')

export const commentLimit = 25;

// ----- Create

/**
 * Add a comment document in a specified post
 *
 * Firestore path : /users/{authorId}/posts/{postId}/comments/{commentId}
 *
 */
export async function createComment({
                                        authorId,
                                        postId,
                                        text,
                                        images,
                                        userMentions,
                                        indexMentions,
                                        parentCommentId,
                                    }: any): Promise<boolean> {
    if (
        !authorId ||
        !postId ||
        !(
            text ||
            (images && images.imagesUrl?.length > 0)
        )
    ) {
        if (MODE_DEBUG) {
            console.warn(
                `createComment was called but authorId:${authorId}, postId:${postId} or (text:${text} || imageUrl:${JSON.stringify(
                    images.imagesUrl,
                )}) are falsy`,
            );
        }
        throw new Error('comments.errors.somethingWentWrong')
    }

    const connectedUser = getCurrentUserDataAsDbType();
    if (!connectedUser) {
        if (MODE_DEBUG) {
            console.warn(
                'connectedUser is falsy, yet createComment have been called !',
            );
        }
        throw new Error('comments.errors.loginToPost')
    }

    const Data: IComment['Data'] = {
        Author: connectedUser,
    };

    if (text) {
        Data.Text = text;
        if (userMentions?.length) {
            Data.UserMentions = userMentions;
        }
        if (indexMentions?.length) {
            Data.IndexMentions = indexMentions;
        }
    }

    if (
        images &&
        images.imagesUrl?.length > 0
    ) {
        Data.Images = images.imagesUrl;
    }

    const commentDoc = {
        Date: serverTimestamp(),
        Data,
    };

    if (MODE_DEBUG) {
        console.debug('createComment doc:', JSON.stringify(commentDoc));
    }

    let createCommentPath = getCommentCollectionPath(authorId, postId);

    let path = `/users/${authorId}/posts/${postId}/comments/`;

    if (parentCommentId) {
        createCommentPath = collection(createCommentPath, parentCommentId, 'replies')

        path += `${parentCommentId}/replies/`;
    }

    if (MODE_DEBUG) {
        console.debug(`createComment path: ${path}`);
    }

    const createdComment = await addDoc(createCommentPath, commentDoc);


    if (!createdComment) {
        // It should have thrown
        throw new Error('comments.errors.postError');
    }

    return {
        Id: createdComment.id,
        Date: Timestamp.now(),
        ...commentDoc.Data,
    };
}


// ----- Read

/**
 * Return a list of comments
 *
 * @param authorId the author of the post
 * @param postId
 * @param parentCommentId if any
 * @param before ask for comments created before this date
 * @param after ask for comments created after this date. If specified, **before** is not taken into account
 */
export async function getComments({
                                      authorId,
                                      postId,
                                      parentCommentId,
                                      before,
                                      after,
                                  }: any) {
    if (!authorId || !postId) {
        if (MODE_DEBUG) {
            console.warn(
                `getComments was called but authorId:${authorId} or postId:${postId} are falsy`,
            );
        }
        return;
    }

    // By default, fetch all comments until now
    before = before || Timestamp.now();

    let getCommentsPath = getCommentCollectionPath(authorId, postId);
    let path = `/users/${authorId}/posts/${postId}/comments/`;

    if (parentCommentId) {
        getCommentsPath = collection(getCommentsPath, parentCommentId, 'replies')

        path += `${parentCommentId}/replies/`;
    }

    if (MODE_DEBUG) {
        console.debug(`getComments path: ${path}`);
    }

    const commentsQuery = query(getCommentsPath, where('Date', after ? '>=' : '<', after || before), orderBy('Date', 'desc'), limit(commentLimit))
    // The comments are sorted by desc here since we want to return the X newest comment
    const commentsDocs = await getDocs(commentsQuery)

    const comments: IComment[] = [];
    commentsDocs.forEach(doc => {
        const comment = doc.data();
        if (parentCommentId) {
            handleIncomingCommentReplyDoc(doc, comment);
        } else {
            handleIncomingCommentDoc(doc, comment);
        }

        comments.push({
            Id: doc.id,
            Date: comment.Date,
            ...comment.Data,
        });
    });

    // Sort by oldest to newest
    return comments.sort((a: any, b: any) => b.Date - a.Date);
}


// /**
//  * Get a specified comment
//  *
//  * @param postAuthorId
//  * @param postId
//  * @param parentCommentId
//  * @param commentId
//  */
//  export async function getComment({
//     postAuthorId,
//     postId,
//     parentCommentId,
//     commentId,
//   }:any): Promise<void> {
//     if (!postAuthorId || !postId || !commentId) {
//       if (MODE_DEBUG) {
//         console.warn(
//           `getComment was called but authorId:${postAuthorId}, postId:${postId} or commentId:${commentId} are falsy`,
//         );
//       }
//       return;
//     }

//     let getCommentsPath = getCommentCollectionPath(postAuthorId, postId);
//     let path = `/users/${postAuthorId}/posts/${postId}/comments/`;

//     if (parentCommentId) {
//       getCommentsPath = collection(getCommentsPath,parentCommentId,'replies')

//       path += `${parentCommentId}/replies/`;
//     }

//     const getCommentPath = doc(getCommentsPath,commentId);

//     path += `${commentId}`;

//     if (MODE_DEBUG) {
//       console.debug(`getComment path: ${path}`);
//     }

//     return await getDoc(getCommentPath)
//       .then((doc:any) => {
//         if (doc.exists()) {
//           const comment = doc.data();
//           return {
//             Id: doc.id,
//             Date: comment.Date,
//             ...comment.Data,
//           };
//         } else {
//           return null;
//         }
//       })
//       .catch((error:Error) => {
//         if (MODE_DEBUG) {
//           console.warn('getComment error:', error);
//         }
//         return null;
//       });
//   }


// ----- Update

/**
 * Update a comment Text or images
 *
 * @param text
 * @param userMentions
 * @param indexMentions
 * @param images
 * @param postAuthorId
 * @param postId
 * @param commentId
 * @param parentCommentId
 * @param commentAuthor
 * @returns {Promise<object>} A promise resolving to the updated flatted comment data
 */
export async function updateComment({
                                        text,
                                        userMentions,
                                        indexMentions,
                                        images,
                                        postAuthorId,
                                        postId,
                                        commentId,
                                        parentCommentId,
                                        commentAuthor
                                    }: {
    text: string,
    userMentions: UserMention[],
    indexMentions: IndexMention[],
    images: {
        imagesUrl: string[],
    },
    postAuthorId: string,
    postId: string,
    commentId: string,
    parentCommentId?: string,
    commentAuthor: IComment["Author"]
}) {

    const state = store.getState();
    const {user}: any = state.auth.data;
    const currentUserId = user?.uid;

    const Data: IComment['Data'] = {};

    if (text) {
        Data.Text = text;
        if (userMentions?.length) {
            Data.UserMentions = userMentions;
        }
        if (indexMentions?.length) {
            Data.IndexMentions = indexMentions;
        }
    }

    if (
        images &&
        images.imagesUrl?.length > 0 &&
        images.imagesUrl?.[0]
    ) {
        Data.Images = images.imagesUrl;
    }

    let updateCommentsPath = getCommentCollectionPath(postAuthorId, postId);

    if (parentCommentId) {
        updateCommentsPath = collection(updateCommentsPath, parentCommentId, 'replies')
    }

    return updateDoc(doc(updateCommentsPath, commentId), {
        'Data.Author': commentAuthor,
        'Data.Text': Data.Text || deleteField(),
        'Data.UserMentions': Data.UserMentions || deleteField(),
        'Data.IndexMentions': Data.IndexMentions || deleteField(),
        'Data.Images': Data.Images || deleteField(),
        'Data.ImageSizes': Data.ImageSizes || deleteField(),
        'Data.LastEditDate': serverTimestamp(),
        'Data.LastEditBy': currentUserId,
    });
}


/**
 * Delete a comment by adding a IsDeleted attribute at true
 *
 * @param postAuthorId
 * @param postId
 * @param commentId
 * @param parentCommentId
 * @param date
 * @returns {Promise}
 */

export async function deleteComment({
                                        postAuthorId,
                                        postId,
                                        commentId,
                                        parentCommentId,
                                        date,
                                    }: { postAuthorId: string, postId: string, commentId: string, parentCommentId?: string, date: Date | Timestamp }) {

    if (postAuthorId && postId && commentId && date) {
        const state = store.getState();
        const {user}: any = state.auth.data;
        const currentUserId = user?.uid;

        let deleteCommentsPath = getCommentCollectionPath(postAuthorId, postId);

        if (parentCommentId) {
            deleteCommentsPath = collection(deleteCommentsPath, parentCommentId, 'replies')
        }

        return updateDoc(doc(deleteCommentsPath, commentId), {
            'Data.IsDeleted': true,
            'Data.DeletionDate': serverTimestamp(),
            'Data.DeletedBy': currentUserId,
            CreationDate: (date instanceof Timestamp) ? date : new Date(date),
            Date: deleteField(), // So that it doesn't appear in comment queries
        });
    }
}


// ----- Helper
/**
 * Standard actions dispatched when a comment document is retrieved
 *
 * @param doc
 * @param data
 */
function handleIncomingCommentDoc(doc: any, data: any) {
    // Build the full ID
    let ref = doc.ref,
        fullId = ref.id;
    // Ref is a docreference
    // noinspection JSAssignmentUsedAsCondition
    while ((ref = ref.parent.parent)) {
        // If ref.parent is not a subcollection, ref.parent.parent is null
        fullId = ref.id + '/' + fullId;
    }
    // Yes, that's all I needed to build to full ID. Impressive, isn't it?

    const {Data} = data;

    // Dispatch the users
    // if (Data.Author && data.Date) {
    //     const authorData = {...Data.Author};
    //     console.log(authorData.PictureDate);
    //     console.log(authorData.PictureDate.toDate());
    //     if (authorData.PictureDate && authorData.PictureDate ! instanceof Date) {
    //         authorData.PictureDate = authorData?.PictureDate?.toDate();
    //     }
    //     store.dispatch(setUser({users: [authorData], date: data.Date.toDate()}));
    // }

    // Should never happen, but I like safeguards
    if (!Data || Data.IsDeleted) {
        return;
    }

    store.dispatch(
        setCommentReactions(
            {
                fullId,
                likeCount: Data.ReactionsCount?.Likes || 0,
                replyCount: Data.RepliesCount || 0
            }
        ),
    );
    // Comment reply data
    // if (Data.LastReply) {
    //     const {LastReply} = Data;
    //     if (LastReply.Author && LastReply.Date) {
    //         const authorData = {...LastReply.Author};
    //         if (authorData.PictureDate) {
    //             authorData.PictureDate = authorData.PictureDate.toDate();
    //         }
    //         store.dispatch(
    //             setUser({users: [authorData], date: LastReply.Date.toDate()}),
    //         );
    //     }
    // }
}


/**
 * Standard actions dispatched when a comment reply document is retrieved
 *
 * @param doc
 * @param data
 */
function handleIncomingCommentReplyDoc(doc: any, data: any) {
    // Build the full ID
    let ref = doc.ref,
        fullId = ref.id;
    // Ref is a docreference
    // noinspection JSAssignmentUsedAsCondition
    while ((ref = ref.parent.parent)) {
        // If ref.parent is not a subcollection, ref.parent.parent is null
        fullId = ref.id + '/' + fullId;
    }
    // Yes, that's all I needed to build to full ID. Impressive, isn't it?

    const {Data} = data;

    // Dispatch the users
    // if (Data.Author && data.Date) {
    //     const authorData = {...Data.Author};
    //     if (authorData.PictureDate) {
    //         authorData.PictureDate = authorData.PictureDate.toDate();
    //     }
    //
    //     store.dispatch(setUser({users: [authorData], date: data.Date.toDate()}));
    // }

    // Should never happen, but I like safeguards
    if (!Data || Data.IsDeleted) {
        return;
    }
    store.dispatch(
        setCommentReplyReaction({fullId, likeCount: Data.ReactionsCount?.Likes || 0}),
    );
}